import { Paper, Divider, Stack, Group, Text } from '@mantine/core';
import { Address, AddressAddressTypeEnum, Contact, Phone, PhonePhoneTypeEnum } from '../../api/generated';
import { IconPhone, IconBuildingSkyscraper, IconDeviceMobile, IconUser, IconPrinter } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';

import classes from './XeroContactRecord.module.css';

// todo centralize this, its used in ContactRow also
const getPrintedPhone = (phone: Phone) => {
  return [phone.phoneAreaCode, phone.phoneNumber].filter(v => !!v).join(' ');
}


export function XeroContactRecord({ contact }: { contact: Contact }) {

  const primaryPersonName = (contact.firstName || '') + (contact.lastName ? ' ' + contact.lastName : '');

  const basicAttributes = {
    'Last updated': dayjs(contact.updatedDateUTC).format('DD/MM/YY HH:mm'),
    'Company reg. number': contact.companyNumber,
    'VAT number': contact.taxNumber,
    'Email address': contact.emailAddress,
    'Website': contact.website,
    'Primary person': primaryPersonName
  };
  
  const phoneCfg = {
    [PhonePhoneTypeEnum.Default]: { idx: 1, icon: <IconPhone className={classes.xeroRecordPhoneIcon} /> },
    [PhonePhoneTypeEnum.Office]: { idx: 2, icon: <IconBuildingSkyscraper className={classes.xeroRecordPhoneIcon} /> },
    [PhonePhoneTypeEnum.Mobile]: { idx: 3, icon: <IconDeviceMobile className={classes.xeroRecordPhoneIcon} /> },
    [PhonePhoneTypeEnum.Ddi]: { idx: 4, icon: <IconUser className={classes.xeroRecordPhoneIcon} /> },
    [PhonePhoneTypeEnum.Fax]: { idx: 5, icon: <IconPrinter className={classes.xeroRecordPhoneIcon} /> }
  };

  const phoneContent = sortBy(contact.phones?.filter(p => !!p.phoneNumber), p => phoneCfg[p.phoneType!].idx).map(
    phone => (
      <div key={phone.phoneNumber} className={classes.xeroRecordPhoneLine}>
        <span>{phoneCfg[phone.phoneType!].icon}</span>
        <span>{getPrintedPhone(phone)}</span>
      </div>
    )
  );

  const billingAddress = contact.addresses?.filter(a => !!a.addressLine1).find(a => a.addressType === AddressAddressTypeEnum.Pobox);
  const deliveryAddress = contact.addresses?.filter(a => !!a.addressLine1).find(a => a.addressType === AddressAddressTypeEnum.Street);

  const renderAddress = (address: Address) => (
    <>
      {address.attentionTo && <div>{address.attentionTo}</div>}
      {address.addressLine1 && <div>{address.addressLine1}</div>}
      {address.addressLine2 && <div>{address.addressLine2}</div>}
      {address.addressLine3 && <div>{address.addressLine3}</div>}
      {address.addressLine4 && <div>{address.addressLine4}</div>}
      {address.city && <div>{address.city}</div>}
      {address.region && <div>{address.region}</div>}
      {address.postalCode && <div>{address.postalCode}</div>}
      {address.country && <div>{address.country}</div>}
    </>
  )

  return (
    <Paper
          className={classes.xeroRecordPaper}
          shadow="xs"
          radius="md"
        >

          <div className={classes.xeroRecordTitle}>
            <Text fz="lg" fw={500}>Xero Record</Text>
            <Divider mt="xs" />
          </div>


          <div className={classes.xeroRecordGrid}>
            <Stack className={classes.xeroRecordStack}>
              {Object.entries(basicAttributes).map(([label, value]) => (
                <Group key={label} className={classes.xeroRecordGroup}>
                  <div className={classes.xeroRecordLabel}>{label}</div>
                  <div className={classes.xeroRecordValue}>{value}</div>
                </Group>
              )
              )}
            </Stack>
            <Stack className={classes.xeroRecordStack}>
              <Group className={classes.xeroRecordGroup}>
                <div className={classes.xeroRecordLabel}>Phone number</div>
                <div className={classes.xeroRecordValue}>
                  {phoneContent}
                </div>
              </Group>
              <Group className={classes.xeroRecordGroup}>
                <div className={classes.xeroRecordLabel}>Addresses</div>
                <div className={classes.xeroRecordValue}>
                  <div>
                    {billingAddress && (
                    <>
                      <div>
                        <Text fw={700} tt="uppercase" c="dimmed" fz="xs">Billing</Text>
                      </div>
                      {renderAddress(billingAddress)}
                    </>
                  )}
                  </div>
                  <div>
                  {deliveryAddress && (
                    <>
                      <div>
                        <Text fw={700} tt="uppercase" c="dimmed" fz="xs">Delivery</Text>
                      </div>
                      {renderAddress(deliveryAddress)}
                    </>
                  )}
                  </div>
                </div>
              </Group>
            </Stack>
          </div>


        </Paper>
  );
}