import { Button, rem, Table } from '@mantine/core';
import { PracticeUserInvite, User } from '../../../api/generated';
import { isPracticeUserInvite, showGeneralErrorNotification } from '../../../lib/helpers';
import { formatDateShort } from '../../../lib/formatters';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MutationKey, QueryKey } from '../../../queryKeys';
import { apiClient } from '../../../api/apiClient';
import { notifications } from '@mantine/notifications';


export function ColleagueRow({ practiceUserOrInvite }: { practiceUserOrInvite: User | PracticeUserInvite }) {

  const practiceUser = !isPracticeUserInvite(practiceUserOrInvite) ? practiceUserOrInvite : undefined;
  const practiceUserInvite = isPracticeUserInvite(practiceUserOrInvite) ? practiceUserOrInvite : undefined;
  
  const queryClient = useQueryClient();

  const { mutate: resendPracticeUserInvite, isPending: resendPracticeUserInviteIsPending } = useMutation({
    mutationKey: [MutationKey.ResendPracticeUserInvite, practiceUserInvite?.id],
    mutationFn: async () => {
      const res = await apiClient.practice.resendPracticeUserInvite(practiceUserInvite!.practiceId, practiceUserInvite!.id);
      return res.data;
    },
    onError: (error: any) => {
      if (error.response.data.code === 'COOLDOWN') {
        notifications.show({
          title: 'Invite not resent',
          message: 'You need to wait a few minutes before resending again',
          color: 'red'        
        });
      } else {
        showGeneralErrorNotification();
      }
    },
    onSuccess: () => {
      notifications.show({
        title: 'Invite resent',
        message: 'The invite has been resent',
        radius: 'md',
        color: 'green'
      });
      queryClient.invalidateQueries({ queryKey: [QueryKey.GetPracticeUserInvites, practiceUserInvite!.practiceId] });            
    }
  });


  return (
    <Table.Tr>
      <Table.Td>{practiceUser ? practiceUser.firstName + ' ' + practiceUser.lastName : practiceUserInvite?.recipientFirstName + ' ' + practiceUserInvite?.recipientLastName}</Table.Td>
      <Table.Td>{practiceUser?.email || practiceUserInvite?.recipientEmail}</Table.Td>
      <Table.Td>{practiceUser?.practiceRole || ''}</Table.Td>
      <Table.Td>{practiceUser ? 'Active' : `Invited ${formatDateShort(practiceUserInvite!.resent || practiceUserInvite!.created)}`}</Table.Td>
      <Table.Td w={rem(25)}>{practiceUserInvite ? (<Button variant="transparent" onClick={() => resendPracticeUserInvite()} loading={resendPracticeUserInviteIsPending}>Resend</Button>) : undefined}</Table.Td>
    </Table.Tr>
  );
}
