
export function getCompaniesHouseCompanyProfileHref(companyNumber: string) {
  return `https://find-and-update.company-information.service.gov.uk/company/${companyNumber}`;
}

export function getCompaniesHouseCompanyOfficersHref(companyNumber: string) {
  return `https://find-and-update.company-information.service.gov.uk/company/${companyNumber}/officers`;
}

const XERO_APP_ID =  process.env.REACT_APP_XERO_APP_ID || 'c8d54020-6f79-403b-a5f4-cc14aaf3c12e';

export function getXeroAppStoreChoosePlanHref(orgShortCode?: string) {
  return `https://apps.xero.com/${orgShortCode}/subscribe/${XERO_APP_ID}`;
}

export function getXeroAppStoreManageSubscriptionHref(orgShortCode?: string) {
  return `https://apps.xero.com/${orgShortCode}/my-apps/${XERO_APP_ID}`;  
}
