import { Group, Badge, Text } from '@mantine/core';
import { IconFlagCheck, IconFlagExclamation, IconMail, IconMailOpened, IconUserCheck } from '@tabler/icons-react';
import { formatDateTimeDynamic } from '../../lib/formatters';
import { Message, MessageSeverityEnum } from '../../api/generated';

import classes from './MessageListItem.module.css';
import { useDisclosure } from '@mantine/hooks';
import { MessageModal } from './MessageModal';

const getBadgePropsForMessage = (message: Message) => {
  switch (message.severity) {
    case MessageSeverityEnum.Critical:
      return { variant: message.resolved ? 'light' : 'filled', color: 'red.7', children: [message.resolved ? (message.resolvedByUser ? 'Dismissed' : 'Resolved') : 'Critical'] };
    case MessageSeverityEnum.Important:
      return { color: 'orange.5', children: ['Important'] };
    case MessageSeverityEnum.Advisory:
      return { color: 'blue', children: ['Advisory'] };
    case MessageSeverityEnum.General:
      return { variant: 'light', color: 'gray.8', children: ['General'] };
  }
}


const getIconForMessage = (message: Message) => {
  switch (message.severity) {

    case MessageSeverityEnum.Important:
      return (!message.resolved ? (
        <IconFlagExclamation color='var(--mantine-color-orange-5)' />
      ) :
        message.resolvedByUser ? (
          <IconUserCheck stroke={1} color='var(--mantine-color-orange-5)' />
        ) : (
          <IconFlagCheck stroke={1} color='var(--mantine-color-orange-5)' />
        )
      );
    case MessageSeverityEnum.Critical:
      return (
        !message.resolved ? (
          <IconFlagExclamation color='var(--mantine-color-red-7)' />
        ) :
          message.resolvedByUser ? (
            <IconUserCheck stroke={1} color='var(--mantine-color-red-7)' />
          ) : (
            <IconFlagCheck stroke={1} color='var(--mantine-color-red-7)' />
          )
      );
    // case MessageSeverityEnum.Advisory:
    //   return <IconInfoCircle color='var(--mantine-color-blue-6)' />;
  }
}

export function MessageListItem({ message }: { message: Message }) {
  
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);

  return (
    <>     
      <MessageModal 
        opened={modalOpened} 
        onClose={closeModal} 
        message={message}
      />
      <div className={classes.root} data-unread={!message.read || undefined} onClick={openModal}>
        <Group wrap="nowrap" className={classes.iconContainer}>
          {!message.read ? <IconMail /> : <IconMailOpened stroke={1} />}
          {getIconForMessage(message)}
        </Group>
        <div>
          <Group wrap="nowrap">
            <Badge w="100px" {...getBadgePropsForMessage(message)} />
            <Text data-unread={!message.read || undefined} className={classes.heading}>
              {message.heading}
            </Text>
          </Group>
        </div>
        <div className={classes.preheadingContainer}>
          <Text data-unread={!message.read || undefined} className={classes.preheading} lineClamp={1}>
            {message.contentPreheading ?? (message.contentBody && message.contentBody[0])}
          </Text>
        </div>
        <div>
          <Text data-unread={!message.read || undefined} className={classes.date}>
            {formatDateTimeDynamic(message.created)}
          </Text>
        </div>
      </div>
    </>
  );


}