import { Invoice, InvoiceAnalysisStateConclusion, InvoiceAnalysisStateConclusionTypeEnum } from '../../api/generated';
import { formatNounForInvoiceType, formatNounForNormalizedDocument } from '../formatters';


export function getTitleForInvoiceAnalysisConclusion(invoice: Invoice, conclusion: InvoiceAnalysisStateConclusion) {
  const noun = formatNounForInvoiceType(invoice.type!, false);
  switch (conclusion.type) {
    case InvoiceAnalysisStateConclusionTypeEnum.DocumentIsOutlier:
      return `This ${noun} is a statistical outlier`;
    case InvoiceAnalysisStateConclusionTypeEnum.LineItemIsOutlier:
      return `Line item #${(conclusion.data as any).lineItem.ordinal + 1} is a statistical outlier`;    
    case InvoiceAnalysisStateConclusionTypeEnum.PossibleDuplicate:      
      const otherDocumentDescriptor = `another ${formatNounForNormalizedDocument((conclusion.data as any).duplicate.item)}`;
      return `This ${noun} is a possible duplicate of ${otherDocumentDescriptor}`;
    case InvoiceAnalysisStateConclusionTypeEnum.BankDetailsChanged:
      return `Bank details for this ${noun} have changed`;
    default: 
      return `Unknown`;     
  }
}
