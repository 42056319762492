import { Text, Button, Container, Group, Stack, Stepper, TextInput, Select, Box, Center, List } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { useState } from 'react';
import { MutationKey, QueryKey } from '../../../queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from '../../../api/apiClient';
import { notifications } from '@mantine/notifications';
import { ColleagueAccessTable } from './ColleagueAccessTable';
import { Practice } from '../../../api/generated';
import { ConnectXeroButton } from '../../ConnectXeroButton';
import { showGeneralErrorNotification } from '../../../lib/helpers';

interface CreatePracticeClientFormValues {
  name: string;
  practiceReference?: string;
  industry?: string;
}

export function CreatePracticeClientSteps({ practice, onClose }: { practice: Practice, onClose?: () => void }) {

  const [activeStep, setActiveStep] = useState(0);

  const prevStep = () => {
    setActiveStep(s => s - 1);
  };

  const nextStep = () => {
    setActiveStep(s => s + 1);
  };

  const queryClient = useQueryClient();

  const { mutate: createPracticeClient, isPending: createPracticeClientIsPending, data: createdPracticeClient } = useMutation({
    mutationKey: [MutationKey.CreatePracticeClient, practice.id],
    mutationFn: async (data: CreatePracticeClientFormValues) => {
      // todo handle dupe client name
      const res = await apiClient.practice.createPracticeClient(practice.id, data);
      return res.data;
    },
    onError: () => {
      showGeneralErrorNotification();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.GetClients, practice.id] });
      nextStep();
    }
  });

  const createPracticeClientForm = useForm<CreatePracticeClientFormValues>({
    mode: 'uncontrolled',
    validate: {
      name: isNotEmpty('Name is required')
    }
  });



  return (
    <Container pt="lg" px="lg">
      <Stepper active={activeStep} allowNextStepsSelect={false} size="sm" iconSize={28}>
        <Stepper.Step label="Details">

          <form onSubmit={createPracticeClientForm.onSubmit((values) => createPracticeClient(values))}>
            <Stack p="lg">
              <TextInput
                label="Name"
                placeholder="Client name"
                description="Company or sole trader name"
                required
                key={createPracticeClientForm.key('name')}
                {...createPracticeClientForm.getInputProps('name')}
              />
              <TextInput
                label="Practice reference"
                placeholder="Practice reference"
                description="Optional for internal use"
                key={createPracticeClientForm.key('practiceReference')}
                {...createPracticeClientForm.getInputProps('practiceReference')}
              />

              <Select
                data={[
                  { label: 'Agriculture, Forestry and Fishing', value: 'Agriculture, Forestry and Fishing' },
                  { label: 'Mining and Quarrying', value: 'Mining and Quarrying' },
                  { label: 'Manufacturing', value: 'Manufacturing' },
                  { label: 'Electricity, Gas, Steam and Air Conditioning Supply', value: 'Electricity, Gas, Steam and Air Conditioning Supply' },
                  { label: 'Water Supply; Sewerage, Waste Management and Remediation Activities', value: 'Water Supply; Sewerage, Waste Management and Remediation Activities' },
                  { label: 'Construction', value: 'Construction' },
                  { label: 'Wholesale and Retail Trade; Repair of Motor Vehicles and Motorcycles', value: 'Wholesale and Retail Trade; Repair of Motor Vehicles and Motorcycles' },
                  { label: 'Transportation and Storage', value: 'Transportation and Storage' },
                  { label: 'Accommodation and Food Service Activities', value: 'Accommodation and Food Service Activities' },
                  { label: 'Information and Communication', value: 'Information and Communication' },
                  { label: 'Financial and Insurance Activities', value: 'Financial and Insurance Activities' },
                  { label: 'Real Estate Activities', value: 'Real Estate Activities' },
                  { label: 'Professional, Scientific and Technical Activities', value: 'Professional, Scientific and Technical Activities' },
                  { label: 'Administrative and Support Service Activities', value: 'Administrative and Support Service Activities' },
                  { label: 'Public Administration and Defence; Compulsory Social Security', value: 'Public Administration and Defence; Compulsory Social Security' },
                  { label: 'Education', value: 'Education' },
                  { label: 'Human Health and Social Work Activities', value: 'Human Health and Social Work Activities' },
                  { label: 'Arts, Entertainment and Recreation', value: 'Arts, Entertainment and Recreation' },
                  { label: 'Other Service Activities', value: 'Other Service Activities' }
                ]}
                label="Industry"
                placeholder="Industry"
                description="Optional"
                key={createPracticeClientForm.key('industry')}
                {...createPracticeClientForm.getInputProps('industry')}
              />

              <Group justify="flex-end">
                <Button mt="lg" type="submit" loading={createPracticeClientIsPending}>Continue</Button>
              </Group>
            </Stack>
          </form>

        </Stepper.Step>

        <Stepper.Step label="Colleague Access">
          {practice && createdPracticeClient && (
            <Stack>
              <ColleagueAccessTable practice={practice} clientId={createdPracticeClient.id} isDuringClientCreation />
              <Group justify="flex-end">
                <Button mt="lg" onClick={() => nextStep()}>Continue</Button>
              </Group>
            </Stack>
          )}

        </Stepper.Step>

        <Stepper.Step label="Connect Xero">
          {practice && createdPracticeClient && (
            <Stack>
              <Stack justify="center" flex={1} mih="200px">
                <Center>
                  <ConnectXeroButton type="practice-client" practiceId={createdPracticeClient.practiceId} clientId={createdPracticeClient.id}>Connect Xero</ConnectXeroButton>
                </Center>
                <Text fw={500} ta="center">Connect Xero to enable fraud protection</Text>
                <List type="ordered" withPadding>
                  <List.Item>Click the connect Xero button and you'll be redirected to Xero and prompted to log in.</List.Item>
                  <List.Item>Once logged in, choose an organisation to activate fraud protection.</List.Item>
                </List>
              </Stack>
              <Group justify="space-between">
                <Button variant="default" onClick={() => prevStep()}>Back</Button>
                <Button variant="subtle" onClick={() => onClose && onClose()}>Skip</Button>
              </Group>
            </Stack>
          )}

        </Stepper.Step>

        <Stepper.Step label="Invite Client Users">
          Invite Client
        </Stepper.Step>

        <Stepper.Completed>
          Done
        </Stepper.Completed>

      </Stepper>

    </Container>
  );

}