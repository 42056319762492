
import { Text, Container, Space, Group, Title, Center, Loader } from '@mantine/core';
import { IconArrowNarrowLeft } from '@tabler/icons-react';
import { Link, useParams } from 'react-router-dom';
import { formatBankTransactionTitle, formatDateTimeRelative } from '../../lib/formatters';

import classes from './BankTransactionDetailView.module.css';
import { useContext } from 'react';
import { UserContext } from '../../userContext';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../queryKeys';
import { apiClient } from '../../api/apiClient';
import { UnableToLoadAlert } from '../UnableToLoadAlert';
import { XeroBankTransactionRecord } from './XeroBankTransactionRecord';
import { BankTransactionDetailStatus } from './BankTransactionDetailStatus';
import { BankTransactionDetailAnalysisConclusionList } from './BankTransactionDetailAnalysisConclusionList';
import { BankTransactionDetailAttachments } from './BankTransactionDetailAttachments';

export function BankTransactionDetailView() {


  const { bankTransactionId } = useParams();

  if (!bankTransactionId) throw new Error('invoiceId undefined');

  const { activeClient } = useContext(UserContext);

  const { data: bankTransaction, isPending: bankTransactionIsPending, isError: bankTransactionIsError } = useQuery({
    queryKey: [QueryKey.GetBankTransaction, activeClient?.id, bankTransactionId],
    queryFn: async () => {
      const res = await apiClient.xero.getBankTransaction(bankTransactionId);
      return res.data;
    }
  });



  const { data: bankTransactionAnalysisState, isPending: bankTransactionAnalysisStateIsPending } = useQuery({
    queryKey: [QueryKey.GetInvoiceAnalysisState, bankTransaction?.bankTransactionID],
    queryFn: async () => {
      const res = await apiClient.analysis.getBankTransactionAnalysisStateByXeroBankTransactionId(bankTransaction!.bankTransactionID!);
      return res.data;
    },
    enabled: !!bankTransaction
  });



  const { data: taxRates } = useQuery({
    queryKey: [QueryKey.GetTaxTypes, activeClient?.id],
    queryFn: async () => {
      const res = await apiClient.xero.getTaxRates();
      return res.data;
    }
  });


  const { data: accounts } = useQuery({
    queryKey: [QueryKey.GetAccounts, activeClient?.id],
    queryFn: async () => {
      const res = await apiClient.xero.getAccounts();
      return res.data;
    }
  });

  if (bankTransactionIsPending || bankTransactionAnalysisStateIsPending) return (
    <Center mt={350}><Loader /></Center>
  );

  if (bankTransactionIsError || !bankTransaction) return (
    <Center mt={250}>
      <UnableToLoadAlert />
    </Center>
  );

  return (
    <Container size="xl" py="xl">
      <Link to="..?itemType=transactions" className={classes.backAnchor}>
        <IconArrowNarrowLeft className={classes.backAnchorIcon} />
        Bills & Invoices
      </Link>
      <Space h="sm" />
      <Group p="sm" justify="space-between">
        <div>
          <Title order={2}>{formatBankTransactionTitle(bankTransaction)}</Title>
          {bankTransaction.contact?.name && (
            <Text tt="uppercase" c="dimmed" fw={700} fz="md">{bankTransaction.contact.name}</Text>
          )}
        </div>
        <div>
          <Text fz="md" fw={500} c="dimmed">
            Last analysed
          </Text>
          <Text fz="lg">{bankTransactionAnalysisState?.lastAnalysisJobCompletedAt ? formatDateTimeRelative(bankTransactionAnalysisState?.lastAnalysisJobCompletedAt) : 'Not Analysed'}</Text>
        </div>
      </Group>
      <Text pl="sm" mt="md">
        Here's an in-depth look at what we've discovered about this transaction.
      </Text>
      <Space h="xl" />

      <XeroBankTransactionRecord bankTransaction={bankTransaction} taxRates={taxRates} accounts={accounts} />
      <Space h="xl" />

      <BankTransactionDetailStatus bankTransaction={bankTransaction} bankTransactionAnalysisState={bankTransactionAnalysisState} />
      <Space h="xl" />

      <BankTransactionDetailAttachments bankTransaction={bankTransaction} bankTransactionAnalysisState={bankTransactionAnalysisState} />
      <Space h="xl" />

      <BankTransactionDetailAnalysisConclusionList bankTransaction={bankTransaction} bankTransactionAnalysisState={bankTransactionAnalysisState} />
      <Space h="xl" />

    </Container>
  );
}
