import { Text, Button, Group, Table, Menu, Indicator, Stack } from '@mantine/core';
import { Client } from '../../../api/generated';
import { ConnectXeroButton } from '../../ConnectXeroButton';
import { apiClient } from '../../../api/apiClient';
import { MutationKey, QueryKey } from '../../../queryKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { showGeneralErrorNotification } from '../../../lib/helpers';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../userContext';
import { EditClientModal } from './EditClientModal';
import { EditXeroIntegrationModal } from './EditXeroIntegrationModal';
import { useDisclosure } from '@mantine/hooks';
import pluralize from 'pluralize';



export function ClientRow({ client }: { client: Client }) {

  const { user } = useContext(UserContext);

  const [editClientModalOpened, { open: openEditClientModal, close: closeEditClientModal }] = useDisclosure();
  const [editXeroIntegrationModalOpened, { open: openEditXeroIntegrationModal, close: closeEditXeroIntegrationModal }] = useDisclosure();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { data: xeroTenantConnection, isError: xeroTenantConnectionIsError, isPending: xeroTenantConnectionIsPending } = useQuery({
    queryKey: [QueryKey.GetClientXeroTenantConnection, client.id],
    queryFn: async () => {
      const res = await apiClient.xero.getClientXeroTenantConnection(client.id);
      return res.data;
    }
  });

  const { data: unreadMessagesCount } = useQuery({
    queryKey: [QueryKey.GetMessagesByClient, client!.id],
    queryFn: async () => {
      const res = await apiClient.message.getMessages(0, 0, client!.id);
      return res.data;
    },
    enabled: !!xeroTenantConnection
  });

  const { mutate: setActiveClient, isPending: setActiveClientPending } = useMutation({
    mutationKey: [MutationKey.SetActiveClient, client.id],
    mutationFn: async () => {
      const res = await apiClient.client.setActiveClient({ clientId: client.id });
      return res.data;
    },
    onError: () => {
      showGeneralErrorNotification();
    },
    onSuccess: (activeClient: Client) => {
      queryClient.setQueryData([QueryKey.GetActiveClient, user?.id], activeClient);
      navigate('/');
    }
  });

  const userCanAccessClient = client.practiceUserAccess.some(pua => pua.userId === user?.id && pua.canAccess);

  return (
    <>
      <EditClientModal client={client} opened={editClientModalOpened} onClose={closeEditClientModal} />
      <EditXeroIntegrationModal client={client} opened={editXeroIntegrationModalOpened} onClose={closeEditXeroIntegrationModal} />
      <Table.Tr>
        <Table.Td>
          {client.name}
        </Table.Td>
        <Table.Td>
          {client.practiceReference}
        </Table.Td>
        <Table.Td>
          <Stack>
            {xeroTenantConnection && (
              <Group>
                <Text fz="sm">Connected to {xeroTenantConnection.tenantName}</Text>
              </Group>
            )}
            {unreadMessagesCount && (
              <Group>                
                <Indicator size="8" inline color="gray.7" />
                <Text fz="sm">{unreadMessagesCount.unread} Unread</Text>
                <Indicator size="8" inline color="red.7" />
                <Text fz="sm">{unreadMessagesCount.unreadCritical} Critical</Text>
                <Indicator size="8" inline color="orange.7" />
                <Text fz="sm">{unreadMessagesCount.unreadCritical} Important</Text>
                <Indicator size="8" inline color="blue.6" />
                <Text fz="sm">{unreadMessagesCount.unreadCritical} Advisory</Text>
              </Group>
            )}
          </Stack>
        </Table.Td>
        <Table.Td>
          <Group gap="xl" justify="flex-end">
            {!xeroTenantConnectionIsPending && !xeroTenantConnectionIsError && (
              xeroTenantConnection ? (
                <Button disabled={!userCanAccessClient} variant="transparent" fw={500} loading={setActiveClientPending} onClick={() => setActiveClient()}>Go to Dashboard</Button>
              ) : (
                <ConnectXeroButton disabled={!userCanAccessClient} type="practice-client" variant="outline" practiceId={client.practiceId} clientId={client.id}>Connect Xero</ConnectXeroButton>
              )
            )}
            <Menu shadow="md" width={200} position="bottom-start">
              <Menu.Target>
                <Button variant="default" disabled={!userCanAccessClient}>Actions</Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>{client.name}</Menu.Label>
                <Menu.Item onClick={openEditClientModal}>Edit Client</Menu.Item>
                {xeroTenantConnection && (
                  <Menu.Item onClick={openEditXeroIntegrationModal}>Edit Xero Integration</Menu.Item>
                )}
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Table.Td>
      </Table.Tr>
    </>
  );

}
