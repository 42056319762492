import { InvoiceStatusEnum } from '../../api/generated';

export function getColorForXeroInvoiceStatus(status: InvoiceStatusEnum | undefined) {
  switch (status) {
    case InvoiceStatusEnum.Draft:
      return 'gray';
    default:
      return 'blue';
  }
}
