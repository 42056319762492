import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { UserContext } from '../userContext';
import { Client, User } from '../api/generated';

export function ProtectedRoute({ redirectFn  }: { redirectFn: (user?: User, activeClient?: Client, clients?: Client[], destination?: any) => string | null | undefined }) {

  const location = useLocation();

  const { user, activeClient, clients, isLoading } = useContext(UserContext);  
  if (isLoading) {
    console.log('protected route returning null', location.pathname);
    return null;
  }

  const redirectTo = redirectFn(user, activeClient, clients, location.state?.destination);
  
  if (!redirectTo) {
    console.log('protected route not redirecting', location.pathname);
    return <Outlet />;    
  }

  const destination = location.pathname;

  console.log('protected route redirecting', redirectTo);
  return <Navigate to={redirectTo} state={{ destination }} />;   
}