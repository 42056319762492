import { Text, Container, Space, Title, Paper, Box, TagsInput, Group, Center, Skeleton, Stack, Loader, UnstyledButton, Checkbox, Button, Anchor } from '@mantine/core';
import { useContext } from 'react';
import { UserContext } from '../../userContext';
import { UserList } from './UserList';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MutationKey, QueryKey } from '../../queryKeys';
import { apiClient } from '../../api/apiClient';
import { UnableToLoadAlert } from '../UnableToLoadAlert';
import { notifications } from '@mantine/notifications';

import classes from './UserSettingsView.module.css';
import { SubscriptionManager } from './SubscriptionManager';
import { showGeneralChangesSavedSuccessfullyNotification, showGeneralErrorNotification } from '../../lib/helpers';
import { useDisclosure } from '@mantine/hooks';
import { FraudOutreachEmailPreviewModal } from './FraudOutreachEmailPreviewModal';

export function UserSettingsView() {

  const { activeClient, user } = useContext(UserContext);

  const queryClient = useQueryClient();

  const { data: userAlertingPreferences, isPending: userAlertingPreferencesIsPending, isError: userAlertingPreferencesIsError } = useQuery({
    queryKey: [QueryKey.GetUserAlertingPreferences, activeClient?.id, user?.id],
    queryFn: async () => {
      const res = await apiClient.alertingPreferences.getUserAlertingPreferences();
      return res.data;
    }
  });

  const { mutate: setUserAlertingPreferences } = useMutation({
    mutationKey: [MutationKey.SetUserAlertingPreferences, activeClient?.id, user?.id],
    mutationFn: async (preferences: { messagesMuted?: boolean }) => {
      const res = await apiClient.alertingPreferences.upsertUserAlertingPreferences(preferences);
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKey.GetUserAlertingPreferences, activeClient?.id, user?.id], data);
      notifications.show({
        radius: 'md',
        color: 'green',
        title: `Alerting Preferences updated`,
        message: `Your Alerting Preferences have been updated.`,
      });
    },
    onError: () => {
      notifications.show({
        radius: 'md',
        color: 'red',
        title: `Alerting Preferences could not be updated`,
        message: `Something went wrong updating your Alerting Preferences. Please try again later.`,
      });
    }
  });


  const { data: additionalAlertingContacts, isError: additionalAlertingContactsIsError, isPending: additionalAlertingContactsIsPending } = useQuery({
    queryKey: [QueryKey.GetAdditionalAlertingContacts, activeClient?.id],
    queryFn: async () => {
      const res = await apiClient.alertingPreferences.getAdditionalAlertingContacts();
      return res.data;
    },
    placeholderData: keepPreviousData
  });




  const { mutate: setAdditionalAlertingContacts, isPending: setAdditionalAlertingContactsIsPending } = useMutation({
    mutationKey: [MutationKey.SetAdditionalAlertingContacts, activeClient?.id],
    mutationFn: async (recipientEmails: string[]) => {
      const toAdd = recipientEmails.filter(email => !additionalAlertingContacts?.some(c => c.recipientEmail === email))[0];
      const toRemove = additionalAlertingContacts?.filter(c => !recipientEmails.includes(c.recipientEmail))[0];
      if (toAdd) {
        const res = await apiClient.alertingPreferences.createAdditionalAlertingContact({ recipientEmail: toAdd });
        return res.data;
      }
      if (toRemove) {
        const res = await apiClient.alertingPreferences.deleteAdditionalAlertingContact(toRemove.id);
        return res.data;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.GetAdditionalAlertingContacts]
      });
    },
    onError: (e, recipientEmails) => {
      const toAdd = recipientEmails.filter(email => !additionalAlertingContacts?.some(c => c.recipientEmail === email))[0];
      const toRemove = additionalAlertingContacts?.filter(c => !recipientEmails.includes(c.recipientEmail))[0];
      notifications.show({
        radius: 'md',
        color: 'red',
        title: `Additional Alerting Contact could not be ${toAdd ? 'added' : 'removed'}`,
        message: `${toAdd || toRemove} could not be ${toAdd ? 'added' : 'removed'}. ${toAdd ? `Check this is a valid email address and is not the email address of an existing Xero user.` : `Please try again later.`}`,
      });
    }
  });

  const { data: clientFraudOutreachSetting, isError: clientFraudOutreachSettingIsError, isPending: clientFraudOutreachSettingIsPending } = useQuery({
    queryKey: [QueryKey.GetClientFraudOutreachSetting, activeClient?.id],
    queryFn: async () => {
      const res = await apiClient.fraudOutreach.getClientFraudOutreachSetting(activeClient!.id);
      return res.data;
    },
    placeholderData: keepPreviousData
  });

  const fraudOutreachEnabled = !!clientFraudOutreachSetting?.outreachEnabled;

  const { mutate: upsertClientFraudOutreachSetting, isPending: upsertClientFraudOutreachSettingIsPending } = useMutation({
    mutationKey: [MutationKey.UpsertClientFraudOutreachSetting, activeClient?.id],
    mutationFn: async (outreachEnabled: boolean) => {
      const res = await apiClient.fraudOutreach.upsertClientFraudOutreachSetting(activeClient!.id, { outreachEnabled });
      return res.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData([QueryKey.GetClientFraudOutreachSetting, activeClient?.id], data);
      showGeneralChangesSavedSuccessfullyNotification();
    },
    onError: () => {
      showGeneralErrorNotification();
    }
  });


  const [fraudOutreachEmailPreviewModalOpened, { open: openFraudOutreachEmailPreviewModal, close: closeFraudOutreachEmailPreviewModal }] = useDisclosure(false);


  return (
    <>
    <FraudOutreachEmailPreviewModal opened={fraudOutreachEmailPreviewModalOpened} onClose={closeFraudOutreachEmailPreviewModal} companyName={activeClient?.name || ''} />
    <Container size="xl" py="xl">
      <Title order={2}>Users & Settings</Title>
      <Text mt="md">
        Manage your account details and customize your settings to optimize your Meysey experience.
      </Text>
      <Space h="xl" />
      <Space h="xl" />

      <Paper p="lg" radius="md" shadow="xs">
        <Text fz="lg" fw={500}>{activeClient?.name || 'Organisation'}'s Users</Text>
        {/* <Divider mt="xs" /> */}
        <Text fz="sm">Invite new users from your Xero organisation to use Meysey and manage existing users.</Text>
        <Space h="md" />
        <UserList client={activeClient!} />
      </Paper>

      <Space h="xl" />


      <Paper p="lg" radius="md" shadow="xs">
        <Text fz="lg" fw={500}>Additional Alerting Contacts</Text>
        {/* <Divider mt="xs" /> */}
        <Text fz="sm">Configure additional email addresses we should notify with alerts.</Text>
        <Box p="lg" my="md">
          {additionalAlertingContactsIsError ? (
            <Center mt="lg">
              <UnableToLoadAlert />
            </Center>
          ) : additionalAlertingContactsIsPending ? (
            <Stack flex={1}>
              <Skeleton animate height={8} radius="xl" />
              <Skeleton animate height={8} mt={6} w="70%" radius="xl" />
            </Stack>
          ) : (
            <TagsInput
              maxTags={5}
              label={
                <Group gap="lg">
                  <Text fz="sm" fw={500} pb={5} pl={5}>Enter up to 5 email addresses</Text>
                  {setAdditionalAlertingContactsIsPending && (<Loader mb={3} size={14} />)}
                </Group>
              }
              placeholder="Enter an email address"
              value={additionalAlertingContacts.map(c => c.recipientEmail)}
              onChange={setAdditionalAlertingContacts}
            // rightSection={}                         
            />
          )}
        </Box>
      </Paper>

      <Space h="xl" />


      <Paper p="lg" radius="md" shadow="xs">
        <Text fz="lg" fw={500}>Your Alerting Preferences</Text>
        <Text fz="sm">Set your alerting preferences to ensure you're informed about the aspects that matter most to your business.</Text>
        <Space h="md" />
        <Box p="md">
          {userAlertingPreferencesIsError ? (
            <Center mt="lg">
              <UnableToLoadAlert />
            </Center>
          ) : userAlertingPreferencesIsPending ? (
            <Stack flex={1}>
              <Skeleton animate height={8} radius="xl" />
              <Skeleton animate height={8} mt={6} w="70%" radius="xl" />
            </Stack>
          ) : (
            <UnstyledButton className={classes.muteContainer} onClick={() => setUserAlertingPreferences({ messagesMuted: !userAlertingPreferences?.messagesMuted })}>
              <Checkbox
                size="sm"
                checked={!!userAlertingPreferences?.messagesMuted}
              />
              <div>
                <Text fw={500} mb={7} lh={1}>
                  Mute notifications
                </Text>
                <Text fz="sm" c="dimmed">
                  Temporarily mute all notifications from Meysey.
                </Text>
              </div>
            </UnstyledButton>
          )}
        </Box>


      </Paper>

      <Space h="xl" />
      <Paper p="lg" radius="md" shadow="xs">
        <Text fz="lg" fw={500}>Fraud Prevention Outreach</Text>
        <Text fz="sm">Automatically notify your suppliers and customers of the confidential channels they can use to report suspected wrongdoing.</Text>
        <Space h="md" />

        <Box p="md">

          {clientFraudOutreachSettingIsError ? (
            <Center mt="lg">
              <UnableToLoadAlert />
            </Center>
          ) : clientFraudOutreachSettingIsPending ? (
            <Stack flex={1}>
              <Skeleton animate height={8} radius="xl" />
              <Skeleton animate height={8} mt={6} w="70%" radius="xl" />
            </Stack>
          ) : (
            <Group wrap="nowrap">
              <div>
                {fraudOutreachEnabled ? (
                  <Button size="compact-sm" color="red" loading={upsertClientFraudOutreachSettingIsPending} onClick={() => upsertClientFraudOutreachSetting(false)}>Deactivate</Button>
                ) : (
                  <Button size="compact-sm" color="green" loading={upsertClientFraudOutreachSettingIsPending} onClick={() => upsertClientFraudOutreachSetting(true)}>Activate</Button>
                )}
              </div>
              {fraudOutreachEnabled ? (
                <Text fz="sm">
                  <strong>Fraud outreach is active</strong>. Suppliers and customers will be sent an email the first time they bill or are invoiced (provided an email address can be detected) informing them about Meysey's whistleblowing channels.
                  This email will only be sent once per contact and can be <Anchor inherit onClick={() => openFraudOutreachEmailPreviewModal()}>previewed here</Anchor>.
                </Text>
              ) : (
                <Text fz="sm">
                  Once activated, suppliers and customers will be sent an email next time they bill or are invoiced (provided an email address can be detected) informing them about Meysey's whistleblowing channels.
                  This email will only be sent once per contact and can be <Anchor inherit onClick={() => openFraudOutreachEmailPreviewModal()}>previewed here</Anchor>.
                </Text>
              )}
            </Group>
          )}
        </Box>
      </Paper>

      <Space h="xl" />


      <SubscriptionManager />

      <Space h="xl" />


    </Container>
    </>
  );
}