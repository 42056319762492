import { Table, Loader, Center, Paper, Button, Group, SegmentedControl, Space } from '@mantine/core';
import { apiClient } from '../../api/apiClient';
import { ContactsRow } from './ContactsRow';
import { UnableToLoadAlert } from '../UnableToLoadAlert';
import { QueryKey } from '../../queryKeys';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { UserContext } from '../../userContext';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortBy } from 'lodash';
import React from 'react';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';


export function ContactsTable() {

  const { activeClient } = useContext(UserContext);
  
  const [filter, setFilter] = useState<'all' | 'suppliers' | 'customers'>('all');
  const [page, setPage] = React.useState(1);

  const { data: contacts, isPending, isError, } = useQuery({
    queryKey: [QueryKey.GetContacts, activeClient?.id, filter, page],
    queryFn: async () => {
      const res = await apiClient.xero.getContacts(filter === 'customers' ? true : undefined, filter === 'suppliers' ? true : undefined, page);
      return res.data;
    },
    placeholderData: keepPreviousData
  });


  const { data: nextPageContacts } = useQuery({
    queryKey: [QueryKey.GetContacts, activeClient?.id, filter, page + 1],
    queryFn: async () => {
      const res = await apiClient.xero.getContacts(filter === 'customers' ? true : undefined, filter === 'suppliers' ? true : undefined, page + 1);
      return res.data;
    }
  });

  const navigate = useNavigate();

  if (isPending) return (
    <Center mt={350}><Loader /></Center>
  );

  if (isError) return (
    <Center mt={250}>
      <UnableToLoadAlert />
    </Center>
  );

  const rows = sortBy((contacts || []), c => c.name).map((contact, i) => (
    <ContactsRow key={i} contact={contact} onClick={() => navigate(contact.contactID!)} />
  ));

  return (
    <>
      <SegmentedControl
        size="md"
        value={filter}
        onChange={(value) => setFilter(value as 'all' | 'suppliers' | 'customers')}
        data={[
          { label: 'All Contacts', value: 'all' },
          { label: 'Suppliers', value: 'suppliers' },
          { label: 'Customers', value: 'customers' }
        ]}        
      />
      <Space h="lg" />
      <Paper shadow="xs" radius="md">
        <Table verticalSpacing="md" highlightOnHover>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Paper>
      <Space h="lg" />
      <Group justify="space-between">
        <Button 
          variant="default"
          onClick={() => setPage(page - 1)}
          style={{ visibility: page === 1 ? 'hidden' : 'visible' }}
          leftSection={<IconChevronLeft size={14} />}
        >
          Previous Page
        </Button>
        <Button 
          variant="default"
          onClick={() => setPage(page + 1)}
          style={{ visibility: !nextPageContacts || !nextPageContacts.length ? 'hidden' : 'visible' }}
          rightSection={<IconChevronRight size={14} />}
        >
          Next Page
        </Button>
      </Group>
    </>
  );
}