import { Badge, Group, Text } from '@mantine/core';
import { Contact, ContactAnalysisStateConclusion, SeekCompanyResult } from '../../api/generated';

import { formatDateTimeDynamic } from '../../lib/formatters';
import { IconFlagExclamation, IconInfoCircle } from '@tabler/icons-react';
import { ContactDetailAnalysisConclusionModal } from './ContactDetailAnalysisConclusionModal';
import { useDisclosure } from '@mantine/hooks';
import { getSeverityForContactAnalysisConclusionType } from '../../lib/contactAnalysis/getSeverityForContactAnalysisConclusionType';
import { getTitleForContactAnalysisConclusion } from '../../lib/contactAnalysis/getTitleForContactAnalysisConclusion';

import classes from './ContactDetailAnalysisConclusionListItem.module.css';
import { getDescriptionForContactAnalysisConclusion } from '../../lib/contactAnalysis/getDescriptionForContactAnalysisConclusion';
import { ConclusionSeverity } from '../../lib/conclusionSeverity';

const getBadgePropsForConclusionSeverity = (severity: ConclusionSeverity) => {
  switch (severity) {
    case 'critical':
      return { variant: 'filled', color: 'red.7', children: ['Critical'] };
    case 'important':
      return { color: 'orange.5', children: ['Important'] };
    case 'advisory':
      return { color: 'blue', children: ['Advisory'] };
  }
}

const getIconForSeverity = (severity: ConclusionSeverity) => {
  switch (severity) {
    case 'advisory':
      return <IconInfoCircle color='var(--mantine-color-blue-6)' />;
    case 'important':
      return <IconFlagExclamation color='var(--mantine-color-orange-5)' />;
    case 'critical':
      return <IconFlagExclamation color='var(--mantine-color-red-7)' />;

  }
}


export function ContactDetailAnalysisConclusionListItem({ contact, conclusion, companyData }: { contact: Contact, companyData: SeekCompanyResult, conclusion: ContactAnalysisStateConclusion }) {

  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);

  const conclusionTypeLabel = getTitleForContactAnalysisConclusion(conclusion.type);
  const conclusionSeverity = getSeverityForContactAnalysisConclusionType(conclusion.type);
  
  return (
    <>
      <ContactDetailAnalysisConclusionModal 
        opened={modalOpened} 
        onClose={closeModal} 
        contact={contact}
        conclusion={conclusion}
        companyData={companyData}
      />
      <div className={classes.root} onClick={openModal}>
        <Group wrap="nowrap">
          {getIconForSeverity(conclusionSeverity)}
          <Badge mb={1} w="80px" size="sm" {...getBadgePropsForConclusionSeverity(conclusionSeverity)} />
          <Text className={classes.conclusionTypeLabel}>{conclusionTypeLabel}</Text>
        </Group>
        <div className={classes.conclusionBody}>
          <Text c="dimmed" lineClamp={1}>
            {getDescriptionForContactAnalysisConclusion(conclusion, companyData.companyName)[0]}
          </Text>
        </div>
        <div className={classes.conclusionTimestamp}>
          <Text fz="xs" c="dimmed">First detected</Text>
          <Text fz="sm">{formatDateTimeDynamic(conclusion.created)}</Text>
        </div>
      </div>
    </>
  );

}