import { Title, Text, Button, Container, Group } from '@mantine/core';
import classes from './SomethingWrong.module.css';
import { Link } from 'react-router-dom';

export function SomethingWrong() {
  return (
    <Container className={classes.root}>
      <div className={classes.label}>500</div>
      <Title className={classes.title}>Something went wrong.</Title>
      <Text c="dimmed" size="lg" ta="center" className={classes.description}>
        Something went wrong.
      </Text>
      <Group justify="center">
        <Button to="/" component={Link} variant="subtle" size="md">
          Take me back to home page
        </Button>
      </Group>
    </Container>
  );
}