import { Group, Title, Text, Card, SimpleGrid, Container, rem, useMantineTheme, Anchor, Space } from '@mantine/core';
import { IconFileInvoice, IconPaperclip, IconAddressBook } from '@tabler/icons-react';
import { ConnectXeroButton } from './ConnectXeroButton';


import classes from './ConnectView.module.css';
import { apiClient } from '../api/apiClient';

const mockdata = [
  {
    title: 'Attachments Examination',
    description:
      'Meysey scrutinizes every attachment uploaded during the reconciliation of bank transactions and bills. It looks for inconsistencies, mismatched details, and other indicators of potentially suspicious activity.',
    icon: IconPaperclip,
  },
  {
    title: 'Contacts Analysis',
    description:
      'By evaluating the contacts within Xero, Meysey identifies if any details (like email addresses or phone numbers) have been associated with fraudulent activities in the past, providing an extra layer of security.',
    icon: IconAddressBook,
  },
  {
    title: 'Bill Pre-Payment Scan',
    description:
      `Meysey's proactive approach extends to scrutinizing bills before they even hit your payment queue. By conducting an advanced AI-driven analysis of every bill awaiting payment, Meysey identifies potential overcharges, mismatched details, and other anomalies that might otherwise go unnoticed.`,
    icon: IconFileInvoice,
  },

  // {
  //   title: 'Data Cross-Referencing',
  //   description:
  //     `Meysey is not limited to just examining data within Xero. It compares data from various external sources to verify the authenticity of transaction details. This could involve checking a vendor's details against a global database of verified companies, or verifying transaction details against recognized patterns of fraudulent activity.`,
  //   icon: IconCookie,
  // },

  // {
  //   title: 'Alerts and Notifications',
  //   description:
  //     `If any suspicious activity is detected, Meysey sends real-time alerts to the user, allowing for quick intervention and action.`,
  //   icon: IconCookie,
  // },

  // {
  //   title: 'Integration with Xero',
  //   description:
  //     `Meysey seamlessly integrates with Xero, ensuring that the user experience is not disrupted. This means businesses can continue to use Xero as usual, with the added assurance that their financial data is being monitored for any anomalies.`,
  //   icon: IconCookie,
  // },
];


export function ConnectView() {
  const theme = useMantineTheme();

  const features = mockdata.map((feature) => (
    <Card key={feature.title} shadow="md" radius="md" className={classes.card} padding="xl">
      <feature.icon style={{ width: rem(50), height: rem(50) }} stroke={2} color={theme.colors.blue[6]} />
      <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>
      <Text fz="sm" c="dimmed" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));

  return (
    <Container size="lg" py="xl">

      <Title order={2} className={classes.title} ta="center" mt="sm">
        Fighting fraud for small businesses
      </Title>

      <Text c="dimmed" className={classes.description} ta="center" mt="md">
        Meysey is a friendly guardian for small businesses, harnessing the power of AI to spot potential financial discrepancies.
        Not only does Meysey alert you to potential issues, it also offers easy-to-follow actions to keep your financial journey smooth and trustworthy.
      </Text>
      <SimpleGrid cols={{ base: 1, md: 3 }} spacing="xl" mt={50}  >
        {features}
      </SimpleGrid>
      <Space h="xl"/>
      <Group mt={50} justify='center'>
        <ConnectXeroButton size="lg" type="client">Connect Xero to Begin</ConnectXeroButton>        
      </Group>
      <Space h="xl"/>
      <Text ta="center" fz="sm">
        Already have an account?        
      </Text>
      <Anchor display="block" ta="center" href={apiClient.xeroClientConsentUrl}>
          Sign in with Xero
      </Anchor>
    </Container>
  );
}