import { notifications } from '@mantine/notifications';
import { PracticeUserInvite, User, UserTypeEnum } from '../api/generated';

export function isClientUser(user: User | undefined): boolean {
  return user?.type === UserTypeEnum.Client;
}

export function isPracticeUser(user: User | undefined): boolean {
  return user?.type === UserTypeEnum.Practice;
}

export function showGeneralErrorNotification() {
  notifications.show({
    radius: 'md',
    color: 'red',
    title: 'Error',
    message: 'An error occurred. Please try again later.'
  });
}

export function showGeneralChangesSavedSuccessfullyNotification() {
  notifications.show({
    radius: 'md',
    color: 'green',
    title: `Changes saved`,
    message: `Your changes were saved successfully.`,
  });
}


export function isPracticeUserInvite(obj: any): obj is PracticeUserInvite {
  return !!obj.recipientEmail;
}
