import { Center, Title, Text, Button, Container } from '@mantine/core';
import { IconUserPlus, IconUserSearch } from '@tabler/icons-react';


export function NoClientsHeroUnit({ onAddClick }: { onAddClick: () => void }) {
  return (    
    <Container mt="150px">
      <Center>
          <IconUserSearch size="5rem" stroke={0.8}/>
      </Center>
      <Title mt="md" ta="center" order={3} >No clients yet</Title>
      <Text ta="center" fz="lg" >Add a client to get started</Text>      
      <Center mt="xl">
        <Button leftSection={<IconUserPlus size="22px" />} size="md" onClick={onAddClick}>Add Client</Button>
      </Center>      
    </Container>
  );
}

