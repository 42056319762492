import { Text, Group, Anchor, Stack, Skeleton, Indicator } from '@mantine/core';
import { IconMail } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { QueryKey } from '../../queryKeys';
import { useContext } from 'react';
import { UserContext } from '../../userContext';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from '../../api/apiClient';


export function MessageCentreStatus() {


  const { activeClient } = useContext(UserContext);

  const { data: unreadMessageCounts, isPending: unreadMessageCountsPending } = useQuery({
    queryKey: [QueryKey.GetMessages, activeClient?.id],
    queryFn: async () => {
      const res = await apiClient.message.getMessages(0, 0);
      return res.data;
    }
  });


  return (
    !unreadMessageCounts || unreadMessageCountsPending ? (
      <Skeleton h="1rem" animate />
    ) : (
      <Group  wrap="nowrap">
        <IconMail stroke={1} size="30px" />
        <Stack gap="0">
          <Text fw={500}>
            <Anchor
              inherit
              c="black"
              component={Link}
              to="/message-centre"
            >
              You have {unreadMessageCounts.unread === 0 ? 'no' : unreadMessageCounts.unread} unread messages
            </Anchor>
          </Text>
          <Group ml="sm" gap="xl">
            {
              unreadMessageCounts.unreadCritical > 0 && (
                <Group>
                  <Indicator size="8" inline color="red.7" />
                  <Text fz="sm">{unreadMessageCounts.unreadCritical} Critical</Text>
                </Group>
              )
            }
            {
              unreadMessageCounts.unreadImportant > 0 && (
                <Group>
                  <Indicator size="8" inline color="orange.7" />
                  <Text fz="sm">{unreadMessageCounts.unreadImportant} Important</Text>
                </Group>
              )
            }
            {
              unreadMessageCounts.unreadAdvisory > 0 && (
                <Group>
                  <Indicator size="8" inline color="blue.6" />
                  <Text fz="sm">{unreadMessageCounts.unreadAdvisory} Advisory</Text>
                </Group>
              )
            }
          </Group>
        </Stack>

      </Group>
    )
  );
}