import { Text, Divider, Paper, Accordion, Group } from '@mantine/core';
import { BankTransaction, BankTransactionAnalysisState, BankTransactionAnalysisStateConclusionTypeEnum } from '../../api/generated';
import classes from './InvoiceDetailAttachments.module.css';
import { IconFile } from '@tabler/icons-react';
import { AttachmentDetail } from './AttachmentDetail';


export function BankTransactionDetailAttachments({ bankTransaction, bankTransactionAnalysisState }: { bankTransaction: BankTransaction, bankTransactionAnalysisState: BankTransactionAnalysisState | undefined }) {

  const hasAttachments = !!bankTransactionAnalysisState?.normalizedBankTransaction?.attachments?.length;

  const accordionItems = (bankTransactionAnalysisState?.normalizedBankTransaction?.attachments || []).map(attachment => {
    const extractionConclusion = bankTransactionAnalysisState?.conclusions?.find(c => c.type === BankTransactionAnalysisStateConclusionTypeEnum.AttachmentDataExtracted && (c.meta as any)?.attachmentExtId === attachment.extId);    
    return (
      <Accordion.Item value={attachment.fileName} key={attachment.extId}>
        <Accordion.Control icon={<IconFile />}>
          <Group justify="space-between" pr="lg">
            <Text>{attachment.fileName}</Text>
            {extractionConclusion ? (
              <Text fw={500}>Processed</Text>
            ) : (
              <Text fw={500} c="dimmed">Not Processed</Text>
            )}
          </Group>
        </Accordion.Control>
        <Accordion.Panel>
          <AttachmentDetail attachment={attachment} extractionConclusion={extractionConclusion} />
        </Accordion.Panel>
      </Accordion.Item>
    )
  });

  return (
    <Paper shadow="xs" radius="md" p="lg">
      <Text fz="lg" fw={500}>Attachments</Text>
      <Divider mt="xs" />
      <div className={classes.root}>
        {hasAttachments ? (
          <Accordion defaultValue={bankTransactionAnalysisState?.normalizedBankTransaction?.attachments[0]?.fileName} >
            {accordionItems}
          </Accordion>
        ) : (
          <Text ta="center" c="gray" fz="sm" fw={700} my="xl">No attachments</Text>
        )}
      </div>
    </Paper>
  );
}


