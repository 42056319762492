
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.GOOGLE_IDENTITY_PLATFORM_API_KEY || 'AIzaSyAby7UWCa-zV6iCeD2-IwvAwZioDmKvfpk',
  authDomain: process.env.GOOGLE_IDENTITY_PLATFORM_AUTH_DOMAIN || 'meysey.firebaseapp.com'
};

export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
