import { Modal, Space, Text, Timeline, rem } from '@mantine/core';
import { Invoice, InvoiceAnalysisStateConclusion } from '../../api/generated';

import classes from './InvoiceDetailAnalysisConclusionModal.module.css';
import { IconFlagExclamation, IconInfoCircleFilled } from '@tabler/icons-react';
import { capitalize } from 'lodash';
import React from 'react';
import { formatDateTimeDynamic, formatInvoiceTitle } from '../../lib/formatters';
import { getSeverityForInvoiceAnalysisConclusionType } from '../../lib/invoiceAnalysis/getSeverityForInvoiceAnalysisConclusionType';
import { getTitleForInvoiceAnalysisConclusion } from '../../lib/invoiceAnalysis/getTitleForInvoiceAnalysisConclusion';
import { getDescriptionForInvoiceAnalysisConclusion } from '../../lib/invoiceAnalysis/getDescriptionForInvoiceAnalysisConclusion';
import { getRecommendationForInvoiceAnalysisConclusion } from '../../lib/invoiceAnalysis/getRecommendationForInvoiceAnalysisConclusion';

interface InvoiceDetailAnalysisConclusionModalProps {
  opened: boolean;
  onClose: () => void;
  conclusion: InvoiceAnalysisStateConclusion;
  invoice: Invoice;
}

export function InvoiceDetailAnalysisConclusionModal({ opened, onClose, conclusion, invoice }: InvoiceDetailAnalysisConclusionModalProps) {
  // todo refactor the styles!

  const severity = getSeverityForInvoiceAnalysisConclusionType(conclusion.type);

  let icon: React.ReactNode;

  switch (severity) {
    case 'advisory':
      icon = <IconInfoCircleFilled style={{ width: rem(32), height: rem(32) }} color="var(--mantine-color-blue-6)" />;
      break;
    case 'important':
      icon = <IconFlagExclamation style={{ width: rem(32), height: rem(32) }} color='var(--mantine-color-orange-5)' />;
      break;
    case 'critical':
      icon = <IconFlagExclamation style={{ width: rem(32), height: rem(32) }} color='var(--mantine-color-red-7)' />;
  }


  return (
    <Modal.Root
      opened={opened}
      onClose={onClose}
      size={rem(805)}
      centered
    >
      <Modal.Overlay />
      <Modal.Content radius="md" pt="lg">
        <div className={classes.root}>

          <div className={classes.heading}>
            {icon}
            <Text >{capitalize(severity)} Notice</Text>
            <Space h="md" />
            {/* todo */}
            <Text mt="lg" fz="xl" fw={700}>{formatInvoiceTitle(invoice)}</Text> 
            <Text c="dimmed" fz="sm">{invoice.contact?.name}</Text>
            {/* <Text>{companyData?.companyNumber}</Text> */}
            <Space h="md" />
            <Text fz="xl" mt="md" fw={700} flex={1}>{getTitleForInvoiceAnalysisConclusion(invoice, conclusion)}</Text>
          </div>

          <Space h="lg" />
          <div className={classes.mainSection}>
            <div className={classes.bodyContainer}>
              <div className={classes.conclusionBody}>
                <Text fz="md" fw={500} className={classes.bodyHeading}>Findings</Text>
                {getDescriptionForInvoiceAnalysisConclusion(invoice, conclusion).map((text, index) => (
                  <Text key={index}>{text}</Text>
                ))}
              </div>

              <div className={classes.conclusionBody}>
                <Text fz="md" fw={500} className={classes.bodyHeading}>Recommendation</Text>
                {getRecommendationForInvoiceAnalysisConclusion(invoice, conclusion).map((text, index) => (
                  <Text key={index}>{text}</Text>
                ))}
              </div>
            </div>

            <div className={classes.border} />

            <div className={classes.timelineContainer}>
              <Text fz="md" fw={500} className={classes.bodyHeading} >Timeline</Text>

              <div className={classes.timeline}>
                <Timeline active={2} bulletSize={12} lineWidth={2}>
                  <Timeline.Item title="First detected" fz="sm">
                    <Text size="xs" mt={4}>{formatDateTimeDynamic(conclusion.created)}</Text>
                  </Timeline.Item>

                  <Timeline.Item lineVariant="dotted" title="Checked" fz="sm">
                    <Text size="xs" mt={4}>3 minutes ago</Text>
                  </Timeline.Item>

                  <Timeline.Item fz="sm" title="Monitoring">
                    {/* <Text size="xs" mt={4}>Continuing to monitor</Text> */}
                  </Timeline.Item>
                </Timeline>
              </div>
            </div>

          </div>


        </div>

      </Modal.Content>
    </Modal.Root>
  );
}
