import { Invoice, InvoiceTypeEnum, BankTransaction, BankTransactionTypeEnum } from '../../api/generated';


export function isBill(invoice: Invoice) {
  return invoice.type === InvoiceTypeEnum.Accpay;
}


export function isSpend(bankTransaction: BankTransaction) {
  return bankTransaction.type === BankTransactionTypeEnum.Spend;
}
