import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiClient } from '../../api/apiClient';
import { MutationKey, QueryKey } from '../../queryKeys';
import { UserContext } from '../../userContext';
import { useContext } from 'react';
import { notifications } from '@mantine/notifications';
import { Text, Alert, Anchor, Button, Center, Flex, Group, Paper, Skeleton, Space, Stack, Box } from '@mantine/core';
import { UnableToLoadAlert } from '../UnableToLoadAlert';
import { IconInfoCircle } from '@tabler/icons-react';
import { SubscriptionTypeEnum } from '../../api/generated';
import { formatMeyseySubscriptionPlan, formatDateMedium } from '../../lib/formatters';
import { getXeroAppStoreChoosePlanHref, getXeroAppStoreManageSubscriptionHref } from '../../lib/hrefs';

import classes from './SubscriptionManager.module.css';

export function SubscriptionManager() {

  const { activeClient } = useContext(UserContext);

  const queryClient = useQueryClient();

  const { mutate: disconnectXero, isPending: disconnectXeroIsPending } = useMutation({
    mutationKey: [MutationKey.DisconnectXero],
    mutationFn: async () => {
      const res = await apiClient.xero.disconnectXero();
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.GetTenants]
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.GetActiveTenant]
      });
      notifications.show({
        radius: 'md',
        color: 'green',
        title: `Xero disconnected`,
        message: `Meysey has been disconnected from this Xero organisation.`,
      });
    },
    onError: () => {
      notifications.show({
        radius: 'md',
        color: 'red',
        title: `Xero could not be disconnected`,
        message: `Something went wrong disconnecting Meysey from this Xero organisation. Please try again later.`,
      });
    }
  });

  const { data: clientTenant, isPending: clientTenantIsPending, isError: clientTenantIsError } = useQuery({
    queryKey: [QueryKey.GetClientTenant, activeClient?.id],
    queryFn: async () => {
      const res = await apiClient.xero.getClientTenant();
      return res.data;
    },
    enabled: !!activeClient
  });

  const { data: meyseyPresentSubscription, isPending: meyseyPresentSubscriptionIsPending, isError: meyseyPresentSubscriptionIsError } = useQuery({
    queryKey: [QueryKey.GetPresentSubscription, activeClient?.id],
    queryFn: async () => {
      const res = await apiClient.subscription.getPresentSubscription();
      return res.data;
    }
  });

  const { data: meyseyPendingSubscription, isPending: meyseyPendingSubscriptionIsPending, isError: meyseyPendingSubscriptionIsError } = useQuery({
    queryKey: [QueryKey.GetPendingSubscription, activeClient?.id],
    queryFn: async () => {
      const res = await apiClient.subscription.getPendingSubscription();
      return res.data;
    }
  });

  const { data: xeroPresentSubscription, isPending: xeroPresentSubscriptionIsPending, isError: xeroPresentSubscriptionIsError } = useQuery({
    queryKey: [QueryKey.GetPresentSubscription, activeClient?.id, meyseyPresentSubscription?.xeroSubscriptionId],
    queryFn: async () => {
      const res = await apiClient.xero.getXeroSubscriptionById(meyseyPresentSubscription?.xeroSubscriptionId!);
      return res.data;
    },
    enabled: !!meyseyPresentSubscription?.xeroSubscriptionId
  });

  const isError = meyseyPresentSubscriptionIsError || xeroPresentSubscriptionIsError || meyseyPendingSubscriptionIsError || clientTenantIsError;
  const isPending = meyseyPresentSubscriptionIsPending || (!!meyseyPresentSubscription?.xeroSubscriptionId && xeroPresentSubscriptionIsPending) || meyseyPendingSubscriptionIsPending || clientTenantIsPending;

  const hasFreeTrialSubscription = meyseyPresentSubscription?.type === SubscriptionTypeEnum.FreeTrial;
  const hasXeroAppStoreSubscription = meyseyPresentSubscription?.type === SubscriptionTypeEnum.XeroAppStore;

  const hasPendingDowngrade = hasXeroAppStoreSubscription && meyseyPresentSubscription?.effectiveTo && !meyseyPresentSubscription?.expired && (meyseyPendingSubscription && !meyseyPendingSubscription.expired);
  const hasPendingCancellation = hasXeroAppStoreSubscription && meyseyPresentSubscription?.effectiveTo && !meyseyPresentSubscription?.expired && (!meyseyPendingSubscription || meyseyPendingSubscription.expired);
  const hasEffectiveCancellation = hasXeroAppStoreSubscription && meyseyPresentSubscription?.expired;
  const hasExpiredFreeTrial = hasFreeTrialSubscription && meyseyPresentSubscription?.expired;
  const hasActivePaidSubscription = hasXeroAppStoreSubscription && !meyseyPresentSubscription?.expired
  const hasActiveFreeTrial = hasFreeTrialSubscription && !meyseyPresentSubscription?.expired;

  return (
    <Paper p="lg" radius="md" shadow="xs">
      <Text fz="lg" fw={500}>Meysey Subscription</Text>
      <Text fz="sm">Manage and review details of your subscription to Meysey.</Text>
      <Space h="md" />
      {isError ? (
        <Center mt="lg">
          <UnableToLoadAlert />
        </Center>
      ) : isPending ? (
        <Stack flex={1} p="md">
          <Skeleton animate height={8} radius="xl" />
          <Skeleton animate height={8} mt={6} w="70%" radius="xl" />
        </Stack>
      ) : (
        <>
          <Flex p="md" gap={{ base: 20, md: 40, xl: 100 }} wrap="wrap">

            {(hasActiveFreeTrial || hasActivePaidSubscription || hasEffectiveCancellation) && (
              <div>
                <Text fz="sm" fw={500} mb={7}>
                  Current Plan
                </Text>
                {(hasActiveFreeTrial || hasActivePaidSubscription) && (
                  <Text lh={1} fw={700}>
                    {formatMeyseySubscriptionPlan(meyseyPresentSubscription?.plan)}
                  </Text>
                )}
                {hasEffectiveCancellation && (
                  <>
                    <Text lh={1} fw={700}>
                      CANCELLED
                    </Text>
                    <Text mt={7} c="dimmed" fz="sm">
                      Your subscription has been cancelled. Please select a new plan to continue using Meysey.
                    </Text>
                  </>
                )}
                {hasActiveFreeTrial && (
                  <Text mt={7} c="dimmed" fz="sm">
                    Your free trial ends on {formatDateMedium(meyseyPresentSubscription?.effectiveTo!)}
                  </Text>
                )}
              </div>
            )}
            {hasExpiredFreeTrial && (
              <Text className={classes.freeTrialExpiredMessage} lh={1} fw={700}>
                Your free trial has expired and no further analysis will be performed until you upgrade.
              </Text>
            )}
            {hasActivePaidSubscription && (
              <div>
                <Text fz="sm" fw={500} mb={7}>
                  Billing Period Ends
                </Text>
                <Text lh={1} fw={700}>
                  {formatDateMedium(xeroPresentSubscription?.currentPeriodEnd!)}
                </Text>
              </div>
            )}
            {hasActivePaidSubscription && !hasPendingCancellation && (
              <div>
                <Text fz="sm" fw={500} mb={7}>
                  Manage Subscription
                </Text>
                <Group gap="xl">
                  <Anchor display="block" fw={500} lh={1} href={getXeroAppStoreChoosePlanHref(clientTenant?.orgData?.shortCode)} >Change Plan</Anchor>
                  <Anchor display="block" fw={500} lh={1} href={getXeroAppStoreManageSubscriptionHref(clientTenant?.orgData?.shortCode)}>Cancel</Anchor>
                </Group>
              </div>
            )}
            {(hasFreeTrialSubscription) && (
              <Button className={classes.upgradeButton} size="compact-md" component="a" href={getXeroAppStoreChoosePlanHref(clientTenant?.orgData?.shortCode)}>
                Upgrade
              </Button>
            )}
            {(hasEffectiveCancellation || hasPendingCancellation) && (
              <Button className={classes.upgradeButton} size="compact-md" component="a" href={getXeroAppStoreChoosePlanHref(clientTenant?.orgData?.shortCode)}>
                Select Plan
              </Button>
            )}
          </Flex>


          {hasPendingCancellation && (
            <Alert color="orange" p="sm" mt="lg" title="Subscription cancelled" icon={<IconInfoCircle />}>
              Your subscription is cancelled and no further analysis will be performed from {formatDateMedium(meyseyPresentSubscription?.effectiveTo!)}. Please select a new plan to continue using Meysey.
            </Alert>
          )}
          {hasPendingDowngrade && (
            <Alert mt="lg" p="sm" title="Pending downgrade" icon={<IconInfoCircle />}>
              Your plan will be downgraded to <strong>{formatMeyseySubscriptionPlan(meyseyPendingSubscription.plan)}</strong> on {formatDateMedium(meyseyPresentSubscription?.effectiveTo!)}.
            </Alert>
          )}
          <Group px="md" justify="flex-end">
            <Button
              variant="transparent"
              color="red.7"
              mt="lg"
              px={0}
              loading={disconnectXeroIsPending}
              onClick={() => disconnectXero()}
            >
              Disconnect Xero
            </Button>

          </Group>
        </>
      )}
    </Paper>
  );
}