import { Text, Container, Title, Space, SegmentedControl, Center, Loader, Group, Button } from '@mantine/core';
import { InvoicesTable } from './InvoicesTable';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../userContext';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../queryKeys';
import { apiClient } from '../../api/apiClient';
import { UnableToLoadAlert } from '../UnableToLoadAlert';
import { BankTransactionsTable } from './BankTransactionsTable';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';


export function BillsAndInvoicesView() {

  const [searchParams, setSearchParams] = useSearchParams();

  const [itemType, setItemType] = useState(searchParams.get('itemType') || 'bills');
  const [page, setPage] = React.useState(1);

  let content: React.ReactNode;

  
  const { activeClient } = useContext(UserContext);

  // bills/invoices should be split into two diff queries so that keepPreviousData doesnt keep 
  // if we're switching itemtype
  const { data: bills, isPending: billsIsPending, isError: billsIsError } = useQuery({
    queryKey: [QueryKey.GetBills, activeClient?.id, itemType, page],
    queryFn: async () => {
      const res = await apiClient.xero.getInvoices(true, page);
      return res.data;
    },
    enabled: itemType === 'bills',
    placeholderData: keepPreviousData
  }); 
  
  const { data: nextPageBills } = useQuery({
    queryKey: [QueryKey.GetInvoices, activeClient?.id, itemType, page + 1],
    queryFn: async () => {
      const res = await apiClient.xero.getInvoices(true, page + 1);
      return res.data;
    },
    enabled: itemType === 'bills' 
  });

  const { data: invoices, isPending: invoicesIsPending, isError: invoicesIsError } = useQuery({
    queryKey: [QueryKey.GetInvoices, activeClient?.id, itemType, page],
    queryFn: async () => {
      const res = await apiClient.xero.getInvoices(false, page);
      return res.data;
    },
    enabled: itemType === 'invoices',
    placeholderData: keepPreviousData
  }); 
  
  const { data: nextPageInvoices } = useQuery({
    queryKey: [QueryKey.GetInvoices, activeClient?.id, itemType, page + 1],
    queryFn: async () => {
      const res = await apiClient.xero.getInvoices(false, page + 1);
      return res.data;
    },
    enabled: itemType === 'invoices' 
  });

  const { data: bankTransactions, isPending: bankTransactionsIsPending, isError: bankTransactionsIsError } = useQuery({
    queryKey: [QueryKey.GetBankTransactions, activeClient?.id, page],
    queryFn: async () => {
      const res = await apiClient.xero.getBankTransactions(page);
      return res.data;
    },
    enabled: itemType === 'transactions',
    placeholderData: keepPreviousData
  });

  const { data: nextPageBankTransactions } = useQuery({
    queryKey: [QueryKey.GetBankTransactions, activeClient?.id, page + 1],
    queryFn: async () => {
      const res = await apiClient.xero.getBankTransactions(page + 1);
      return res.data;
    },
    enabled: itemType === 'transactions'    
  });

  const isError = (itemType === 'bills' && billsIsError) || (itemType === 'invoices' && invoicesIsError) || (itemType === 'transactions' && bankTransactionsIsError);
  const isPending = (itemType === 'bills' && billsIsPending) || (itemType === 'invoices' && invoicesIsPending) || (itemType === 'transactions' && bankTransactionsIsPending);

  if (isError) {
    content = (
      <Center mt={250}>
        <UnableToLoadAlert />
      </Center>
    );
  } else if (isPending) {
    content = (
      <Center mt={350}><Loader /></Center>
    );
  } else if (itemType === 'bills' && bills) {
    content = (
      <InvoicesTable invoices={bills} />
    );
  } else if (itemType === 'invoices' && invoices) {
    content = (
      <InvoicesTable invoices={invoices} />
    );
  } else if (itemType === 'transactions' && bankTransactions) {
    content = (
      <BankTransactionsTable bankTransactions={bankTransactions} />
    )
  }

  
  const isNextPage = (itemType === 'bills' && nextPageBills && nextPageBills.length) || (itemType === 'invoices' && nextPageInvoices && nextPageInvoices.length) || (itemType === 'transactions' && nextPageBankTransactions && nextPageBankTransactions.length);
  


  return (
    <Container size="xl" py="xl">
      <Title order={2}>Bills & Invoices</Title>
      <Text mt="md">
        Here you'll find the results of the analysis conducted on your Xero bills, invoices and other bank transactions. To view detailed findings, simply select an entry.
      </Text>
      <Space h="xl" />
      <SegmentedControl
        size="md"
        value={itemType}
        onChange={(value) => {
          setItemType(value);
          setSearchParams({ ...searchParams, itemType: value }, { replace: true });
        }}
        data={[
          { label: 'Bills', value: 'bills' },
          { label: 'Invoices', value: 'invoices' },
          { label: 'Other Bank Transactions', value: 'transactions' }
        ]}        
      />
      <Space h="lg" />
      {content}
      <Space h="lg" />
      <Group justify="space-between">
        <Button 
          variant="default"
          onClick={() => setPage(page - 1)}
          style={{ visibility: page === 1 ? 'hidden' : 'visible' }}
          leftSection={<IconChevronLeft size={14} />}
        >
          Previous Page
        </Button>
        <Button 
          variant="default"
          onClick={() => setPage(page + 1)}
          style={{ visibility: !isNextPage ? 'hidden' : 'visible' }}
          rightSection={<IconChevronRight size={14} />}
        >
          Next Page
        </Button>
      </Group>
    </Container>
  )

}