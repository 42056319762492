import { ContactAnalysisStateConclusion, ContactAnalysisStateConclusionTypeEnum } from '../../api/generated';

export function getRecommendationForContactAnalysisConclusion(conclusion: ContactAnalysisStateConclusion, companyName?: string): string[] {
  switch (conclusion.type) {
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasAccountsOverdue:
      return [
        `Review any recent financial transactions or agreements with ${companyName} to ensure they remain viable.`,
        `Consider setting reminders for future checks on ${companyName}'s financial status updates at Companies House.`
      ];

    case ContactAnalysisStateConclusionTypeEnum.CompanyHasConfirmationStatementOverdue:
      return [
        `Keep a close watch on any forthcoming updates from ${companyName} regarding their confirmation statement to gauge their compliance commitment.`,
        `Adjust your engagement strategy with ${companyName} based on their responsiveness to resolving this overdue requirement.`
      ];

    case ContactAnalysisStateConclusionTypeEnum.CompanyHasArchivalStatus:
      return [
        `Verify the current operational status of ${companyName} through direct communication or third-party sources before proceeding with any transactions.`,
        `Seek alternative suppliers or customers if ${companyName} cannot confirm active business operations.`
      ];

    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersResidingOverseas:
      return [
        `Evaluate how the international presence of ${companyName}'s officers might impact your business relationship and any legal or tax implications.`,
        `Consider the need for additional due diligence to understand the corporate governance and control mechanisms in place.`
      ];

    case ContactAnalysisStateConclusionTypeEnum.CompanyHasDistressedStatus:
      return [
        `Assess the level of financial exposure your business has with ${companyName} and consider strategies to mitigate potential risks.`,
        `Stay updated on ${companyName}'s financial health and status to make informed decisions about your future interactions.`
      ];

    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOnlyOneOfficer:
      return [
        `Understand the implications of ${companyName}'s single-officer structure on your business dealings, especially concerning decision-making and business continuity.`,
        `Monitor any changes in the officer structure of ${companyName} that could affect your business relationship.`
      ];

    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersAppointedInPast6Months:
      return [
        `Keep abreast of any strategic changes or new directions taken by ${companyName} following the appointment of new officers.`,
        `Review the backgrounds of newly appointed officers for potential insights into ${companyName}'s future business dealings and reliability.`
      ];

    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersWithDisqualifications:
      return [
        `Reevaluate your trust and confidence in the management of ${companyName}, given the presence of disqualified officers.`,
        `Consider the need for additional scrutiny or independent verification of ${companyName}'s business practices and compliance.`
      ];

    case ContactAnalysisStateConclusionTypeEnum.CompanyHasPoBoxAddress:
      return [
        `Exercise due diligence to establish the physical presence and legitimacy of ${companyName}, beyond the PO Box address.`,
        `Request additional verification or documentation from ${companyName} to confirm their operational authenticity.`
      ];

    case ContactAnalysisStateConclusionTypeEnum.CompanyHasPreviousNamesInPast12Months:
      return [
        `Investigate the reasons behind ${companyName}'s recent name change to assess any potential impact on your business relationship.`,
        `Maintain vigilance for any additional changes or updates from ${companyName} that could signal further developments or shifts in their business strategy.`
      ];

    case ContactAnalysisStateConclusionTypeEnum.CompanyIsLessThan12MonthsOld:
      return [
        `Given the limited operational history of ${companyName}, it's prudent to conduct in-depth due diligence. Ensure you verify their business credentials, financial stability, and market reputation to mitigate fraud risk.`,
        `Consider engaging in transactions with clearly defined terms and safeguards. Utilize escrow arrangements or secure payment terms to protect your interests when dealing with newer entities like ${companyName}.`
      ];

    case ContactAnalysisStateConclusionTypeEnum.CompanyIsLessThan24MonthsOld:
      return [
        `For companies like ${companyName}, which are still establishing their presence, maintain a heightened level of scrutiny. Regular checks on their financial performance and any emerging market feedback are advisable to preempt potential fraud.`,
        `Be cautious with financial engagements, preferring structured payment plans that are contingent on verified delivery of goods or services. This approach helps in building a secure and transparent business relationship while minimizing exposure to fraud.`
      ];
      
    default:
      return ['No specific action recommended at this time.'];
  }
}