import { Text, Alert, Group, Anchor } from '@mantine/core';
import { formatDateMedium } from '../lib/formatters';

import { useContext } from 'react';
import { UserContext } from '../userContext';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../queryKeys';
import { apiClient } from '../api/apiClient';
import { SubscriptionTypeEnum } from '../api/generated';
import { getXeroAppStoreChoosePlanHref } from '../lib/hrefs';

import classes from './SubscriptionBanner.module.css';

export function SubscriptionBanner() {
  const { activeClient } = useContext(UserContext);

  const { data: subscription, isPending: subscriptionIsPending, isError: subscriptionIsError } = useQuery({
    queryKey: [QueryKey.GetPresentSubscription, activeClient?.id],
    queryFn: async () => {
      const res = await apiClient.subscription.getPresentSubscription();
      return res.data;
    },
    enabled: !!activeClient,
    // until we plug in code to parse subscriptionid on landing, refresh waiting for the webhook
    refetchInterval: (query) => !query.state.data || query.state.data.type === SubscriptionTypeEnum.FreeTrial ? 10000 : false
  });
  
  const { data: clientTenant, isPending: clientTenantIsPending, isError: clientTenantIsError } = useQuery({
    queryKey: [QueryKey.GetClientTenant, activeClient?.id],
    queryFn: async () => {
      const res = await apiClient.xero.getClientTenant();
      return res.data;
    },
    enabled: !!activeClient
  });
    
  const { data: pendingSubscription, isPending: pendingSubscriptionIsPending, isError: pendingSubscriptionIsError } = useQuery({
    queryKey: [QueryKey.GetPendingSubscription, activeClient?.id],
    queryFn: async () => {
      const res = await apiClient.subscription.getPendingSubscription();
      return res.data;
    },
    enabled: !!activeClient
  });

  if (subscriptionIsPending || subscriptionIsError || pendingSubscriptionIsPending || pendingSubscriptionIsError || clientTenantIsPending || clientTenantIsError) {
    return null;
  }

  if (subscription && subscription.type === SubscriptionTypeEnum.FreeTrial) {
    return (
      <Alert className={classes.root} color={subscription.expired ? 'red' : undefined}>
        <Group justify="space-between">
          <Text fw={subscription.expired ? 700 : 500}>
            Your free trial {subscription.expired ? 'expired' : 'ends'} on {formatDateMedium(subscription.effectiveTo!)} {subscription.expired && 'and no further analysis will be performed'}
          </Text>
          <Anchor href={getXeroAppStoreChoosePlanHref(clientTenant?.orgData?.shortCode)} fw={subscription.expired ? 700 : 500}>Subscribe to Stay Protected</Anchor>
        </Group>
      </Alert>
    );
  }

  if (subscription && subscription.type === SubscriptionTypeEnum.XeroAppStore && subscription.expired) {
    return (
      <Alert className={classes.root} color='red'>
        <Group justify="space-between">
          <Text fw={700}>
            Your subscription has ended and no further analysis will be performed
          </Text>
          <Anchor href={getXeroAppStoreChoosePlanHref(clientTenant?.orgData?.shortCode)} fw={subscription.expired ? 700 : 500}>Reactivate</Anchor>
        </Group>
      </Alert>
    );
  }

  if (subscription && subscription.type === SubscriptionTypeEnum.XeroAppStore && subscription?.effectiveTo && !subscription.expired && (!pendingSubscription || pendingSubscription.expired)) {
    return (
      <Alert className={classes.root} color='orange'>
        <Group justify="space-between">
          <Text fw={500}>
            Your subscription is cancelled and no further analysis will be performed from {formatDateMedium(subscription?.effectiveTo!)}. Please select a new plan to continue using Meysey.
          </Text>
          <Anchor href={getXeroAppStoreChoosePlanHref(clientTenant?.orgData?.shortCode)} fw={500}>Select Plan</Anchor>
        </Group>
      </Alert>
    );
  }
  

  return null;

}