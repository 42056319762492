import { ContactAnalysisStateConclusionTypeEnum } from '../../api/generated';


export function getAdvisorySummaryPropsForContactAnalysisConclusionType(type: ContactAnalysisStateConclusionTypeEnum) {

  // todo refactor magic string
  const link = `/suppliers-and-customers`;
  const labelNoun = 'Contact';

  switch (type) {
    case ContactAnalysisStateConclusionTypeEnum.ContactCouldNotBeMatchedToCompany:
      return {
        title: 'Contact Could Not Be Matched to Legal Entity',
        link,
        labelNoun
      };
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasArchivalStatus:
      return {
        title: 'Legal Entity Dissolved or Archived',
        link,
        labelNoun
      };
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasDistressedStatus:
      return {
        title: 'Legal Entity with Indicators of Financial Distress',
        link,
        labelNoun
      };
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasAccountsOverdue:
      return {
        title: 'Financial Accounts Overdue',
        link,
        labelNoun
      };
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasConfirmationStatementOverdue:
      return {
        title: 'Confirmation Statement Overdue',
        link,
        labelNoun
      };
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasPoBoxAddress:
      return {
        title: 'Registered Office Address is a PO Box',
        link,
        labelNoun
      };
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasPreviousNamesInPast12Months:
      return {
        title: 'Name Changed Within Last 12 Months',
        link,
        labelNoun
      };
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersWithDisqualifications:
      return {
        title: 'Officers with Disqualifications Present',
        link,
        labelNoun
      };
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersResidingOverseas:
      return {
        title: 'Officers Residing Overseas',
        link,
        labelNoun
      };
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOnlyOneOfficer:
      return {
        title: 'Single Officer Company',
        link,
        labelNoun
      };
    case ContactAnalysisStateConclusionTypeEnum.CompanyIsLessThan12MonthsOld:
      return {
        title: 'Legal Entity Less Than 12 Months Old',
        link,
        labelNoun
      };
    case ContactAnalysisStateConclusionTypeEnum.CompanyIsLessThan24MonthsOld:
      return {
        title: 'Legal Entity Less Than 24 Months Old',
        link,
        labelNoun
      };
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersAppointedInPast6Months:
      return {
        title: 'Recent Officer Appointments in Last 6 Months',
        link,
        labelNoun
      };    
    default:
      return {
        title: 'Unknown',
        link,
        labelNoun
      };
  }
}