


import { Text, Divider, Group, Paper, Space, Stack, Center } from '@mantine/core';
import { Contact, ContactAnalysisData, ContactAnalysisState, ContactAnalysisStateConclusion, ContactAnalysisStateConclusionTypeEnum } from '../../api/generated';
import { IconDatabaseOff, IconHomeCheck, IconLockCheck, IconHomeQuestion, IconHomeExclamation } from '@tabler/icons-react';

import classes from './ContactDetailStatus.module.css';
import React from 'react';
import { formatCurrency, formatDateMedium, formatNumber, formatPercentage, formatRegisteredOfficeAddressShort } from '../../lib/formatters';
import pluralize from 'pluralize';
import { sortBy } from 'lodash';
import { getCompanyMatchStateFromContactAnalysisConclusions } from '../../lib/contactAnalysis/getCompanyMatchStateFromContactAnalysisConclusions';


function ContactDetailStatusCard(props: {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  statusIcon?: React.ElementType;
  statusColor?: string;
  statusLabel?: React.ReactNode;
  children?: React.ReactNode;
}) {
  const { title, subtitle, statusLabel, children, statusColor } = props;
  const Icon = props.statusIcon || IconLockCheck;
  return (
    <Paper radius="md" shadow="md" p="xs" withBorder className={classes.statusCard}>
      <div className={classes.statusCardTitleContainer}>
        <Text className={classes.statusCardTitle}>{title}</Text>
        <Text ta="center" fz="xs">{subtitle}</Text>
      </div>
      <div className={classes.statusCardIconContainer}>
        <Center>
          <Icon className={classes.statusCardIcon} color={statusColor} />
        </Center>
        <Text className={classes.statusCardStatusLabel} c={statusColor}>{statusLabel || 'No issues detected'}</Text>
      </div>
      <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {children}
      </div>
    </Paper>
  );
}

function AccountingRecordStats({ data }: { data: ContactAnalysisData }) {

  const sumValue = formatCurrency(data.sumValue!);
  const meanValue = formatCurrency(data.meanValue!);
  const minValue = formatCurrency(data.minValue!);
  const maxValue = formatCurrency(data.maxValue!);

  const valueRelativeStdDev = formatPercentage(data.valueStdDev! / data.meanValue!);


  return (
    <div className={classes.statusCardStats}>
      <Text fz="xs">Sum value</Text>
      <Text><span className={classes.statusCardMainStat}>{sumValue}</span></Text>
      <Space h="md" />
      <Group justify="center" gap="xl" >
        <Stack gap={0} ta="center">
          <Text fz="xs">Typical</Text>
          <Text fz="xs" fw={700}>{meanValue} <span className={classes.statusCardStatSubtle}>± {valueRelativeStdDev}</span></Text>
        </Stack>
        <Stack gap={0} ta="center">
          <Text fz="xs">Range</Text>
          <Text fz="xs" fw={700}>{minValue} ↔ {maxValue}</Text>
        </Stack>
      </Group>
    </div>
  );
}

function CompaniesHouseMatchesCard({ matchConclusions }: { matchConclusions: ContactAnalysisStateConclusion[] }) {

  // todo : handle analysis incomplete
  const { satisfactoryMatch, uncertainMatches } = getCompanyMatchStateFromContactAnalysisConclusions(matchConclusions);

  let title: string | undefined;
  const subtitle = `via Companies House`;
  let statusIcon: React.ElementType | undefined;
  let statusColor: string | undefined;
  let statusLabel: React.ReactNode;
  // let statusLabel = matchConclusions?.length ? <a href="#companies-house-records">View results</a> : 'Unable to match';
  let bottomSection: React.ReactNode;

  if (satisfactoryMatch) {

    title = 'Matched to Company';
    statusIcon = IconHomeCheck;
    statusColor = 'var(--mantine-color-green-7)';
    statusLabel = 'Strong match found';
    const companyData = matchConclusions[0].data.seekCompanyResult!;
    bottomSection = (
      <div className={classes.statusCardStats}>
        <Text><span className={classes.statusCardMainStat}>{companyData.companyName}</span></Text>
        <Text fz="xs" fw={700} lineClamp={2}>{formatRegisteredOfficeAddressShort(companyData.registeredOfficeAddress)}</Text>
        <Space h="lg" />
        <Group justify="center" gap="xl" >
          <Stack gap={0} ta="center">
            <Text fz="xs">Reg No</Text>
            <Text fz="xs" fw={700}>{companyData.companyNumber}</Text>
          </Stack>
          <Stack gap={0} ta="center">
            <Text fz="xs">Incorporated</Text>
            <Text fz="xs" fw={700}>{formatDateMedium(companyData.dateOfCreation)}</Text>
          </Stack>

          {/* <Stack gap={0} ta="center">
            <Text fz="xs">Range</Text>
            <Text fz="xs" fw={700}>{minValue} ↔ {maxValue}</Text>
          </Stack> */}
        </Group>
      </div>
    );

  } else if (uncertainMatches.length) {

    title = `${uncertainMatches.length} Companies Matched`;
    statusIcon = IconHomeExclamation;
    statusLabel = <a href="#companies-house-records">View results</a>;
    statusColor = 'var(--mantine-color-orange-7)';
    const sortedUncertainMatches = sortBy(uncertainMatches, mc => -mc.confidence!).slice(0, 5);

    const confidences = sortedUncertainMatches.map(mc => mc.confidence!);
    const seekCompanyResults = sortedUncertainMatches.map(mc => mc.data.seekCompanyResult!);

    bottomSection = (
      <div className={classes.statusCardStatsTable}>
        <div>
          {seekCompanyResults.map((seekCompanyResult, i) => {
            const { companyName } = seekCompanyResult;
            return (
              <Text fz="xs" key={i} fw={i === 0 ? 500 : 400}>{companyName}</Text>
            );
          })}
        </div>
        <div>
          {confidences.map((confidence, i) => (
            <div>
              <Text fz="xs" key={i} fw={i === 0 ? 500 : 400}>{formatPercentage(confidence - 0.01)}</Text>
            </div>
          )
          )}
        </div>
      </div>
    );

  } else {
    title = 'No Companies Matched';
    statusIcon = IconHomeQuestion;
    statusLabel = 'Unable to match';
    statusColor = 'var(--mantine-color-gray-6';
    bottomSection = (<NoDataPlaceholder />);
  }

  return (
    <ContactDetailStatusCard
      title={title}
      subtitle={subtitle}
      statusIcon={statusIcon}
      statusLabel={statusLabel}
      statusColor={statusColor}
    >
      {bottomSection}
    </ContactDetailStatusCard>
  );
}

function NoDataPlaceholder() {
  return (
    <Text fz="xs" c="dimmed" ta="center">No data</Text>
  );
}

function ContactDetailStatusCards({ contact, contactAnalysisState }: { contact: Contact, contactAnalysisState: ContactAnalysisState }) {


  const hasBeenInvoiced = contactAnalysisState?.conclusions?.find(c => c.type === ContactAnalysisStateConclusionTypeEnum.ContactHasBeenInvoiced);
  const hasBilled = contactAnalysisState?.conclusions?.find(c => c.type === ContactAnalysisStateConclusionTypeEnum.ContactHasBilled);
  const hasBankTransactions = contactAnalysisState?.conclusions?.find(c => c.type === ContactAnalysisStateConclusionTypeEnum.ContactHasBankTransactions);


  const matchConclusions = contactAnalysisState?.conclusions?.filter(c => [
    ContactAnalysisStateConclusionTypeEnum.ContactStrongMatchToCompany,
    ContactAnalysisStateConclusionTypeEnum.ContactWeakMatchToCompany,
    ContactAnalysisStateConclusionTypeEnum.ContactExplicitMatchToCompany
  ].includes(c.type)) || [];

  // todo find a better way to do this


  const invoicesCardTitle = `${formatNumber(hasBeenInvoiced?.data.count || 0)} ${pluralize('Invoice', hasBeenInvoiced?.data.count!)} Scanned`;
  const invoicesCardSubtitle = hasBeenInvoiced ? `${formatDateMedium(hasBeenInvoiced?.data.earliest!)} → ${formatDateMedium(hasBeenInvoiced?.data.latest!)}` : 'No Invoices in Xero';
  const invoicesStatusIcon = hasBeenInvoiced ? undefined : IconDatabaseOff;
  const invoicesStatusLabel = hasBeenInvoiced ? undefined : 'Nothing to analyse';
  const invoicesStatusColor = hasBeenInvoiced ? undefined : 'var(--mantine-color-gray-6)';

  const invoicesStatusCard = (
    <ContactDetailStatusCard
      title={invoicesCardTitle}
      subtitle={invoicesCardSubtitle}
      statusIcon={invoicesStatusIcon}
      statusColor={invoicesStatusColor}
      statusLabel={invoicesStatusLabel}
    >
      {/* todo: handle data is undefined! */}
      {hasBeenInvoiced ? (
        <AccountingRecordStats data={hasBeenInvoiced.data} />
      ) : (
        <NoDataPlaceholder />
      )}
    </ContactDetailStatusCard>
  );


  const billsCardTitle = `${formatNumber(hasBilled?.data.count || 0)} ${pluralize('Bill', hasBilled?.data.count!)} Scanned`;
  const billsCardSubtitle = hasBilled ? `${formatDateMedium(hasBilled?.data.earliest!)} → ${formatDateMedium(hasBilled?.data.latest!)}` : 'No Bills in Xero';
  const billsStatusIcon = hasBilled ? undefined : IconDatabaseOff;
  const billsStatusLabel = hasBilled ? undefined : 'Nothing to analyse';
  const billsStatusColor = hasBilled ? undefined : 'var(--mantine-color-gray-6)';

  const billsStatusCard = (
    <ContactDetailStatusCard
      title={billsCardTitle}
      subtitle={billsCardSubtitle}
      statusIcon={billsStatusIcon}
      statusColor={billsStatusColor}
      statusLabel={billsStatusLabel}
    >
      {/* todo: handle data is undefined! */}
      {hasBilled ? (
        <AccountingRecordStats data={hasBilled.data} />
      ) : (
        <NoDataPlaceholder />
      )}
    </ContactDetailStatusCard>
  );

  const otherTransactionsCardTitle = `${formatNumber(hasBankTransactions?.data.count || 0)} ${pluralize('Bank Transaction', hasBankTransactions?.data.count!)} Scanned`;
  const otherTransactionsCardSubtitle = hasBankTransactions ? `${formatDateMedium(hasBankTransactions?.data.earliest!)} → ${formatDateMedium(hasBankTransactions?.data.latest!)}` : 'No Bank Transactions in Xero';
  const otherTransactionsStatusIcon = hasBankTransactions ? undefined : IconDatabaseOff;
  const otherTransactionsStatusLabel = hasBankTransactions ? undefined : 'Nothing to analyse';
  const otherTransactionsStatusColor = hasBankTransactions ? undefined : 'var(--mantine-color-gray-6)';

  const otherTransactionsStatusCard = (
    <ContactDetailStatusCard
      title={otherTransactionsCardTitle}
      subtitle={otherTransactionsCardSubtitle}
      statusIcon={otherTransactionsStatusIcon}
      statusColor={otherTransactionsStatusColor}
      statusLabel={otherTransactionsStatusLabel}
    >
      {/* todo: handle data is undefined! */}
      {hasBankTransactions ? (
        <AccountingRecordStats data={hasBankTransactions.data} />
      ) : (
        <NoDataPlaceholder />
      )}
    </ContactDetailStatusCard>

  );

  const accountingRecordStatusCards = sortBy([
    (contact.isCustomer || hasBeenInvoiced?.data.count) ? {
      count: hasBeenInvoiced?.data.count,
      card: invoicesStatusCard
    } : undefined,
    (contact.isSupplier || hasBilled?.data.count) ? {
      count: hasBilled?.data.count,
      card: billsStatusCard
    } : undefined,
    (hasBankTransactions?.data.count) ? {
      count: hasBankTransactions?.data.count,
      card: otherTransactionsStatusCard
    } : undefined].filter(i => !!i), i => i!.count).map(i => i!.card);


  return (
    <>
      <CompaniesHouseMatchesCard matchConclusions={matchConclusions} />
      {accountingRecordStatusCards}
    </>
  );

}

export function ContactDetailStatus({ contact, contactAnalysisState }: { contact: Contact, contactAnalysisState: ContactAnalysisState | undefined }) {



  return (
    <Paper radius="md" shadow="xs" p="lg" className={classes.statusRoot}>

      <Text fz="lg" fw={500}>Fraud Detection Status</Text>
      <Divider className={classes.statusDivider} my="xs" />

      <div className={classes.statusInner}>

        {contactAnalysisState ?
          (<ContactDetailStatusCards contact={contact} contactAnalysisState={contactAnalysisState} />)
          :
          (
            <Text fz="lg" c="white" my="xl">Not Yet Analysed</Text>
          )
        }




      </div>
    </Paper>
  )
}
