import { Text, Container, Title, Space, Center, Loader, Paper, Table, Anchor } from '@mantine/core';
import { UnableToLoadAlert } from '../UnableToLoadAlert';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { UserContext } from '../../userContext';
import { apiClient } from '../../api/apiClient';
import { QueryKey } from '../../queryKeys';
import { BankTransactionAnalysisStateConclusion, ContactAnalysisConclusion, InvoiceAnalysisStateConclusion, NormalizedBankTransaction, NormalizedBankTransactionTypeEnum, NormalizedContact, NormalizedInvoice, NormalizedInvoiceTypeEnum, RecentAnalysisQueryResult, RecentAnalysisQueryResultTypeEnum } from '../../api/generated';
import { formatDateMedium, formatDateTimeDynamic } from '../../lib/formatters';
import { Link } from 'react-router-dom';

function formatRecentAnalysisQueryResultType(type: RecentAnalysisQueryResultTypeEnum) {
  switch (type) {
    case RecentAnalysisQueryResultTypeEnum.BankTransaction:
      return 'Bank Transaction';
    case RecentAnalysisQueryResultTypeEnum.Bill:
      return 'Bill';
    case RecentAnalysisQueryResultTypeEnum.Contact:
      return 'Contact';
    case RecentAnalysisQueryResultTypeEnum.Invoice:
      return 'Invoice';
  }
}



function getLinkFromNormalizedEntity(normalizedEntity: any) {
  switch (normalizedEntity.entity) {
    case 'NormalizedBankTransaction':
      return `/bills-and-invoices/bank-transactions/${normalizedEntity.extId}`;
    case 'NormalizedInvoice':
      return `/bills-and-invoices/invoices/${normalizedEntity.extId}`;
    default: 
      return `/suppliers-and-customers/${normalizedEntity.extId}`;
  }
}



function getDescriptionFromNormalizedEntity(normalizedEntity: any) {
  switch (normalizedEntity.entity) {
    case 'NormalizedBankTransaction':
      const normalizedBankTransaction = normalizedEntity as NormalizedBankTransaction;
      return  `Transaction: ${normalizedBankTransaction.type === NormalizedBankTransactionTypeEnum.Spend ? `Spend Money` : `Receive Money`}${normalizedBankTransaction.reference ? ` - ${normalizedBankTransaction.reference}` : ``} dated ${formatDateMedium(normalizedBankTransaction.date!)}`;
    case 'NormalizedInvoice':
      const normalizedInvoice = normalizedEntity as NormalizedInvoice;
      return `${normalizedInvoice.type === NormalizedInvoiceTypeEnum.Payable ? 'Bill' : 'Invoice'}${normalizedInvoice.invoiceNumber ? ` ${normalizedInvoice.invoiceNumber}` : ``} dated ${formatDateMedium(normalizedInvoice.date!)}`;
    default: 
      return normalizedEntity.contactName;
  }
}

export interface RecentAnalysisQueryResultFullyTyped extends RecentAnalysisQueryResult {
  normalizedEntity: NormalizedBankTransaction | NormalizedInvoice | NormalizedContact;
  conclusions: BankTransactionAnalysisStateConclusion[] | InvoiceAnalysisStateConclusion[] | ContactAnalysisConclusion[];
}

export function ScanningActivityView() {

  const { activeClient } = useContext(UserContext);
  

  const { data: recentScanningActivity, isPending: recentScanningActivityIsPending, isError: recentScanningActivityIsError } = useQuery({
    queryKey: [QueryKey.GetRecentScanningActivity, activeClient?.id],
    queryFn: async () => {
      const res = await apiClient.analysis.getRecentScanningActivity(50, 0);
      return res.data;
    }
  });

  const isError = recentScanningActivityIsError;
  const isPending = recentScanningActivityIsPending || !recentScanningActivity;

  const getRows = (recentScanningActivity: RecentAnalysisQueryResultFullyTyped[]) => {
    return recentScanningActivity.map((activity, idx) => {
      return (
        <Table.Tr key={idx}>
          <Table.Td>{formatDateTimeDynamic(activity.created)}</Table.Td>
          <Table.Td>{formatRecentAnalysisQueryResultType(activity.type)}</Table.Td>          
          <Table.Td>            
            {getDescriptionFromNormalizedEntity(activity.normalizedEntity)}            
          </Table.Td>
          <Table.Td>
            <Anchor inherit component={Link} to={getLinkFromNormalizedEntity(activity.normalizedEntity)}>
              View
            </Anchor>
          </Table.Td>
        </Table.Tr>
      );
    });
  };

  return (
    <Container size="xl" py="xl">
      <Title order={2}>Scanning Activity</Title>
      <Text mt="md">
        Here you'll find a log of recent scanning activity performed on your data.
      </Text>
      <Space h="xl" />
      <Space h="xl" />
      
      {isError ? (
          <Center mt={250}>
            <UnableToLoadAlert />
          </Center>
        ) : isPending ? (
          <Center mt={250}><Loader /></Center>
        ) : (
          <>            
            <Paper shadow="xs" radius="md">
              <Table verticalSpacing="md">
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Date / Time</Table.Th>
                    <Table.Th>Type</Table.Th>                    
                    <Table.Th>Description</Table.Th>
                    <Table.Th></Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{getRows(recentScanningActivity as RecentAnalysisQueryResultFullyTyped[])}</Table.Tbody>
              </Table>
            </Paper>
          </>
        )}


        <Space h="lg" />

    </Container>
  );
}