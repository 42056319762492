
interface PreviewFileOpts {
  url?: string;
  promise?: Promise<ArrayBuffer>;
  divId: string;
  viewerConfig?: any;
  fileName: string;
  fileId?: string;
}

export class AdobeDcClient {

  private readyPromise: Promise<void>;
  private adobeDCView: any;

  constructor() {
    this.readyPromise = new Promise((resolve) => {
      if ((window as any).AdobeDC) {
        resolve();
      } else {
        /* Wait for Adobe Acrobat Services PDF Embed API to be ready */
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
          resolve();
        });
      }
    });
  }

  ready() {
    return this.readyPromise;
  }

  previewFile({ divId, viewerConfig, url, promise, fileName, fileId }: PreviewFileOpts) {
    const config: any = {
      /* Pass your registered client id */
      clientId: process.env.REACT_APP_ADOBE_DC_CLIENT_ID || '0b73d739e9a1465697cbf45a12e8883f'
    };

    if (divId) {
      /* Optional only for Light Box embed mode */
      /* Pass the div id in which PDF should be rendered */
      config.divId = divId;
    }

    /* Initialize the AdobeDC View object */
    this.adobeDCView = new (window as any).AdobeDC.View(config);

    /* Invoke the file preview API on Adobe DC View object */
    const previewFilePromise = this.adobeDCView.previewFile({
      /* Pass information on how to access the file */
      content: {
        /* Location of file where it is hosted */
        ...(url ? {
          location: {
            url,
            /*
            If the file URL requires some additional headers, then it can be passed as follows:-
            headers: [
                {
                    key: "<HEADER_KEY>",
                    value: "<HEADER_VALUE>",
                }
            ]
            */
          }
        } : {
          promise
        })
      },
      /* Pass meta data of file */
      metaData: {
        /* file name */
        fileName,
        /* file ID */
        id: fileId,
      }
    }, viewerConfig);

    return previewFilePromise;
  }


}
