/* tslint:disable */
/* eslint-disable */
/**
 * Meysey API
 * Meysey API Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AcceptPracticeUserInviteDto
 */
export interface AcceptPracticeUserInviteDto {
    /**
     * 
     * @type {string}
     * @memberof AcceptPracticeUserInviteDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AcceptPracticeUserInviteDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AcceptPracticeUserInviteDto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof AcceptPracticeUserInviteDto
     */
    practicePosition?: string;
}
/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * Customer defined alpha numeric account code e.g 200 or SALES (max length = 10)
     * @type {string}
     * @memberof Account
     */
    code?: string;
    /**
     * Name of account (max length = 150)
     * @type {string}
     * @memberof Account
     */
    name?: string;
    /**
     * The Xero identifier for an account – specified as a string following  the endpoint name   e.g. /297c2dc5-cc47-4afd-8ec8-74990b8761e9
     * @type {string}
     * @memberof Account
     */
    accountID?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    type?: AccountTypeEnum;
    /**
     * For bank accounts only (Account Type BANK)
     * @type {string}
     * @memberof Account
     */
    bankAccountNumber?: string;
    /**
     * Accounts with a status of ACTIVE can be updated to ARCHIVED. See Account Status Codes
     * @type {string}
     * @memberof Account
     */
    status?: AccountStatusEnum;
    /**
     * Description of the Account. Valid for all types of accounts except bank accounts (max length = 4000)
     * @type {string}
     * @memberof Account
     */
    description?: string;
    /**
     * For bank accounts only. See Bank Account types
     * @type {string}
     * @memberof Account
     */
    bankAccountType?: AccountBankAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    currencyCode?: AccountCurrencyCodeEnum;
    /**
     * The tax type from taxRates
     * @type {string}
     * @memberof Account
     */
    taxType?: string;
    /**
     * Boolean – describes whether account can have payments applied to it
     * @type {boolean}
     * @memberof Account
     */
    enablePaymentsToAccount?: boolean;
    /**
     * Boolean – describes whether account code is available for use with expense claims
     * @type {boolean}
     * @memberof Account
     */
    showInExpenseClaims?: boolean;
    /**
     * See Account Class Types
     * @type {string}
     * @memberof Account
     */
    _class?: AccountClassEnum;
    /**
     * If this is a system account then this element is returned. See System Account types. Note that non-system accounts may have this element set as either “” or null.
     * @type {string}
     * @memberof Account
     */
    systemAccount?: AccountSystemAccountEnum;
    /**
     * Shown if set
     * @type {string}
     * @memberof Account
     */
    reportingCode?: string;
    /**
     * Shown if set
     * @type {string}
     * @memberof Account
     */
    reportingCodeName?: string;
    /**
     * boolean to indicate if an account has an attachment (read only)
     * @type {boolean}
     * @memberof Account
     */
    hasAttachments?: boolean;
    /**
     * Last modified date UTC format
     * @type {string}
     * @memberof Account
     */
    updatedDateUTC?: string;
    /**
     * Boolean – describes whether the account is shown in the watchlist widget on the dashboard
     * @type {boolean}
     * @memberof Account
     */
    addToWatchlist?: boolean;
    /**
     * Displays array of validation error messages from the API
     * @type {Array<ValidationError>}
     * @memberof Account
     */
    validationErrors?: Array<ValidationError>;
}

/**
    * @export
    * @enum {string}
    */
export enum AccountTypeEnum {
    Bank = 'BANK',
    Current = 'CURRENT',
    Currliab = 'CURRLIAB',
    Depreciatn = 'DEPRECIATN',
    Directcosts = 'DIRECTCOSTS',
    Equity = 'EQUITY',
    Expense = 'EXPENSE',
    Fixed = 'FIXED',
    Inventory = 'INVENTORY',
    Liability = 'LIABILITY',
    Noncurrent = 'NONCURRENT',
    Otherincome = 'OTHERINCOME',
    Overheads = 'OVERHEADS',
    Prepayment = 'PREPAYMENT',
    Revenue = 'REVENUE',
    Sales = 'SALES',
    Termliab = 'TERMLIAB',
    Payg = 'PAYG'
}
/**
    * @export
    * @enum {string}
    */
export enum AccountStatusEnum {
    Active = 'ACTIVE',
    Archived = 'ARCHIVED',
    Deleted = 'DELETED'
}
/**
    * @export
    * @enum {string}
    */
export enum AccountBankAccountTypeEnum {
    Bank = 'BANK',
    Creditcard = 'CREDITCARD',
    Paypal = 'PAYPAL',
    None = 'NONE',
    Empty = ''
}
/**
    * @export
    * @enum {string}
    */
export enum AccountCurrencyCodeEnum {
    Aed = 'AED',
    Afn = 'AFN',
    All = 'ALL',
    Amd = 'AMD',
    Ang = 'ANG',
    Aoa = 'AOA',
    Ars = 'ARS',
    Aud = 'AUD',
    Awg = 'AWG',
    Azn = 'AZN',
    Bam = 'BAM',
    Bbd = 'BBD',
    Bdt = 'BDT',
    Bgn = 'BGN',
    Bhd = 'BHD',
    Bif = 'BIF',
    Bmd = 'BMD',
    Bnd = 'BND',
    Bob = 'BOB',
    Brl = 'BRL',
    Bsd = 'BSD',
    Btn = 'BTN',
    Bwp = 'BWP',
    Byn = 'BYN',
    Byr = 'BYR',
    Bzd = 'BZD',
    Cad = 'CAD',
    Cdf = 'CDF',
    Chf = 'CHF',
    Clf = 'CLF',
    Clp = 'CLP',
    Cny = 'CNY',
    Cop = 'COP',
    Crc = 'CRC',
    Cuc = 'CUC',
    Cup = 'CUP',
    Cve = 'CVE',
    Czk = 'CZK',
    Djf = 'DJF',
    Dkk = 'DKK',
    Dop = 'DOP',
    Dzd = 'DZD',
    Eek = 'EEK',
    Egp = 'EGP',
    Ern = 'ERN',
    Etb = 'ETB',
    Eur = 'EUR',
    Fjd = 'FJD',
    Fkp = 'FKP',
    Gbp = 'GBP',
    Gel = 'GEL',
    Ghs = 'GHS',
    Gip = 'GIP',
    Gmd = 'GMD',
    Gnf = 'GNF',
    Gtq = 'GTQ',
    Gyd = 'GYD',
    Hkd = 'HKD',
    Hnl = 'HNL',
    Hrk = 'HRK',
    Htg = 'HTG',
    Huf = 'HUF',
    Idr = 'IDR',
    Ils = 'ILS',
    Inr = 'INR',
    Iqd = 'IQD',
    Irr = 'IRR',
    Isk = 'ISK',
    Jmd = 'JMD',
    Jod = 'JOD',
    Jpy = 'JPY',
    Kes = 'KES',
    Kgs = 'KGS',
    Khr = 'KHR',
    Kmf = 'KMF',
    Kpw = 'KPW',
    Krw = 'KRW',
    Kwd = 'KWD',
    Kyd = 'KYD',
    Kzt = 'KZT',
    Lak = 'LAK',
    Lbp = 'LBP',
    Lkr = 'LKR',
    Lrd = 'LRD',
    Lsl = 'LSL',
    Ltl = 'LTL',
    Lvl = 'LVL',
    Lyd = 'LYD',
    Mad = 'MAD',
    Mdl = 'MDL',
    Mga = 'MGA',
    Mkd = 'MKD',
    Mmk = 'MMK',
    Mnt = 'MNT',
    Mop = 'MOP',
    Mro = 'MRO',
    Mru = 'MRU',
    Mur = 'MUR',
    Mvr = 'MVR',
    Mwk = 'MWK',
    Mxn = 'MXN',
    Mxv = 'MXV',
    Myr = 'MYR',
    Mzn = 'MZN',
    Nad = 'NAD',
    Ngn = 'NGN',
    Nio = 'NIO',
    Nok = 'NOK',
    Npr = 'NPR',
    Nzd = 'NZD',
    Omr = 'OMR',
    Pab = 'PAB',
    Pen = 'PEN',
    Pgk = 'PGK',
    Php = 'PHP',
    Pkr = 'PKR',
    Pln = 'PLN',
    Pyg = 'PYG',
    Qar = 'QAR',
    Ron = 'RON',
    Rsd = 'RSD',
    Rub = 'RUB',
    Rwf = 'RWF',
    Sar = 'SAR',
    Sbd = 'SBD',
    Scr = 'SCR',
    Sdg = 'SDG',
    Sek = 'SEK',
    Sgd = 'SGD',
    Shp = 'SHP',
    Skk = 'SKK',
    Sle = 'SLE',
    Sll = 'SLL',
    Sos = 'SOS',
    Srd = 'SRD',
    Std = 'STD',
    Stn = 'STN',
    Svc = 'SVC',
    Syp = 'SYP',
    Szl = 'SZL',
    Thb = 'THB',
    Tjs = 'TJS',
    Tmt = 'TMT',
    Tnd = 'TND',
    Top = 'TOP',
    Try = 'TRY',
    TryLira = 'TRY_LIRA',
    Ttd = 'TTD',
    Twd = 'TWD',
    Tzs = 'TZS',
    Uah = 'UAH',
    Ugx = 'UGX',
    Usd = 'USD',
    Uyu = 'UYU',
    Uzs = 'UZS',
    Vef = 'VEF',
    Ves = 'VES',
    Vnd = 'VND',
    Vuv = 'VUV',
    Wst = 'WST',
    Xaf = 'XAF',
    Xcd = 'XCD',
    Xof = 'XOF',
    Xpf = 'XPF',
    Yer = 'YER',
    Zar = 'ZAR',
    Zmw = 'ZMW',
    Zmk = 'ZMK',
    Zwd = 'ZWD'
}
/**
    * @export
    * @enum {string}
    */
export enum AccountClassEnum {
    Asset = 'ASSET',
    Equity = 'EQUITY',
    Expense = 'EXPENSE',
    Liability = 'LIABILITY',
    Revenue = 'REVENUE'
}
/**
    * @export
    * @enum {string}
    */
export enum AccountSystemAccountEnum {
    Debtors = 'DEBTORS',
    Creditors = 'CREDITORS',
    Bankcurrencygain = 'BANKCURRENCYGAIN',
    Gst = 'GST',
    Gstonimports = 'GSTONIMPORTS',
    Historical = 'HISTORICAL',
    Realisedcurrencygain = 'REALISEDCURRENCYGAIN',
    Retainedearnings = 'RETAINEDEARNINGS',
    Rounding = 'ROUNDING',
    Trackingtransfers = 'TRACKINGTRANSFERS',
    Unpaidexpclm = 'UNPAIDEXPCLM',
    Unrealisedcurrencygain = 'UNREALISEDCURRENCYGAIN',
    Wagepayables = 'WAGEPAYABLES',
    Cisassets = 'CISASSETS',
    Cisasset = 'CISASSET',
    Cislabour = 'CISLABOUR',
    Cislabourexpense = 'CISLABOUREXPENSE',
    Cislabourincome = 'CISLABOURINCOME',
    Cisliability = 'CISLIABILITY',
    Cismaterials = 'CISMATERIALS',
    Empty = ''
}

/**
 * 
 * @export
 * @interface AccountsPayable
 */
export interface AccountsPayable {
    /**
     * 
     * @type {number}
     * @memberof AccountsPayable
     */
    outstanding?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountsPayable
     */
    overdue?: number;
}
/**
 * 
 * @export
 * @interface AccountsReceivable
 */
export interface AccountsReceivable {
    /**
     * 
     * @type {number}
     * @memberof AccountsReceivable
     */
    outstanding?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountsReceivable
     */
    overdue?: number;
}
/**
 * 
 * @export
 * @interface AdditionalAlertingContact
 */
export interface AdditionalAlertingContact {
    /**
     * 
     * @type {string}
     * @memberof AdditionalAlertingContact
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalAlertingContact
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalAlertingContact
     */
    deleted?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalAlertingContact
     */
    deletedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalAlertingContact
     */
    createdByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalAlertingContact
     */
    xeroTenantConnectionId: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalAlertingContact
     */
    recipientEmail: string;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * define the type of address
     * @type {string}
     * @memberof Address
     */
    addressType?: AddressAddressTypeEnum;
    /**
     * max length = 500
     * @type {string}
     * @memberof Address
     */
    addressLine1?: string;
    /**
     * max length = 500
     * @type {string}
     * @memberof Address
     */
    addressLine2?: string;
    /**
     * max length = 500
     * @type {string}
     * @memberof Address
     */
    addressLine3?: string;
    /**
     * max length = 500
     * @type {string}
     * @memberof Address
     */
    addressLine4?: string;
    /**
     * max length = 255
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * max length = 255
     * @type {string}
     * @memberof Address
     */
    region?: string;
    /**
     * max length = 50
     * @type {string}
     * @memberof Address
     */
    postalCode?: string;
    /**
     * max length = 50, [A-Z], [a-z] only
     * @type {string}
     * @memberof Address
     */
    country?: string;
    /**
     * max length = 255
     * @type {string}
     * @memberof Address
     */
    attentionTo?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AddressAddressTypeEnum {
    Pobox = 'POBOX',
    Street = 'STREET'
}

/**
 * 
 * @export
 * @interface AddressForOrganisation
 */
export interface AddressForOrganisation {
    /**
     * define the type of address
     * @type {string}
     * @memberof AddressForOrganisation
     */
    addressType?: AddressForOrganisationAddressTypeEnum;
    /**
     * max length = 500
     * @type {string}
     * @memberof AddressForOrganisation
     */
    addressLine1?: string;
    /**
     * max length = 500
     * @type {string}
     * @memberof AddressForOrganisation
     */
    addressLine2?: string;
    /**
     * max length = 500
     * @type {string}
     * @memberof AddressForOrganisation
     */
    addressLine3?: string;
    /**
     * max length = 500
     * @type {string}
     * @memberof AddressForOrganisation
     */
    addressLine4?: string;
    /**
     * max length = 255
     * @type {string}
     * @memberof AddressForOrganisation
     */
    city?: string;
    /**
     * max length = 255
     * @type {string}
     * @memberof AddressForOrganisation
     */
    region?: string;
    /**
     * max length = 50
     * @type {string}
     * @memberof AddressForOrganisation
     */
    postalCode?: string;
    /**
     * max length = 50, [A-Z], [a-z] only
     * @type {string}
     * @memberof AddressForOrganisation
     */
    country?: string;
    /**
     * max length = 255
     * @type {string}
     * @memberof AddressForOrganisation
     */
    attentionTo?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AddressForOrganisationAddressTypeEnum {
    Pobox = 'POBOX',
    Street = 'STREET',
    Delivery = 'DELIVERY'
}

/**
 * 
 * @export
 * @interface Allocation
 */
export interface Allocation {
    /**
     * Xero generated unique identifier
     * @type {string}
     * @memberof Allocation
     */
    allocationID?: string;
    /**
     * 
     * @type {Invoice}
     * @memberof Allocation
     */
    invoice: Invoice;
    /**
     * 
     * @type {Overpayment}
     * @memberof Allocation
     */
    overpayment?: Overpayment;
    /**
     * 
     * @type {Prepayment}
     * @memberof Allocation
     */
    prepayment?: Prepayment;
    /**
     * 
     * @type {CreditNote}
     * @memberof Allocation
     */
    creditNote?: CreditNote;
    /**
     * the amount being applied to the invoice
     * @type {number}
     * @memberof Allocation
     */
    amount: number;
    /**
     * the date the allocation is applied YYYY-MM-DD.
     * @type {string}
     * @memberof Allocation
     */
    date: string;
    /**
     * A flag that returns true when the allocation is succesfully deleted
     * @type {boolean}
     * @memberof Allocation
     */
    isDeleted?: boolean;
    /**
     * A string to indicate if a invoice status
     * @type {string}
     * @memberof Allocation
     */
    statusAttributeString?: string;
    /**
     * Displays array of validation error messages from the API
     * @type {Array<ValidationError>}
     * @memberof Allocation
     */
    validationErrors?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * Unique ID for the file
     * @type {string}
     * @memberof Attachment
     */
    attachmentID?: string;
    /**
     * Name of the file
     * @type {string}
     * @memberof Attachment
     */
    fileName?: string;
    /**
     * URL to the file on xero.com
     * @type {string}
     * @memberof Attachment
     */
    url?: string;
    /**
     * Type of file
     * @type {string}
     * @memberof Attachment
     */
    mimeType?: string;
    /**
     * Length of the file content
     * @type {number}
     * @memberof Attachment
     */
    contentLength?: number;
    /**
     * Include the file with the online invoice
     * @type {boolean}
     * @memberof Attachment
     */
    includeOnline?: boolean;
}
/**
 * 
 * @export
 * @interface Balances
 */
export interface Balances {
    /**
     * 
     * @type {AccountsReceivable}
     * @memberof Balances
     */
    accountsReceivable?: AccountsReceivable;
    /**
     * 
     * @type {AccountsPayable}
     * @memberof Balances
     */
    accountsPayable?: AccountsPayable;
}
/**
 * 
 * @export
 * @interface BankTransaction
 */
export interface BankTransaction {
    /**
     * See Bank Transaction Types
     * @type {string}
     * @memberof BankTransaction
     */
    type: BankTransactionTypeEnum;
    /**
     * 
     * @type {Contact}
     * @memberof BankTransaction
     */
    contact?: Contact;
    /**
     * See LineItems
     * @type {Array<LineItem>}
     * @memberof BankTransaction
     */
    lineItems: Array<LineItem>;
    /**
     * 
     * @type {Account}
     * @memberof BankTransaction
     */
    bankAccount: Account;
    /**
     * Boolean to show if transaction is reconciled
     * @type {boolean}
     * @memberof BankTransaction
     */
    isReconciled?: boolean;
    /**
     * Date of transaction – YYYY-MM-DD
     * @type {string}
     * @memberof BankTransaction
     */
    date?: string;
    /**
     * Reference for the transaction. Only supported for SPEND and RECEIVE transactions.
     * @type {string}
     * @memberof BankTransaction
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof BankTransaction
     */
    currencyCode?: BankTransactionCurrencyCodeEnum;
    /**
     * Exchange rate to base currency when money is spent or received. e.g.0.7500 Only used for bank transactions in non base currency. If this isn’t specified for non base currency accounts then either the user-defined rate (preference) or the XE.com day rate will be used. Setting currency is only supported on overpayments.
     * @type {number}
     * @memberof BankTransaction
     */
    currencyRate?: number;
    /**
     * URL link to a source document – shown as “Go to App Name”
     * @type {string}
     * @memberof BankTransaction
     */
    url?: string;
    /**
     * See Bank Transaction Status Codes
     * @type {string}
     * @memberof BankTransaction
     */
    status?: BankTransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BankTransaction
     */
    lineAmountTypes?: BankTransactionLineAmountTypesEnum;
    /**
     * Total of bank transaction excluding taxes
     * @type {number}
     * @memberof BankTransaction
     */
    subTotal?: number;
    /**
     * Total tax on bank transaction
     * @type {number}
     * @memberof BankTransaction
     */
    totalTax?: number;
    /**
     * Total of bank transaction tax inclusive
     * @type {number}
     * @memberof BankTransaction
     */
    total?: number;
    /**
     * Xero generated unique identifier for bank transaction
     * @type {string}
     * @memberof BankTransaction
     */
    bankTransactionID?: string;
    /**
     * Xero generated unique identifier for a Prepayment. This will be returned on BankTransactions with a Type of SPEND-PREPAYMENT or RECEIVE-PREPAYMENT
     * @type {string}
     * @memberof BankTransaction
     */
    prepaymentID?: string;
    /**
     * Xero generated unique identifier for an Overpayment. This will be returned on BankTransactions with a Type of SPEND-OVERPAYMENT or RECEIVE-OVERPAYMENT
     * @type {string}
     * @memberof BankTransaction
     */
    overpaymentID?: string;
    /**
     * Last modified date UTC format
     * @type {string}
     * @memberof BankTransaction
     */
    updatedDateUTC?: string;
    /**
     * Boolean to indicate if a bank transaction has an attachment
     * @type {boolean}
     * @memberof BankTransaction
     */
    hasAttachments?: boolean;
    /**
     * A string to indicate if a invoice status
     * @type {string}
     * @memberof BankTransaction
     */
    statusAttributeString?: string;
    /**
     * Displays array of validation error messages from the API
     * @type {Array<ValidationError>}
     * @memberof BankTransaction
     */
    validationErrors?: Array<ValidationError>;
}

/**
    * @export
    * @enum {string}
    */
export enum BankTransactionTypeEnum {
    Receive = 'RECEIVE',
    ReceiveOverpayment = 'RECEIVE-OVERPAYMENT',
    Receiveoverpayment = 'RECEIVEOVERPAYMENT',
    ReceivePrepayment = 'RECEIVE-PREPAYMENT',
    Receiveprepayment = 'RECEIVEPREPAYMENT',
    Spend = 'SPEND',
    SpendOverpayment = 'SPEND-OVERPAYMENT',
    Spendoverpayment = 'SPENDOVERPAYMENT',
    SpendPrepayment = 'SPEND-PREPAYMENT',
    Spendprepayment = 'SPENDPREPAYMENT',
    ReceiveTransfer = 'RECEIVE-TRANSFER',
    Receivetransfer = 'RECEIVETRANSFER',
    SpendTransfer = 'SPEND-TRANSFER',
    Spendtransfer = 'SPENDTRANSFER'
}
/**
    * @export
    * @enum {string}
    */
export enum BankTransactionCurrencyCodeEnum {
    Aed = 'AED',
    Afn = 'AFN',
    All = 'ALL',
    Amd = 'AMD',
    Ang = 'ANG',
    Aoa = 'AOA',
    Ars = 'ARS',
    Aud = 'AUD',
    Awg = 'AWG',
    Azn = 'AZN',
    Bam = 'BAM',
    Bbd = 'BBD',
    Bdt = 'BDT',
    Bgn = 'BGN',
    Bhd = 'BHD',
    Bif = 'BIF',
    Bmd = 'BMD',
    Bnd = 'BND',
    Bob = 'BOB',
    Brl = 'BRL',
    Bsd = 'BSD',
    Btn = 'BTN',
    Bwp = 'BWP',
    Byn = 'BYN',
    Byr = 'BYR',
    Bzd = 'BZD',
    Cad = 'CAD',
    Cdf = 'CDF',
    Chf = 'CHF',
    Clf = 'CLF',
    Clp = 'CLP',
    Cny = 'CNY',
    Cop = 'COP',
    Crc = 'CRC',
    Cuc = 'CUC',
    Cup = 'CUP',
    Cve = 'CVE',
    Czk = 'CZK',
    Djf = 'DJF',
    Dkk = 'DKK',
    Dop = 'DOP',
    Dzd = 'DZD',
    Eek = 'EEK',
    Egp = 'EGP',
    Ern = 'ERN',
    Etb = 'ETB',
    Eur = 'EUR',
    Fjd = 'FJD',
    Fkp = 'FKP',
    Gbp = 'GBP',
    Gel = 'GEL',
    Ghs = 'GHS',
    Gip = 'GIP',
    Gmd = 'GMD',
    Gnf = 'GNF',
    Gtq = 'GTQ',
    Gyd = 'GYD',
    Hkd = 'HKD',
    Hnl = 'HNL',
    Hrk = 'HRK',
    Htg = 'HTG',
    Huf = 'HUF',
    Idr = 'IDR',
    Ils = 'ILS',
    Inr = 'INR',
    Iqd = 'IQD',
    Irr = 'IRR',
    Isk = 'ISK',
    Jmd = 'JMD',
    Jod = 'JOD',
    Jpy = 'JPY',
    Kes = 'KES',
    Kgs = 'KGS',
    Khr = 'KHR',
    Kmf = 'KMF',
    Kpw = 'KPW',
    Krw = 'KRW',
    Kwd = 'KWD',
    Kyd = 'KYD',
    Kzt = 'KZT',
    Lak = 'LAK',
    Lbp = 'LBP',
    Lkr = 'LKR',
    Lrd = 'LRD',
    Lsl = 'LSL',
    Ltl = 'LTL',
    Lvl = 'LVL',
    Lyd = 'LYD',
    Mad = 'MAD',
    Mdl = 'MDL',
    Mga = 'MGA',
    Mkd = 'MKD',
    Mmk = 'MMK',
    Mnt = 'MNT',
    Mop = 'MOP',
    Mro = 'MRO',
    Mru = 'MRU',
    Mur = 'MUR',
    Mvr = 'MVR',
    Mwk = 'MWK',
    Mxn = 'MXN',
    Mxv = 'MXV',
    Myr = 'MYR',
    Mzn = 'MZN',
    Nad = 'NAD',
    Ngn = 'NGN',
    Nio = 'NIO',
    Nok = 'NOK',
    Npr = 'NPR',
    Nzd = 'NZD',
    Omr = 'OMR',
    Pab = 'PAB',
    Pen = 'PEN',
    Pgk = 'PGK',
    Php = 'PHP',
    Pkr = 'PKR',
    Pln = 'PLN',
    Pyg = 'PYG',
    Qar = 'QAR',
    Ron = 'RON',
    Rsd = 'RSD',
    Rub = 'RUB',
    Rwf = 'RWF',
    Sar = 'SAR',
    Sbd = 'SBD',
    Scr = 'SCR',
    Sdg = 'SDG',
    Sek = 'SEK',
    Sgd = 'SGD',
    Shp = 'SHP',
    Skk = 'SKK',
    Sle = 'SLE',
    Sll = 'SLL',
    Sos = 'SOS',
    Srd = 'SRD',
    Std = 'STD',
    Stn = 'STN',
    Svc = 'SVC',
    Syp = 'SYP',
    Szl = 'SZL',
    Thb = 'THB',
    Tjs = 'TJS',
    Tmt = 'TMT',
    Tnd = 'TND',
    Top = 'TOP',
    Try = 'TRY',
    TryLira = 'TRY_LIRA',
    Ttd = 'TTD',
    Twd = 'TWD',
    Tzs = 'TZS',
    Uah = 'UAH',
    Ugx = 'UGX',
    Usd = 'USD',
    Uyu = 'UYU',
    Uzs = 'UZS',
    Vef = 'VEF',
    Ves = 'VES',
    Vnd = 'VND',
    Vuv = 'VUV',
    Wst = 'WST',
    Xaf = 'XAF',
    Xcd = 'XCD',
    Xof = 'XOF',
    Xpf = 'XPF',
    Yer = 'YER',
    Zar = 'ZAR',
    Zmw = 'ZMW',
    Zmk = 'ZMK',
    Zwd = 'ZWD'
}
/**
    * @export
    * @enum {string}
    */
export enum BankTransactionStatusEnum {
    Authorised = 'AUTHORISED',
    Deleted = 'DELETED',
    Voided = 'VOIDED'
}
/**
    * @export
    * @enum {string}
    */
export enum BankTransactionLineAmountTypesEnum {
    Exclusive = 'Exclusive',
    Inclusive = 'Inclusive',
    NoTax = 'NoTax'
}

/**
 * 
 * @export
 * @interface BankTransactionAnalysisState
 */
export interface BankTransactionAnalysisState {
    /**
     * 
     * @type {string}
     * @memberof BankTransactionAnalysisState
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BankTransactionAnalysisState
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof BankTransactionAnalysisState
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof BankTransactionAnalysisState
     */
    lastAnalysisJobCompletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof BankTransactionAnalysisState
     */
    lastAnalysisJobCompletedId?: string;
    /**
     * 
     * @type {string}
     * @memberof BankTransactionAnalysisState
     */
    lastAnalysisJobCompletedName?: string;
    /**
     * 
     * @type {string}
     * @memberof BankTransactionAnalysisState
     */
    lastBankTransactionAnalysisCompletedId?: string;
    /**
     * 
     * @type {Array<BankTransactionAnalysisStateConclusion>}
     * @memberof BankTransactionAnalysisState
     */
    conclusions?: Array<BankTransactionAnalysisStateConclusion>;
    /**
     * 
     * @type {string}
     * @memberof BankTransactionAnalysisState
     */
    xeroTenantId: string;
    /**
     * 
     * @type {string}
     * @memberof BankTransactionAnalysisState
     */
    xeroBankTransactionId: string;
    /**
     * 
     * @type {NormalizedBankTransaction}
     * @memberof BankTransactionAnalysisState
     */
    normalizedBankTransaction: NormalizedBankTransaction;
    /**
     * 
     * @type {string}
     * @memberof BankTransactionAnalysisState
     */
    lastAnalysisJobStartedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof BankTransactionAnalysisState
     */
    lastAnalysisJobStartedId?: string;
    /**
     * 
     * @type {string}
     * @memberof BankTransactionAnalysisState
     */
    lastAnalysisJobStartedName?: string;
    /**
     * 
     * @type {string}
     * @memberof BankTransactionAnalysisState
     */
    lastBankTransactionAnalysisStartedId?: string;
}
/**
 * 
 * @export
 * @interface BankTransactionAnalysisStateConclusion
 */
export interface BankTransactionAnalysisStateConclusion {
    /**
     * 
     * @type {string}
     * @memberof BankTransactionAnalysisStateConclusion
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BankTransactionAnalysisStateConclusion
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof BankTransactionAnalysisStateConclusion
     */
    invoiceAnalysisId?: string;
    /**
     * 
     * @type {string}
     * @memberof BankTransactionAnalysisStateConclusion
     */
    bankTransactionAnalysisId?: string;
    /**
     * 
     * @type {string}
     * @memberof BankTransactionAnalysisStateConclusion
     */
    type: BankTransactionAnalysisStateConclusionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BankTransactionAnalysisStateConclusion
     */
    correlationId: string | null;
    /**
     * 
     * @type {number}
     * @memberof BankTransactionAnalysisStateConclusion
     */
    confidence: number | null;
    /**
     * 
     * @type {object}
     * @memberof BankTransactionAnalysisStateConclusion
     */
    data: object;
    /**
     * 
     * @type {object}
     * @memberof BankTransactionAnalysisStateConclusion
     */
    meta: object | null;
}

/**
    * @export
    * @enum {string}
    */
export enum BankTransactionAnalysisStateConclusionTypeEnum {
    DocumentIsOutlier = 'DocumentIsOutlier',
    LineItemIsOutlier = 'LineItemIsOutlier',
    PossibleDuplicate = 'PossibleDuplicate',
    AttachmentDataExtracted = 'AttachmentDataExtracted',
    BankDetailsChanged = 'BankDetailsChanged'
}

/**
 * 
 * @export
 * @interface BatchPayment
 */
export interface BatchPayment {
    /**
     * 
     * @type {Account}
     * @memberof BatchPayment
     */
    account?: Account;
    /**
     * (NZ Only) Optional references for the batch payment transaction. It will also show with the batch payment transaction in the bank reconciliation Find & Match screen. Depending on your individual bank, the detail may also show on the bank statement you import into Xero.
     * @type {string}
     * @memberof BatchPayment
     */
    reference?: string;
    /**
     * (NZ Only) Optional references for the batch payment transaction. It will also show with the batch payment transaction in the bank reconciliation Find & Match screen. Depending on your individual bank, the detail may also show on the bank statement you import into Xero.
     * @type {string}
     * @memberof BatchPayment
     */
    particulars?: string;
    /**
     * (NZ Only) Optional references for the batch payment transaction. It will also show with the batch payment transaction in the bank reconciliation Find & Match screen. Depending on your individual bank, the detail may also show on the bank statement you import into Xero.
     * @type {string}
     * @memberof BatchPayment
     */
    code?: string;
    /**
     * (Non-NZ Only) These details are sent to the org’s bank as a reference for the batch payment transaction. They will also show with the batch payment transaction in the bank reconciliation Find & Match screen. Depending on your individual bank, the detail may also show on the bank statement imported into Xero. Maximum field length = 18
     * @type {string}
     * @memberof BatchPayment
     */
    details?: string;
    /**
     * (UK Only) Only shows on the statement line in Xero. Max length =18
     * @type {string}
     * @memberof BatchPayment
     */
    narrative?: string;
    /**
     * The Xero generated unique identifier for the bank transaction (read-only)
     * @type {string}
     * @memberof BatchPayment
     */
    batchPaymentID?: string;
    /**
     * Date the payment is being made (YYYY-MM-DD) e.g. 2009-09-06
     * @type {string}
     * @memberof BatchPayment
     */
    dateString?: string;
    /**
     * Date the payment is being made (YYYY-MM-DD) e.g. 2009-09-06
     * @type {string}
     * @memberof BatchPayment
     */
    date?: string;
    /**
     * The amount of the payment. Must be less than or equal to the outstanding amount owing on the invoice e.g. 200.00
     * @type {number}
     * @memberof BatchPayment
     */
    amount?: number;
    /**
     * An array of payments
     * @type {Array<Payment>}
     * @memberof BatchPayment
     */
    payments?: Array<Payment>;
    /**
     * PAYBATCH for bill payments or RECBATCH for sales invoice payments (read-only)
     * @type {string}
     * @memberof BatchPayment
     */
    type?: BatchPaymentTypeEnum;
    /**
     * AUTHORISED or DELETED (read-only). New batch payments will have a status of AUTHORISED. It is not possible to delete batch payments via the API.
     * @type {string}
     * @memberof BatchPayment
     */
    status?: BatchPaymentStatusEnum;
    /**
     * The total of the payments that make up the batch (read-only)
     * @type {number}
     * @memberof BatchPayment
     */
    totalAmount?: number;
    /**
     * UTC timestamp of last update to the payment
     * @type {string}
     * @memberof BatchPayment
     */
    updatedDateUTC?: string;
    /**
     * Booelan that tells you if the batch payment has been reconciled (read-only)
     * @type {boolean}
     * @memberof BatchPayment
     */
    isReconciled?: boolean;
    /**
     * Displays array of validation error messages from the API
     * @type {Array<ValidationError>}
     * @memberof BatchPayment
     */
    validationErrors?: Array<ValidationError>;
}

/**
    * @export
    * @enum {string}
    */
export enum BatchPaymentTypeEnum {
    Paybatch = 'PAYBATCH',
    Recbatch = 'RECBATCH'
}
/**
    * @export
    * @enum {string}
    */
export enum BatchPaymentStatusEnum {
    Authorised = 'AUTHORISED',
    Deleted = 'DELETED'
}

/**
 * 
 * @export
 * @interface BatchPaymentDetails
 */
export interface BatchPaymentDetails {
    /**
     * Bank account number for use with Batch Payments
     * @type {string}
     * @memberof BatchPaymentDetails
     */
    bankAccountNumber?: string;
    /**
     * Name of bank for use with Batch Payments
     * @type {string}
     * @memberof BatchPaymentDetails
     */
    bankAccountName?: string;
    /**
     * (Non-NZ Only) These details are sent to the org’s bank as a reference for the batch payment transaction. They will also show with the batch payment transaction in the bank reconciliation Find & Match screen. Depending on your individual bank, the detail may also show on the bank statement imported into Xero. Maximum field length = 18
     * @type {string}
     * @memberof BatchPaymentDetails
     */
    details?: string;
    /**
     * (NZ Only) Optional references for the batch payment transaction. It will also show with the batch payment transaction in the bank reconciliation Find & Match screen. Depending on your individual bank, the detail may also show on the bank statement you import into Xero.
     * @type {string}
     * @memberof BatchPaymentDetails
     */
    code?: string;
    /**
     * (NZ Only) Optional references for the batch payment transaction. It will also show with the batch payment transaction in the bank reconciliation Find & Match screen. Depending on your individual bank, the detail may also show on the bank statement you import into Xero.
     * @type {string}
     * @memberof BatchPaymentDetails
     */
    reference?: string;
}
/**
 * 
 * @export
 * @interface Bill
 */
export interface Bill {
    /**
     * Day of Month (0-31)
     * @type {number}
     * @memberof Bill
     */
    day?: number;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    type?: BillTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum BillTypeEnum {
    Daysafterbilldate = 'DAYSAFTERBILLDATE',
    Daysafterbillmonth = 'DAYSAFTERBILLMONTH',
    Ofcurrentmonth = 'OFCURRENTMONTH',
    Offollowingmonth = 'OFFOLLOWINGMONTH'
}

/**
 * 
 * @export
 * @interface BrandingTheme
 */
export interface BrandingTheme {
    /**
     * Xero identifier
     * @type {string}
     * @memberof BrandingTheme
     */
    brandingThemeID?: string;
    /**
     * Name of branding theme
     * @type {string}
     * @memberof BrandingTheme
     */
    name?: string;
    /**
     * The location of the image file used as the logo on this branding theme
     * @type {string}
     * @memberof BrandingTheme
     */
    logoUrl?: string;
    /**
     * Always INVOICE
     * @type {string}
     * @memberof BrandingTheme
     */
    type?: BrandingThemeTypeEnum;
    /**
     * Integer – ranked order of branding theme. The default branding theme has a value of 0
     * @type {number}
     * @memberof BrandingTheme
     */
    sortOrder?: number;
    /**
     * UTC timestamp of creation date of branding theme
     * @type {string}
     * @memberof BrandingTheme
     */
    createdDateUTC?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BrandingThemeTypeEnum {
    Invoice = 'INVOICE'
}

/**
 * 
 * @export
 * @interface Client
 */
export interface Client {
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    updatedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    practiceReference?: string;
    /**
     * 
     * @type {object}
     * @memberof Client
     */
    marketingAttributes: object;
    /**
     * 
     * @type {Array<ClientPracticeUserAccess>}
     * @memberof Client
     */
    practiceUserAccess: Array<ClientPracticeUserAccess>;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    practiceId: string;
}
/**
 * 
 * @export
 * @interface ClientFraudOutreachSetting
 */
export interface ClientFraudOutreachSetting {
    /**
     * 
     * @type {string}
     * @memberof ClientFraudOutreachSetting
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ClientFraudOutreachSetting
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof ClientFraudOutreachSetting
     */
    updated: string;
    /**
     * 
     * @type {string}
     * @memberof ClientFraudOutreachSetting
     */
    clientId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClientFraudOutreachSetting
     */
    outreachEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClientFraudOutreachSetting
     */
    outreachLastEnabled?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientFraudOutreachSetting
     */
    outreachLastEnabledBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientFraudOutreachSetting
     */
    outreachLastDisabled?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientFraudOutreachSetting
     */
    outreachLastDisabledBy?: string;
}
/**
 * 
 * @export
 * @interface ClientPracticeUserAccess
 */
export interface ClientPracticeUserAccess {
    /**
     * 
     * @type {string}
     * @memberof ClientPracticeUserAccess
     */
    userId: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientPracticeUserAccess
     */
    inviteId: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientPracticeUserAccess
     */
    canAccess: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientPracticeUserAccess
     */
    isAccountManager: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ClientSortKey {
    Name = 'name',
    PracticeReference = 'practiceReference'
}

/**
 * 
 * @export
 * @interface ClientUserInvite
 */
export interface ClientUserInvite {
    /**
     * 
     * @type {string}
     * @memberof ClientUserInvite
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ClientUserInvite
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof ClientUserInvite
     */
    createdByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof ClientUserInvite
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof ClientUserInvite
     */
    extUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientUserInvite
     */
    extUserSource?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientUserInvite
     */
    recipientName: string;
    /**
     * 
     * @type {string}
     * @memberof ClientUserInvite
     */
    recipientEmail: string;
}
/**
 * 
 * @export
 * @interface CompaniesHouseHttpCompanyProfileRegisteredOfficeAddress
 */
export interface CompaniesHouseHttpCompanyProfileRegisteredOfficeAddress {
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpCompanyProfileRegisteredOfficeAddress
     */
    address_line_1?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpCompanyProfileRegisteredOfficeAddress
     */
    address_line_2?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpCompanyProfileRegisteredOfficeAddress
     */
    care_of?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpCompanyProfileRegisteredOfficeAddress
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpCompanyProfileRegisteredOfficeAddress
     */
    locality?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpCompanyProfileRegisteredOfficeAddress
     */
    po_box?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpCompanyProfileRegisteredOfficeAddress
     */
    postal_code?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpCompanyProfileRegisteredOfficeAddress
     */
    premises?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpCompanyProfileRegisteredOfficeAddress
     */
    region?: string;
}
/**
 * 
 * @export
 * @interface CompaniesHouseHttpNaturalOfficerDisqualification
 */
export interface CompaniesHouseHttpNaturalOfficerDisqualification {
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualification
     */
    date_of_birth?: string;
    /**
     * 
     * @type {Array<CompaniesHouseHttpNaturalOfficerDisqualificationDisqualifications>}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualification
     */
    disqualifications: Array<CompaniesHouseHttpNaturalOfficerDisqualificationDisqualifications>;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualification
     */
    etag: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualification
     */
    forename?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualification
     */
    honours?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualification
     */
    kind: string;
    /**
     * 
     * @type {CompaniesHouseHttpNaturalOfficerDisqualificationLinks}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualification
     */
    links: CompaniesHouseHttpNaturalOfficerDisqualificationLinks;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualification
     */
    nationality?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualification
     */
    other_forenames?: string;
    /**
     * 
     * @type {Array<CompaniesHouseHttpNaturalOfficerDisqualificationPermissionsToAct>}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualification
     */
    permissions_to_act?: Array<CompaniesHouseHttpNaturalOfficerDisqualificationPermissionsToAct>;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualification
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualification
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface CompaniesHouseHttpNaturalOfficerDisqualificationAddress
 */
export interface CompaniesHouseHttpNaturalOfficerDisqualificationAddress {
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationAddress
     */
    address_line_1?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationAddress
     */
    address_line_2?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationAddress
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationAddress
     */
    locality?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationAddress
     */
    postal_code?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationAddress
     */
    premises?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationAddress
     */
    region?: string;
}
/**
 * 
 * @export
 * @interface CompaniesHouseHttpNaturalOfficerDisqualificationDisqualifications
 */
export interface CompaniesHouseHttpNaturalOfficerDisqualificationDisqualifications {
    /**
     * 
     * @type {CompaniesHouseHttpNaturalOfficerDisqualificationAddress}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationDisqualifications
     */
    address: CompaniesHouseHttpNaturalOfficerDisqualificationAddress;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationDisqualifications
     */
    case_identifier?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationDisqualifications
     */
    company_names?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationDisqualifications
     */
    court_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationDisqualifications
     */
    disqualification_type: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationDisqualifications
     */
    disqualified_from: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationDisqualifications
     */
    disqualified_until: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationDisqualifications
     */
    heard_on?: string;
    /**
     * 
     * @type {Array<CompaniesHouseHttpNaturalOfficerDisqualificationLastVariation>}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationDisqualifications
     */
    last_variation?: Array<CompaniesHouseHttpNaturalOfficerDisqualificationLastVariation>;
    /**
     * 
     * @type {CompaniesHouseHttpNaturalOfficerDisqualificationReason}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationDisqualifications
     */
    reason: CompaniesHouseHttpNaturalOfficerDisqualificationReason;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationDisqualifications
     */
    undertaken_on?: string;
}
/**
 * 
 * @export
 * @interface CompaniesHouseHttpNaturalOfficerDisqualificationLastVariation
 */
export interface CompaniesHouseHttpNaturalOfficerDisqualificationLastVariation {
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationLastVariation
     */
    case_identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationLastVariation
     */
    court_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationLastVariation
     */
    varied_on?: string;
}
/**
 * 
 * @export
 * @interface CompaniesHouseHttpNaturalOfficerDisqualificationLinks
 */
export interface CompaniesHouseHttpNaturalOfficerDisqualificationLinks {
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationLinks
     */
    self: string;
}
/**
 * 
 * @export
 * @interface CompaniesHouseHttpNaturalOfficerDisqualificationPermissionsToAct
 */
export interface CompaniesHouseHttpNaturalOfficerDisqualificationPermissionsToAct {
    /**
     * 
     * @type {Array<string>}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationPermissionsToAct
     */
    company_names?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationPermissionsToAct
     */
    court_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationPermissionsToAct
     */
    expires_on: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationPermissionsToAct
     */
    granted_on: string;
}
/**
 * 
 * @export
 * @interface CompaniesHouseHttpNaturalOfficerDisqualificationReason
 */
export interface CompaniesHouseHttpNaturalOfficerDisqualificationReason {
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationReason
     */
    act: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationReason
     */
    article?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationReason
     */
    description_identifier: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpNaturalOfficerDisqualificationReason
     */
    section?: string;
}
/**
 * 
 * @export
 * @interface CompaniesHouseHttpOfficerSummary
 */
export interface CompaniesHouseHttpOfficerSummary {
    /**
     * 
     * @type {CompaniesHouseHttpOfficerSummaryAddress}
     * @memberof CompaniesHouseHttpOfficerSummary
     */
    address: CompaniesHouseHttpOfficerSummaryAddress;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummary
     */
    appointed_before?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummary
     */
    appointed_on: string;
    /**
     * 
     * @type {CompaniesHouseHttpOfficerSummaryContactDetails}
     * @memberof CompaniesHouseHttpOfficerSummary
     */
    contact_details?: CompaniesHouseHttpOfficerSummaryContactDetails;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummary
     */
    country_of_residence?: string;
    /**
     * 
     * @type {CompaniesHouseHttpOfficerSummaryDateOfBirth}
     * @memberof CompaniesHouseHttpOfficerSummary
     */
    date_of_birth?: CompaniesHouseHttpOfficerSummaryDateOfBirth;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummary
     */
    etag?: string;
    /**
     * 
     * @type {Array<CompaniesHouseHttpOfficerSummaryFormerNames>}
     * @memberof CompaniesHouseHttpOfficerSummary
     */
    former_names?: Array<CompaniesHouseHttpOfficerSummaryFormerNames>;
    /**
     * 
     * @type {CompaniesHouseHttpOfficerSummaryIdentification}
     * @memberof CompaniesHouseHttpOfficerSummary
     */
    identification?: CompaniesHouseHttpOfficerSummaryIdentification;
    /**
     * 
     * @type {boolean}
     * @memberof CompaniesHouseHttpOfficerSummary
     */
    is_pre_1992_appointment?: boolean;
    /**
     * 
     * @type {CompaniesHouseHttpOfficerSummaryLinks}
     * @memberof CompaniesHouseHttpOfficerSummary
     */
    links: CompaniesHouseHttpOfficerSummaryLinks;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummary
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummary
     */
    nationality?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummary
     */
    occupation?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummary
     */
    officer_role: string;
    /**
     * 
     * @type {CompaniesHouseHttpOfficerSummaryPrincipalOfficeAddress}
     * @memberof CompaniesHouseHttpOfficerSummary
     */
    principal_office_address?: CompaniesHouseHttpOfficerSummaryPrincipalOfficeAddress;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummary
     */
    resigned_on?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummary
     */
    responsibilities?: string;
}
/**
 * 
 * @export
 * @interface CompaniesHouseHttpOfficerSummaryAddress
 */
export interface CompaniesHouseHttpOfficerSummaryAddress {
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryAddress
     */
    address_line_1: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryAddress
     */
    address_line_2?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryAddress
     */
    care_of?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryAddress
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryAddress
     */
    locality: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryAddress
     */
    po_box?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryAddress
     */
    postal_code?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryAddress
     */
    premises?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryAddress
     */
    region?: string;
}
/**
 * 
 * @export
 * @interface CompaniesHouseHttpOfficerSummaryContactDetails
 */
export interface CompaniesHouseHttpOfficerSummaryContactDetails {
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryContactDetails
     */
    contact_name?: string;
}
/**
 * 
 * @export
 * @interface CompaniesHouseHttpOfficerSummaryDateOfBirth
 */
export interface CompaniesHouseHttpOfficerSummaryDateOfBirth {
    /**
     * 
     * @type {number}
     * @memberof CompaniesHouseHttpOfficerSummaryDateOfBirth
     */
    day?: number;
    /**
     * 
     * @type {number}
     * @memberof CompaniesHouseHttpOfficerSummaryDateOfBirth
     */
    month: number;
    /**
     * 
     * @type {number}
     * @memberof CompaniesHouseHttpOfficerSummaryDateOfBirth
     */
    year: number;
}
/**
 * 
 * @export
 * @interface CompaniesHouseHttpOfficerSummaryFormerNames
 */
export interface CompaniesHouseHttpOfficerSummaryFormerNames {
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryFormerNames
     */
    forenames?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryFormerNames
     */
    surname?: string;
}
/**
 * 
 * @export
 * @interface CompaniesHouseHttpOfficerSummaryIdentification
 */
export interface CompaniesHouseHttpOfficerSummaryIdentification {
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryIdentification
     */
    identification_type?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryIdentification
     */
    legal_authority?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryIdentification
     */
    legal_form?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryIdentification
     */
    place_registered?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryIdentification
     */
    registration_number?: string;
}
/**
 * 
 * @export
 * @interface CompaniesHouseHttpOfficerSummaryLinks
 */
export interface CompaniesHouseHttpOfficerSummaryLinks {
    /**
     * 
     * @type {CompaniesHouseHttpOfficerSummaryLinksOfficer}
     * @memberof CompaniesHouseHttpOfficerSummaryLinks
     */
    officer: CompaniesHouseHttpOfficerSummaryLinksOfficer;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryLinks
     */
    self: string;
}
/**
 * 
 * @export
 * @interface CompaniesHouseHttpOfficerSummaryLinksOfficer
 */
export interface CompaniesHouseHttpOfficerSummaryLinksOfficer {
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryLinksOfficer
     */
    appointments: string;
}
/**
 * 
 * @export
 * @interface CompaniesHouseHttpOfficerSummaryPrincipalOfficeAddress
 */
export interface CompaniesHouseHttpOfficerSummaryPrincipalOfficeAddress {
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryPrincipalOfficeAddress
     */
    address_line_1?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryPrincipalOfficeAddress
     */
    address_line_2?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryPrincipalOfficeAddress
     */
    care_of?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryPrincipalOfficeAddress
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryPrincipalOfficeAddress
     */
    locality?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryPrincipalOfficeAddress
     */
    po_box?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryPrincipalOfficeAddress
     */
    postal_code?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryPrincipalOfficeAddress
     */
    premises?: string;
    /**
     * 
     * @type {string}
     * @memberof CompaniesHouseHttpOfficerSummaryPrincipalOfficeAddress
     */
    region?: string;
}
/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * Xero identifier
     * @type {string}
     * @memberof Contact
     */
    contactID?: string;
    /**
     * ID for the destination of a merged contact. Only returned when using paging or when fetching a contact by ContactId or ContactNumber.
     * @type {string}
     * @memberof Contact
     */
    mergedToContactID?: string;
    /**
     * This can be updated via the API only i.e. This field is read only on the Xero contact screen, used to identify contacts in external systems (max length = 50). If the Contact Number is used, this is displayed as Contact Code in the Contacts UI in Xero.
     * @type {string}
     * @memberof Contact
     */
    contactNumber?: string;
    /**
     * A user defined account number. This can be updated via the API and the Xero UI (max length = 50)
     * @type {string}
     * @memberof Contact
     */
    accountNumber?: string;
    /**
     * Current status of a contact – see contact status types
     * @type {string}
     * @memberof Contact
     */
    contactStatus?: ContactContactStatusEnum;
    /**
     * Full name of contact/organisation (max length = 255)
     * @type {string}
     * @memberof Contact
     */
    name?: string;
    /**
     * First name of contact person (max length = 255)
     * @type {string}
     * @memberof Contact
     */
    firstName?: string;
    /**
     * Last name of contact person (max length = 255)
     * @type {string}
     * @memberof Contact
     */
    lastName?: string;
    /**
     * Company registration number (max length = 50)
     * @type {string}
     * @memberof Contact
     */
    companyNumber?: string;
    /**
     * Email address of contact person (umlauts not supported) (max length  = 255)
     * @type {string}
     * @memberof Contact
     */
    emailAddress?: string;
    /**
     * See contact persons
     * @type {Array<ContactPerson>}
     * @memberof Contact
     */
    contactPersons?: Array<ContactPerson>;
    /**
     * Bank account number of contact
     * @type {string}
     * @memberof Contact
     */
    bankAccountDetails?: string;
    /**
     * Tax number of contact – this is also known as the ABN (Australia), GST Number (New Zealand), VAT Number (UK) or Tax ID Number (US and global) in the Xero UI depending on which regionalized version of Xero you are using (max length = 50)
     * @type {string}
     * @memberof Contact
     */
    taxNumber?: string;
    /**
     * The tax type from TaxRates
     * @type {string}
     * @memberof Contact
     */
    accountsReceivableTaxType?: string;
    /**
     * The tax type from TaxRates
     * @type {string}
     * @memberof Contact
     */
    accountsPayableTaxType?: string;
    /**
     * Store certain address types for a contact – see address types
     * @type {Array<Address>}
     * @memberof Contact
     */
    addresses?: Array<Address>;
    /**
     * Store certain phone types for a contact – see phone types
     * @type {Array<Phone>}
     * @memberof Contact
     */
    phones?: Array<Phone>;
    /**
     * true or false – Boolean that describes if a contact that has any AP  invoices entered against them. Cannot be set via PUT or POST – it is automatically set when an accounts payable invoice is generated against this contact.
     * @type {boolean}
     * @memberof Contact
     */
    isSupplier?: boolean;
    /**
     * true or false – Boolean that describes if a contact has any AR invoices entered against them. Cannot be set via PUT or POST – it is automatically set when an accounts receivable invoice is generated against this contact.
     * @type {boolean}
     * @memberof Contact
     */
    isCustomer?: boolean;
    /**
     * The default sales line amount type for a contact. Only available when summaryOnly parameter or paging is used, or when fetch by ContactId or ContactNumber.
     * @type {string}
     * @memberof Contact
     */
    salesDefaultLineAmountType?: ContactSalesDefaultLineAmountTypeEnum;
    /**
     * The default purchases line amount type for a contact Only available when summaryOnly parameter or paging is used, or when fetch by ContactId or ContactNumber.
     * @type {string}
     * @memberof Contact
     */
    purchasesDefaultLineAmountType?: ContactPurchasesDefaultLineAmountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    defaultCurrency?: ContactDefaultCurrencyEnum;
    /**
     * Store XeroNetworkKey for contacts.
     * @type {string}
     * @memberof Contact
     */
    xeroNetworkKey?: string;
    /**
     * The default sales account code for contacts
     * @type {string}
     * @memberof Contact
     */
    salesDefaultAccountCode?: string;
    /**
     * The default purchases account code for contacts
     * @type {string}
     * @memberof Contact
     */
    purchasesDefaultAccountCode?: string;
    /**
     * The default sales tracking categories for contacts
     * @type {Array<SalesTrackingCategory>}
     * @memberof Contact
     */
    salesTrackingCategories?: Array<SalesTrackingCategory>;
    /**
     * The default purchases tracking categories for contacts
     * @type {Array<SalesTrackingCategory>}
     * @memberof Contact
     */
    purchasesTrackingCategories?: Array<SalesTrackingCategory>;
    /**
     * The name of the Tracking Category assigned to the contact under SalesTrackingCategories and PurchasesTrackingCategories
     * @type {string}
     * @memberof Contact
     */
    trackingCategoryName?: string;
    /**
     * The name of the Tracking Option assigned to the contact under SalesTrackingCategories and PurchasesTrackingCategories
     * @type {string}
     * @memberof Contact
     */
    trackingCategoryOption?: string;
    /**
     * 
     * @type {PaymentTerm}
     * @memberof Contact
     */
    paymentTerms?: PaymentTerm;
    /**
     * UTC timestamp of last update to contact
     * @type {string}
     * @memberof Contact
     */
    updatedDateUTC?: string;
    /**
     * Displays which contact groups a contact is included in
     * @type {Array<ContactGroup>}
     * @memberof Contact
     */
    contactGroups?: Array<ContactGroup>;
    /**
     * Website address for contact (read only)
     * @type {string}
     * @memberof Contact
     */
    website?: string;
    /**
     * 
     * @type {BrandingTheme}
     * @memberof Contact
     */
    brandingTheme?: BrandingTheme;
    /**
     * 
     * @type {BatchPaymentDetails}
     * @memberof Contact
     */
    batchPayments?: BatchPaymentDetails;
    /**
     * The default discount rate for the contact (read only)
     * @type {number}
     * @memberof Contact
     */
    discount?: number;
    /**
     * 
     * @type {Balances}
     * @memberof Contact
     */
    balances?: Balances;
    /**
     * Displays array of attachments from the API
     * @type {Array<Attachment>}
     * @memberof Contact
     */
    attachments?: Array<Attachment>;
    /**
     * A boolean to indicate if a contact has an attachment
     * @type {boolean}
     * @memberof Contact
     */
    hasAttachments?: boolean;
    /**
     * Displays validation errors returned from the API
     * @type {Array<ValidationError>}
     * @memberof Contact
     */
    validationErrors?: Array<ValidationError>;
    /**
     * A boolean to indicate if a contact has an validation errors
     * @type {boolean}
     * @memberof Contact
     */
    hasValidationErrors?: boolean;
    /**
     * Status of object
     * @type {string}
     * @memberof Contact
     */
    statusAttributeString?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ContactContactStatusEnum {
    Active = 'ACTIVE',
    Archived = 'ARCHIVED',
    Gdprrequest = 'GDPRREQUEST'
}
/**
    * @export
    * @enum {string}
    */
export enum ContactSalesDefaultLineAmountTypeEnum {
    Inclusive = 'INCLUSIVE',
    Exclusive = 'EXCLUSIVE',
    None = 'NONE'
}
/**
    * @export
    * @enum {string}
    */
export enum ContactPurchasesDefaultLineAmountTypeEnum {
    Inclusive = 'INCLUSIVE',
    Exclusive = 'EXCLUSIVE',
    None = 'NONE'
}
/**
    * @export
    * @enum {string}
    */
export enum ContactDefaultCurrencyEnum {
    Aed = 'AED',
    Afn = 'AFN',
    All = 'ALL',
    Amd = 'AMD',
    Ang = 'ANG',
    Aoa = 'AOA',
    Ars = 'ARS',
    Aud = 'AUD',
    Awg = 'AWG',
    Azn = 'AZN',
    Bam = 'BAM',
    Bbd = 'BBD',
    Bdt = 'BDT',
    Bgn = 'BGN',
    Bhd = 'BHD',
    Bif = 'BIF',
    Bmd = 'BMD',
    Bnd = 'BND',
    Bob = 'BOB',
    Brl = 'BRL',
    Bsd = 'BSD',
    Btn = 'BTN',
    Bwp = 'BWP',
    Byn = 'BYN',
    Byr = 'BYR',
    Bzd = 'BZD',
    Cad = 'CAD',
    Cdf = 'CDF',
    Chf = 'CHF',
    Clf = 'CLF',
    Clp = 'CLP',
    Cny = 'CNY',
    Cop = 'COP',
    Crc = 'CRC',
    Cuc = 'CUC',
    Cup = 'CUP',
    Cve = 'CVE',
    Czk = 'CZK',
    Djf = 'DJF',
    Dkk = 'DKK',
    Dop = 'DOP',
    Dzd = 'DZD',
    Eek = 'EEK',
    Egp = 'EGP',
    Ern = 'ERN',
    Etb = 'ETB',
    Eur = 'EUR',
    Fjd = 'FJD',
    Fkp = 'FKP',
    Gbp = 'GBP',
    Gel = 'GEL',
    Ghs = 'GHS',
    Gip = 'GIP',
    Gmd = 'GMD',
    Gnf = 'GNF',
    Gtq = 'GTQ',
    Gyd = 'GYD',
    Hkd = 'HKD',
    Hnl = 'HNL',
    Hrk = 'HRK',
    Htg = 'HTG',
    Huf = 'HUF',
    Idr = 'IDR',
    Ils = 'ILS',
    Inr = 'INR',
    Iqd = 'IQD',
    Irr = 'IRR',
    Isk = 'ISK',
    Jmd = 'JMD',
    Jod = 'JOD',
    Jpy = 'JPY',
    Kes = 'KES',
    Kgs = 'KGS',
    Khr = 'KHR',
    Kmf = 'KMF',
    Kpw = 'KPW',
    Krw = 'KRW',
    Kwd = 'KWD',
    Kyd = 'KYD',
    Kzt = 'KZT',
    Lak = 'LAK',
    Lbp = 'LBP',
    Lkr = 'LKR',
    Lrd = 'LRD',
    Lsl = 'LSL',
    Ltl = 'LTL',
    Lvl = 'LVL',
    Lyd = 'LYD',
    Mad = 'MAD',
    Mdl = 'MDL',
    Mga = 'MGA',
    Mkd = 'MKD',
    Mmk = 'MMK',
    Mnt = 'MNT',
    Mop = 'MOP',
    Mro = 'MRO',
    Mru = 'MRU',
    Mur = 'MUR',
    Mvr = 'MVR',
    Mwk = 'MWK',
    Mxn = 'MXN',
    Mxv = 'MXV',
    Myr = 'MYR',
    Mzn = 'MZN',
    Nad = 'NAD',
    Ngn = 'NGN',
    Nio = 'NIO',
    Nok = 'NOK',
    Npr = 'NPR',
    Nzd = 'NZD',
    Omr = 'OMR',
    Pab = 'PAB',
    Pen = 'PEN',
    Pgk = 'PGK',
    Php = 'PHP',
    Pkr = 'PKR',
    Pln = 'PLN',
    Pyg = 'PYG',
    Qar = 'QAR',
    Ron = 'RON',
    Rsd = 'RSD',
    Rub = 'RUB',
    Rwf = 'RWF',
    Sar = 'SAR',
    Sbd = 'SBD',
    Scr = 'SCR',
    Sdg = 'SDG',
    Sek = 'SEK',
    Sgd = 'SGD',
    Shp = 'SHP',
    Skk = 'SKK',
    Sle = 'SLE',
    Sll = 'SLL',
    Sos = 'SOS',
    Srd = 'SRD',
    Std = 'STD',
    Stn = 'STN',
    Svc = 'SVC',
    Syp = 'SYP',
    Szl = 'SZL',
    Thb = 'THB',
    Tjs = 'TJS',
    Tmt = 'TMT',
    Tnd = 'TND',
    Top = 'TOP',
    Try = 'TRY',
    TryLira = 'TRY_LIRA',
    Ttd = 'TTD',
    Twd = 'TWD',
    Tzs = 'TZS',
    Uah = 'UAH',
    Ugx = 'UGX',
    Usd = 'USD',
    Uyu = 'UYU',
    Uzs = 'UZS',
    Vef = 'VEF',
    Ves = 'VES',
    Vnd = 'VND',
    Vuv = 'VUV',
    Wst = 'WST',
    Xaf = 'XAF',
    Xcd = 'XCD',
    Xof = 'XOF',
    Xpf = 'XPF',
    Yer = 'YER',
    Zar = 'ZAR',
    Zmw = 'ZMW',
    Zmk = 'ZMK',
    Zwd = 'ZWD'
}

/**
 * 
 * @export
 * @interface ContactAnalysis
 */
export interface ContactAnalysis {
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysis
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysis
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysis
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysis
     */
    jobId: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysis
     */
    jobName: string;
    /**
     * 
     * @type {number}
     * @memberof ContactAnalysis
     */
    itemCount: number;
    /**
     * 
     * @type {Array<ContactAnalysisItem>}
     * @memberof ContactAnalysis
     */
    items?: Array<ContactAnalysisItem>;
    /**
     * 
     * @type {number}
     * @memberof ContactAnalysis
     */
    conclusionCount: number;
    /**
     * 
     * @type {Array<ContactAnalysisConclusion>}
     * @memberof ContactAnalysis
     */
    conclusions?: Array<ContactAnalysisConclusion>;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysis
     */
    xeroTenantId: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysis
     */
    xeroContactId: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysis
     */
    correlationId: string;
    /**
     * 
     * @type {NormalizedContact}
     * @memberof ContactAnalysis
     */
    normalizedContact: NormalizedContact;
}
/**
 * 
 * @export
 * @interface ContactAnalysisCompanyNatureOfBusiness
 */
export interface ContactAnalysisCompanyNatureOfBusiness {
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisCompanyNatureOfBusiness
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisCompanyNatureOfBusiness
     */
    description: string;
}
/**
 * 
 * @export
 * @interface ContactAnalysisCompanyPreviousName
 */
export interface ContactAnalysisCompanyPreviousName {
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisCompanyPreviousName
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisCompanyPreviousName
     */
    effectiveFrom: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisCompanyPreviousName
     */
    ceasedOn: string;
}
/**
 * 
 * @export
 * @interface ContactAnalysisConclusion
 */
export interface ContactAnalysisConclusion {
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisConclusion
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisConclusion
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisConclusion
     */
    contactAnalysisId: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisConclusion
     */
    type: ContactAnalysisConclusionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisConclusion
     */
    correlationId: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContactAnalysisConclusion
     */
    confidence: number | null;
    /**
     * 
     * @type {ContactAnalysisData}
     * @memberof ContactAnalysisConclusion
     */
    data: ContactAnalysisData;
    /**
     * 
     * @type {object}
     * @memberof ContactAnalysisConclusion
     */
    meta: object | null;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisConclusion
     */
    subjectMatterId: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ContactAnalysisConclusionTypeEnum {
    ContactCompanyNumberLikelyToBeIncorrect = 'ContactCompanyNumberLikelyToBeIncorrect',
    ContactExplicitMatchToCompany = 'ContactExplicitMatchToCompany',
    ContactStrongMatchToCompany = 'ContactStrongMatchToCompany',
    ContactWeakMatchToCompany = 'ContactWeakMatchToCompany',
    ContactCouldNotBeMatchedToCompany = 'ContactCouldNotBeMatchedToCompany',
    CompanyHasArchivalStatus = 'CompanyHasArchivalStatus',
    CompanyHasDistressedStatus = 'CompanyHasDistressedStatus',
    CompanyHasPoBoxAddress = 'CompanyHasPoBoxAddress',
    CompanyHasPreviousNamesInPast12Months = 'CompanyHasPreviousNamesInPast12Months',
    CompanyHasAccountsOverdue = 'CompanyHasAccountsOverdue',
    CompanyHasConfirmationStatementOverdue = 'CompanyHasConfirmationStatementOverdue',
    CompanyIsLessThan12MonthsOld = 'CompanyIsLessThan12MonthsOld',
    CompanyIsLessThan24MonthsOld = 'CompanyIsLessThan24MonthsOld',
    CompanyHasOnlyOneOfficer = 'CompanyHasOnlyOneOfficer',
    CompanyHasOfficersAppointedInPast6Months = 'CompanyHasOfficersAppointedInPast6Months',
    CompanyHasOfficersResidingOverseas = 'CompanyHasOfficersResidingOverseas',
    CompanyHasOfficersWithDisqualifications = 'CompanyHasOfficersWithDisqualifications',
    ContactHasBilled = 'ContactHasBilled',
    ContactHasBeenInvoiced = 'ContactHasBeenInvoiced',
    ContactHasBankTransactions = 'ContactHasBankTransactions',
    CompanyHasOfficersCount = 'CompanyHasOfficersCount',
    CompanyHasNatureOfBusiness = 'CompanyHasNatureOfBusiness',
    CompanyHasPreviousNames = 'CompanyHasPreviousNames',
    ContactHasMonthlyTransactionValueHistory = 'ContactHasMonthlyTransactionValueHistory',
    ContactHasMonthlyTransactionCountHistory = 'ContactHasMonthlyTransactionCountHistory'
}

/**
 * 
 * @export
 * @interface ContactAnalysisData
 */
export interface ContactAnalysisData {
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisData
     */
    companyStatus?: ContactAnalysisDataCompanyStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisData
     */
    companyStatusDetail?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisData
     */
    dateOfCreation?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisData
     */
    detected?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisData
     */
    lastMadeUpTo?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisData
     */
    nextDue?: string;
    /**
     * 
     * @type {CompaniesHouseHttpOfficerSummary}
     * @memberof ContactAnalysisData
     */
    officer?: CompaniesHouseHttpOfficerSummary;
    /**
     * 
     * @type {Array<CompaniesHouseHttpNaturalOfficerDisqualification>}
     * @memberof ContactAnalysisData
     */
    officerDisqualifications?: Array<CompaniesHouseHttpNaturalOfficerDisqualification>;
    /**
     * 
     * @type {Array<CompaniesHouseHttpOfficerSummary>}
     * @memberof ContactAnalysisData
     */
    officers?: Array<CompaniesHouseHttpOfficerSummary>;
    /**
     * 
     * @type {Array<ContactAnalysisCompanyPreviousName>}
     * @memberof ContactAnalysisData
     */
    previousNames?: Array<ContactAnalysisCompanyPreviousName>;
    /**
     * 
     * @type {CompaniesHouseHttpCompanyProfileRegisteredOfficeAddress}
     * @memberof ContactAnalysisData
     */
    registeredOfficeAddress?: CompaniesHouseHttpCompanyProfileRegisteredOfficeAddress;
    /**
     * 
     * @type {SeekCompanyResult}
     * @memberof ContactAnalysisData
     */
    seekCompanyResult?: SeekCompanyResult;
    /**
     * 
     * @type {Array<ContactAnalysisCompanyNatureOfBusiness>}
     * @memberof ContactAnalysisData
     */
    natureOfBusiness?: Array<ContactAnalysisCompanyNatureOfBusiness>;
    /**
     * 
     * @type {number}
     * @memberof ContactAnalysisData
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof ContactAnalysisData
     */
    sumValue?: number;
    /**
     * 
     * @type {number}
     * @memberof ContactAnalysisData
     */
    minValue?: number;
    /**
     * 
     * @type {number}
     * @memberof ContactAnalysisData
     */
    maxValue?: number;
    /**
     * 
     * @type {number}
     * @memberof ContactAnalysisData
     */
    meanValue?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisData
     */
    latest?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisData
     */
    earliest?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactAnalysisData
     */
    valueStdDev?: number;
    /**
     * 
     * @type {object}
     * @memberof ContactAnalysisData
     */
    yearMonthTimeSeries?: object;
}

/**
    * @export
    * @enum {string}
    */
export enum ContactAnalysisDataCompanyStatusEnum {
    Active = 'active',
    Dissolved = 'dissolved',
    Liquidation = 'liquidation',
    Receivership = 'receivership',
    ConvertedClosed = 'converted-closed',
    VoluntaryArrangement = 'voluntary-arrangement',
    InsolvencyProceedings = 'insolvency-proceedings',
    Administration = 'administration',
    Open = 'open',
    Closed = 'closed',
    Registered = 'registered',
    Removed = 'removed'
}

/**
 * 
 * @export
 * @interface ContactAnalysisItem
 */
export interface ContactAnalysisItem {
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisItem
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisItem
     */
    contactAnalysisId: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisItem
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisItem
     */
    type: ContactAnalysisItemTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof ContactAnalysisItem
     */
    inputs: object;
    /**
     * 
     * @type {object}
     * @memberof ContactAnalysisItem
     */
    results: object;
}

/**
    * @export
    * @enum {string}
    */
export enum ContactAnalysisItemTypeEnum {
    CompaniesHouseAdvancedSearch = 'CompaniesHouseAdvancedSearch',
    CompaniesHouseAlphabeticalSearch = 'CompaniesHouseAlphabeticalSearch',
    CompaniesHouseGetCompanyProfileByContactCompanyNumber = 'CompaniesHouseGetCompanyProfileByContactCompanyNumber',
    SeekCompanyResultScored = 'SeekCompanyResultScored',
    SeekCompanyFindingsGeneratedFromResults = 'SeekCompanyFindingsGeneratedFromResults',
    SeekCompanyConclusionsDerivedFromFindings = 'SeekCompanyConclusionsDerivedFromFindings',
    AnalyseMatchedCompanyConclusionsGenerated = 'AnalyseMatchedCompanyConclusionsGenerated',
    CompaniesHouseGetCompanyProfileByMatchedCompanyNumber = 'CompaniesHouseGetCompanyProfileByMatchedCompanyNumber',
    CompaniesHouseGetCompanyOfficersByMatchedCompanyNumber = 'CompaniesHouseGetCompanyOfficersByMatchedCompanyNumber',
    CompaniesHouseGetNaturalOfficerDisqualificationsByOfficerId = 'CompaniesHouseGetNaturalOfficerDisqualificationsByOfficerId',
    CompaniesHouseBasicSearch = 'CompaniesHouseBasicSearch'
}

/**
 * 
 * @export
 * @interface ContactAnalysisState
 */
export interface ContactAnalysisState {
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisState
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisState
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisState
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisState
     */
    lastAnalysisJobCompletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisState
     */
    lastAnalysisJobCompletedId?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisState
     */
    lastAnalysisJobCompletedName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisState
     */
    lastContactAnalysisCompletedId?: string;
    /**
     * 
     * @type {Array<ContactAnalysisStateConclusion>}
     * @memberof ContactAnalysisState
     */
    conclusions?: Array<ContactAnalysisStateConclusion>;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisState
     */
    xeroTenantId: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisState
     */
    xeroContactId: string;
    /**
     * 
     * @type {NormalizedContact}
     * @memberof ContactAnalysisState
     */
    normalizedContact: NormalizedContact;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisState
     */
    lastAnalysisJobStartedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisState
     */
    lastAnalysisJobStartedId?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisState
     */
    lastAnalysisJobStartedName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisState
     */
    lastContactAnalysisStartedId?: string;
}
/**
 * 
 * @export
 * @interface ContactAnalysisStateConclusion
 */
export interface ContactAnalysisStateConclusion {
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisStateConclusion
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisStateConclusion
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisStateConclusion
     */
    contactAnalysisId: string;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisStateConclusion
     */
    type: ContactAnalysisStateConclusionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisStateConclusion
     */
    correlationId: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContactAnalysisStateConclusion
     */
    confidence: number | null;
    /**
     * 
     * @type {ContactAnalysisData}
     * @memberof ContactAnalysisStateConclusion
     */
    data: ContactAnalysisData;
    /**
     * 
     * @type {object}
     * @memberof ContactAnalysisStateConclusion
     */
    meta: object | null;
    /**
     * 
     * @type {string}
     * @memberof ContactAnalysisStateConclusion
     */
    subjectMatterId: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ContactAnalysisStateConclusionTypeEnum {
    ContactCompanyNumberLikelyToBeIncorrect = 'ContactCompanyNumberLikelyToBeIncorrect',
    ContactExplicitMatchToCompany = 'ContactExplicitMatchToCompany',
    ContactStrongMatchToCompany = 'ContactStrongMatchToCompany',
    ContactWeakMatchToCompany = 'ContactWeakMatchToCompany',
    ContactCouldNotBeMatchedToCompany = 'ContactCouldNotBeMatchedToCompany',
    CompanyHasArchivalStatus = 'CompanyHasArchivalStatus',
    CompanyHasDistressedStatus = 'CompanyHasDistressedStatus',
    CompanyHasPoBoxAddress = 'CompanyHasPoBoxAddress',
    CompanyHasPreviousNamesInPast12Months = 'CompanyHasPreviousNamesInPast12Months',
    CompanyHasAccountsOverdue = 'CompanyHasAccountsOverdue',
    CompanyHasConfirmationStatementOverdue = 'CompanyHasConfirmationStatementOverdue',
    CompanyIsLessThan12MonthsOld = 'CompanyIsLessThan12MonthsOld',
    CompanyIsLessThan24MonthsOld = 'CompanyIsLessThan24MonthsOld',
    CompanyHasOnlyOneOfficer = 'CompanyHasOnlyOneOfficer',
    CompanyHasOfficersAppointedInPast6Months = 'CompanyHasOfficersAppointedInPast6Months',
    CompanyHasOfficersResidingOverseas = 'CompanyHasOfficersResidingOverseas',
    CompanyHasOfficersWithDisqualifications = 'CompanyHasOfficersWithDisqualifications',
    ContactHasBilled = 'ContactHasBilled',
    ContactHasBeenInvoiced = 'ContactHasBeenInvoiced',
    ContactHasBankTransactions = 'ContactHasBankTransactions',
    CompanyHasOfficersCount = 'CompanyHasOfficersCount',
    CompanyHasNatureOfBusiness = 'CompanyHasNatureOfBusiness',
    CompanyHasPreviousNames = 'CompanyHasPreviousNames',
    ContactHasMonthlyTransactionValueHistory = 'ContactHasMonthlyTransactionValueHistory',
    ContactHasMonthlyTransactionCountHistory = 'ContactHasMonthlyTransactionCountHistory'
}

/**
 * 
 * @export
 * @interface ContactGroup
 */
export interface ContactGroup {
    /**
     * The Name of the contact group. Required when creating a new contact  group
     * @type {string}
     * @memberof ContactGroup
     */
    name?: string;
    /**
     * The Status of a contact group. To delete a contact group update the status to DELETED. Only contact groups with a status of ACTIVE are returned on GETs.
     * @type {string}
     * @memberof ContactGroup
     */
    status?: ContactGroupStatusEnum;
    /**
     * The Xero identifier for an contact group – specified as a string following the endpoint name. e.g. /297c2dc5-cc47-4afd-8ec8-74990b8761e9
     * @type {string}
     * @memberof ContactGroup
     */
    contactGroupID?: string;
    /**
     * The ContactID and Name of Contacts in a contact group. Returned on GETs when the ContactGroupID is supplied in the URL.
     * @type {Array<Contact>}
     * @memberof ContactGroup
     */
    contacts?: Array<Contact>;
}

/**
    * @export
    * @enum {string}
    */
export enum ContactGroupStatusEnum {
    Active = 'ACTIVE',
    Deleted = 'DELETED'
}

/**
 * 
 * @export
 * @interface ContactPerson
 */
export interface ContactPerson {
    /**
     * First name of person
     * @type {string}
     * @memberof ContactPerson
     */
    firstName?: string;
    /**
     * Last name of person
     * @type {string}
     * @memberof ContactPerson
     */
    lastName?: string;
    /**
     * Email address of person
     * @type {string}
     * @memberof ContactPerson
     */
    emailAddress?: string;
    /**
     * boolean to indicate whether contact should be included on emails with invoices etc.
     * @type {boolean}
     * @memberof ContactPerson
     */
    includeInEmails?: boolean;
}
/**
 * 
 * @export
 * @interface CreateAdditionalAlertingContactDto
 */
export interface CreateAdditionalAlertingContactDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAdditionalAlertingContactDto
     */
    recipientEmail: string;
}
/**
 * 
 * @export
 * @interface CreateClientUserInviteDto
 */
export interface CreateClientUserInviteDto {
    /**
     * 
     * @type {string}
     * @memberof CreateClientUserInviteDto
     */
    recipientName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientUserInviteDto
     */
    recipientEmail: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientUserInviteDto
     */
    extUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientUserInviteDto
     */
    extUserSource?: string;
}
/**
 * 
 * @export
 * @interface CreatePracticeClientDto
 */
export interface CreatePracticeClientDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePracticeClientDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePracticeClientDto
     */
    practiceReference?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePracticeClientDto
     */
    industry?: string;
}
/**
 * 
 * @export
 * @interface CreatePracticeUserInviteDto
 */
export interface CreatePracticeUserInviteDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePracticeUserInviteDto
     */
    recipientFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePracticeUserInviteDto
     */
    recipientLastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePracticeUserInviteDto
     */
    recipientEmail: string;
}
/**
 * 
 * @export
 * @interface CreditNote
 */
export interface CreditNote {
    /**
     * See Credit Note Types
     * @type {string}
     * @memberof CreditNote
     */
    type?: CreditNoteTypeEnum;
    /**
     * 
     * @type {Contact}
     * @memberof CreditNote
     */
    contact?: Contact;
    /**
     * The date the credit note is issued YYYY-MM-DD. If the Date element is not specified then it will default to the current date based on the timezone setting of the organisation
     * @type {string}
     * @memberof CreditNote
     */
    date?: string;
    /**
     * Date invoice is due – YYYY-MM-DD
     * @type {string}
     * @memberof CreditNote
     */
    dueDate?: string;
    /**
     * See Credit Note Status Codes
     * @type {string}
     * @memberof CreditNote
     */
    status?: CreditNoteStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreditNote
     */
    lineAmountTypes?: CreditNoteLineAmountTypesEnum;
    /**
     * See Invoice Line Items
     * @type {Array<LineItem>}
     * @memberof CreditNote
     */
    lineItems?: Array<LineItem>;
    /**
     * The subtotal of the credit note excluding taxes
     * @type {number}
     * @memberof CreditNote
     */
    subTotal?: number;
    /**
     * The total tax on the credit note
     * @type {number}
     * @memberof CreditNote
     */
    totalTax?: number;
    /**
     * The total of the Credit Note(subtotal + total tax)
     * @type {number}
     * @memberof CreditNote
     */
    total?: number;
    /**
     * CIS deduction for UK contractors
     * @type {number}
     * @memberof CreditNote
     */
    cISDeduction?: number;
    /**
     * CIS Deduction rate for the organisation
     * @type {number}
     * @memberof CreditNote
     */
    cISRate?: number;
    /**
     * UTC timestamp of last update to the credit note
     * @type {string}
     * @memberof CreditNote
     */
    updatedDateUTC?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditNote
     */
    currencyCode?: CreditNoteCurrencyCodeEnum;
    /**
     * Date when credit note was fully paid(UTC format)
     * @type {string}
     * @memberof CreditNote
     */
    fullyPaidOnDate?: string;
    /**
     * Xero generated unique identifier
     * @type {string}
     * @memberof CreditNote
     */
    creditNoteID?: string;
    /**
     * ACCRECCREDIT – Unique alpha numeric code identifying credit note (when missing will auto-generate from your Organisation Invoice Settings)
     * @type {string}
     * @memberof CreditNote
     */
    creditNoteNumber?: string;
    /**
     * ACCRECCREDIT only – additional reference number
     * @type {string}
     * @memberof CreditNote
     */
    reference?: string;
    /**
     * boolean to indicate if a credit note has been sent to a contact via  the Xero app (currently read only)
     * @type {boolean}
     * @memberof CreditNote
     */
    sentToContact?: boolean;
    /**
     * The currency rate for a multicurrency invoice. If no rate is specified, the XE.com day rate is used
     * @type {number}
     * @memberof CreditNote
     */
    currencyRate?: number;
    /**
     * The remaining credit balance on the Credit Note
     * @type {number}
     * @memberof CreditNote
     */
    remainingCredit?: number;
    /**
     * See Allocations
     * @type {Array<Allocation>}
     * @memberof CreditNote
     */
    allocations?: Array<Allocation>;
    /**
     * The amount of applied to an invoice
     * @type {number}
     * @memberof CreditNote
     */
    appliedAmount?: number;
    /**
     * See Payments
     * @type {Array<Payment>}
     * @memberof CreditNote
     */
    payments?: Array<Payment>;
    /**
     * See BrandingThemes
     * @type {string}
     * @memberof CreditNote
     */
    brandingThemeID?: string;
    /**
     * A string to indicate if a invoice status
     * @type {string}
     * @memberof CreditNote
     */
    statusAttributeString?: string;
    /**
     * boolean to indicate if a credit note has an attachment
     * @type {boolean}
     * @memberof CreditNote
     */
    hasAttachments?: boolean;
    /**
     * A boolean to indicate if a credit note has an validation errors
     * @type {boolean}
     * @memberof CreditNote
     */
    hasErrors?: boolean;
    /**
     * Displays array of validation error messages from the API
     * @type {Array<ValidationError>}
     * @memberof CreditNote
     */
    validationErrors?: Array<ValidationError>;
    /**
     * Displays array of warning messages from the API
     * @type {Array<ValidationError>}
     * @memberof CreditNote
     */
    warnings?: Array<ValidationError>;
}

/**
    * @export
    * @enum {string}
    */
export enum CreditNoteTypeEnum {
    Accpaycredit = 'ACCPAYCREDIT',
    Accreccredit = 'ACCRECCREDIT'
}
/**
    * @export
    * @enum {string}
    */
export enum CreditNoteStatusEnum {
    Draft = 'DRAFT',
    Submitted = 'SUBMITTED',
    Deleted = 'DELETED',
    Authorised = 'AUTHORISED',
    Paid = 'PAID',
    Voided = 'VOIDED'
}
/**
    * @export
    * @enum {string}
    */
export enum CreditNoteLineAmountTypesEnum {
    Exclusive = 'Exclusive',
    Inclusive = 'Inclusive',
    NoTax = 'NoTax'
}
/**
    * @export
    * @enum {string}
    */
export enum CreditNoteCurrencyCodeEnum {
    Aed = 'AED',
    Afn = 'AFN',
    All = 'ALL',
    Amd = 'AMD',
    Ang = 'ANG',
    Aoa = 'AOA',
    Ars = 'ARS',
    Aud = 'AUD',
    Awg = 'AWG',
    Azn = 'AZN',
    Bam = 'BAM',
    Bbd = 'BBD',
    Bdt = 'BDT',
    Bgn = 'BGN',
    Bhd = 'BHD',
    Bif = 'BIF',
    Bmd = 'BMD',
    Bnd = 'BND',
    Bob = 'BOB',
    Brl = 'BRL',
    Bsd = 'BSD',
    Btn = 'BTN',
    Bwp = 'BWP',
    Byn = 'BYN',
    Byr = 'BYR',
    Bzd = 'BZD',
    Cad = 'CAD',
    Cdf = 'CDF',
    Chf = 'CHF',
    Clf = 'CLF',
    Clp = 'CLP',
    Cny = 'CNY',
    Cop = 'COP',
    Crc = 'CRC',
    Cuc = 'CUC',
    Cup = 'CUP',
    Cve = 'CVE',
    Czk = 'CZK',
    Djf = 'DJF',
    Dkk = 'DKK',
    Dop = 'DOP',
    Dzd = 'DZD',
    Eek = 'EEK',
    Egp = 'EGP',
    Ern = 'ERN',
    Etb = 'ETB',
    Eur = 'EUR',
    Fjd = 'FJD',
    Fkp = 'FKP',
    Gbp = 'GBP',
    Gel = 'GEL',
    Ghs = 'GHS',
    Gip = 'GIP',
    Gmd = 'GMD',
    Gnf = 'GNF',
    Gtq = 'GTQ',
    Gyd = 'GYD',
    Hkd = 'HKD',
    Hnl = 'HNL',
    Hrk = 'HRK',
    Htg = 'HTG',
    Huf = 'HUF',
    Idr = 'IDR',
    Ils = 'ILS',
    Inr = 'INR',
    Iqd = 'IQD',
    Irr = 'IRR',
    Isk = 'ISK',
    Jmd = 'JMD',
    Jod = 'JOD',
    Jpy = 'JPY',
    Kes = 'KES',
    Kgs = 'KGS',
    Khr = 'KHR',
    Kmf = 'KMF',
    Kpw = 'KPW',
    Krw = 'KRW',
    Kwd = 'KWD',
    Kyd = 'KYD',
    Kzt = 'KZT',
    Lak = 'LAK',
    Lbp = 'LBP',
    Lkr = 'LKR',
    Lrd = 'LRD',
    Lsl = 'LSL',
    Ltl = 'LTL',
    Lvl = 'LVL',
    Lyd = 'LYD',
    Mad = 'MAD',
    Mdl = 'MDL',
    Mga = 'MGA',
    Mkd = 'MKD',
    Mmk = 'MMK',
    Mnt = 'MNT',
    Mop = 'MOP',
    Mro = 'MRO',
    Mru = 'MRU',
    Mur = 'MUR',
    Mvr = 'MVR',
    Mwk = 'MWK',
    Mxn = 'MXN',
    Mxv = 'MXV',
    Myr = 'MYR',
    Mzn = 'MZN',
    Nad = 'NAD',
    Ngn = 'NGN',
    Nio = 'NIO',
    Nok = 'NOK',
    Npr = 'NPR',
    Nzd = 'NZD',
    Omr = 'OMR',
    Pab = 'PAB',
    Pen = 'PEN',
    Pgk = 'PGK',
    Php = 'PHP',
    Pkr = 'PKR',
    Pln = 'PLN',
    Pyg = 'PYG',
    Qar = 'QAR',
    Ron = 'RON',
    Rsd = 'RSD',
    Rub = 'RUB',
    Rwf = 'RWF',
    Sar = 'SAR',
    Sbd = 'SBD',
    Scr = 'SCR',
    Sdg = 'SDG',
    Sek = 'SEK',
    Sgd = 'SGD',
    Shp = 'SHP',
    Skk = 'SKK',
    Sle = 'SLE',
    Sll = 'SLL',
    Sos = 'SOS',
    Srd = 'SRD',
    Std = 'STD',
    Stn = 'STN',
    Svc = 'SVC',
    Syp = 'SYP',
    Szl = 'SZL',
    Thb = 'THB',
    Tjs = 'TJS',
    Tmt = 'TMT',
    Tnd = 'TND',
    Top = 'TOP',
    Try = 'TRY',
    TryLira = 'TRY_LIRA',
    Ttd = 'TTD',
    Twd = 'TWD',
    Tzs = 'TZS',
    Uah = 'UAH',
    Ugx = 'UGX',
    Usd = 'USD',
    Uyu = 'UYU',
    Uzs = 'UZS',
    Vef = 'VEF',
    Ves = 'VES',
    Vnd = 'VND',
    Vuv = 'VUV',
    Wst = 'WST',
    Xaf = 'XAF',
    Xcd = 'XCD',
    Xof = 'XOF',
    Xpf = 'XPF',
    Yer = 'YER',
    Zar = 'ZAR',
    Zmw = 'ZMW',
    Zmk = 'ZMK',
    Zwd = 'ZWD'
}

/**
 * 
 * @export
 * @interface ExternalLink
 */
export interface ExternalLink {
    /**
     * See External link types
     * @type {string}
     * @memberof ExternalLink
     */
    linkType?: ExternalLinkLinkTypeEnum;
    /**
     * URL for service e.g. http://twitter.com/xeroapi
     * @type {string}
     * @memberof ExternalLink
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalLink
     */
    description?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ExternalLinkLinkTypeEnum {
    Facebook = 'Facebook',
    GooglePlus = 'GooglePlus',
    LinkedIn = 'LinkedIn',
    Twitter = 'Twitter',
    Website = 'Website'
}

/**
 * 
 * @export
 * @interface HistoryRecord
 */
export interface HistoryRecord {
    /**
     * details
     * @type {string}
     * @memberof HistoryRecord
     */
    details?: string;
    /**
     * Name of branding theme
     * @type {string}
     * @memberof HistoryRecord
     */
    changes?: string;
    /**
     * has a value of 0
     * @type {string}
     * @memberof HistoryRecord
     */
    user?: string;
    /**
     * UTC timestamp of creation date of branding theme
     * @type {string}
     * @memberof HistoryRecord
     */
    dateUTC?: string;
}
/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * See Invoice Types
     * @type {string}
     * @memberof Invoice
     */
    type?: InvoiceTypeEnum;
    /**
     * 
     * @type {Contact}
     * @memberof Invoice
     */
    contact?: Contact;
    /**
     * See LineItems
     * @type {Array<LineItem>}
     * @memberof Invoice
     */
    lineItems?: Array<LineItem>;
    /**
     * Date invoice was issued – YYYY-MM-DD. If the Date element is not specified it will default to the current date based on the timezone setting of the organisation
     * @type {string}
     * @memberof Invoice
     */
    date?: string;
    /**
     * Date invoice is due – YYYY-MM-DD
     * @type {string}
     * @memberof Invoice
     */
    dueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    lineAmountTypes?: InvoiceLineAmountTypesEnum;
    /**
     * ACCREC – Unique alpha numeric code identifying invoice (when missing will auto-generate from your Organisation Invoice Settings) (max length = 255)
     * @type {string}
     * @memberof Invoice
     */
    invoiceNumber?: string;
    /**
     * ACCREC only – additional reference number
     * @type {string}
     * @memberof Invoice
     */
    reference?: string;
    /**
     * See BrandingThemes
     * @type {string}
     * @memberof Invoice
     */
    brandingThemeID?: string;
    /**
     * URL link to a source document – shown as “Go to [appName]” in the Xero app
     * @type {string}
     * @memberof Invoice
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    currencyCode?: InvoiceCurrencyCodeEnum;
    /**
     * The currency rate for a multicurrency invoice. If no rate is specified, the XE.com day rate is used. (max length = [18].[6])
     * @type {number}
     * @memberof Invoice
     */
    currencyRate?: number;
    /**
     * See Invoice Status Codes
     * @type {string}
     * @memberof Invoice
     */
    status?: InvoiceStatusEnum;
    /**
     * Boolean to set whether the invoice in the Xero app should be marked as “sent”. This can be set only on invoices that have been approved
     * @type {boolean}
     * @memberof Invoice
     */
    sentToContact?: boolean;
    /**
     * Shown on sales invoices (Accounts Receivable) when this has been set
     * @type {string}
     * @memberof Invoice
     */
    expectedPaymentDate?: string;
    /**
     * Shown on bills (Accounts Payable) when this has been set
     * @type {string}
     * @memberof Invoice
     */
    plannedPaymentDate?: string;
    /**
     * CIS deduction for UK contractors
     * @type {number}
     * @memberof Invoice
     */
    cISDeduction?: number;
    /**
     * CIS Deduction rate for the organisation
     * @type {number}
     * @memberof Invoice
     */
    cISRate?: number;
    /**
     * Total of invoice excluding taxes
     * @type {number}
     * @memberof Invoice
     */
    subTotal?: number;
    /**
     * Total tax on invoice
     * @type {number}
     * @memberof Invoice
     */
    totalTax?: number;
    /**
     * Total of Invoice tax inclusive (i.e. SubTotal + TotalTax). This will be ignored if it doesn’t equal the sum of the LineAmounts
     * @type {number}
     * @memberof Invoice
     */
    total?: number;
    /**
     * Total of discounts applied on the invoice line items
     * @type {number}
     * @memberof Invoice
     */
    totalDiscount?: number;
    /**
     * Xero generated unique identifier for invoice
     * @type {string}
     * @memberof Invoice
     */
    invoiceID?: string;
    /**
     * Xero generated unique identifier for repeating invoices
     * @type {string}
     * @memberof Invoice
     */
    repeatingInvoiceID?: string;
    /**
     * boolean to indicate if an invoice has an attachment
     * @type {boolean}
     * @memberof Invoice
     */
    hasAttachments?: boolean;
    /**
     * boolean to indicate if an invoice has a discount
     * @type {boolean}
     * @memberof Invoice
     */
    isDiscounted?: boolean;
    /**
     * See Payments
     * @type {Array<Payment>}
     * @memberof Invoice
     */
    payments?: Array<Payment>;
    /**
     * See Prepayments
     * @type {Array<Prepayment>}
     * @memberof Invoice
     */
    prepayments?: Array<Prepayment>;
    /**
     * See Overpayments
     * @type {Array<Overpayment>}
     * @memberof Invoice
     */
    overpayments?: Array<Overpayment>;
    /**
     * Amount remaining to be paid on invoice
     * @type {number}
     * @memberof Invoice
     */
    amountDue?: number;
    /**
     * Sum of payments received for invoice
     * @type {number}
     * @memberof Invoice
     */
    amountPaid?: number;
    /**
     * The date the invoice was fully paid. Only returned on fully paid invoices
     * @type {string}
     * @memberof Invoice
     */
    fullyPaidOnDate?: string;
    /**
     * Sum of all credit notes, over-payments and pre-payments applied to invoice
     * @type {number}
     * @memberof Invoice
     */
    amountCredited?: number;
    /**
     * Last modified date UTC format
     * @type {string}
     * @memberof Invoice
     */
    updatedDateUTC?: string;
    /**
     * Details of credit notes that have been applied to an invoice
     * @type {Array<CreditNote>}
     * @memberof Invoice
     */
    creditNotes?: Array<CreditNote>;
    /**
     * Displays array of attachments from the API
     * @type {Array<Attachment>}
     * @memberof Invoice
     */
    attachments?: Array<Attachment>;
    /**
     * A boolean to indicate if a invoice has an validation errors
     * @type {boolean}
     * @memberof Invoice
     */
    hasErrors?: boolean;
    /**
     * A string to indicate if a invoice status
     * @type {string}
     * @memberof Invoice
     */
    statusAttributeString?: string;
    /**
     * Displays array of validation error messages from the API
     * @type {Array<ValidationError>}
     * @memberof Invoice
     */
    validationErrors?: Array<ValidationError>;
    /**
     * Displays array of warning messages from the API
     * @type {Array<ValidationError>}
     * @memberof Invoice
     */
    warnings?: Array<ValidationError>;
}

/**
    * @export
    * @enum {string}
    */
export enum InvoiceTypeEnum {
    Accpay = 'ACCPAY',
    Accpaycredit = 'ACCPAYCREDIT',
    Apoverpayment = 'APOVERPAYMENT',
    Apprepayment = 'APPREPAYMENT',
    Accrec = 'ACCREC',
    Accreccredit = 'ACCRECCREDIT',
    Aroverpayment = 'AROVERPAYMENT',
    Arprepayment = 'ARPREPAYMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum InvoiceLineAmountTypesEnum {
    Exclusive = 'Exclusive',
    Inclusive = 'Inclusive',
    NoTax = 'NoTax'
}
/**
    * @export
    * @enum {string}
    */
export enum InvoiceCurrencyCodeEnum {
    Aed = 'AED',
    Afn = 'AFN',
    All = 'ALL',
    Amd = 'AMD',
    Ang = 'ANG',
    Aoa = 'AOA',
    Ars = 'ARS',
    Aud = 'AUD',
    Awg = 'AWG',
    Azn = 'AZN',
    Bam = 'BAM',
    Bbd = 'BBD',
    Bdt = 'BDT',
    Bgn = 'BGN',
    Bhd = 'BHD',
    Bif = 'BIF',
    Bmd = 'BMD',
    Bnd = 'BND',
    Bob = 'BOB',
    Brl = 'BRL',
    Bsd = 'BSD',
    Btn = 'BTN',
    Bwp = 'BWP',
    Byn = 'BYN',
    Byr = 'BYR',
    Bzd = 'BZD',
    Cad = 'CAD',
    Cdf = 'CDF',
    Chf = 'CHF',
    Clf = 'CLF',
    Clp = 'CLP',
    Cny = 'CNY',
    Cop = 'COP',
    Crc = 'CRC',
    Cuc = 'CUC',
    Cup = 'CUP',
    Cve = 'CVE',
    Czk = 'CZK',
    Djf = 'DJF',
    Dkk = 'DKK',
    Dop = 'DOP',
    Dzd = 'DZD',
    Eek = 'EEK',
    Egp = 'EGP',
    Ern = 'ERN',
    Etb = 'ETB',
    Eur = 'EUR',
    Fjd = 'FJD',
    Fkp = 'FKP',
    Gbp = 'GBP',
    Gel = 'GEL',
    Ghs = 'GHS',
    Gip = 'GIP',
    Gmd = 'GMD',
    Gnf = 'GNF',
    Gtq = 'GTQ',
    Gyd = 'GYD',
    Hkd = 'HKD',
    Hnl = 'HNL',
    Hrk = 'HRK',
    Htg = 'HTG',
    Huf = 'HUF',
    Idr = 'IDR',
    Ils = 'ILS',
    Inr = 'INR',
    Iqd = 'IQD',
    Irr = 'IRR',
    Isk = 'ISK',
    Jmd = 'JMD',
    Jod = 'JOD',
    Jpy = 'JPY',
    Kes = 'KES',
    Kgs = 'KGS',
    Khr = 'KHR',
    Kmf = 'KMF',
    Kpw = 'KPW',
    Krw = 'KRW',
    Kwd = 'KWD',
    Kyd = 'KYD',
    Kzt = 'KZT',
    Lak = 'LAK',
    Lbp = 'LBP',
    Lkr = 'LKR',
    Lrd = 'LRD',
    Lsl = 'LSL',
    Ltl = 'LTL',
    Lvl = 'LVL',
    Lyd = 'LYD',
    Mad = 'MAD',
    Mdl = 'MDL',
    Mga = 'MGA',
    Mkd = 'MKD',
    Mmk = 'MMK',
    Mnt = 'MNT',
    Mop = 'MOP',
    Mro = 'MRO',
    Mru = 'MRU',
    Mur = 'MUR',
    Mvr = 'MVR',
    Mwk = 'MWK',
    Mxn = 'MXN',
    Mxv = 'MXV',
    Myr = 'MYR',
    Mzn = 'MZN',
    Nad = 'NAD',
    Ngn = 'NGN',
    Nio = 'NIO',
    Nok = 'NOK',
    Npr = 'NPR',
    Nzd = 'NZD',
    Omr = 'OMR',
    Pab = 'PAB',
    Pen = 'PEN',
    Pgk = 'PGK',
    Php = 'PHP',
    Pkr = 'PKR',
    Pln = 'PLN',
    Pyg = 'PYG',
    Qar = 'QAR',
    Ron = 'RON',
    Rsd = 'RSD',
    Rub = 'RUB',
    Rwf = 'RWF',
    Sar = 'SAR',
    Sbd = 'SBD',
    Scr = 'SCR',
    Sdg = 'SDG',
    Sek = 'SEK',
    Sgd = 'SGD',
    Shp = 'SHP',
    Skk = 'SKK',
    Sle = 'SLE',
    Sll = 'SLL',
    Sos = 'SOS',
    Srd = 'SRD',
    Std = 'STD',
    Stn = 'STN',
    Svc = 'SVC',
    Syp = 'SYP',
    Szl = 'SZL',
    Thb = 'THB',
    Tjs = 'TJS',
    Tmt = 'TMT',
    Tnd = 'TND',
    Top = 'TOP',
    Try = 'TRY',
    TryLira = 'TRY_LIRA',
    Ttd = 'TTD',
    Twd = 'TWD',
    Tzs = 'TZS',
    Uah = 'UAH',
    Ugx = 'UGX',
    Usd = 'USD',
    Uyu = 'UYU',
    Uzs = 'UZS',
    Vef = 'VEF',
    Ves = 'VES',
    Vnd = 'VND',
    Vuv = 'VUV',
    Wst = 'WST',
    Xaf = 'XAF',
    Xcd = 'XCD',
    Xof = 'XOF',
    Xpf = 'XPF',
    Yer = 'YER',
    Zar = 'ZAR',
    Zmw = 'ZMW',
    Zmk = 'ZMK',
    Zwd = 'ZWD'
}
/**
    * @export
    * @enum {string}
    */
export enum InvoiceStatusEnum {
    Draft = 'DRAFT',
    Submitted = 'SUBMITTED',
    Deleted = 'DELETED',
    Authorised = 'AUTHORISED',
    Paid = 'PAID',
    Voided = 'VOIDED'
}

/**
 * 
 * @export
 * @interface InvoiceAnalysisState
 */
export interface InvoiceAnalysisState {
    /**
     * 
     * @type {string}
     * @memberof InvoiceAnalysisState
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAnalysisState
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAnalysisState
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAnalysisState
     */
    lastAnalysisJobCompletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAnalysisState
     */
    lastAnalysisJobCompletedId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAnalysisState
     */
    lastAnalysisJobCompletedName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAnalysisState
     */
    lastInvoiceAnalysisCompletedId?: string;
    /**
     * 
     * @type {Array<InvoiceAnalysisStateConclusion>}
     * @memberof InvoiceAnalysisState
     */
    conclusions?: Array<InvoiceAnalysisStateConclusion>;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAnalysisState
     */
    xeroTenantId: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAnalysisState
     */
    xeroInvoiceId: string;
    /**
     * 
     * @type {NormalizedInvoice}
     * @memberof InvoiceAnalysisState
     */
    normalizedInvoice: NormalizedInvoice;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAnalysisState
     */
    lastAnalysisJobStartedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAnalysisState
     */
    lastAnalysisJobStartedId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAnalysisState
     */
    lastAnalysisJobStartedName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAnalysisState
     */
    lastInvoiceAnalysisStartedId?: string;
}
/**
 * 
 * @export
 * @interface InvoiceAnalysisStateConclusion
 */
export interface InvoiceAnalysisStateConclusion {
    /**
     * 
     * @type {string}
     * @memberof InvoiceAnalysisStateConclusion
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAnalysisStateConclusion
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAnalysisStateConclusion
     */
    invoiceAnalysisId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAnalysisStateConclusion
     */
    bankTransactionAnalysisId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAnalysisStateConclusion
     */
    type: InvoiceAnalysisStateConclusionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAnalysisStateConclusion
     */
    correlationId: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceAnalysisStateConclusion
     */
    confidence: number | null;
    /**
     * 
     * @type {object}
     * @memberof InvoiceAnalysisStateConclusion
     */
    data: object;
    /**
     * 
     * @type {object}
     * @memberof InvoiceAnalysisStateConclusion
     */
    meta: object | null;
}

/**
    * @export
    * @enum {string}
    */
export enum InvoiceAnalysisStateConclusionTypeEnum {
    DocumentIsOutlier = 'DocumentIsOutlier',
    LineItemIsOutlier = 'LineItemIsOutlier',
    PossibleDuplicate = 'PossibleDuplicate',
    AttachmentDataExtracted = 'AttachmentDataExtracted',
    BankDetailsChanged = 'BankDetailsChanged'
}

/**
 * 
 * @export
 * @interface LineItem
 */
export interface LineItem {
    /**
     * LineItem unique ID
     * @type {string}
     * @memberof LineItem
     */
    lineItemID?: string;
    /**
     * Description needs to be at least 1 char long. A line item with just a description (i.e no unit amount or quantity) can be created by specifying just a <Description> element that contains at least 1 character
     * @type {string}
     * @memberof LineItem
     */
    description?: string;
    /**
     * LineItem Quantity
     * @type {number}
     * @memberof LineItem
     */
    quantity?: number;
    /**
     * LineItem Unit Amount
     * @type {number}
     * @memberof LineItem
     */
    unitAmount?: number;
    /**
     * See Items
     * @type {string}
     * @memberof LineItem
     */
    itemCode?: string;
    /**
     * See Accounts
     * @type {string}
     * @memberof LineItem
     */
    accountCode?: string;
    /**
     * The associated account ID related to this line item
     * @type {string}
     * @memberof LineItem
     */
    accountID?: string;
    /**
     * The tax type from TaxRates
     * @type {string}
     * @memberof LineItem
     */
    taxType?: string;
    /**
     * The tax amount is auto calculated as a percentage of the line amount (see below) based on the tax rate. This value can be overriden if the calculated <TaxAmount> is not correct.
     * @type {number}
     * @memberof LineItem
     */
    taxAmount?: number;
    /**
     * 
     * @type {LineItemItem}
     * @memberof LineItem
     */
    item?: LineItemItem;
    /**
     * If you wish to omit either the Quantity or UnitAmount you can provide a LineAmount and Xero will calculate the missing amount for you. The line amount reflects the discounted price if either a DiscountRate or DiscountAmount has been used i.e. LineAmount = Quantity * Unit Amount * ((100 - DiscountRate)/100) or LineAmount = (Quantity * UnitAmount) - DiscountAmount
     * @type {number}
     * @memberof LineItem
     */
    lineAmount?: number;
    /**
     * Optional Tracking Category – see Tracking.  Any LineItem can have a  maximum of 2 <TrackingCategory> elements.
     * @type {Array<LineItemTracking>}
     * @memberof LineItem
     */
    tracking?: Array<LineItemTracking>;
    /**
     * Percentage discount being applied to a line item (only supported on  ACCREC invoices – ACC PAY invoices and credit notes in Xero do not support discounts
     * @type {number}
     * @memberof LineItem
     */
    discountRate?: number;
    /**
     * Discount amount being applied to a line item. Only supported on ACCREC invoices and quotes. ACCPAY invoices and credit notes in Xero do not support discounts.
     * @type {number}
     * @memberof LineItem
     */
    discountAmount?: number;
    /**
     * The Xero identifier for a Repeating Invoice
     * @type {string}
     * @memberof LineItem
     */
    repeatingInvoiceID?: string;
}
/**
 * 
 * @export
 * @interface LineItemItem
 */
export interface LineItemItem {
    /**
     * User defined item code (max length = 30)
     * @type {string}
     * @memberof LineItemItem
     */
    code?: string;
    /**
     * The name of the item (max length = 50)
     * @type {string}
     * @memberof LineItemItem
     */
    name?: string;
    /**
     * The Xero identifier for an Item
     * @type {string}
     * @memberof LineItemItem
     */
    itemID?: string;
}
/**
 * 
 * @export
 * @interface LineItemTracking
 */
export interface LineItemTracking {
    /**
     * The Xero identifier for a tracking category
     * @type {string}
     * @memberof LineItemTracking
     */
    trackingCategoryID?: string;
    /**
     * The Xero identifier for a tracking category option
     * @type {string}
     * @memberof LineItemTracking
     */
    trackingOptionID?: string;
    /**
     * The name of the tracking category
     * @type {string}
     * @memberof LineItemTracking
     */
    name?: string;
    /**
     * See Tracking Options
     * @type {string}
     * @memberof LineItemTracking
     */
    option?: string;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    read?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    readByUser?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    resolved?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    resolvedByUser?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    nonce: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    xeroTenantConnectionId: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    heading: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    severity: MessageSeverityEnum;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    contentPreheading?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Message
     */
    contentBody?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    contentTemplate?: string;
    /**
     * 
     * @type {object}
     * @memberof Message
     */
    contentData?: object;
}

/**
    * @export
    * @enum {string}
    */
export enum MessageSeverityEnum {
    General = 'General',
    Advisory = 'Advisory',
    Important = 'Important',
    Critical = 'Critical'
}

/**
 * 
 * @export
 * @interface NormalizedAttachment
 */
export interface NormalizedAttachment {
    /**
     * 
     * @type {string}
     * @memberof NormalizedAttachment
     */
    source: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedAttachment
     */
    extId: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedAttachment
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedAttachment
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedAttachment
     */
    mimeType: string;
    /**
     * 
     * @type {number}
     * @memberof NormalizedAttachment
     */
    contentLength: number;
}
/**
 * 
 * @export
 * @interface NormalizedBankTransaction
 */
export interface NormalizedBankTransaction {
    /**
     * 
     * @type {string}
     * @memberof NormalizedBankTransaction
     */
    entity: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedBankTransaction
     */
    extId: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedBankTransaction
     */
    extContactId?: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedBankTransaction
     */
    contactName?: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedBankTransaction
     */
    extBankAccountId?: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedBankTransaction
     */
    bankAccountName?: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedBankTransaction
     */
    reference?: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedBankTransaction
     */
    type: NormalizedBankTransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NormalizedBankTransaction
     */
    status: NormalizedBankTransactionStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof NormalizedBankTransaction
     */
    isReconciled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NormalizedBankTransaction
     */
    hasAttachments: boolean;
    /**
     * 
     * @type {Array<NormalizedAttachment>}
     * @memberof NormalizedBankTransaction
     */
    attachments: Array<NormalizedAttachment>;
    /**
     * 
     * @type {Array<NormalizedLineItem>}
     * @memberof NormalizedBankTransaction
     */
    lineItems: Array<NormalizedLineItem>;
    /**
     * 
     * @type {string}
     * @memberof NormalizedBankTransaction
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedBankTransaction
     */
    currencyCode: string;
    /**
     * 
     * @type {number}
     * @memberof NormalizedBankTransaction
     */
    currencyRate?: number;
    /**
     * 
     * @type {number}
     * @memberof NormalizedBankTransaction
     */
    subTotal: number;
    /**
     * 
     * @type {number}
     * @memberof NormalizedBankTransaction
     */
    totalTax: number;
    /**
     * 
     * @type {number}
     * @memberof NormalizedBankTransaction
     */
    total: number;
}

/**
    * @export
    * @enum {string}
    */
export enum NormalizedBankTransactionTypeEnum {
    Receive = 'Receive',
    Spend = 'Spend',
    SpendTransfer = 'SpendTransfer',
    ReceiveTransfer = 'ReceiveTransfer'
}
/**
    * @export
    * @enum {string}
    */
export enum NormalizedBankTransactionStatusEnum {
    Approved = 'Approved',
    Voided = 'Voided'
}

/**
 * 
 * @export
 * @interface NormalizedContact
 */
export interface NormalizedContact {
    /**
     * 
     * @type {string}
     * @memberof NormalizedContact
     */
    extId: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedContact
     */
    contactName: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedContact
     */
    primaryPersonFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedContact
     */
    primaryPersonLastName: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedContact
     */
    primaryPersonEmail: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedContact
     */
    website: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedContact
     */
    companyNumber: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedContact
     */
    bankAccountName: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedContact
     */
    bankAccountSortCode: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedContact
     */
    bankAccountNumber: string;
    /**
     * 
     * @type {NormalizedContactAddress}
     * @memberof NormalizedContact
     */
    deliveryAddress: NormalizedContactAddress;
    /**
     * 
     * @type {NormalizedContactAddress}
     * @memberof NormalizedContact
     */
    billingAddress: NormalizedContactAddress;
    /**
     * 
     * @type {boolean}
     * @memberof NormalizedContact
     */
    isSupplier: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NormalizedContact
     */
    isCustomer: boolean;
}
/**
 * 
 * @export
 * @interface NormalizedContactAddress
 */
export interface NormalizedContactAddress {
    /**
     * 
     * @type {string}
     * @memberof NormalizedContactAddress
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedContactAddress
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedContactAddress
     */
    addressLine3?: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedContactAddress
     */
    addressLine4?: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedContactAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedContactAddress
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedContactAddress
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedContactAddress
     */
    country?: string;
}
/**
 * 
 * @export
 * @interface NormalizedInvoice
 */
export interface NormalizedInvoice {
    /**
     * 
     * @type {string}
     * @memberof NormalizedInvoice
     */
    entity: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedInvoice
     */
    extId: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedInvoice
     */
    extContactId?: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedInvoice
     */
    contactName?: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedInvoice
     */
    invoiceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedInvoice
     */
    reference: string;
    /**
     * 
     * @type {boolean}
     * @memberof NormalizedInvoice
     */
    hasAttachments: boolean;
    /**
     * 
     * @type {Array<NormalizedAttachment>}
     * @memberof NormalizedInvoice
     */
    attachments: Array<NormalizedAttachment>;
    /**
     * 
     * @type {string}
     * @memberof NormalizedInvoice
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedInvoice
     */
    dueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedInvoice
     */
    currencyCode: string;
    /**
     * 
     * @type {number}
     * @memberof NormalizedInvoice
     */
    currencyRate?: number;
    /**
     * 
     * @type {number}
     * @memberof NormalizedInvoice
     */
    subTotal: number;
    /**
     * 
     * @type {number}
     * @memberof NormalizedInvoice
     */
    totalTax?: number;
    /**
     * 
     * @type {number}
     * @memberof NormalizedInvoice
     */
    totalDiscount?: number;
    /**
     * 
     * @type {number}
     * @memberof NormalizedInvoice
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof NormalizedInvoice
     */
    amountDue: number;
    /**
     * 
     * @type {number}
     * @memberof NormalizedInvoice
     */
    amountCredited: number;
    /**
     * 
     * @type {number}
     * @memberof NormalizedInvoice
     */
    amountPaid: number;
    /**
     * 
     * @type {string}
     * @memberof NormalizedInvoice
     */
    type: NormalizedInvoiceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NormalizedInvoice
     */
    status: NormalizedInvoiceStatusEnum;
    /**
     * 
     * @type {Array<NormalizedLineItem>}
     * @memberof NormalizedInvoice
     */
    lineItems: Array<NormalizedLineItem>;
}

/**
    * @export
    * @enum {string}
    */
export enum NormalizedInvoiceTypeEnum {
    Receivable = 'Receivable',
    Payable = 'Payable'
}
/**
    * @export
    * @enum {string}
    */
export enum NormalizedInvoiceStatusEnum {
    Draft = 'Draft',
    Approved = 'Approved',
    Paid = 'Paid',
    Voided = 'Voided'
}

/**
 * 
 * @export
 * @interface NormalizedLineItem
 */
export interface NormalizedLineItem {
    /**
     * 
     * @type {string}
     * @memberof NormalizedLineItem
     */
    extId: string;
    /**
     * 
     * @type {number}
     * @memberof NormalizedLineItem
     */
    ordinal: number;
    /**
     * 
     * @type {string}
     * @memberof NormalizedLineItem
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof NormalizedLineItem
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof NormalizedLineItem
     */
    unitAmount: number;
    /**
     * 
     * @type {number}
     * @memberof NormalizedLineItem
     */
    taxAmount: number;
    /**
     * 
     * @type {number}
     * @memberof NormalizedLineItem
     */
    lineAmount: number;
    /**
     * 
     * @type {string}
     * @memberof NormalizedLineItem
     */
    extItemId?: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedLineItem
     */
    extAccountId?: string;
    /**
     * 
     * @type {string}
     * @memberof NormalizedLineItem
     */
    accountName?: string;
    /**
     * 
     * @type {number}
     * @memberof NormalizedLineItem
     */
    discountRate?: number;
    /**
     * 
     * @type {number}
     * @memberof NormalizedLineItem
     */
    discountAmount?: number;
}
/**
 * 
 * @export
 * @interface Organisation
 */
export interface Organisation {
    /**
     * Unique Xero identifier
     * @type {string}
     * @memberof Organisation
     */
    organisationID?: string;
    /**
     * Display a unique key used for Xero-to-Xero transactions
     * @type {string}
     * @memberof Organisation
     */
    aPIKey?: string;
    /**
     * Display name of organisation shown in Xero
     * @type {string}
     * @memberof Organisation
     */
    name?: string;
    /**
     * Organisation name shown on Reports
     * @type {string}
     * @memberof Organisation
     */
    legalName?: string;
    /**
     * Boolean to describe if organisation is registered with a local tax authority i.e. true, false
     * @type {boolean}
     * @memberof Organisation
     */
    paysTax?: boolean;
    /**
     * See Version Types
     * @type {string}
     * @memberof Organisation
     */
    version?: OrganisationVersionEnum;
    /**
     * Organisation Type
     * @type {string}
     * @memberof Organisation
     */
    organisationType?: OrganisationOrganisationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Organisation
     */
    baseCurrency?: OrganisationBaseCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof Organisation
     */
    countryCode?: OrganisationCountryCodeEnum;
    /**
     * Boolean to describe if organisation is a demo company.
     * @type {boolean}
     * @memberof Organisation
     */
    isDemoCompany?: boolean;
    /**
     * Will be set to ACTIVE if you can connect to organisation via the Xero API
     * @type {string}
     * @memberof Organisation
     */
    organisationStatus?: string;
    /**
     * Shows for New Zealand, Australian and UK organisations
     * @type {string}
     * @memberof Organisation
     */
    registrationNumber?: string;
    /**
     * Shown if set. US Only.
     * @type {string}
     * @memberof Organisation
     */
    employerIdentificationNumber?: string;
    /**
     * Shown if set. Displays in the Xero UI as Tax File Number (AU), GST Number (NZ), VAT Number (UK) and Tax ID Number (US & Global).
     * @type {string}
     * @memberof Organisation
     */
    taxNumber?: string;
    /**
     * Calendar day e.g. 0-31
     * @type {number}
     * @memberof Organisation
     */
    financialYearEndDay?: number;
    /**
     * Calendar Month e.g. 1-12
     * @type {number}
     * @memberof Organisation
     */
    financialYearEndMonth?: number;
    /**
     * The accounting basis used for tax returns. See Sales Tax Basis
     * @type {string}
     * @memberof Organisation
     */
    salesTaxBasis?: OrganisationSalesTaxBasisEnum;
    /**
     * The frequency with which tax returns are processed. See Sales Tax Period
     * @type {string}
     * @memberof Organisation
     */
    salesTaxPeriod?: OrganisationSalesTaxPeriodEnum;
    /**
     * The default for LineAmountTypes on sales transactions
     * @type {string}
     * @memberof Organisation
     */
    defaultSalesTax?: string;
    /**
     * The default for LineAmountTypes on purchase transactions
     * @type {string}
     * @memberof Organisation
     */
    defaultPurchasesTax?: string;
    /**
     * Shown if set. See lock dates
     * @type {string}
     * @memberof Organisation
     */
    periodLockDate?: string;
    /**
     * Shown if set. See lock dates
     * @type {string}
     * @memberof Organisation
     */
    endOfYearLockDate?: string;
    /**
     * Timestamp when the organisation was created in Xero
     * @type {string}
     * @memberof Organisation
     */
    createdDateUTC?: string;
    /**
     * 
     * @type {string}
     * @memberof Organisation
     */
    timezone?: OrganisationTimezoneEnum;
    /**
     * Organisation Entity Type
     * @type {string}
     * @memberof Organisation
     */
    organisationEntityType?: OrganisationOrganisationEntityTypeEnum;
    /**
     * A unique identifier for the organisation. Potential uses.
     * @type {string}
     * @memberof Organisation
     */
    shortCode?: string;
    /**
     * Organisation Classes describe which plan the Xero organisation is on (e.g. DEMO, TRIAL, PREMIUM)
     * @type {string}
     * @memberof Organisation
     */
    _class?: OrganisationClassEnum;
    /**
     * BUSINESS or PARTNER. Partner edition organisations are sold exclusively through accounting partners and have restricted functionality (e.g. no access to invoicing)
     * @type {string}
     * @memberof Organisation
     */
    edition?: OrganisationEditionEnum;
    /**
     * Description of business type as defined in Organisation settings
     * @type {string}
     * @memberof Organisation
     */
    lineOfBusiness?: string;
    /**
     * Address details for organisation – see Addresses
     * @type {Array<AddressForOrganisation>}
     * @memberof Organisation
     */
    addresses?: Array<AddressForOrganisation>;
    /**
     * Phones details for organisation – see Phones
     * @type {Array<Phone>}
     * @memberof Organisation
     */
    phones?: Array<Phone>;
    /**
     * Organisation profile links for popular services such as Facebook,Twitter, GooglePlus and LinkedIn. You can also add link to your website here. Shown if Organisation settings  is updated in Xero. See ExternalLinks below
     * @type {Array<ExternalLink>}
     * @memberof Organisation
     */
    externalLinks?: Array<ExternalLink>;
    /**
     * 
     * @type {PaymentTerm}
     * @memberof Organisation
     */
    paymentTerms?: PaymentTerm;
}

/**
    * @export
    * @enum {string}
    */
export enum OrganisationVersionEnum {
    Au = 'AU',
    Nz = 'NZ',
    Global = 'GLOBAL',
    Uk = 'UK',
    Us = 'US',
    Auonramp = 'AUONRAMP',
    Nzonramp = 'NZONRAMP',
    Globalonramp = 'GLOBALONRAMP',
    Ukonramp = 'UKONRAMP',
    Usonramp = 'USONRAMP'
}
/**
    * @export
    * @enum {string}
    */
export enum OrganisationOrganisationTypeEnum {
    AccountingPractice = 'ACCOUNTING_PRACTICE',
    Accountingpractice = 'ACCOUNTINGPRACTICE',
    Company = 'COMPANY',
    Charity = 'CHARITY',
    ClubOrSociety = 'CLUB_OR_SOCIETY',
    Cluborsociety = 'CLUBORSOCIETY',
    Individual = 'INDIVIDUAL',
    LookThroughCompany = 'LOOK_THROUGH_COMPANY',
    Lookthroughcompany = 'LOOKTHROUGHCOMPANY',
    NotForProfit = 'NOT_FOR_PROFIT',
    Notforprofit = 'NOTFORPROFIT',
    Partnership = 'PARTNERSHIP',
    SCorporation = 'S_CORPORATION',
    Scorporation = 'SCORPORATION',
    SelfManagedSuperannuationFund = 'SELF_MANAGED_SUPERANNUATION_FUND',
    Selfmanagedsuperannuationfund = 'SELFMANAGEDSUPERANNUATIONFUND',
    SoleTrader = 'SOLE_TRADER',
    Soletrader = 'SOLETRADER',
    SuperannuationFund = 'SUPERANNUATION_FUND',
    Superannuationfund = 'SUPERANNUATIONFUND',
    Trust = 'TRUST'
}
/**
    * @export
    * @enum {string}
    */
export enum OrganisationBaseCurrencyEnum {
    Aed = 'AED',
    Afn = 'AFN',
    All = 'ALL',
    Amd = 'AMD',
    Ang = 'ANG',
    Aoa = 'AOA',
    Ars = 'ARS',
    Aud = 'AUD',
    Awg = 'AWG',
    Azn = 'AZN',
    Bam = 'BAM',
    Bbd = 'BBD',
    Bdt = 'BDT',
    Bgn = 'BGN',
    Bhd = 'BHD',
    Bif = 'BIF',
    Bmd = 'BMD',
    Bnd = 'BND',
    Bob = 'BOB',
    Brl = 'BRL',
    Bsd = 'BSD',
    Btn = 'BTN',
    Bwp = 'BWP',
    Byn = 'BYN',
    Byr = 'BYR',
    Bzd = 'BZD',
    Cad = 'CAD',
    Cdf = 'CDF',
    Chf = 'CHF',
    Clf = 'CLF',
    Clp = 'CLP',
    Cny = 'CNY',
    Cop = 'COP',
    Crc = 'CRC',
    Cuc = 'CUC',
    Cup = 'CUP',
    Cve = 'CVE',
    Czk = 'CZK',
    Djf = 'DJF',
    Dkk = 'DKK',
    Dop = 'DOP',
    Dzd = 'DZD',
    Eek = 'EEK',
    Egp = 'EGP',
    Ern = 'ERN',
    Etb = 'ETB',
    Eur = 'EUR',
    Fjd = 'FJD',
    Fkp = 'FKP',
    Gbp = 'GBP',
    Gel = 'GEL',
    Ghs = 'GHS',
    Gip = 'GIP',
    Gmd = 'GMD',
    Gnf = 'GNF',
    Gtq = 'GTQ',
    Gyd = 'GYD',
    Hkd = 'HKD',
    Hnl = 'HNL',
    Hrk = 'HRK',
    Htg = 'HTG',
    Huf = 'HUF',
    Idr = 'IDR',
    Ils = 'ILS',
    Inr = 'INR',
    Iqd = 'IQD',
    Irr = 'IRR',
    Isk = 'ISK',
    Jmd = 'JMD',
    Jod = 'JOD',
    Jpy = 'JPY',
    Kes = 'KES',
    Kgs = 'KGS',
    Khr = 'KHR',
    Kmf = 'KMF',
    Kpw = 'KPW',
    Krw = 'KRW',
    Kwd = 'KWD',
    Kyd = 'KYD',
    Kzt = 'KZT',
    Lak = 'LAK',
    Lbp = 'LBP',
    Lkr = 'LKR',
    Lrd = 'LRD',
    Lsl = 'LSL',
    Ltl = 'LTL',
    Lvl = 'LVL',
    Lyd = 'LYD',
    Mad = 'MAD',
    Mdl = 'MDL',
    Mga = 'MGA',
    Mkd = 'MKD',
    Mmk = 'MMK',
    Mnt = 'MNT',
    Mop = 'MOP',
    Mro = 'MRO',
    Mru = 'MRU',
    Mur = 'MUR',
    Mvr = 'MVR',
    Mwk = 'MWK',
    Mxn = 'MXN',
    Mxv = 'MXV',
    Myr = 'MYR',
    Mzn = 'MZN',
    Nad = 'NAD',
    Ngn = 'NGN',
    Nio = 'NIO',
    Nok = 'NOK',
    Npr = 'NPR',
    Nzd = 'NZD',
    Omr = 'OMR',
    Pab = 'PAB',
    Pen = 'PEN',
    Pgk = 'PGK',
    Php = 'PHP',
    Pkr = 'PKR',
    Pln = 'PLN',
    Pyg = 'PYG',
    Qar = 'QAR',
    Ron = 'RON',
    Rsd = 'RSD',
    Rub = 'RUB',
    Rwf = 'RWF',
    Sar = 'SAR',
    Sbd = 'SBD',
    Scr = 'SCR',
    Sdg = 'SDG',
    Sek = 'SEK',
    Sgd = 'SGD',
    Shp = 'SHP',
    Skk = 'SKK',
    Sle = 'SLE',
    Sll = 'SLL',
    Sos = 'SOS',
    Srd = 'SRD',
    Std = 'STD',
    Stn = 'STN',
    Svc = 'SVC',
    Syp = 'SYP',
    Szl = 'SZL',
    Thb = 'THB',
    Tjs = 'TJS',
    Tmt = 'TMT',
    Tnd = 'TND',
    Top = 'TOP',
    Try = 'TRY',
    TryLira = 'TRY_LIRA',
    Ttd = 'TTD',
    Twd = 'TWD',
    Tzs = 'TZS',
    Uah = 'UAH',
    Ugx = 'UGX',
    Usd = 'USD',
    Uyu = 'UYU',
    Uzs = 'UZS',
    Vef = 'VEF',
    Ves = 'VES',
    Vnd = 'VND',
    Vuv = 'VUV',
    Wst = 'WST',
    Xaf = 'XAF',
    Xcd = 'XCD',
    Xof = 'XOF',
    Xpf = 'XPF',
    Yer = 'YER',
    Zar = 'ZAR',
    Zmw = 'ZMW',
    Zmk = 'ZMK',
    Zwd = 'ZWD'
}
/**
    * @export
    * @enum {string}
    */
export enum OrganisationCountryCodeEnum {
    Ad = 'AD',
    Ae = 'AE',
    Af = 'AF',
    Ag = 'AG',
    Ai = 'AI',
    Al = 'AL',
    Am = 'AM',
    An = 'AN',
    Ao = 'AO',
    Aq = 'AQ',
    Ar = 'AR',
    As = 'AS',
    At = 'AT',
    Au = 'AU',
    Aw = 'AW',
    Az = 'AZ',
    Ba = 'BA',
    Bb = 'BB',
    Bd = 'BD',
    Be = 'BE',
    Bf = 'BF',
    Bg = 'BG',
    Bh = 'BH',
    Bi = 'BI',
    Bj = 'BJ',
    Bl = 'BL',
    Bm = 'BM',
    Bn = 'BN',
    Bo = 'BO',
    Br = 'BR',
    Bs = 'BS',
    Bt = 'BT',
    Bw = 'BW',
    By = 'BY',
    Bz = 'BZ',
    Ca = 'CA',
    Cc = 'CC',
    Cd = 'CD',
    Cf = 'CF',
    Cg = 'CG',
    Ch = 'CH',
    Ci = 'CI',
    Ck = 'CK',
    Cl = 'CL',
    Cm = 'CM',
    Cn = 'CN',
    Co = 'CO',
    Cr = 'CR',
    Cu = 'CU',
    Cv = 'CV',
    Cw = 'CW',
    Cx = 'CX',
    Cy = 'CY',
    Cz = 'CZ',
    De = 'DE',
    Dj = 'DJ',
    Dk = 'DK',
    Dm = 'DM',
    Do = 'DO',
    Dz = 'DZ',
    Ec = 'EC',
    Ee = 'EE',
    Eg = 'EG',
    Eh = 'EH',
    Er = 'ER',
    Es = 'ES',
    Et = 'ET',
    Fi = 'FI',
    Fj = 'FJ',
    Fk = 'FK',
    Fm = 'FM',
    Fo = 'FO',
    Fr = 'FR',
    Ga = 'GA',
    Gb = 'GB',
    Gd = 'GD',
    Ge = 'GE',
    Gg = 'GG',
    Gh = 'GH',
    Gi = 'GI',
    Gl = 'GL',
    Gm = 'GM',
    Gn = 'GN',
    Gq = 'GQ',
    Gr = 'GR',
    Gt = 'GT',
    Gu = 'GU',
    Gw = 'GW',
    Gy = 'GY',
    Hk = 'HK',
    Hn = 'HN',
    Hr = 'HR',
    Ht = 'HT',
    Hu = 'HU',
    Id = 'ID',
    Ie = 'IE',
    Il = 'IL',
    Im = 'IM',
    In = 'IN',
    Io = 'IO',
    Iq = 'IQ',
    Ir = 'IR',
    Is = 'IS',
    It = 'IT',
    Je = 'JE',
    Jm = 'JM',
    Jo = 'JO',
    Jp = 'JP',
    Ke = 'KE',
    Kg = 'KG',
    Kh = 'KH',
    Ki = 'KI',
    Km = 'KM',
    Kn = 'KN',
    Kp = 'KP',
    Kr = 'KR',
    Kw = 'KW',
    Ky = 'KY',
    Kz = 'KZ',
    La = 'LA',
    Lb = 'LB',
    Lc = 'LC',
    Li = 'LI',
    Lk = 'LK',
    Lr = 'LR',
    Ls = 'LS',
    Lt = 'LT',
    Lu = 'LU',
    Lv = 'LV',
    Ly = 'LY',
    Ma = 'MA',
    Mc = 'MC',
    Md = 'MD',
    Me = 'ME',
    Mf = 'MF',
    Mg = 'MG',
    Mh = 'MH',
    Mk = 'MK',
    Ml = 'ML',
    Mm = 'MM',
    Mn = 'MN',
    Mo = 'MO',
    Mp = 'MP',
    Mr = 'MR',
    Ms = 'MS',
    Mt = 'MT',
    Mu = 'MU',
    Mv = 'MV',
    Mw = 'MW',
    Mx = 'MX',
    My = 'MY',
    Mz = 'MZ',
    Na = 'NA',
    Nc = 'NC',
    Ne = 'NE',
    Ng = 'NG',
    Ni = 'NI',
    Nl = 'NL',
    No = 'NO',
    Np = 'NP',
    Nr = 'NR',
    Nu = 'NU',
    Nz = 'NZ',
    Om = 'OM',
    Pa = 'PA',
    Pe = 'PE',
    Pf = 'PF',
    Pg = 'PG',
    Ph = 'PH',
    Pk = 'PK',
    Pl = 'PL',
    Pm = 'PM',
    Pn = 'PN',
    Pr = 'PR',
    Ps = 'PS',
    Pt = 'PT',
    Pw = 'PW',
    Py = 'PY',
    Qa = 'QA',
    Re = 'RE',
    Ro = 'RO',
    Rs = 'RS',
    Ru = 'RU',
    Rw = 'RW',
    Sa = 'SA',
    Sb = 'SB',
    Sc = 'SC',
    Sd = 'SD',
    Se = 'SE',
    Sg = 'SG',
    Sh = 'SH',
    Si = 'SI',
    Sj = 'SJ',
    Sk = 'SK',
    Sl = 'SL',
    Sm = 'SM',
    Sn = 'SN',
    So = 'SO',
    Sr = 'SR',
    Ss = 'SS',
    St = 'ST',
    Sv = 'SV',
    Sx = 'SX',
    Sy = 'SY',
    Sz = 'SZ',
    Tc = 'TC',
    Td = 'TD',
    Tg = 'TG',
    Th = 'TH',
    Tj = 'TJ',
    Tk = 'TK',
    Tl = 'TL',
    Tm = 'TM',
    Tn = 'TN',
    To = 'TO',
    Tr = 'TR',
    Tt = 'TT',
    Tv = 'TV',
    Tw = 'TW',
    Tz = 'TZ',
    Ua = 'UA',
    Ug = 'UG',
    Us = 'US',
    Uy = 'UY',
    Uz = 'UZ',
    Va = 'VA',
    Vc = 'VC',
    Ve = 'VE',
    Vg = 'VG',
    Vi = 'VI',
    Vn = 'VN',
    Vu = 'VU',
    Wf = 'WF',
    Ws = 'WS',
    Xk = 'XK',
    Ye = 'YE',
    Yt = 'YT',
    Za = 'ZA',
    Zm = 'ZM',
    Zw = 'ZW'
}
/**
    * @export
    * @enum {string}
    */
export enum OrganisationSalesTaxBasisEnum {
    Payments = 'PAYMENTS',
    Invoice = 'INVOICE',
    None = 'NONE',
    Cash = 'CASH',
    Accrual = 'ACCRUAL',
    Flatratecash = 'FLATRATECASH',
    Flatrateaccrual = 'FLATRATEACCRUAL',
    Accruals = 'ACCRUALS'
}
/**
    * @export
    * @enum {string}
    */
export enum OrganisationSalesTaxPeriodEnum {
    Monthly = 'MONTHLY',
    Quarterly1 = 'QUARTERLY1',
    Quarterly2 = 'QUARTERLY2',
    Quarterly3 = 'QUARTERLY3',
    Annually = 'ANNUALLY',
    Onemonths = 'ONEMONTHS',
    Twomonths = 'TWOMONTHS',
    Sixmonths = 'SIXMONTHS',
    _1Monthly = '1MONTHLY',
    _2Monthly = '2MONTHLY',
    _3Monthly = '3MONTHLY',
    _6Monthly = '6MONTHLY',
    Quarterly = 'QUARTERLY',
    Yearly = 'YEARLY',
    None = 'NONE'
}
/**
    * @export
    * @enum {string}
    */
export enum OrganisationTimezoneEnum {
    Afghanistanstandardtime = 'AFGHANISTANSTANDARDTIME',
    Alaskanstandardtime = 'ALASKANSTANDARDTIME',
    Aleutianstandardtime = 'ALEUTIANSTANDARDTIME',
    Altaistandardtime = 'ALTAISTANDARDTIME',
    Arabianstandardtime = 'ARABIANSTANDARDTIME',
    Arabicstandardtime = 'ARABICSTANDARDTIME',
    Arabstandardtime = 'ARABSTANDARDTIME',
    Argentinastandardtime = 'ARGENTINASTANDARDTIME',
    Astrakhanstandardtime = 'ASTRAKHANSTANDARDTIME',
    Atlanticstandardtime = 'ATLANTICSTANDARDTIME',
    Auscentralstandardtime = 'AUSCENTRALSTANDARDTIME',
    Auscentralwstandardtime = 'AUSCENTRALWSTANDARDTIME',
    Auseasternstandardtime = 'AUSEASTERNSTANDARDTIME',
    Azerbaijanstandardtime = 'AZERBAIJANSTANDARDTIME',
    Azoresstandardtime = 'AZORESSTANDARDTIME',
    Bahiastandardtime = 'BAHIASTANDARDTIME',
    Bangladeshstandardtime = 'BANGLADESHSTANDARDTIME',
    Belarusstandardtime = 'BELARUSSTANDARDTIME',
    Bougainvillestandardtime = 'BOUGAINVILLESTANDARDTIME',
    Canadacentralstandardtime = 'CANADACENTRALSTANDARDTIME',
    Capeverdestandardtime = 'CAPEVERDESTANDARDTIME',
    Caucasusstandardtime = 'CAUCASUSSTANDARDTIME',
    Cenaustraliastandardtime = 'CENAUSTRALIASTANDARDTIME',
    Centralamericastandardtime = 'CENTRALAMERICASTANDARDTIME',
    Centralasiastandardtime = 'CENTRALASIASTANDARDTIME',
    Centralbrazilianstandardtime = 'CENTRALBRAZILIANSTANDARDTIME',
    Centraleuropeanstandardtime = 'CENTRALEUROPEANSTANDARDTIME',
    Centraleuropestandardtime = 'CENTRALEUROPESTANDARDTIME',
    Centralpacificstandardtime = 'CENTRALPACIFICSTANDARDTIME',
    Centralstandardtime = 'CENTRALSTANDARDTIME',
    CentralstandardtimeMexico = 'CENTRALSTANDARDTIME(MEXICO)',
    Centralstandardtimemexico = 'CENTRALSTANDARDTIMEMEXICO',
    Chathamislandsstandardtime = 'CHATHAMISLANDSSTANDARDTIME',
    Chinastandardtime = 'CHINASTANDARDTIME',
    Cubastandardtime = 'CUBASTANDARDTIME',
    Datelinestandardtime = 'DATELINESTANDARDTIME',
    Eafricastandardtime = 'EAFRICASTANDARDTIME',
    Easterislandstandardtime = 'EASTERISLANDSTANDARDTIME',
    Easternstandardtime = 'EASTERNSTANDARDTIME',
    EasternstandardtimeMexico = 'EASTERNSTANDARDTIME(MEXICO)',
    Easternstandardtimemexico = 'EASTERNSTANDARDTIMEMEXICO',
    Eaustraliastandardtime = 'EAUSTRALIASTANDARDTIME',
    Eeuropestandardtime = 'EEUROPESTANDARDTIME',
    Egyptstandardtime = 'EGYPTSTANDARDTIME',
    Ekaterinburgstandardtime = 'EKATERINBURGSTANDARDTIME',
    Esouthamericastandardtime = 'ESOUTHAMERICASTANDARDTIME',
    Fijistandardtime = 'FIJISTANDARDTIME',
    Flestandardtime = 'FLESTANDARDTIME',
    Georgianstandardtime = 'GEORGIANSTANDARDTIME',
    Gmtstandardtime = 'GMTSTANDARDTIME',
    Greenlandstandardtime = 'GREENLANDSTANDARDTIME',
    Greenwichstandardtime = 'GREENWICHSTANDARDTIME',
    Gtbstandardtime = 'GTBSTANDARDTIME',
    Haitistandardtime = 'HAITISTANDARDTIME',
    Hawaiianstandardtime = 'HAWAIIANSTANDARDTIME',
    Indiastandardtime = 'INDIASTANDARDTIME',
    Iranstandardtime = 'IRANSTANDARDTIME',
    Israelstandardtime = 'ISRAELSTANDARDTIME',
    Jordanstandardtime = 'JORDANSTANDARDTIME',
    Kaliningradstandardtime = 'KALININGRADSTANDARDTIME',
    Kamchatkastandardtime = 'KAMCHATKASTANDARDTIME',
    Koreastandardtime = 'KOREASTANDARDTIME',
    Libyastandardtime = 'LIBYASTANDARDTIME',
    Lineislandsstandardtime = 'LINEISLANDSSTANDARDTIME',
    Lordhowestandardtime = 'LORDHOWESTANDARDTIME',
    Magadanstandardtime = 'MAGADANSTANDARDTIME',
    Magallanesstandardtime = 'MAGALLANESSTANDARDTIME',
    Marquesasstandardtime = 'MARQUESASSTANDARDTIME',
    Mauritiusstandardtime = 'MAURITIUSSTANDARDTIME',
    Midatlanticstandardtime = 'MIDATLANTICSTANDARDTIME',
    Middleeaststandardtime = 'MIDDLEEASTSTANDARDTIME',
    Montevideostandardtime = 'MONTEVIDEOSTANDARDTIME',
    Moroccostandardtime = 'MOROCCOSTANDARDTIME',
    Mountainstandardtime = 'MOUNTAINSTANDARDTIME',
    MountainstandardtimeMexico = 'MOUNTAINSTANDARDTIME(MEXICO)',
    Mountainstandardtimemexico = 'MOUNTAINSTANDARDTIMEMEXICO',
    Myanmarstandardtime = 'MYANMARSTANDARDTIME',
    Namibiastandardtime = 'NAMIBIASTANDARDTIME',
    Ncentralasiastandardtime = 'NCENTRALASIASTANDARDTIME',
    Nepalstandardtime = 'NEPALSTANDARDTIME',
    Newfoundlandstandardtime = 'NEWFOUNDLANDSTANDARDTIME',
    Newzealandstandardtime = 'NEWZEALANDSTANDARDTIME',
    Norfolkstandardtime = 'NORFOLKSTANDARDTIME',
    Northasiaeaststandardtime = 'NORTHASIAEASTSTANDARDTIME',
    Northasiastandardtime = 'NORTHASIASTANDARDTIME',
    Northkoreastandardtime = 'NORTHKOREASTANDARDTIME',
    Omskstandardtime = 'OMSKSTANDARDTIME',
    Pacificsastandardtime = 'PACIFICSASTANDARDTIME',
    Pacificstandardtime = 'PACIFICSTANDARDTIME',
    PacificstandardtimeMexico = 'PACIFICSTANDARDTIME(MEXICO)',
    Pacificstandardtimemexico = 'PACIFICSTANDARDTIMEMEXICO',
    Pakistanstandardtime = 'PAKISTANSTANDARDTIME',
    Paraguaystandardtime = 'PARAGUAYSTANDARDTIME',
    Qyzylordastandardtime = 'QYZYLORDASTANDARDTIME',
    Romancestandardtime = 'ROMANCESTANDARDTIME',
    Russianstandardtime = 'RUSSIANSTANDARDTIME',
    Russiatimezone10 = 'RUSSIATIMEZONE10',
    Russiatimezone11 = 'RUSSIATIMEZONE11',
    Russiatimezone3 = 'RUSSIATIMEZONE3',
    Saeasternstandardtime = 'SAEASTERNSTANDARDTIME',
    Saintpierrestandardtime = 'SAINTPIERRESTANDARDTIME',
    Sakhalinstandardtime = 'SAKHALINSTANDARDTIME',
    Samoastandardtime = 'SAMOASTANDARDTIME',
    Saotomestandardtime = 'SAOTOMESTANDARDTIME',
    Sapacificstandardtime = 'SAPACIFICSTANDARDTIME',
    Saratovstandardtime = 'SARATOVSTANDARDTIME',
    Sawesternstandardtime = 'SAWESTERNSTANDARDTIME',
    Seasiastandardtime = 'SEASIASTANDARDTIME',
    Singaporestandardtime = 'SINGAPORESTANDARDTIME',
    Southafricastandardtime = 'SOUTHAFRICASTANDARDTIME',
    Southsudanstandardtime = 'SOUTHSUDANSTANDARDTIME',
    Srilankastandardtime = 'SRILANKASTANDARDTIME',
    Sudanstandardtime = 'SUDANSTANDARDTIME',
    Syriastandardtime = 'SYRIASTANDARDTIME',
    Taipeistandardtime = 'TAIPEISTANDARDTIME',
    Tasmaniastandardtime = 'TASMANIASTANDARDTIME',
    Tocantinsstandardtime = 'TOCANTINSSTANDARDTIME',
    Tokyostandardtime = 'TOKYOSTANDARDTIME',
    Tomskstandardtime = 'TOMSKSTANDARDTIME',
    Tongastandardtime = 'TONGASTANDARDTIME',
    Transbaikalstandardtime = 'TRANSBAIKALSTANDARDTIME',
    Turkeystandardtime = 'TURKEYSTANDARDTIME',
    Turksandcaicosstandardtime = 'TURKSANDCAICOSSTANDARDTIME',
    Ulaanbaatarstandardtime = 'ULAANBAATARSTANDARDTIME',
    Useasternstandardtime = 'USEASTERNSTANDARDTIME',
    Usmountainstandardtime = 'USMOUNTAINSTANDARDTIME',
    Utc = 'UTC',
    Utc12 = 'UTC+12',
    Utc13 = 'UTC+13',
    Utc02 = 'UTC02',
    Utc08 = 'UTC08',
    Utc09 = 'UTC09',
    Utc11 = 'UTC11',
    Venezuelastandardtime = 'VENEZUELASTANDARDTIME',
    Vladivostokstandardtime = 'VLADIVOSTOKSTANDARDTIME',
    Volgogradstandardtime = 'VOLGOGRADSTANDARDTIME',
    Waustraliastandardtime = 'WAUSTRALIASTANDARDTIME',
    Wcentralafricastandardtime = 'WCENTRALAFRICASTANDARDTIME',
    Westasiastandardtime = 'WESTASIASTANDARDTIME',
    Westbankstandardtime = 'WESTBANKSTANDARDTIME',
    Westpacificstandardtime = 'WESTPACIFICSTANDARDTIME',
    Weuropestandardtime = 'WEUROPESTANDARDTIME',
    Wmongoliastandardtime = 'WMONGOLIASTANDARDTIME',
    Yakutskstandardtime = 'YAKUTSKSTANDARDTIME',
    Yukonstandardtime = 'YUKONSTANDARDTIME'
}
/**
    * @export
    * @enum {string}
    */
export enum OrganisationOrganisationEntityTypeEnum {
    AccountingPractice = 'ACCOUNTING_PRACTICE',
    Accountingpractice = 'ACCOUNTINGPRACTICE',
    Company = 'COMPANY',
    Charity = 'CHARITY',
    ClubOrSociety = 'CLUB_OR_SOCIETY',
    Cluborsociety = 'CLUBORSOCIETY',
    Individual = 'INDIVIDUAL',
    LookThroughCompany = 'LOOK_THROUGH_COMPANY',
    Lookthroughcompany = 'LOOKTHROUGHCOMPANY',
    NotForProfit = 'NOT_FOR_PROFIT',
    Notforprofit = 'NOTFORPROFIT',
    Partnership = 'PARTNERSHIP',
    SCorporation = 'S_CORPORATION',
    Scorporation = 'SCORPORATION',
    SelfManagedSuperannuationFund = 'SELF_MANAGED_SUPERANNUATION_FUND',
    Selfmanagedsuperannuationfund = 'SELFMANAGEDSUPERANNUATIONFUND',
    SoleTrader = 'SOLE_TRADER',
    Soletrader = 'SOLETRADER',
    SuperannuationFund = 'SUPERANNUATION_FUND',
    Superannuationfund = 'SUPERANNUATIONFUND',
    Trust = 'TRUST'
}
/**
    * @export
    * @enum {string}
    */
export enum OrganisationClassEnum {
    Demo = 'DEMO',
    Trial = 'TRIAL',
    Starter = 'STARTER',
    Standard = 'STANDARD',
    Premium = 'PREMIUM',
    Premium20 = 'PREMIUM_20',
    Premium50 = 'PREMIUM_50',
    Premium100 = 'PREMIUM_100',
    Ledger = 'LEDGER',
    GstCashbook = 'GST_CASHBOOK',
    Gstcashbook = 'GSTCASHBOOK',
    NonGstCashbook = 'NON_GST_CASHBOOK',
    Nongstcashbook = 'NONGSTCASHBOOK',
    Ultimate = 'ULTIMATE',
    Lite = 'LITE'
}
/**
    * @export
    * @enum {string}
    */
export enum OrganisationEditionEnum {
    Business = 'BUSINESS',
    Partner = 'PARTNER'
}

/**
 * 
 * @export
 * @interface Overpayment
 */
export interface Overpayment {
    /**
     * See Overpayment Types
     * @type {string}
     * @memberof Overpayment
     */
    type?: OverpaymentTypeEnum;
    /**
     * 
     * @type {Contact}
     * @memberof Overpayment
     */
    contact?: Contact;
    /**
     * The date the overpayment is created YYYY-MM-DD
     * @type {string}
     * @memberof Overpayment
     */
    date?: string;
    /**
     * See Overpayment Status Codes
     * @type {string}
     * @memberof Overpayment
     */
    status?: OverpaymentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Overpayment
     */
    lineAmountTypes?: OverpaymentLineAmountTypesEnum;
    /**
     * See Overpayment Line Items
     * @type {Array<LineItem>}
     * @memberof Overpayment
     */
    lineItems?: Array<LineItem>;
    /**
     * The subtotal of the overpayment excluding taxes
     * @type {number}
     * @memberof Overpayment
     */
    subTotal?: number;
    /**
     * The total tax on the overpayment
     * @type {number}
     * @memberof Overpayment
     */
    totalTax?: number;
    /**
     * The total of the overpayment (subtotal + total tax)
     * @type {number}
     * @memberof Overpayment
     */
    total?: number;
    /**
     * UTC timestamp of last update to the overpayment
     * @type {string}
     * @memberof Overpayment
     */
    updatedDateUTC?: string;
    /**
     * 
     * @type {string}
     * @memberof Overpayment
     */
    currencyCode?: OverpaymentCurrencyCodeEnum;
    /**
     * Xero generated unique identifier
     * @type {string}
     * @memberof Overpayment
     */
    overpaymentID?: string;
    /**
     * The currency rate for a multicurrency overpayment. If no rate is specified, the XE.com day rate is used
     * @type {number}
     * @memberof Overpayment
     */
    currencyRate?: number;
    /**
     * The remaining credit balance on the overpayment
     * @type {number}
     * @memberof Overpayment
     */
    remainingCredit?: number;
    /**
     * See Allocations
     * @type {Array<Allocation>}
     * @memberof Overpayment
     */
    allocations?: Array<Allocation>;
    /**
     * The amount of applied to an invoice
     * @type {number}
     * @memberof Overpayment
     */
    appliedAmount?: number;
    /**
     * See Payments
     * @type {Array<Payment>}
     * @memberof Overpayment
     */
    payments?: Array<Payment>;
    /**
     * boolean to indicate if a overpayment has an attachment
     * @type {boolean}
     * @memberof Overpayment
     */
    hasAttachments?: boolean;
    /**
     * See Attachments
     * @type {Array<Attachment>}
     * @memberof Overpayment
     */
    attachments?: Array<Attachment>;
}

/**
    * @export
    * @enum {string}
    */
export enum OverpaymentTypeEnum {
    ReceiveOverpayment = 'RECEIVE-OVERPAYMENT',
    Receiveoverpayment = 'RECEIVEOVERPAYMENT',
    SpendOverpayment = 'SPEND-OVERPAYMENT',
    Spendoverpayment = 'SPENDOVERPAYMENT',
    Aroverpayment = 'AROVERPAYMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum OverpaymentStatusEnum {
    Authorised = 'AUTHORISED',
    Paid = 'PAID',
    Voided = 'VOIDED'
}
/**
    * @export
    * @enum {string}
    */
export enum OverpaymentLineAmountTypesEnum {
    Exclusive = 'Exclusive',
    Inclusive = 'Inclusive',
    NoTax = 'NoTax'
}
/**
    * @export
    * @enum {string}
    */
export enum OverpaymentCurrencyCodeEnum {
    Aed = 'AED',
    Afn = 'AFN',
    All = 'ALL',
    Amd = 'AMD',
    Ang = 'ANG',
    Aoa = 'AOA',
    Ars = 'ARS',
    Aud = 'AUD',
    Awg = 'AWG',
    Azn = 'AZN',
    Bam = 'BAM',
    Bbd = 'BBD',
    Bdt = 'BDT',
    Bgn = 'BGN',
    Bhd = 'BHD',
    Bif = 'BIF',
    Bmd = 'BMD',
    Bnd = 'BND',
    Bob = 'BOB',
    Brl = 'BRL',
    Bsd = 'BSD',
    Btn = 'BTN',
    Bwp = 'BWP',
    Byn = 'BYN',
    Byr = 'BYR',
    Bzd = 'BZD',
    Cad = 'CAD',
    Cdf = 'CDF',
    Chf = 'CHF',
    Clf = 'CLF',
    Clp = 'CLP',
    Cny = 'CNY',
    Cop = 'COP',
    Crc = 'CRC',
    Cuc = 'CUC',
    Cup = 'CUP',
    Cve = 'CVE',
    Czk = 'CZK',
    Djf = 'DJF',
    Dkk = 'DKK',
    Dop = 'DOP',
    Dzd = 'DZD',
    Eek = 'EEK',
    Egp = 'EGP',
    Ern = 'ERN',
    Etb = 'ETB',
    Eur = 'EUR',
    Fjd = 'FJD',
    Fkp = 'FKP',
    Gbp = 'GBP',
    Gel = 'GEL',
    Ghs = 'GHS',
    Gip = 'GIP',
    Gmd = 'GMD',
    Gnf = 'GNF',
    Gtq = 'GTQ',
    Gyd = 'GYD',
    Hkd = 'HKD',
    Hnl = 'HNL',
    Hrk = 'HRK',
    Htg = 'HTG',
    Huf = 'HUF',
    Idr = 'IDR',
    Ils = 'ILS',
    Inr = 'INR',
    Iqd = 'IQD',
    Irr = 'IRR',
    Isk = 'ISK',
    Jmd = 'JMD',
    Jod = 'JOD',
    Jpy = 'JPY',
    Kes = 'KES',
    Kgs = 'KGS',
    Khr = 'KHR',
    Kmf = 'KMF',
    Kpw = 'KPW',
    Krw = 'KRW',
    Kwd = 'KWD',
    Kyd = 'KYD',
    Kzt = 'KZT',
    Lak = 'LAK',
    Lbp = 'LBP',
    Lkr = 'LKR',
    Lrd = 'LRD',
    Lsl = 'LSL',
    Ltl = 'LTL',
    Lvl = 'LVL',
    Lyd = 'LYD',
    Mad = 'MAD',
    Mdl = 'MDL',
    Mga = 'MGA',
    Mkd = 'MKD',
    Mmk = 'MMK',
    Mnt = 'MNT',
    Mop = 'MOP',
    Mro = 'MRO',
    Mru = 'MRU',
    Mur = 'MUR',
    Mvr = 'MVR',
    Mwk = 'MWK',
    Mxn = 'MXN',
    Mxv = 'MXV',
    Myr = 'MYR',
    Mzn = 'MZN',
    Nad = 'NAD',
    Ngn = 'NGN',
    Nio = 'NIO',
    Nok = 'NOK',
    Npr = 'NPR',
    Nzd = 'NZD',
    Omr = 'OMR',
    Pab = 'PAB',
    Pen = 'PEN',
    Pgk = 'PGK',
    Php = 'PHP',
    Pkr = 'PKR',
    Pln = 'PLN',
    Pyg = 'PYG',
    Qar = 'QAR',
    Ron = 'RON',
    Rsd = 'RSD',
    Rub = 'RUB',
    Rwf = 'RWF',
    Sar = 'SAR',
    Sbd = 'SBD',
    Scr = 'SCR',
    Sdg = 'SDG',
    Sek = 'SEK',
    Sgd = 'SGD',
    Shp = 'SHP',
    Skk = 'SKK',
    Sle = 'SLE',
    Sll = 'SLL',
    Sos = 'SOS',
    Srd = 'SRD',
    Std = 'STD',
    Stn = 'STN',
    Svc = 'SVC',
    Syp = 'SYP',
    Szl = 'SZL',
    Thb = 'THB',
    Tjs = 'TJS',
    Tmt = 'TMT',
    Tnd = 'TND',
    Top = 'TOP',
    Try = 'TRY',
    TryLira = 'TRY_LIRA',
    Ttd = 'TTD',
    Twd = 'TWD',
    Tzs = 'TZS',
    Uah = 'UAH',
    Ugx = 'UGX',
    Usd = 'USD',
    Uyu = 'UYU',
    Uzs = 'UZS',
    Vef = 'VEF',
    Ves = 'VES',
    Vnd = 'VND',
    Vuv = 'VUV',
    Wst = 'WST',
    Xaf = 'XAF',
    Xcd = 'XCD',
    Xof = 'XOF',
    Xpf = 'XPF',
    Yer = 'YER',
    Zar = 'ZAR',
    Zmw = 'ZMW',
    Zmk = 'ZMK',
    Zwd = 'ZWD'
}

/**
 * 
 * @export
 * @interface PaginatedClient
 */
export interface PaginatedClient {
    /**
     * 
     * @type {Array<Client>}
     * @memberof PaginatedClient
     */
    data: Array<Client>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedClient
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedClient
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedClient
     */
    offset: number;
}
/**
 * 
 * @export
 * @interface PaginatedMessage
 */
export interface PaginatedMessage {
    /**
     * 
     * @type {Array<Message>}
     * @memberof PaginatedMessage
     */
    data: Array<Message>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedMessage
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedMessage
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedMessage
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedMessage
     */
    unread: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedMessage
     */
    unreadCritical: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedMessage
     */
    unreadImportant: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedMessage
     */
    unreadAdvisory: number;
}
/**
 * 
 * @export
 * @interface PatchClientDto
 */
export interface PatchClientDto {
    /**
     * 
     * @type {string}
     * @memberof PatchClientDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PatchClientDto
     */
    practiceReference?: string;
}
/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {Invoice}
     * @memberof Payment
     */
    invoice?: Invoice;
    /**
     * 
     * @type {CreditNote}
     * @memberof Payment
     */
    creditNote?: CreditNote;
    /**
     * 
     * @type {Prepayment}
     * @memberof Payment
     */
    prepayment?: Prepayment;
    /**
     * 
     * @type {Overpayment}
     * @memberof Payment
     */
    overpayment?: Overpayment;
    /**
     * Number of invoice or credit note you are applying payment to e.g.INV-4003
     * @type {string}
     * @memberof Payment
     */
    invoiceNumber?: string;
    /**
     * Number of invoice or credit note you are applying payment to e.g. INV-4003
     * @type {string}
     * @memberof Payment
     */
    creditNoteNumber?: string;
    /**
     * 
     * @type {BatchPayment}
     * @memberof Payment
     */
    batchPayment?: BatchPayment;
    /**
     * 
     * @type {Account}
     * @memberof Payment
     */
    account?: Account;
    /**
     * Code of account you are using to make the payment e.g. 001 (note- not all accounts have a code value)
     * @type {string}
     * @memberof Payment
     */
    code?: string;
    /**
     * Date the payment is being made (YYYY-MM-DD) e.g. 2009-09-06
     * @type {string}
     * @memberof Payment
     */
    date?: string;
    /**
     * Exchange rate when payment is received. Only used for non base currency invoices and credit notes e.g. 0.7500
     * @type {number}
     * @memberof Payment
     */
    currencyRate?: number;
    /**
     * The amount of the payment. Must be less than or equal to the outstanding amount owing on the invoice e.g. 200.00
     * @type {number}
     * @memberof Payment
     */
    amount?: number;
    /**
     * The amount of the payment in the currency of the bank account.
     * @type {number}
     * @memberof Payment
     */
    bankAmount?: number;
    /**
     * An optional description for the payment e.g. Direct Debit
     * @type {string}
     * @memberof Payment
     */
    reference?: string;
    /**
     * An optional parameter for the payment. A boolean indicating whether you would like the payment to be created as reconciled when using PUT, or whether a payment has been reconciled when using GET
     * @type {boolean}
     * @memberof Payment
     */
    isReconciled?: boolean;
    /**
     * The status of the payment.
     * @type {string}
     * @memberof Payment
     */
    status?: PaymentStatusEnum;
    /**
     * See Payment Types.
     * @type {string}
     * @memberof Payment
     */
    paymentType?: PaymentPaymentTypeEnum;
    /**
     * UTC timestamp of last update to the payment
     * @type {string}
     * @memberof Payment
     */
    updatedDateUTC?: string;
    /**
     * The Xero identifier for an Payment e.g. 297c2dc5-cc47-4afd-8ec8-74990b8761e9
     * @type {string}
     * @memberof Payment
     */
    paymentID?: string;
    /**
     * Present if the payment was created as part of a batch.
     * @type {string}
     * @memberof Payment
     */
    batchPaymentID?: string;
    /**
     * The suppliers bank account number the payment is being made to
     * @type {string}
     * @memberof Payment
     */
    bankAccountNumber?: string;
    /**
     * The suppliers bank account number the payment is being made to
     * @type {string}
     * @memberof Payment
     */
    particulars?: string;
    /**
     * The information to appear on the supplier\\\'s bank account
     * @type {string}
     * @memberof Payment
     */
    details?: string;
    /**
     * A boolean to indicate if a contact has an validation errors
     * @type {boolean}
     * @memberof Payment
     */
    hasAccount?: boolean;
    /**
     * A boolean to indicate if a contact has an validation errors
     * @type {boolean}
     * @memberof Payment
     */
    hasValidationErrors?: boolean;
    /**
     * A string to indicate if a invoice status
     * @type {string}
     * @memberof Payment
     */
    statusAttributeString?: string;
    /**
     * Displays array of validation error messages from the API
     * @type {Array<ValidationError>}
     * @memberof Payment
     */
    validationErrors?: Array<ValidationError>;
    /**
     * Displays array of warning messages from the API
     * @type {Array<ValidationError>}
     * @memberof Payment
     */
    warnings?: Array<ValidationError>;
}

/**
    * @export
    * @enum {string}
    */
export enum PaymentStatusEnum {
    Authorised = 'AUTHORISED',
    Deleted = 'DELETED'
}
/**
    * @export
    * @enum {string}
    */
export enum PaymentPaymentTypeEnum {
    Accrecpayment = 'ACCRECPAYMENT',
    Accpaypayment = 'ACCPAYPAYMENT',
    Arcreditpayment = 'ARCREDITPAYMENT',
    Apcreditpayment = 'APCREDITPAYMENT',
    Aroverpaymentpayment = 'AROVERPAYMENTPAYMENT',
    Arprepaymentpayment = 'ARPREPAYMENTPAYMENT',
    Apprepaymentpayment = 'APPREPAYMENTPAYMENT',
    Apoverpaymentpayment = 'APOVERPAYMENTPAYMENT'
}

/**
 * 
 * @export
 * @interface PaymentTerm
 */
export interface PaymentTerm {
    /**
     * 
     * @type {Bill}
     * @memberof PaymentTerm
     */
    bills?: Bill;
    /**
     * 
     * @type {Bill}
     * @memberof PaymentTerm
     */
    sales?: Bill;
}
/**
 * 
 * @export
 * @interface Phone
 */
export interface Phone {
    /**
     * 
     * @type {string}
     * @memberof Phone
     */
    phoneType?: PhonePhoneTypeEnum;
    /**
     * max length = 50
     * @type {string}
     * @memberof Phone
     */
    phoneNumber?: string;
    /**
     * max length = 10
     * @type {string}
     * @memberof Phone
     */
    phoneAreaCode?: string;
    /**
     * max length = 20
     * @type {string}
     * @memberof Phone
     */
    phoneCountryCode?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PhonePhoneTypeEnum {
    Default = 'DEFAULT',
    Ddi = 'DDI',
    Mobile = 'MOBILE',
    Fax = 'FAX',
    Office = 'OFFICE'
}

/**
 * 
 * @export
 * @interface Plan
 */
export interface Plan {
    /**
     * The unique identifier of the plan
     * @type {string}
     * @memberof Plan
     */
    id: string;
    /**
     * The name of the plan. It is used in the invoice line item description.
     * @type {string}
     * @memberof Plan
     */
    name: string;
    /**
     * Status of the plan. Available statuses are ACTIVE, CANCELED, and PENDING_ACTIVATION.
     * @type {string}
     * @memberof Plan
     */
    status: PlanStatusEnum;
    /**
     * List of the subscription items belonging to the plan. It does not include cancelled subscription items.
     * @type {Array<SubscriptionItem>}
     * @memberof Plan
     */
    subscriptionItems: Array<SubscriptionItem>;
}

/**
    * @export
    * @enum {string}
    */
export enum PlanStatusEnum {
    Active = 'ACTIVE',
    Canceled = 'CANCELED',
    PendingActivation = 'PENDING_ACTIVATION',
    Pendingactivation = 'PENDINGACTIVATION'
}

/**
 * 
 * @export
 * @interface Practice
 */
export interface Practice {
    /**
     * 
     * @type {string}
     * @memberof Practice
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Practice
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof Practice
     */
    updated: string;
    /**
     * 
     * @type {string}
     * @memberof Practice
     */
    name: string;
    /**
     * 
     * @type {object}
     * @memberof Practice
     */
    marketingAttributes: object;
    /**
     * 
     * @type {Array<User>}
     * @memberof Practice
     */
    users?: Array<User>;
    /**
     * 
     * @type {Array<Client>}
     * @memberof Practice
     */
    clients?: Array<Client>;
}
/**
 * 
 * @export
 * @interface PracticeUserInvite
 */
export interface PracticeUserInvite {
    /**
     * 
     * @type {string}
     * @memberof PracticeUserInvite
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeUserInvite
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeUserInvite
     */
    resent?: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeUserInvite
     */
    accepted?: string;
    /**
     * 
     * @type {PracticeUserInviteRelatedPractice}
     * @memberof PracticeUserInvite
     */
    practice?: PracticeUserInviteRelatedPractice;
    /**
     * 
     * @type {string}
     * @memberof PracticeUserInvite
     */
    createdByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeUserInvite
     */
    practiceId: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeUserInvite
     */
    recipientFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeUserInvite
     */
    recipientLastName: string;
    /**
     * 
     * @type {string}
     * @memberof PracticeUserInvite
     */
    recipientEmail: string;
}
/**
 * 
 * @export
 * @interface PracticeUserInviteRelatedPractice
 */
export interface PracticeUserInviteRelatedPractice {
    /**
     * 
     * @type {string}
     * @memberof PracticeUserInviteRelatedPractice
     */
    name: string;
}
/**
 * 
 * @export
 * @interface Prepayment
 */
export interface Prepayment {
    /**
     * See Prepayment Types
     * @type {string}
     * @memberof Prepayment
     */
    type?: PrepaymentTypeEnum;
    /**
     * 
     * @type {Contact}
     * @memberof Prepayment
     */
    contact?: Contact;
    /**
     * The date the prepayment is created YYYY-MM-DD
     * @type {string}
     * @memberof Prepayment
     */
    date?: string;
    /**
     * See Prepayment Status Codes
     * @type {string}
     * @memberof Prepayment
     */
    status?: PrepaymentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Prepayment
     */
    lineAmountTypes?: PrepaymentLineAmountTypesEnum;
    /**
     * See Prepayment Line Items
     * @type {Array<LineItem>}
     * @memberof Prepayment
     */
    lineItems?: Array<LineItem>;
    /**
     * The subtotal of the prepayment excluding taxes
     * @type {number}
     * @memberof Prepayment
     */
    subTotal?: number;
    /**
     * The total tax on the prepayment
     * @type {number}
     * @memberof Prepayment
     */
    totalTax?: number;
    /**
     * The total of the prepayment(subtotal + total tax)
     * @type {number}
     * @memberof Prepayment
     */
    total?: number;
    /**
     * Returns Invoice number field. Reference field isn\\\'t available.
     * @type {string}
     * @memberof Prepayment
     */
    reference?: string;
    /**
     * UTC timestamp of last update to the prepayment
     * @type {string}
     * @memberof Prepayment
     */
    updatedDateUTC?: string;
    /**
     * 
     * @type {string}
     * @memberof Prepayment
     */
    currencyCode?: PrepaymentCurrencyCodeEnum;
    /**
     * Xero generated unique identifier
     * @type {string}
     * @memberof Prepayment
     */
    prepaymentID?: string;
    /**
     * The currency rate for a multicurrency prepayment. If no rate is specified, the XE.com day rate is used
     * @type {number}
     * @memberof Prepayment
     */
    currencyRate?: number;
    /**
     * The remaining credit balance on the prepayment
     * @type {number}
     * @memberof Prepayment
     */
    remainingCredit?: number;
    /**
     * See Allocations
     * @type {Array<Allocation>}
     * @memberof Prepayment
     */
    allocations?: Array<Allocation>;
    /**
     * See Payments
     * @type {Array<Payment>}
     * @memberof Prepayment
     */
    payments?: Array<Payment>;
    /**
     * The amount of applied to an invoice
     * @type {number}
     * @memberof Prepayment
     */
    appliedAmount?: number;
    /**
     * boolean to indicate if a prepayment has an attachment
     * @type {boolean}
     * @memberof Prepayment
     */
    hasAttachments?: boolean;
    /**
     * See Attachments
     * @type {Array<Attachment>}
     * @memberof Prepayment
     */
    attachments?: Array<Attachment>;
}

/**
    * @export
    * @enum {string}
    */
export enum PrepaymentTypeEnum {
    ReceivePrepayment = 'RECEIVE-PREPAYMENT',
    Receiveprepayment = 'RECEIVEPREPAYMENT',
    SpendPrepayment = 'SPEND-PREPAYMENT',
    Spendprepayment = 'SPENDPREPAYMENT',
    Arprepayment = 'ARPREPAYMENT',
    Apprepayment = 'APPREPAYMENT'
}
/**
    * @export
    * @enum {string}
    */
export enum PrepaymentStatusEnum {
    Authorised = 'AUTHORISED',
    Paid = 'PAID',
    Voided = 'VOIDED'
}
/**
    * @export
    * @enum {string}
    */
export enum PrepaymentLineAmountTypesEnum {
    Exclusive = 'Exclusive',
    Inclusive = 'Inclusive',
    NoTax = 'NoTax'
}
/**
    * @export
    * @enum {string}
    */
export enum PrepaymentCurrencyCodeEnum {
    Aed = 'AED',
    Afn = 'AFN',
    All = 'ALL',
    Amd = 'AMD',
    Ang = 'ANG',
    Aoa = 'AOA',
    Ars = 'ARS',
    Aud = 'AUD',
    Awg = 'AWG',
    Azn = 'AZN',
    Bam = 'BAM',
    Bbd = 'BBD',
    Bdt = 'BDT',
    Bgn = 'BGN',
    Bhd = 'BHD',
    Bif = 'BIF',
    Bmd = 'BMD',
    Bnd = 'BND',
    Bob = 'BOB',
    Brl = 'BRL',
    Bsd = 'BSD',
    Btn = 'BTN',
    Bwp = 'BWP',
    Byn = 'BYN',
    Byr = 'BYR',
    Bzd = 'BZD',
    Cad = 'CAD',
    Cdf = 'CDF',
    Chf = 'CHF',
    Clf = 'CLF',
    Clp = 'CLP',
    Cny = 'CNY',
    Cop = 'COP',
    Crc = 'CRC',
    Cuc = 'CUC',
    Cup = 'CUP',
    Cve = 'CVE',
    Czk = 'CZK',
    Djf = 'DJF',
    Dkk = 'DKK',
    Dop = 'DOP',
    Dzd = 'DZD',
    Eek = 'EEK',
    Egp = 'EGP',
    Ern = 'ERN',
    Etb = 'ETB',
    Eur = 'EUR',
    Fjd = 'FJD',
    Fkp = 'FKP',
    Gbp = 'GBP',
    Gel = 'GEL',
    Ghs = 'GHS',
    Gip = 'GIP',
    Gmd = 'GMD',
    Gnf = 'GNF',
    Gtq = 'GTQ',
    Gyd = 'GYD',
    Hkd = 'HKD',
    Hnl = 'HNL',
    Hrk = 'HRK',
    Htg = 'HTG',
    Huf = 'HUF',
    Idr = 'IDR',
    Ils = 'ILS',
    Inr = 'INR',
    Iqd = 'IQD',
    Irr = 'IRR',
    Isk = 'ISK',
    Jmd = 'JMD',
    Jod = 'JOD',
    Jpy = 'JPY',
    Kes = 'KES',
    Kgs = 'KGS',
    Khr = 'KHR',
    Kmf = 'KMF',
    Kpw = 'KPW',
    Krw = 'KRW',
    Kwd = 'KWD',
    Kyd = 'KYD',
    Kzt = 'KZT',
    Lak = 'LAK',
    Lbp = 'LBP',
    Lkr = 'LKR',
    Lrd = 'LRD',
    Lsl = 'LSL',
    Ltl = 'LTL',
    Lvl = 'LVL',
    Lyd = 'LYD',
    Mad = 'MAD',
    Mdl = 'MDL',
    Mga = 'MGA',
    Mkd = 'MKD',
    Mmk = 'MMK',
    Mnt = 'MNT',
    Mop = 'MOP',
    Mro = 'MRO',
    Mru = 'MRU',
    Mur = 'MUR',
    Mvr = 'MVR',
    Mwk = 'MWK',
    Mxn = 'MXN',
    Mxv = 'MXV',
    Myr = 'MYR',
    Mzn = 'MZN',
    Nad = 'NAD',
    Ngn = 'NGN',
    Nio = 'NIO',
    Nok = 'NOK',
    Npr = 'NPR',
    Nzd = 'NZD',
    Omr = 'OMR',
    Pab = 'PAB',
    Pen = 'PEN',
    Pgk = 'PGK',
    Php = 'PHP',
    Pkr = 'PKR',
    Pln = 'PLN',
    Pyg = 'PYG',
    Qar = 'QAR',
    Ron = 'RON',
    Rsd = 'RSD',
    Rub = 'RUB',
    Rwf = 'RWF',
    Sar = 'SAR',
    Sbd = 'SBD',
    Scr = 'SCR',
    Sdg = 'SDG',
    Sek = 'SEK',
    Sgd = 'SGD',
    Shp = 'SHP',
    Skk = 'SKK',
    Sle = 'SLE',
    Sll = 'SLL',
    Sos = 'SOS',
    Srd = 'SRD',
    Std = 'STD',
    Stn = 'STN',
    Svc = 'SVC',
    Syp = 'SYP',
    Szl = 'SZL',
    Thb = 'THB',
    Tjs = 'TJS',
    Tmt = 'TMT',
    Tnd = 'TND',
    Top = 'TOP',
    Try = 'TRY',
    TryLira = 'TRY_LIRA',
    Ttd = 'TTD',
    Twd = 'TWD',
    Tzs = 'TZS',
    Uah = 'UAH',
    Ugx = 'UGX',
    Usd = 'USD',
    Uyu = 'UYU',
    Uzs = 'UZS',
    Vef = 'VEF',
    Ves = 'VES',
    Vnd = 'VND',
    Vuv = 'VUV',
    Wst = 'WST',
    Xaf = 'XAF',
    Xcd = 'XCD',
    Xof = 'XOF',
    Xpf = 'XPF',
    Yer = 'YER',
    Zar = 'ZAR',
    Zmw = 'ZMW',
    Zmk = 'ZMK',
    Zwd = 'ZWD'
}

/**
 * 
 * @export
 * @interface Price
 */
export interface Price {
    /**
     * The net (before tax) amount.
     * @type {number}
     * @memberof Price
     */
    amount: number;
    /**
     * The currency of the price.
     * @type {string}
     * @memberof Price
     */
    currency: string;
    /**
     * The unique identifier of the price.
     * @type {string}
     * @memberof Price
     */
    id: string;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * The unique identifier for the product
     * @type {string}
     * @memberof Product
     */
    id?: string;
    /**
     * The name of the product
     * @type {string}
     * @memberof Product
     */
    name?: string;
    /**
     * The unit of the per seat product. e.g. \\\"user\\\", \\\"organisation\\\", \\\"SMS\\\", etc
     * @type {string}
     * @memberof Product
     */
    seatUnit?: string;
    /**
     * The pricing model of the product: * FIXED: Customers are charged a fixed amount for each billing period * PER_SEAT: Customers are charged based on the number of units they purchase * METERED: Customers are charged per use of this product
     * @type {string}
     * @memberof Product
     */
    type?: ProductTypeEnum;
    /**
     * The unit of the usage product. e.g. \\\"user\\\", \\\"minutes\\\", \\\"SMS\\\", etc
     * @type {string}
     * @memberof Product
     */
    usageUnit?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ProductTypeEnum {
    Fixed = 'FIXED',
    PerSeat = 'PER_SEAT',
    Perseat = 'PERSEAT',
    Metered = 'METERED'
}

/**
 * 
 * @export
 * @interface RecentAnalysisQueryResult
 */
export interface RecentAnalysisQueryResult {
    /**
     * 
     * @type {string}
     * @memberof RecentAnalysisQueryResult
     */
    type: RecentAnalysisQueryResultTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RecentAnalysisQueryResult
     */
    created: string;
    /**
     * 
     * @type {object}
     * @memberof RecentAnalysisQueryResult
     */
    normalizedEntity: object;
    /**
     * 
     * @type {object}
     * @memberof RecentAnalysisQueryResult
     */
    conclusions: object;
}

/**
    * @export
    * @enum {string}
    */
export enum RecentAnalysisQueryResultTypeEnum {
    Contact = 'Contact',
    Invoice = 'Invoice',
    Bill = 'Bill',
    BankTransaction = 'BankTransaction'
}

/**
 * 
 * @export
 * @interface RegisterPracticeDto
 */
export interface RegisterPracticeDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterPracticeDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPracticeDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPracticeDto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPracticeDto
     */
    practiceName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPracticeDto
     */
    practiceEmployees?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterPracticeDto
     */
    practicePosition?: string;
}
/**
 * 
 * @export
 * @interface SalesTrackingCategory
 */
export interface SalesTrackingCategory {
    /**
     * The default sales tracking category name for contacts
     * @type {string}
     * @memberof SalesTrackingCategory
     */
    trackingCategoryName?: string;
    /**
     * The default purchase tracking category name for contacts
     * @type {string}
     * @memberof SalesTrackingCategory
     */
    trackingOptionName?: string;
}
/**
 * 
 * @export
 * @interface SeekCompanyResult
 */
export interface SeekCompanyResult {
    /**
     * 
     * @type {string}
     * @memberof SeekCompanyResult
     */
    type: SeekCompanyResultTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SeekCompanyResult
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof SeekCompanyResult
     */
    dateOfCreation: string;
    /**
     * 
     * @type {string}
     * @memberof SeekCompanyResult
     */
    dateOfCessation?: string;
    /**
     * 
     * @type {string}
     * @memberof SeekCompanyResult
     */
    companyNumber: string;
    /**
     * 
     * @type {SeekCompanyResultAddress}
     * @memberof SeekCompanyResult
     */
    registeredOfficeAddress: SeekCompanyResultAddress;
    /**
     * 
     * @type {string}
     * @memberof SeekCompanyResult
     */
    companyStatus: SeekCompanyResultCompanyStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SeekCompanyResultTypeEnum {
    SeekCompanyByName = 'SeekCompanyByName',
    SeekCompanyByNumber = 'SeekCompanyByNumber'
}
/**
    * @export
    * @enum {string}
    */
export enum SeekCompanyResultCompanyStatusEnum {
    Active = 'active',
    Dissolved = 'dissolved',
    Liquidation = 'liquidation',
    Receivership = 'receivership',
    ConvertedClosed = 'converted-closed',
    VoluntaryArrangement = 'voluntary-arrangement',
    InsolvencyProceedings = 'insolvency-proceedings',
    Administration = 'administration',
    Open = 'open',
    Closed = 'closed',
    Registered = 'registered',
    Removed = 'removed'
}

/**
 * 
 * @export
 * @interface SeekCompanyResultAddress
 */
export interface SeekCompanyResultAddress {
    /**
     * 
     * @type {string}
     * @memberof SeekCompanyResultAddress
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof SeekCompanyResultAddress
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof SeekCompanyResultAddress
     */
    careOf?: string;
    /**
     * 
     * @type {string}
     * @memberof SeekCompanyResultAddress
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof SeekCompanyResultAddress
     */
    locality?: string;
    /**
     * 
     * @type {string}
     * @memberof SeekCompanyResultAddress
     */
    poBox?: string;
    /**
     * 
     * @type {string}
     * @memberof SeekCompanyResultAddress
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SeekCompanyResultAddress
     */
    premises?: string;
    /**
     * 
     * @type {string}
     * @memberof SeekCompanyResultAddress
     */
    region?: string;
}
/**
 * 
 * @export
 * @interface SetActiveClientDto
 */
export interface SetActiveClientDto {
    /**
     * 
     * @type {string}
     * @memberof SetActiveClientDto
     */
    clientId: string;
}
/**
 * 
 * @export
 * @interface SetPracticeUserAccessDto
 */
export interface SetPracticeUserAccessDto {
    /**
     * 
     * @type {string}
     * @memberof SetPracticeUserAccessDto
     */
    userId: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetPracticeUserAccessDto
     */
    inviteId: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SetPracticeUserAccessDto
     */
    canAccess: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SetPracticeUserAccessDto
     */
    isAccountManager: boolean;
}
/**
 * 
 * @export
 * @interface SetXeroTenantConnectionClientDto
 */
export interface SetXeroTenantConnectionClientDto {
    /**
     * 
     * @type {string}
     * @memberof SetXeroTenantConnectionClientDto
     */
    clientId: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SortDirection {
    Asc = 'asc',
    Desc = 'desc'
}

/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    updated?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Subscription
     */
    pending: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Subscription
     */
    expired: boolean;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    voided?: string;
    /**
     * To track whether jobs that process the approach of expiry (e.g reminders) and actual expiry (e.g disconnect xero / other cleanup), have been completed
     * @type {string}
     * @memberof Subscription
     */
    expiryApproachProcessed?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    expiryProcessed?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    type: SubscriptionTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    plan: SubscriptionPlanEnum;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    effectiveFrom: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    effectiveTo?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    xeroTenantId: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    xeroSubscriptionId?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    xeroSubscriptionStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    xeroSubscriptionPlanId?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    xeroSubscriptionPlanStatus?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SubscriptionTypeEnum {
    FreeTrial = 'FreeTrial',
    XeroAppStore = 'XeroAppStore'
}
/**
    * @export
    * @enum {string}
    */
export enum SubscriptionPlanEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

/**
 * 
 * @export
 * @interface SubscriptionItem
 */
export interface SubscriptionItem {
    /**
     * Date when the subscription to this product will end
     * @type {string}
     * @memberof SubscriptionItem
     */
    endDate?: string;
    /**
     * The unique identifier of the subscription item.
     * @type {string}
     * @memberof SubscriptionItem
     */
    id: string;
    /**
     * 
     * @type {Price}
     * @memberof SubscriptionItem
     */
    price: Price;
    /**
     * 
     * @type {Product}
     * @memberof SubscriptionItem
     */
    product: Product;
    /**
     * The quantity of the item. For a fixed product, it is 1. For a per-seat product, it is a positive integer. For metered products, it is always null.
     * @type {number}
     * @memberof SubscriptionItem
     */
    quantity?: number;
    /**
     * Date the subscription started, or will start. Note: this could be in the future for downgrades or reduced number of seats that haven\\\'t taken effect yet.
     * @type {string}
     * @memberof SubscriptionItem
     */
    startDate: string;
    /**
     * Status of the subscription item. Available statuses are ACTIVE, CANCELED, and PENDING_ACTIVATION.
     * @type {string}
     * @memberof SubscriptionItem
     */
    status: SubscriptionItemStatusEnum;
    /**
     * If the subscription is a test subscription
     * @type {boolean}
     * @memberof SubscriptionItem
     */
    testMode?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum SubscriptionItemStatusEnum {
    Active = 'ACTIVE',
    Canceled = 'CANCELED',
    PendingActivation = 'PENDING_ACTIVATION',
    Pendingactivation = 'PENDINGACTIVATION'
}

/**
 * 
 * @export
 * @interface TaxComponent
 */
export interface TaxComponent {
    /**
     * Name of Tax Component
     * @type {string}
     * @memberof TaxComponent
     */
    name?: string;
    /**
     * Tax Rate (up to 4dp)
     * @type {number}
     * @memberof TaxComponent
     */
    rate?: number;
    /**
     * Boolean to describe if Tax rate is compounded.
     * @type {boolean}
     * @memberof TaxComponent
     */
    isCompound?: boolean;
    /**
     * Boolean to describe if tax rate is non-recoverable. Non-recoverable rates are only applicable to Canadian organisations
     * @type {boolean}
     * @memberof TaxComponent
     */
    isNonRecoverable?: boolean;
}
/**
 * 
 * @export
 * @interface TaxRate
 */
export interface TaxRate {
    /**
     * Name of tax rate
     * @type {string}
     * @memberof TaxRate
     */
    name?: string;
    /**
     * The tax type
     * @type {string}
     * @memberof TaxRate
     */
    taxType?: string;
    /**
     * See TaxComponents
     * @type {Array<TaxComponent>}
     * @memberof TaxRate
     */
    taxComponents?: Array<TaxComponent>;
    /**
     * See Status Codes
     * @type {string}
     * @memberof TaxRate
     */
    status?: TaxRateStatusEnum;
    /**
     * See ReportTaxTypes
     * @type {string}
     * @memberof TaxRate
     */
    reportTaxType?: TaxRateReportTaxTypeEnum;
    /**
     * Boolean to describe if tax rate can be used for asset accounts i.e.  true,false
     * @type {boolean}
     * @memberof TaxRate
     */
    canApplyToAssets?: boolean;
    /**
     * Boolean to describe if tax rate can be used for equity accounts i.e true,false
     * @type {boolean}
     * @memberof TaxRate
     */
    canApplyToEquity?: boolean;
    /**
     * Boolean to describe if tax rate can be used for expense accounts  i.e. true,false
     * @type {boolean}
     * @memberof TaxRate
     */
    canApplyToExpenses?: boolean;
    /**
     * Boolean to describe if tax rate can be used for liability accounts  i.e. true,false
     * @type {boolean}
     * @memberof TaxRate
     */
    canApplyToLiabilities?: boolean;
    /**
     * Boolean to describe if tax rate can be used for revenue accounts i.e. true,false
     * @type {boolean}
     * @memberof TaxRate
     */
    canApplyToRevenue?: boolean;
    /**
     * Tax Rate (decimal to 4dp) e.g 12.5000
     * @type {number}
     * @memberof TaxRate
     */
    displayTaxRate?: number;
    /**
     * Effective Tax Rate (decimal to 4dp) e.g 12.5000
     * @type {number}
     * @memberof TaxRate
     */
    effectiveRate?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TaxRateStatusEnum {
    Active = 'ACTIVE',
    Deleted = 'DELETED',
    Archived = 'ARCHIVED',
    Pending = 'PENDING'
}
/**
    * @export
    * @enum {string}
    */
export enum TaxRateReportTaxTypeEnum {
    Avalara = 'AVALARA',
    Basexcluded = 'BASEXCLUDED',
    Capitalsalesoutput = 'CAPITALSALESOUTPUT',
    Capitalexpensesinput = 'CAPITALEXPENSESINPUT',
    Ecoutput = 'ECOUTPUT',
    Ecoutputservices = 'ECOUTPUTSERVICES',
    Ecinput = 'ECINPUT',
    Ecacquisitions = 'ECACQUISITIONS',
    Exemptexpenses = 'EXEMPTEXPENSES',
    Exemptinput = 'EXEMPTINPUT',
    Exemptoutput = 'EXEMPTOUTPUT',
    Gstonimports = 'GSTONIMPORTS',
    Input = 'INPUT',
    Inputtaxed = 'INPUTTAXED',
    Mosssales = 'MOSSSALES',
    None = 'NONE',
    Noneoutput = 'NONEOUTPUT',
    Output = 'OUTPUT',
    Purchasesinput = 'PURCHASESINPUT',
    Salesoutput = 'SALESOUTPUT',
    Exemptcapital = 'EXEMPTCAPITAL',
    Exemptexport = 'EXEMPTEXPORT',
    Capitalexinput = 'CAPITALEXINPUT',
    Gstoncapimports = 'GSTONCAPIMPORTS',
    Gstoncapitalimports = 'GSTONCAPITALIMPORTS',
    Reversecharges = 'REVERSECHARGES',
    Payments = 'PAYMENTS',
    Invoice = 'INVOICE',
    Cash = 'CASH',
    Accrual = 'ACCRUAL',
    Flatratecash = 'FLATRATECASH',
    Flatrateaccrual = 'FLATRATEACCRUAL',
    Accruals = 'ACCRUALS',
    Txca = 'TXCA',
    Srcas = 'SRCAS',
    Dsoutput = 'DSOUTPUT',
    Blinput2 = 'BLINPUT2',
    Epinput = 'EPINPUT',
    Iminput2 = 'IMINPUT2',
    Meinput = 'MEINPUT',
    Igdsinput2 = 'IGDSINPUT2',
    Esn33Output = 'ESN33OUTPUT',
    Opinput = 'OPINPUT',
    Osoutput = 'OSOUTPUT',
    Txn33Input = 'TXN33INPUT',
    Txessinput = 'TXESSINPUT',
    Txreinput = 'TXREINPUT',
    Txpetinput = 'TXPETINPUT',
    Nrinput = 'NRINPUT',
    Es33Output = 'ES33OUTPUT',
    Zeroratedinput = 'ZERORATEDINPUT',
    Zeroratedoutput = 'ZERORATEDOUTPUT',
    Drchargesupply = 'DRCHARGESUPPLY',
    Drcharge = 'DRCHARGE',
    Capinput = 'CAPINPUT',
    Capimports = 'CAPIMPORTS',
    Iminput = 'IMINPUT',
    Input2 = 'INPUT2',
    Ciuinput = 'CIUINPUT',
    Srinput = 'SRINPUT',
    Output2 = 'OUTPUT2',
    Sroutput = 'SROUTPUT',
    Capoutput = 'CAPOUTPUT',
    Sroutput2 = 'SROUTPUT2',
    Ciuoutput = 'CIUOUTPUT',
    Zroutput = 'ZROUTPUT',
    Zrexport = 'ZREXPORT',
    Acc28Plus = 'ACC28PLUS',
    Accupto28 = 'ACCUPTO28',
    Otheroutput = 'OTHEROUTPUT',
    Shoutput = 'SHOUTPUT',
    Zrinput = 'ZRINPUT',
    Baddebt = 'BADDEBT',
    Otherinput = 'OTHERINPUT',
    Baddebtrelief = 'BADDEBTRELIEF',
    Igdsinput3 = 'IGDSINPUT3',
    Srovr = 'SROVR',
    Touristrefund = 'TOURISTREFUND',
    Txrcn33 = 'TXRCN33',
    Txrcre = 'TXRCRE',
    Txrcess = 'TXRCESS',
    Txrcts = 'TXRCTS',
    Capexinput = 'CAPEXINPUT',
    Undefined = 'UNDEFINED',
    Capexoutput = 'CAPEXOUTPUT',
    Zeroexpoutput = 'ZEROEXPOUTPUT',
    Goodsimport = 'GOODSIMPORT',
    Noneinput = 'NONEINPUT',
    Notreported = 'NOTREPORTED',
    Srovrrs = 'SROVRRS',
    Srovrlvg = 'SROVRLVG',
    Srlvg = 'SRLVG',
    Im = 'IM',
    Imess = 'IMESS',
    Imn33 = 'IMN33',
    Imre = 'IMRE',
    Baddebtrecovery = 'BADDEBTRECOVERY',
    Ussalestax = 'USSALESTAX'
}

/**
 * 
 * @export
 * @interface TenantAnalysisState
 */
export interface TenantAnalysisState {
    /**
     * 
     * @type {string}
     * @memberof TenantAnalysisState
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAnalysisState
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAnalysisState
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAnalysisState
     */
    lastAnalysisJobCompletedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAnalysisState
     */
    lastAnalysisJobCompletedId?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAnalysisState
     */
    lastAnalysisJobCompletedName?: string;
    /**
     * 
     * @type {Array<TenantAnalysisStateConclusion>}
     * @memberof TenantAnalysisState
     */
    conclusions?: Array<TenantAnalysisStateConclusion>;
    /**
     * 
     * @type {string}
     * @memberof TenantAnalysisState
     */
    xeroTenantId: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAnalysisState
     */
    lastAnalysisJobStartedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAnalysisState
     */
    lastAnalysisJobStartedId?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAnalysisState
     */
    lastAnalysisJobStartedName?: string;
}
/**
 * 
 * @export
 * @interface TenantAnalysisStateConclusion
 */
export interface TenantAnalysisStateConclusion {
    /**
     * 
     * @type {string}
     * @memberof TenantAnalysisStateConclusion
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAnalysisStateConclusion
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAnalysisStateConclusion
     */
    tenantAnalysisId?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantAnalysisStateConclusion
     */
    type: TenantAnalysisStateConclusionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TenantAnalysisStateConclusion
     */
    correlationId: string | null;
    /**
     * 
     * @type {number}
     * @memberof TenantAnalysisStateConclusion
     */
    confidence: number | null;
    /**
     * 
     * @type {object}
     * @memberof TenantAnalysisStateConclusion
     */
    data: object | null;
    /**
     * 
     * @type {object}
     * @memberof TenantAnalysisStateConclusion
     */
    meta: object | null;
}

/**
    * @export
    * @enum {string}
    */
export enum TenantAnalysisStateConclusionTypeEnum {
    HasXeroUser = 'HasXeroUser',
    HasMonthlyTransactionCountHistory = 'HasMonthlyTransactionCountHistory',
    HasMonthlyTransactionValueHistory = 'HasMonthlyTransactionValueHistory',
    HasSpendHistogram = 'HasSpendHistogram',
    HasReceiveHistogram = 'HasReceiveHistogram'
}

/**
 * 
 * @export
 * @interface TenantAnalysisStats
 */
export interface TenantAnalysisStats {
    /**
     * 
     * @type {number}
     * @memberof TenantAnalysisStats
     */
    contactCount: number;
    /**
     * 
     * @type {number}
     * @memberof TenantAnalysisStats
     */
    supplierCount: number;
    /**
     * 
     * @type {number}
     * @memberof TenantAnalysisStats
     */
    customerCount: number;
    /**
     * 
     * @type {number}
     * @memberof TenantAnalysisStats
     */
    companyCount: number;
    /**
     * 
     * @type {number}
     * @memberof TenantAnalysisStats
     */
    officersCount: number;
    /**
     * 
     * @type {number}
     * @memberof TenantAnalysisStats
     */
    invoiceCount: number;
    /**
     * 
     * @type {number}
     * @memberof TenantAnalysisStats
     */
    billCount: number;
    /**
     * 
     * @type {number}
     * @memberof TenantAnalysisStats
     */
    otherTransactionCount: number;
    /**
     * 
     * @type {number}
     * @memberof TenantAnalysisStats
     */
    readonlyUserCount: number;
    /**
     * 
     * @type {number}
     * @memberof TenantAnalysisStats
     */
    standardUserCount: number;
    /**
     * 
     * @type {number}
     * @memberof TenantAnalysisStats
     */
    advisorUserCount: number;
}
/**
 * 
 * @export
 * @interface UpsertClientFraudOutreachSettingDto
 */
export interface UpsertClientFraudOutreachSettingDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpsertClientFraudOutreachSettingDto
     */
    outreachEnabled: boolean;
}
/**
 * 
 * @export
 * @interface UpsertUserAlertingPreferencesDto
 */
export interface UpsertUserAlertingPreferencesDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpsertUserAlertingPreferencesDto
     */
    messagesMuted?: boolean;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    helpscoutSignature: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    type: UserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firebaseUid: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    xeroUserId: string;
    /**
     * 
     * @type {object}
     * @memberof User
     */
    marketingAttributes: object;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    practiceId: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    practiceRole: UserPracticeRoleEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UserTypeEnum {
    Client = 'Client',
    Practice = 'Practice'
}
/**
    * @export
    * @enum {string}
    */
export enum UserPracticeRoleEnum {
    Owner = 'Owner',
    User = 'User'
}

/**
 * 
 * @export
 * @interface UserAlertingPreferences
 */
export interface UserAlertingPreferences {
    /**
     * 
     * @type {string}
     * @memberof UserAlertingPreferences
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserAlertingPreferences
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof UserAlertingPreferences
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UserAlertingPreferences
     */
    xeroTenantConnectionId: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAlertingPreferences
     */
    messagesMuted?: boolean;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * Validation error message
     * @type {string}
     * @memberof ValidationError
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface XeroApiTenant
 */
export interface XeroApiTenant {
    /**
     * 
     * @type {string}
     * @memberof XeroApiTenant
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof XeroApiTenant
     */
    authEventId: string;
    /**
     * 
     * @type {string}
     * @memberof XeroApiTenant
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof XeroApiTenant
     */
    tenantType: XeroApiTenantTenantTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof XeroApiTenant
     */
    tenantName?: string;
    /**
     * 
     * @type {string}
     * @memberof XeroApiTenant
     */
    createdDateUtc: string;
    /**
     * 
     * @type {string}
     * @memberof XeroApiTenant
     */
    updatedDateUtc: string;
    /**
     * 
     * @type {Organisation}
     * @memberof XeroApiTenant
     */
    orgData?: Organisation;
}

/**
    * @export
    * @enum {string}
    */
export enum XeroApiTenantTenantTypeEnum {
    Organisation = 'ORGANISATION',
    Practicemanager = 'PRACTICEMANAGER',
    Practice = 'PRACTICE',
    Workflowmax = 'WORKFLOWMAX'
}

/**
 * 
 * @export
 * @interface XeroSubscription
 */
export interface XeroSubscription {
    /**
     * End of the current period that the subscription has been invoiced for.
     * @type {string}
     * @memberof XeroSubscription
     */
    currentPeriodEnd: string;
    /**
     * If the subscription has been canceled, this is the date when the subscription ends. If null, the subscription is active and has not been cancelled
     * @type {string}
     * @memberof XeroSubscription
     */
    endDate?: string;
    /**
     * The unique identifier of the subscription
     * @type {string}
     * @memberof XeroSubscription
     */
    id: string;
    /**
     * The Xero generated unique identifier for the organisation
     * @type {string}
     * @memberof XeroSubscription
     */
    organisationId: string;
    /**
     * List of plans for the subscription.
     * @type {Array<Plan>}
     * @memberof XeroSubscription
     */
    plans: Array<Plan>;
    /**
     * Date when the subscription was first created.
     * @type {string}
     * @memberof XeroSubscription
     */
    startDate: string;
    /**
     * Status of the subscription. Available statuses are ACTIVE, CANCELED, and PAST_DUE.
     * @type {string}
     * @memberof XeroSubscription
     */
    status: XeroSubscriptionStatusEnum;
    /**
     * Boolean used to indicate if the subscription is in test mode
     * @type {boolean}
     * @memberof XeroSubscription
     */
    testMode?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum XeroSubscriptionStatusEnum {
    Active = 'ACTIVE',
    Canceled = 'CANCELED',
    PastDue = 'PAST_DUE',
    Pastdue = 'PASTDUE'
}

/**
 * 
 * @export
 * @interface XeroTenantConnection
 */
export interface XeroTenantConnection {
    /**
     * 
     * @type {string}
     * @memberof XeroTenantConnection
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof XeroTenantConnection
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof XeroTenantConnection
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof XeroTenantConnection
     */
    initialAnalysisJobId: string;
    /**
     * 
     * @type {string}
     * @memberof XeroTenantConnection
     */
    disconnected?: string;
    /**
     * 
     * @type {string}
     * @memberof XeroTenantConnection
     */
    disconnectedByUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof XeroTenantConnection
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof XeroTenantConnection
     */
    createdByUserId: string;
    /**
     * 
     * @type {string}
     * @memberof XeroTenantConnection
     */
    xeroTenantId: string;
    /**
     * 
     * @type {string}
     * @memberof XeroTenantConnection
     */
    xeroConnectionId: string;
    /**
     * 
     * @type {string}
     * @memberof XeroTenantConnection
     */
    tenantName: string;
    /**
     * 
     * @type {string}
     * @memberof XeroTenantConnection
     */
    xeroOrganisationCountryCode: string;
    /**
     * 
     * @type {string}
     * @memberof XeroTenantConnection
     */
    xeroOrganisationRegistrationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof XeroTenantConnection
     */
    xeroOrganisationTaxNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof XeroTenantConnection
     */
    xeroOrganisationTimezone: string;
    /**
     * 
     * @type {string}
     * @memberof XeroTenantConnection
     */
    xeroOrganisationShortCode: string;
}
/**
 * 
 * @export
 * @interface XeroUser
 */
export interface XeroUser {
    /**
     * Xero identifier
     * @type {string}
     * @memberof XeroUser
     */
    userID?: string;
    /**
     * Email address of user
     * @type {string}
     * @memberof XeroUser
     */
    emailAddress?: string;
    /**
     * First name of user
     * @type {string}
     * @memberof XeroUser
     */
    firstName?: string;
    /**
     * Last name of user
     * @type {string}
     * @memberof XeroUser
     */
    lastName?: string;
    /**
     * Timestamp of last change to user
     * @type {string}
     * @memberof XeroUser
     */
    updatedDateUTC?: string;
    /**
     * Boolean to indicate if user is the subscriber
     * @type {boolean}
     * @memberof XeroUser
     */
    isSubscriber?: boolean;
    /**
     * User role that defines permissions in Xero and via API (READONLY, INVOICEONLY, STANDARD, FINANCIALADVISER, etc)
     * @type {string}
     * @memberof XeroUser
     */
    organisationRole?: XeroUserOrganisationRoleEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum XeroUserOrganisationRoleEnum {
    Readonly = 'READONLY',
    Invoiceonly = 'INVOICEONLY',
    Standard = 'STANDARD',
    Financialadviser = 'FINANCIALADVISER',
    Managedclient = 'MANAGEDCLIENT',
    Cashbookclient = 'CASHBOOKCLIENT',
    Unknown = 'UNKNOWN'
}


/**
 * AlertingPreferencesApi - axios parameter creator
 * @export
 */
export const AlertingPreferencesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAdditionalAlertingContactDto} createAdditionalAlertingContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdditionalAlertingContact: async (createAdditionalAlertingContactDto: CreateAdditionalAlertingContactDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAdditionalAlertingContactDto' is not null or undefined
            if (createAdditionalAlertingContactDto === null || createAdditionalAlertingContactDto === undefined) {
                throw new RequiredError('createAdditionalAlertingContactDto','Required parameter createAdditionalAlertingContactDto was null or undefined when calling createAdditionalAlertingContact.');
            }
            const localVarPath = `/alerting-preferences/additional-alerting-contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createAdditionalAlertingContactDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createAdditionalAlertingContactDto !== undefined ? createAdditionalAlertingContactDto : {})
                : (createAdditionalAlertingContactDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdditionalAlertingContact: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAdditionalAlertingContact.');
            }
            const localVarPath = `/alerting-preferences/additional-alerting-contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdditionalAlertingContacts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/alerting-preferences/additional-alerting-contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAlertingPreferences: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/alerting-preferences/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertUserAlertingPreferencesDto} upsertUserAlertingPreferencesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertUserAlertingPreferences: async (upsertUserAlertingPreferencesDto: UpsertUserAlertingPreferencesDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsertUserAlertingPreferencesDto' is not null or undefined
            if (upsertUserAlertingPreferencesDto === null || upsertUserAlertingPreferencesDto === undefined) {
                throw new RequiredError('upsertUserAlertingPreferencesDto','Required parameter upsertUserAlertingPreferencesDto was null or undefined when calling upsertUserAlertingPreferences.');
            }
            const localVarPath = `/alerting-preferences/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof upsertUserAlertingPreferencesDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(upsertUserAlertingPreferencesDto !== undefined ? upsertUserAlertingPreferencesDto : {})
                : (upsertUserAlertingPreferencesDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlertingPreferencesApi - functional programming interface
 * @export
 */
export const AlertingPreferencesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAdditionalAlertingContactDto} createAdditionalAlertingContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdditionalAlertingContact(createAdditionalAlertingContactDto: CreateAdditionalAlertingContactDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalAlertingContact>> {
            const localVarAxiosArgs = await AlertingPreferencesApiAxiosParamCreator(configuration).createAdditionalAlertingContact(createAdditionalAlertingContactDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdditionalAlertingContact(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalAlertingContact>> {
            const localVarAxiosArgs = await AlertingPreferencesApiAxiosParamCreator(configuration).deleteAdditionalAlertingContact(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdditionalAlertingContacts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdditionalAlertingContact>>> {
            const localVarAxiosArgs = await AlertingPreferencesApiAxiosParamCreator(configuration).getAdditionalAlertingContacts(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAlertingPreferences(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAlertingPreferences>> {
            const localVarAxiosArgs = await AlertingPreferencesApiAxiosParamCreator(configuration).getUserAlertingPreferences(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpsertUserAlertingPreferencesDto} upsertUserAlertingPreferencesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertUserAlertingPreferences(upsertUserAlertingPreferencesDto: UpsertUserAlertingPreferencesDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAlertingPreferences>> {
            const localVarAxiosArgs = await AlertingPreferencesApiAxiosParamCreator(configuration).upsertUserAlertingPreferences(upsertUserAlertingPreferencesDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AlertingPreferencesApi - factory interface
 * @export
 */
export const AlertingPreferencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {CreateAdditionalAlertingContactDto} createAdditionalAlertingContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdditionalAlertingContact(createAdditionalAlertingContactDto: CreateAdditionalAlertingContactDto, options?: any): AxiosPromise<AdditionalAlertingContact> {
            return AlertingPreferencesApiFp(configuration).createAdditionalAlertingContact(createAdditionalAlertingContactDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdditionalAlertingContact(id: string, options?: any): AxiosPromise<AdditionalAlertingContact> {
            return AlertingPreferencesApiFp(configuration).deleteAdditionalAlertingContact(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdditionalAlertingContacts(options?: any): AxiosPromise<Array<AdditionalAlertingContact>> {
            return AlertingPreferencesApiFp(configuration).getAdditionalAlertingContacts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAlertingPreferences(options?: any): AxiosPromise<UserAlertingPreferences> {
            return AlertingPreferencesApiFp(configuration).getUserAlertingPreferences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertUserAlertingPreferencesDto} upsertUserAlertingPreferencesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertUserAlertingPreferences(upsertUserAlertingPreferencesDto: UpsertUserAlertingPreferencesDto, options?: any): AxiosPromise<UserAlertingPreferences> {
            return AlertingPreferencesApiFp(configuration).upsertUserAlertingPreferences(upsertUserAlertingPreferencesDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AlertingPreferencesApi - object-oriented interface
 * @export
 * @class AlertingPreferencesApi
 * @extends {BaseAPI}
 */
export class AlertingPreferencesApi extends BaseAPI {
    /**
     * 
     * @param {CreateAdditionalAlertingContactDto} createAdditionalAlertingContactDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertingPreferencesApi
     */
    public createAdditionalAlertingContact(createAdditionalAlertingContactDto: CreateAdditionalAlertingContactDto, options?: any) {
        return AlertingPreferencesApiFp(this.configuration).createAdditionalAlertingContact(createAdditionalAlertingContactDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertingPreferencesApi
     */
    public deleteAdditionalAlertingContact(id: string, options?: any) {
        return AlertingPreferencesApiFp(this.configuration).deleteAdditionalAlertingContact(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertingPreferencesApi
     */
    public getAdditionalAlertingContacts(options?: any) {
        return AlertingPreferencesApiFp(this.configuration).getAdditionalAlertingContacts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertingPreferencesApi
     */
    public getUserAlertingPreferences(options?: any) {
        return AlertingPreferencesApiFp(this.configuration).getUserAlertingPreferences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertUserAlertingPreferencesDto} upsertUserAlertingPreferencesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertingPreferencesApi
     */
    public upsertUserAlertingPreferences(upsertUserAlertingPreferencesDto: UpsertUserAlertingPreferencesDto, options?: any) {
        return AlertingPreferencesApiFp(this.configuration).upsertUserAlertingPreferences(upsertUserAlertingPreferencesDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnalysisApi - axios parameter creator
 * @export
 */
export const AnalysisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} xeroBankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankTransactionAnalysisStateByXeroBankTransactionId: async (xeroBankTransactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xeroBankTransactionId' is not null or undefined
            if (xeroBankTransactionId === null || xeroBankTransactionId === undefined) {
                throw new RequiredError('xeroBankTransactionId','Required parameter xeroBankTransactionId was null or undefined when calling getBankTransactionAnalysisStateByXeroBankTransactionId.');
            }
            const localVarPath = `/analysis/bank-transactions/{xeroBankTransactionId}/state`
                .replace(`{${"xeroBankTransactionId"}}`, encodeURIComponent(String(xeroBankTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactAnalysisById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getContactAnalysisById.');
            }
            const localVarPath = `/analysis/contact/analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xeroContactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactAnalysisStateByXeroContactId: async (xeroContactId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xeroContactId' is not null or undefined
            if (xeroContactId === null || xeroContactId === undefined) {
                throw new RequiredError('xeroContactId','Required parameter xeroContactId was null or undefined when calling getContactAnalysisStateByXeroContactId.');
            }
            const localVarPath = `/analysis/contact/{xeroContactId}/state`
                .replace(`{${"xeroContactId"}}`, encodeURIComponent(String(xeroContactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} xeroInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceAnalysisStateByXeroInvoiceId: async (xeroInvoiceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xeroInvoiceId' is not null or undefined
            if (xeroInvoiceId === null || xeroInvoiceId === undefined) {
                throw new RequiredError('xeroInvoiceId','Required parameter xeroInvoiceId was null or undefined when calling getInvoiceAnalysisStateByXeroInvoiceId.');
            }
            const localVarPath = `/analysis/invoices/{xeroInvoiceId}/state`
                .replace(`{${"xeroInvoiceId"}}`, encodeURIComponent(String(xeroInvoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentScanningActivity: async (limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/analysis/tenant/scanning-activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantAnalysisState: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/analysis/tenant/state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantAnalysisStats: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/analysis/tenant/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xeroTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueInitialAnalysis: async (xeroTenantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/analysis/dev-trigger-initial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xeroTenantId !== undefined) {
                localVarQueryParameter['xeroTenantId'] = xeroTenantId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalysisApi - functional programming interface
 * @export
 */
export const AnalysisApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} xeroBankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankTransactionAnalysisStateByXeroBankTransactionId(xeroBankTransactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankTransactionAnalysisState>> {
            const localVarAxiosArgs = await AnalysisApiAxiosParamCreator(configuration).getBankTransactionAnalysisStateByXeroBankTransactionId(xeroBankTransactionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactAnalysisById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactAnalysis>> {
            const localVarAxiosArgs = await AnalysisApiAxiosParamCreator(configuration).getContactAnalysisById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} xeroContactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactAnalysisStateByXeroContactId(xeroContactId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactAnalysisState>> {
            const localVarAxiosArgs = await AnalysisApiAxiosParamCreator(configuration).getContactAnalysisStateByXeroContactId(xeroContactId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} xeroInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoiceAnalysisStateByXeroInvoiceId(xeroInvoiceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceAnalysisState>> {
            const localVarAxiosArgs = await AnalysisApiAxiosParamCreator(configuration).getInvoiceAnalysisStateByXeroInvoiceId(xeroInvoiceId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecentScanningActivity(limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecentAnalysisQueryResult>>> {
            const localVarAxiosArgs = await AnalysisApiAxiosParamCreator(configuration).getRecentScanningActivity(limit, offset, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantAnalysisState(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantAnalysisState>> {
            const localVarAxiosArgs = await AnalysisApiAxiosParamCreator(configuration).getTenantAnalysisState(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantAnalysisStats(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantAnalysisStats>> {
            const localVarAxiosArgs = await AnalysisApiAxiosParamCreator(configuration).getTenantAnalysisStats(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [xeroTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queueInitialAnalysis(xeroTenantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AnalysisApiAxiosParamCreator(configuration).queueInitialAnalysis(xeroTenantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AnalysisApi - factory interface
 * @export
 */
export const AnalysisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} xeroBankTransactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankTransactionAnalysisStateByXeroBankTransactionId(xeroBankTransactionId: string, options?: any): AxiosPromise<BankTransactionAnalysisState> {
            return AnalysisApiFp(configuration).getBankTransactionAnalysisStateByXeroBankTransactionId(xeroBankTransactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactAnalysisById(id: string, options?: any): AxiosPromise<ContactAnalysis> {
            return AnalysisApiFp(configuration).getContactAnalysisById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xeroContactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactAnalysisStateByXeroContactId(xeroContactId: string, options?: any): AxiosPromise<ContactAnalysisState> {
            return AnalysisApiFp(configuration).getContactAnalysisStateByXeroContactId(xeroContactId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} xeroInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceAnalysisStateByXeroInvoiceId(xeroInvoiceId: string, options?: any): AxiosPromise<InvoiceAnalysisState> {
            return AnalysisApiFp(configuration).getInvoiceAnalysisStateByXeroInvoiceId(xeroInvoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentScanningActivity(limit?: number, offset?: number, options?: any): AxiosPromise<Array<RecentAnalysisQueryResult>> {
            return AnalysisApiFp(configuration).getRecentScanningActivity(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantAnalysisState(options?: any): AxiosPromise<TenantAnalysisState> {
            return AnalysisApiFp(configuration).getTenantAnalysisState(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantAnalysisStats(options?: any): AxiosPromise<TenantAnalysisStats> {
            return AnalysisApiFp(configuration).getTenantAnalysisStats(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xeroTenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueInitialAnalysis(xeroTenantId?: string, options?: any): AxiosPromise<void> {
            return AnalysisApiFp(configuration).queueInitialAnalysis(xeroTenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalysisApi - object-oriented interface
 * @export
 * @class AnalysisApi
 * @extends {BaseAPI}
 */
export class AnalysisApi extends BaseAPI {
    /**
     * 
     * @param {string} xeroBankTransactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    public getBankTransactionAnalysisStateByXeroBankTransactionId(xeroBankTransactionId: string, options?: any) {
        return AnalysisApiFp(this.configuration).getBankTransactionAnalysisStateByXeroBankTransactionId(xeroBankTransactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    public getContactAnalysisById(id: string, options?: any) {
        return AnalysisApiFp(this.configuration).getContactAnalysisById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xeroContactId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    public getContactAnalysisStateByXeroContactId(xeroContactId: string, options?: any) {
        return AnalysisApiFp(this.configuration).getContactAnalysisStateByXeroContactId(xeroContactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} xeroInvoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    public getInvoiceAnalysisStateByXeroInvoiceId(xeroInvoiceId: string, options?: any) {
        return AnalysisApiFp(this.configuration).getInvoiceAnalysisStateByXeroInvoiceId(xeroInvoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    public getRecentScanningActivity(limit?: number, offset?: number, options?: any) {
        return AnalysisApiFp(this.configuration).getRecentScanningActivity(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    public getTenantAnalysisState(options?: any) {
        return AnalysisApiFp(this.configuration).getTenantAnalysisState(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    public getTenantAnalysisStats(options?: any) {
        return AnalysisApiFp(this.configuration).getTenantAnalysisStats(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xeroTenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisApi
     */
    public queueInitialAnalysis(xeroTenantId?: string, options?: any) {
        return AnalysisApiFp(this.configuration).queueInitialAnalysis(xeroTenantId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientApi - axios parameter creator
 * @export
 */
export const ClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearActiveClient: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateClientUserInviteDto} createClientUserInviteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientUserInvite: async (id: string, createClientUserInviteDto: CreateClientUserInviteDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createClientUserInvite.');
            }
            // verify required parameter 'createClientUserInviteDto' is not null or undefined
            if (createClientUserInviteDto === null || createClientUserInviteDto === undefined) {
                throw new RequiredError('createClientUserInviteDto','Required parameter createClientUserInviteDto was null or undefined when calling createClientUserInvite.');
            }
            const localVarPath = `/client/{id}/user-invites`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createClientUserInviteDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createClientUserInviteDto !== undefined ? createClientUserInviteDto : {})
                : (createClientUserInviteDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findClientById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findClientById.');
            }
            const localVarPath = `/client/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveClient: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientUserInvites: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getClientUserInvites.');
            }
            const localVarPath = `/client/{id}/user-invites`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientUsers: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getClientUsers.');
            }
            const localVarPath = `/client/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClients: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchClientDto} patchClientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchClient: async (id: string, patchClientDto: PatchClientDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling patchClient.');
            }
            // verify required parameter 'patchClientDto' is not null or undefined
            if (patchClientDto === null || patchClientDto === undefined) {
                throw new RequiredError('patchClientDto','Required parameter patchClientDto was null or undefined when calling patchClient.');
            }
            const localVarPath = `/client/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof patchClientDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(patchClientDto !== undefined ? patchClientDto : {})
                : (patchClientDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetActiveClientDto} setActiveClientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setActiveClient: async (setActiveClientDto: SetActiveClientDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'setActiveClientDto' is not null or undefined
            if (setActiveClientDto === null || setActiveClientDto === undefined) {
                throw new RequiredError('setActiveClientDto','Required parameter setActiveClientDto was null or undefined when calling setActiveClient.');
            }
            const localVarPath = `/client/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof setActiveClientDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(setActiveClientDto !== undefined ? setActiveClientDto : {})
                : (setActiveClientDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<SetPracticeUserAccessDto>} setPracticeUserAccessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setClientPracticeUserAccess: async (id: string, setPracticeUserAccessDto: Array<SetPracticeUserAccessDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling setClientPracticeUserAccess.');
            }
            // verify required parameter 'setPracticeUserAccessDto' is not null or undefined
            if (setPracticeUserAccessDto === null || setPracticeUserAccessDto === undefined) {
                throw new RequiredError('setPracticeUserAccessDto','Required parameter setPracticeUserAccessDto was null or undefined when calling setClientPracticeUserAccess.');
            }
            const localVarPath = `/client/{id}/practice-user-access`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof setPracticeUserAccessDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(setPracticeUserAccessDto !== undefined ? setPracticeUserAccessDto : {})
                : (setPracticeUserAccessDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientApi - functional programming interface
 * @export
 */
export const ClientApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearActiveClient(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ClientApiAxiosParamCreator(configuration).clearActiveClient(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateClientUserInviteDto} createClientUserInviteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClientUserInvite(id: string, createClientUserInviteDto: CreateClientUserInviteDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientUserInvite>> {
            const localVarAxiosArgs = await ClientApiAxiosParamCreator(configuration).createClientUserInvite(id, createClientUserInviteDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findClientById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>> {
            const localVarAxiosArgs = await ClientApiAxiosParamCreator(configuration).findClientById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveClient(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>> {
            const localVarAxiosArgs = await ClientApiAxiosParamCreator(configuration).getActiveClient(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientUserInvites(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientUserInvite>>> {
            const localVarAxiosArgs = await ClientApiAxiosParamCreator(configuration).getClientUserInvites(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientUsers(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await ClientApiAxiosParamCreator(configuration).getClientUsers(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClients(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Client>>> {
            const localVarAxiosArgs = await ClientApiAxiosParamCreator(configuration).getClients(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchClientDto} patchClientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchClient(id: string, patchClientDto: PatchClientDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>> {
            const localVarAxiosArgs = await ClientApiAxiosParamCreator(configuration).patchClient(id, patchClientDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SetActiveClientDto} setActiveClientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setActiveClient(setActiveClientDto: SetActiveClientDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>> {
            const localVarAxiosArgs = await ClientApiAxiosParamCreator(configuration).setActiveClient(setActiveClientDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<SetPracticeUserAccessDto>} setPracticeUserAccessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setClientPracticeUserAccess(id: string, setPracticeUserAccessDto: Array<SetPracticeUserAccessDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>> {
            const localVarAxiosArgs = await ClientApiAxiosParamCreator(configuration).setClientPracticeUserAccess(id, setPracticeUserAccessDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClientApi - factory interface
 * @export
 */
export const ClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearActiveClient(options?: any): AxiosPromise<void> {
            return ClientApiFp(configuration).clearActiveClient(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateClientUserInviteDto} createClientUserInviteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientUserInvite(id: string, createClientUserInviteDto: CreateClientUserInviteDto, options?: any): AxiosPromise<ClientUserInvite> {
            return ClientApiFp(configuration).createClientUserInvite(id, createClientUserInviteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findClientById(id: string, options?: any): AxiosPromise<Client> {
            return ClientApiFp(configuration).findClientById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveClient(options?: any): AxiosPromise<Client> {
            return ClientApiFp(configuration).getActiveClient(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientUserInvites(id: string, options?: any): AxiosPromise<Array<ClientUserInvite>> {
            return ClientApiFp(configuration).getClientUserInvites(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientUsers(id: string, options?: any): AxiosPromise<Array<User>> {
            return ClientApiFp(configuration).getClientUsers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClients(options?: any): AxiosPromise<Array<Client>> {
            return ClientApiFp(configuration).getClients(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchClientDto} patchClientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchClient(id: string, patchClientDto: PatchClientDto, options?: any): AxiosPromise<Client> {
            return ClientApiFp(configuration).patchClient(id, patchClientDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetActiveClientDto} setActiveClientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setActiveClient(setActiveClientDto: SetActiveClientDto, options?: any): AxiosPromise<Client> {
            return ClientApiFp(configuration).setActiveClient(setActiveClientDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<SetPracticeUserAccessDto>} setPracticeUserAccessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setClientPracticeUserAccess(id: string, setPracticeUserAccessDto: Array<SetPracticeUserAccessDto>, options?: any): AxiosPromise<Client> {
            return ClientApiFp(configuration).setClientPracticeUserAccess(id, setPracticeUserAccessDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientApi - object-oriented interface
 * @export
 * @class ClientApi
 * @extends {BaseAPI}
 */
export class ClientApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public clearActiveClient(options?: any) {
        return ClientApiFp(this.configuration).clearActiveClient(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CreateClientUserInviteDto} createClientUserInviteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public createClientUserInvite(id: string, createClientUserInviteDto: CreateClientUserInviteDto, options?: any) {
        return ClientApiFp(this.configuration).createClientUserInvite(id, createClientUserInviteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public findClientById(id: string, options?: any) {
        return ClientApiFp(this.configuration).findClientById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getActiveClient(options?: any) {
        return ClientApiFp(this.configuration).getActiveClient(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getClientUserInvites(id: string, options?: any) {
        return ClientApiFp(this.configuration).getClientUserInvites(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getClientUsers(id: string, options?: any) {
        return ClientApiFp(this.configuration).getClientUsers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getClients(options?: any) {
        return ClientApiFp(this.configuration).getClients(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PatchClientDto} patchClientDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public patchClient(id: string, patchClientDto: PatchClientDto, options?: any) {
        return ClientApiFp(this.configuration).patchClient(id, patchClientDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetActiveClientDto} setActiveClientDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public setActiveClient(setActiveClientDto: SetActiveClientDto, options?: any) {
        return ClientApiFp(this.configuration).setActiveClient(setActiveClientDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Array<SetPracticeUserAccessDto>} setPracticeUserAccessDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public setClientPracticeUserAccess(id: string, setPracticeUserAccessDto: Array<SetPracticeUserAccessDto>, options?: any) {
        return ClientApiFp(this.configuration).setClientPracticeUserAccess(id, setPracticeUserAccessDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceMetadata: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceMetadata(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getServiceMetadata(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceMetadata(options?: any): AxiosPromise<object> {
            return DefaultApiFp(configuration).getServiceMetadata(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getServiceMetadata(options?: any) {
        return DefaultApiFp(this.configuration).getServiceMetadata(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FraudOutreachApi - axios parameter creator
 * @export
 */
export const FraudOutreachApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientFraudOutreachSetting: async (clientId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling getClientFraudOutreachSetting.');
            }
            const localVarPath = `/fraud-outreach/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {UpsertClientFraudOutreachSettingDto} upsertClientFraudOutreachSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertClientFraudOutreachSetting: async (clientId: string, upsertClientFraudOutreachSettingDto: UpsertClientFraudOutreachSettingDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling upsertClientFraudOutreachSetting.');
            }
            // verify required parameter 'upsertClientFraudOutreachSettingDto' is not null or undefined
            if (upsertClientFraudOutreachSettingDto === null || upsertClientFraudOutreachSettingDto === undefined) {
                throw new RequiredError('upsertClientFraudOutreachSettingDto','Required parameter upsertClientFraudOutreachSettingDto was null or undefined when calling upsertClientFraudOutreachSetting.');
            }
            const localVarPath = `/fraud-outreach/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof upsertClientFraudOutreachSettingDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(upsertClientFraudOutreachSettingDto !== undefined ? upsertClientFraudOutreachSettingDto : {})
                : (upsertClientFraudOutreachSettingDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FraudOutreachApi - functional programming interface
 * @export
 */
export const FraudOutreachApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientFraudOutreachSetting(clientId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientFraudOutreachSetting>> {
            const localVarAxiosArgs = await FraudOutreachApiAxiosParamCreator(configuration).getClientFraudOutreachSetting(clientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {UpsertClientFraudOutreachSettingDto} upsertClientFraudOutreachSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertClientFraudOutreachSetting(clientId: string, upsertClientFraudOutreachSettingDto: UpsertClientFraudOutreachSettingDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientFraudOutreachSetting>> {
            const localVarAxiosArgs = await FraudOutreachApiAxiosParamCreator(configuration).upsertClientFraudOutreachSetting(clientId, upsertClientFraudOutreachSettingDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FraudOutreachApi - factory interface
 * @export
 */
export const FraudOutreachApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientFraudOutreachSetting(clientId: string, options?: any): AxiosPromise<ClientFraudOutreachSetting> {
            return FraudOutreachApiFp(configuration).getClientFraudOutreachSetting(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {UpsertClientFraudOutreachSettingDto} upsertClientFraudOutreachSettingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertClientFraudOutreachSetting(clientId: string, upsertClientFraudOutreachSettingDto: UpsertClientFraudOutreachSettingDto, options?: any): AxiosPromise<ClientFraudOutreachSetting> {
            return FraudOutreachApiFp(configuration).upsertClientFraudOutreachSetting(clientId, upsertClientFraudOutreachSettingDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FraudOutreachApi - object-oriented interface
 * @export
 * @class FraudOutreachApi
 * @extends {BaseAPI}
 */
export class FraudOutreachApi extends BaseAPI {
    /**
     * 
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudOutreachApi
     */
    public getClientFraudOutreachSetting(clientId: string, options?: any) {
        return FraudOutreachApiFp(this.configuration).getClientFraudOutreachSetting(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {UpsertClientFraudOutreachSettingDto} upsertClientFraudOutreachSettingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FraudOutreachApi
     */
    public upsertClientFraudOutreachSetting(clientId: string, upsertClientFraudOutreachSettingDto: UpsertClientFraudOutreachSettingDto, options?: any) {
        return FraudOutreachApiFp(this.configuration).upsertClientFraudOutreachSetting(clientId, upsertClientFraudOutreachSettingDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessageApi - axios parameter creator
 * @export
 */
export const MessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages: async (limit?: number, offset?: number, clientId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMessageRead: async (messageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            if (messageId === null || messageId === undefined) {
                throw new RequiredError('messageId','Required parameter messageId was null or undefined when calling setMessageRead.');
            }
            const localVarPath = `/messages/{messageId}/read`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageApi - functional programming interface
 * @export
 */
export const MessageApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessages(limit?: number, offset?: number, clientId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedMessage>> {
            const localVarAxiosArgs = await MessageApiAxiosParamCreator(configuration).getMessages(limit, offset, clientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMessageRead(messageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await MessageApiAxiosParamCreator(configuration).setMessageRead(messageId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MessageApi - factory interface
 * @export
 */
export const MessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages(limit?: number, offset?: number, clientId?: string, options?: any): AxiosPromise<PaginatedMessage> {
            return MessageApiFp(configuration).getMessages(limit, offset, clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMessageRead(messageId: string, options?: any): AxiosPromise<Message> {
            return MessageApiFp(configuration).setMessageRead(messageId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageApi - object-oriented interface
 * @export
 * @class MessageApi
 * @extends {BaseAPI}
 */
export class MessageApi extends BaseAPI {
    /**
     * 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public getMessages(limit?: number, offset?: number, clientId?: string, options?: any) {
        return MessageApiFp(this.configuration).getMessages(limit, offset, clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public setMessageRead(messageId: string, options?: any) {
        return MessageApiFp(this.configuration).setMessageRead(messageId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PracticeApi - axios parameter creator
 * @export
 */
export const PracticeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} practiceId 
         * @param {string} inviteId 
         * @param {AcceptPracticeUserInviteDto} acceptPracticeUserInviteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptPracticeUserInvite: async (practiceId: string, inviteId: string, acceptPracticeUserInviteDto: AcceptPracticeUserInviteDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling acceptPracticeUserInvite.');
            }
            // verify required parameter 'inviteId' is not null or undefined
            if (inviteId === null || inviteId === undefined) {
                throw new RequiredError('inviteId','Required parameter inviteId was null or undefined when calling acceptPracticeUserInvite.');
            }
            // verify required parameter 'acceptPracticeUserInviteDto' is not null or undefined
            if (acceptPracticeUserInviteDto === null || acceptPracticeUserInviteDto === undefined) {
                throw new RequiredError('acceptPracticeUserInviteDto','Required parameter acceptPracticeUserInviteDto was null or undefined when calling acceptPracticeUserInvite.');
            }
            const localVarPath = `/practices/{practiceId}/user-invites/{inviteId}/acceptance`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)))
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof acceptPracticeUserInviteDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(acceptPracticeUserInviteDto !== undefined ? acceptPracticeUserInviteDto : {})
                : (acceptPracticeUserInviteDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreatePracticeClientDto} createPracticeClientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPracticeClient: async (id: string, createPracticeClientDto: CreatePracticeClientDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createPracticeClient.');
            }
            // verify required parameter 'createPracticeClientDto' is not null or undefined
            if (createPracticeClientDto === null || createPracticeClientDto === undefined) {
                throw new RequiredError('createPracticeClientDto','Required parameter createPracticeClientDto was null or undefined when calling createPracticeClient.');
            }
            const localVarPath = `/practices/{id}/clients`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createPracticeClientDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createPracticeClientDto !== undefined ? createPracticeClientDto : {})
                : (createPracticeClientDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreatePracticeUserInviteDto} createPracticeUserInviteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPracticeUserInvite: async (id: string, createPracticeUserInviteDto: CreatePracticeUserInviteDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling createPracticeUserInvite.');
            }
            // verify required parameter 'createPracticeUserInviteDto' is not null or undefined
            if (createPracticeUserInviteDto === null || createPracticeUserInviteDto === undefined) {
                throw new RequiredError('createPracticeUserInviteDto','Required parameter createPracticeUserInviteDto was null or undefined when calling createPracticeUserInvite.');
            }
            const localVarPath = `/practices/{id}/user-invites`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof createPracticeUserInviteDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(createPracticeUserInviteDto !== undefined ? createPracticeUserInviteDto : {})
                : (createPracticeUserInviteDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingPracticeUserInvite: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/practices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPracticeById.');
            }
            const localVarPath = `/practices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {ClientSortKey} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeClients: async (id: string, limit?: number, offset?: number, sortBy?: ClientSortKey, sortDirection?: SortDirection, search?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPracticeClients.');
            }
            const localVarPath = `/practices/{id}/clients`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} [pendingOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeUserInvites: async (id: string, pendingOnly?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPracticeUserInvites.');
            }
            const localVarPath = `/practices/{id}/user-invites`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (pendingOnly !== undefined) {
                localVarQueryParameter['pendingOnly'] = pendingOnly;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeUsers: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPracticeUsers.');
            }
            const localVarPath = `/practices/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterPracticeDto} registerPracticeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (registerPracticeDto: RegisterPracticeDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerPracticeDto' is not null or undefined
            if (registerPracticeDto === null || registerPracticeDto === undefined) {
                throw new RequiredError('registerPracticeDto','Required parameter registerPracticeDto was null or undefined when calling register.');
            }
            const localVarPath = `/practices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof registerPracticeDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(registerPracticeDto !== undefined ? registerPracticeDto : {})
                : (registerPracticeDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendPracticeUserInvite: async (id: string, inviteId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling resendPracticeUserInvite.');
            }
            // verify required parameter 'inviteId' is not null or undefined
            if (inviteId === null || inviteId === undefined) {
                throw new RequiredError('inviteId','Required parameter inviteId was null or undefined when calling resendPracticeUserInvite.');
            }
            const localVarPath = `/practices/{id}/user-invites/{inviteId}/resend`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"inviteId"}}`, encodeURIComponent(String(inviteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PracticeApi - functional programming interface
 * @export
 */
export const PracticeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} practiceId 
         * @param {string} inviteId 
         * @param {AcceptPracticeUserInviteDto} acceptPracticeUserInviteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptPracticeUserInvite(practiceId: string, inviteId: string, acceptPracticeUserInviteDto: AcceptPracticeUserInviteDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await PracticeApiAxiosParamCreator(configuration).acceptPracticeUserInvite(practiceId, inviteId, acceptPracticeUserInviteDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreatePracticeClientDto} createPracticeClientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPracticeClient(id: string, createPracticeClientDto: CreatePracticeClientDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Client>> {
            const localVarAxiosArgs = await PracticeApiAxiosParamCreator(configuration).createPracticeClient(id, createPracticeClientDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreatePracticeUserInviteDto} createPracticeUserInviteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPracticeUserInvite(id: string, createPracticeUserInviteDto: CreatePracticeUserInviteDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeUserInvite>> {
            const localVarAxiosArgs = await PracticeApiAxiosParamCreator(configuration).createPracticeUserInvite(id, createPracticeUserInviteDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingPracticeUserInvite(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeUserInvite>> {
            const localVarAxiosArgs = await PracticeApiAxiosParamCreator(configuration).getPendingPracticeUserInvite(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPracticeById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Practice>> {
            const localVarAxiosArgs = await PracticeApiAxiosParamCreator(configuration).getPracticeById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {ClientSortKey} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPracticeClients(id: string, limit?: number, offset?: number, sortBy?: ClientSortKey, sortDirection?: SortDirection, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedClient>> {
            const localVarAxiosArgs = await PracticeApiAxiosParamCreator(configuration).getPracticeClients(id, limit, offset, sortBy, sortDirection, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} [pendingOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPracticeUserInvites(id: string, pendingOnly?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PracticeUserInvite>>> {
            const localVarAxiosArgs = await PracticeApiAxiosParamCreator(configuration).getPracticeUserInvites(id, pendingOnly, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPracticeUsers(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await PracticeApiAxiosParamCreator(configuration).getPracticeUsers(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RegisterPracticeDto} registerPracticeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(registerPracticeDto: RegisterPracticeDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PracticeApiAxiosParamCreator(configuration).register(registerPracticeDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendPracticeUserInvite(id: string, inviteId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeUserInvite>> {
            const localVarAxiosArgs = await PracticeApiAxiosParamCreator(configuration).resendPracticeUserInvite(id, inviteId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PracticeApi - factory interface
 * @export
 */
export const PracticeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} practiceId 
         * @param {string} inviteId 
         * @param {AcceptPracticeUserInviteDto} acceptPracticeUserInviteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptPracticeUserInvite(practiceId: string, inviteId: string, acceptPracticeUserInviteDto: AcceptPracticeUserInviteDto, options?: any): AxiosPromise<User> {
            return PracticeApiFp(configuration).acceptPracticeUserInvite(practiceId, inviteId, acceptPracticeUserInviteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CreatePracticeClientDto} createPracticeClientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPracticeClient(id: string, createPracticeClientDto: CreatePracticeClientDto, options?: any): AxiosPromise<Client> {
            return PracticeApiFp(configuration).createPracticeClient(id, createPracticeClientDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {CreatePracticeUserInviteDto} createPracticeUserInviteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPracticeUserInvite(id: string, createPracticeUserInviteDto: CreatePracticeUserInviteDto, options?: any): AxiosPromise<PracticeUserInvite> {
            return PracticeApiFp(configuration).createPracticeUserInvite(id, createPracticeUserInviteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingPracticeUserInvite(options?: any): AxiosPromise<PracticeUserInvite> {
            return PracticeApiFp(configuration).getPendingPracticeUserInvite(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeById(id: string, options?: any): AxiosPromise<Practice> {
            return PracticeApiFp(configuration).getPracticeById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {ClientSortKey} [sortBy] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeClients(id: string, limit?: number, offset?: number, sortBy?: ClientSortKey, sortDirection?: SortDirection, search?: string, options?: any): AxiosPromise<PaginatedClient> {
            return PracticeApiFp(configuration).getPracticeClients(id, limit, offset, sortBy, sortDirection, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} [pendingOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeUserInvites(id: string, pendingOnly?: boolean, options?: any): AxiosPromise<Array<PracticeUserInvite>> {
            return PracticeApiFp(configuration).getPracticeUserInvites(id, pendingOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeUsers(id: string, options?: any): AxiosPromise<Array<User>> {
            return PracticeApiFp(configuration).getPracticeUsers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterPracticeDto} registerPracticeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(registerPracticeDto: RegisterPracticeDto, options?: any): AxiosPromise<void> {
            return PracticeApiFp(configuration).register(registerPracticeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} inviteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendPracticeUserInvite(id: string, inviteId: string, options?: any): AxiosPromise<PracticeUserInvite> {
            return PracticeApiFp(configuration).resendPracticeUserInvite(id, inviteId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PracticeApi - object-oriented interface
 * @export
 * @class PracticeApi
 * @extends {BaseAPI}
 */
export class PracticeApi extends BaseAPI {
    /**
     * 
     * @param {string} practiceId 
     * @param {string} inviteId 
     * @param {AcceptPracticeUserInviteDto} acceptPracticeUserInviteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public acceptPracticeUserInvite(practiceId: string, inviteId: string, acceptPracticeUserInviteDto: AcceptPracticeUserInviteDto, options?: any) {
        return PracticeApiFp(this.configuration).acceptPracticeUserInvite(practiceId, inviteId, acceptPracticeUserInviteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CreatePracticeClientDto} createPracticeClientDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public createPracticeClient(id: string, createPracticeClientDto: CreatePracticeClientDto, options?: any) {
        return PracticeApiFp(this.configuration).createPracticeClient(id, createPracticeClientDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {CreatePracticeUserInviteDto} createPracticeUserInviteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public createPracticeUserInvite(id: string, createPracticeUserInviteDto: CreatePracticeUserInviteDto, options?: any) {
        return PracticeApiFp(this.configuration).createPracticeUserInvite(id, createPracticeUserInviteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public getPendingPracticeUserInvite(options?: any) {
        return PracticeApiFp(this.configuration).getPendingPracticeUserInvite(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public getPracticeById(id: string, options?: any) {
        return PracticeApiFp(this.configuration).getPracticeById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {ClientSortKey} [sortBy] 
     * @param {SortDirection} [sortDirection] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public getPracticeClients(id: string, limit?: number, offset?: number, sortBy?: ClientSortKey, sortDirection?: SortDirection, search?: string, options?: any) {
        return PracticeApiFp(this.configuration).getPracticeClients(id, limit, offset, sortBy, sortDirection, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {boolean} [pendingOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public getPracticeUserInvites(id: string, pendingOnly?: boolean, options?: any) {
        return PracticeApiFp(this.configuration).getPracticeUserInvites(id, pendingOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public getPracticeUsers(id: string, options?: any) {
        return PracticeApiFp(this.configuration).getPracticeUsers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterPracticeDto} registerPracticeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public register(registerPracticeDto: RegisterPracticeDto, options?: any) {
        return PracticeApiFp(this.configuration).register(registerPracticeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} inviteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeApi
     */
    public resendPracticeUserInvite(id: string, inviteId: string, options?: any) {
        return PracticeApiFp(this.configuration).resendPracticeUserInvite(id, inviteId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingSubscription: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscription/pending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresentSubscription: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingSubscription(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).getPendingSubscription(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPresentSubscription(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
            const localVarAxiosArgs = await SubscriptionApiAxiosParamCreator(configuration).getPresentSubscription(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingSubscription(options?: any): AxiosPromise<Subscription> {
            return SubscriptionApiFp(configuration).getPendingSubscription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresentSubscription(options?: any): AxiosPromise<Subscription> {
            return SubscriptionApiFp(configuration).getPresentSubscription(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getPendingSubscription(options?: any) {
        return SubscriptionApiFp(this.configuration).getPendingSubscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public getPresentSubscription(options?: any) {
        return SubscriptionApiFp(this.configuration).getPresentSubscription(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getCurrentUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).logout(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<User> {
            return UserApiFp(configuration).getCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).logout(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getCurrentUser(options?: any) {
        return UserApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public logout(options?: any) {
        return UserApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * XeroApi - axios parameter creator
 * @export
 */
export const XeroApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectXero: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/disconnect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectXeroByTenantId: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling disconnectXeroByTenantId.');
            }
            const localVarPath = `/xero/tenants/{id}/disconnect`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankTransaction: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBankTransaction.');
            }
            const localVarPath = `/xero/bank-transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bankTransactionId 
         * @param {string} fileName 
         * @param {string} contentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankTransactionAttachment: async (bankTransactionId: string, fileName: string, contentType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bankTransactionId' is not null or undefined
            if (bankTransactionId === null || bankTransactionId === undefined) {
                throw new RequiredError('bankTransactionId','Required parameter bankTransactionId was null or undefined when calling getBankTransactionAttachment.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling getBankTransactionAttachment.');
            }
            // verify required parameter 'contentType' is not null or undefined
            if (contentType === null || contentType === undefined) {
                throw new RequiredError('contentType','Required parameter contentType was null or undefined when calling getBankTransactionAttachment.');
            }
            const localVarPath = `/xero/bank-transactions/{bankTransactionId}/attachments/{fileName}`
                .replace(`{${"bankTransactionId"}}`, encodeURIComponent(String(bankTransactionId)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contentType !== undefined) {
                localVarQueryParameter['contentType'] = contentType;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankTransactionAttachments: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBankTransactionAttachments.');
            }
            const localVarPath = `/xero/bank-transactions/{id}/attachments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankTransactionHistory: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBankTransactionHistory.');
            }
            const localVarPath = `/xero/bank-transactions/{id}/history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankTransactions: async (page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/bank-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConsentUrl: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/consent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientTenant: async (clientId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/client-tenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientXeroTenantConnection: async (clientId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling getClientXeroTenantConnection.');
            }
            const localVarPath = `/xero/client-tenant-connection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContact: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getContact.');
            }
            const localVarPath = `/xero/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isSupplier] 
         * @param {boolean} [isCustomer] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts: async (isSupplier?: boolean, isCustomer?: boolean, page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isSupplier !== undefined) {
                localVarQueryParameter['isSupplier'] = isSupplier;
            }

            if (isCustomer !== undefined) {
                localVarQueryParameter['isCustomer'] = isCustomer;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoice: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInvoice.');
            }
            const localVarPath = `/xero/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isBill] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoices: async (isBill?: boolean, page?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isBill !== undefined) {
                localVarQueryParameter['isBill'] = isBill;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayment: async (paymentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            if (paymentId === null || paymentId === undefined) {
                throw new RequiredError('paymentId','Required parameter paymentId was null or undefined when calling getPayment.');
            }
            const localVarPath = `/xero/payments/{paymentId}`
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentHistory: async (paymentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            if (paymentId === null || paymentId === undefined) {
                throw new RequiredError('paymentId','Required parameter paymentId was null or undefined when calling getPaymentHistory.');
            }
            const localVarPath = `/xero/payments/{paymentId}/history`
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayments: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeClientConsentUrl: async (practiceId: string, clientId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'practiceId' is not null or undefined
            if (practiceId === null || practiceId === undefined) {
                throw new RequiredError('practiceId','Required parameter practiceId was null or undefined when calling getPracticeClientConsentUrl.');
            }
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling getPracticeClientConsentUrl.');
            }
            const localVarPath = `/xero/consent/practice/{practiceId}/client/{clientId}`
                .replace(`{${"practiceId"}}`, encodeURIComponent(String(practiceId)))
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxRates: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/tax-rates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * for the practice hub when not in a tenant / client context like there would be when accessing the client UI
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantUsers: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getTenantUsers.');
            }
            const localVarPath = `/xero/tenants/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenants: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/tenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserXeroTenantConnections: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/tenant-connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXeroSubscriptionById: async (subscriptionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            if (subscriptionId === null || subscriptionId === undefined) {
                throw new RequiredError('subscriptionId','Required parameter subscriptionId was null or undefined when calling getXeroSubscriptionById.');
            }
            const localVarPath = `/xero/subscription/{subscriptionId}`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXeroTenantConnectionMeyseyUsers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/tenant-connections/active/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleDirectClientRedirect: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/redirect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handlePracticeClientRedirect: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/xero/redirect/practice-client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyXeroAttachment: async (url: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            if (url === null || url === undefined) {
                throw new RequiredError('url','Required parameter url was null or undefined when calling proxyXeroAttachment.');
            }
            const localVarPath = `/xero/attachment-proxy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SetXeroTenantConnectionClientDto} setXeroTenantConnectionClientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setXeroTenantConnectionClient: async (id: string, setXeroTenantConnectionClientDto: SetXeroTenantConnectionClientDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling setXeroTenantConnectionClient.');
            }
            // verify required parameter 'setXeroTenantConnectionClientDto' is not null or undefined
            if (setXeroTenantConnectionClientDto === null || setXeroTenantConnectionClientDto === undefined) {
                throw new RequiredError('setXeroTenantConnectionClientDto','Required parameter setXeroTenantConnectionClientDto was null or undefined when calling setXeroTenantConnectionClient.');
            }
            const localVarPath = `/xero/tenant-connections/{id}/client`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof setXeroTenantConnectionClientDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(setXeroTenantConnectionClientDto !== undefined ? setXeroTenantConnectionClientDto : {})
                : (setXeroTenantConnectionClientDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XeroApi - functional programming interface
 * @export
 */
export const XeroApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disconnectXero(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XeroTenantConnection>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).disconnectXero(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disconnectXeroByTenantId(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XeroTenantConnection>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).disconnectXeroByTenantId(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccounts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Account>>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getAccounts(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankTransaction(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankTransaction>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getBankTransaction(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} bankTransactionId 
         * @param {string} fileName 
         * @param {string} contentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankTransactionAttachment(bankTransactionId: string, fileName: string, contentType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getBankTransactionAttachment(bankTransactionId, fileName, contentType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankTransactionAttachments(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getBankTransactionAttachments(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankTransactionHistory(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HistoryRecord>>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getBankTransactionHistory(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankTransactions(page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BankTransaction>>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getBankTransactions(page, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientConsentUrl(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getClientConsentUrl(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientTenant(clientId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XeroApiTenant>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getClientTenant(clientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientXeroTenantConnection(clientId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XeroTenantConnection>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getClientXeroTenantConnection(clientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContact(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getContact(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} [isSupplier] 
         * @param {boolean} [isCustomer] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContacts(isSupplier?: boolean, isCustomer?: boolean, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Contact>>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getContacts(isSupplier, isCustomer, page, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoice(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invoice>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getInvoice(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} [isBill] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoices(isBill?: boolean, page?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Invoice>>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getInvoices(isBill, page, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayment(paymentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payment>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getPayment(paymentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentHistory(paymentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HistoryRecord>>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getPaymentHistory(paymentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayments(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Payment>>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getPayments(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPracticeClientConsentUrl(practiceId: string, clientId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getPracticeClientConsentUrl(practiceId, clientId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaxRates(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaxRate>>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getTaxRates(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * for the practice hub when not in a tenant / client context like there would be when accessing the client UI
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantUsers(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<XeroUser>>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getTenantUsers(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenants(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<XeroApiTenant>>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getTenants(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserXeroTenantConnections(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<XeroTenantConnection>>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getUserXeroTenantConnections(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<XeroUser>>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getUsers(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getXeroSubscriptionById(subscriptionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<XeroSubscription>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getXeroSubscriptionById(subscriptionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getXeroTenantConnectionMeyseyUsers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).getXeroTenantConnectionMeyseyUsers(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleDirectClientRedirect(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).handleDirectClientRedirect(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handlePracticeClientRedirect(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).handlePracticeClientRedirect(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proxyXeroAttachment(url: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).proxyXeroAttachment(url, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SetXeroTenantConnectionClientDto} setXeroTenantConnectionClientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setXeroTenantConnectionClient(id: string, setXeroTenantConnectionClientDto: SetXeroTenantConnectionClientDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await XeroApiAxiosParamCreator(configuration).setXeroTenantConnectionClient(id, setXeroTenantConnectionClientDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * XeroApi - factory interface
 * @export
 */
export const XeroApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectXero(options?: any): AxiosPromise<XeroTenantConnection> {
            return XeroApiFp(configuration).disconnectXero(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectXeroByTenantId(id: string, options?: any): AxiosPromise<XeroTenantConnection> {
            return XeroApiFp(configuration).disconnectXeroByTenantId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts(options?: any): AxiosPromise<Array<Account>> {
            return XeroApiFp(configuration).getAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankTransaction(id: string, options?: any): AxiosPromise<BankTransaction> {
            return XeroApiFp(configuration).getBankTransaction(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bankTransactionId 
         * @param {string} fileName 
         * @param {string} contentType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankTransactionAttachment(bankTransactionId: string, fileName: string, contentType: string, options?: any): AxiosPromise<void> {
            return XeroApiFp(configuration).getBankTransactionAttachment(bankTransactionId, fileName, contentType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankTransactionAttachments(id: string, options?: any): AxiosPromise<void> {
            return XeroApiFp(configuration).getBankTransactionAttachments(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankTransactionHistory(id: string, options?: any): AxiosPromise<Array<HistoryRecord>> {
            return XeroApiFp(configuration).getBankTransactionHistory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankTransactions(page?: number, options?: any): AxiosPromise<Array<BankTransaction>> {
            return XeroApiFp(configuration).getBankTransactions(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientConsentUrl(options?: any): AxiosPromise<void> {
            return XeroApiFp(configuration).getClientConsentUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [clientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientTenant(clientId?: string, options?: any): AxiosPromise<XeroApiTenant> {
            return XeroApiFp(configuration).getClientTenant(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientXeroTenantConnection(clientId: string, options?: any): AxiosPromise<XeroTenantConnection> {
            return XeroApiFp(configuration).getClientXeroTenantConnection(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContact(id: string, options?: any): AxiosPromise<Contact> {
            return XeroApiFp(configuration).getContact(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isSupplier] 
         * @param {boolean} [isCustomer] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts(isSupplier?: boolean, isCustomer?: boolean, page?: number, options?: any): AxiosPromise<Array<Contact>> {
            return XeroApiFp(configuration).getContacts(isSupplier, isCustomer, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoice(id: string, options?: any): AxiosPromise<Invoice> {
            return XeroApiFp(configuration).getInvoice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isBill] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoices(isBill?: boolean, page?: number, options?: any): AxiosPromise<Array<Invoice>> {
            return XeroApiFp(configuration).getInvoices(isBill, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayment(paymentId: string, options?: any): AxiosPromise<Payment> {
            return XeroApiFp(configuration).getPayment(paymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} paymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentHistory(paymentId: string, options?: any): AxiosPromise<Array<HistoryRecord>> {
            return XeroApiFp(configuration).getPaymentHistory(paymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayments(options?: any): AxiosPromise<Array<Payment>> {
            return XeroApiFp(configuration).getPayments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} practiceId 
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeClientConsentUrl(practiceId: string, clientId: string, options?: any): AxiosPromise<void> {
            return XeroApiFp(configuration).getPracticeClientConsentUrl(practiceId, clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxRates(options?: any): AxiosPromise<Array<TaxRate>> {
            return XeroApiFp(configuration).getTaxRates(options).then((request) => request(axios, basePath));
        },
        /**
         * for the practice hub when not in a tenant / client context like there would be when accessing the client UI
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantUsers(id: string, options?: any): AxiosPromise<Array<XeroUser>> {
            return XeroApiFp(configuration).getTenantUsers(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenants(options?: any): AxiosPromise<Array<XeroApiTenant>> {
            return XeroApiFp(configuration).getTenants(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserXeroTenantConnections(options?: any): AxiosPromise<Array<XeroTenantConnection>> {
            return XeroApiFp(configuration).getUserXeroTenantConnections(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<Array<XeroUser>> {
            return XeroApiFp(configuration).getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXeroSubscriptionById(subscriptionId: string, options?: any): AxiosPromise<XeroSubscription> {
            return XeroApiFp(configuration).getXeroSubscriptionById(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXeroTenantConnectionMeyseyUsers(options?: any): AxiosPromise<Array<User>> {
            return XeroApiFp(configuration).getXeroTenantConnectionMeyseyUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleDirectClientRedirect(options?: any): AxiosPromise<void> {
            return XeroApiFp(configuration).handleDirectClientRedirect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handlePracticeClientRedirect(options?: any): AxiosPromise<void> {
            return XeroApiFp(configuration).handlePracticeClientRedirect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proxyXeroAttachment(url: string, options?: any): AxiosPromise<void> {
            return XeroApiFp(configuration).proxyXeroAttachment(url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {SetXeroTenantConnectionClientDto} setXeroTenantConnectionClientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setXeroTenantConnectionClient(id: string, setXeroTenantConnectionClientDto: SetXeroTenantConnectionClientDto, options?: any): AxiosPromise<void> {
            return XeroApiFp(configuration).setXeroTenantConnectionClient(id, setXeroTenantConnectionClientDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XeroApi - object-oriented interface
 * @export
 * @class XeroApi
 * @extends {BaseAPI}
 */
export class XeroApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public disconnectXero(options?: any) {
        return XeroApiFp(this.configuration).disconnectXero(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public disconnectXeroByTenantId(id: string, options?: any) {
        return XeroApiFp(this.configuration).disconnectXeroByTenantId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getAccounts(options?: any) {
        return XeroApiFp(this.configuration).getAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getBankTransaction(id: string, options?: any) {
        return XeroApiFp(this.configuration).getBankTransaction(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} bankTransactionId 
     * @param {string} fileName 
     * @param {string} contentType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getBankTransactionAttachment(bankTransactionId: string, fileName: string, contentType: string, options?: any) {
        return XeroApiFp(this.configuration).getBankTransactionAttachment(bankTransactionId, fileName, contentType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getBankTransactionAttachments(id: string, options?: any) {
        return XeroApiFp(this.configuration).getBankTransactionAttachments(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getBankTransactionHistory(id: string, options?: any) {
        return XeroApiFp(this.configuration).getBankTransactionHistory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getBankTransactions(page?: number, options?: any) {
        return XeroApiFp(this.configuration).getBankTransactions(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getClientConsentUrl(options?: any) {
        return XeroApiFp(this.configuration).getClientConsentUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [clientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getClientTenant(clientId?: string, options?: any) {
        return XeroApiFp(this.configuration).getClientTenant(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getClientXeroTenantConnection(clientId: string, options?: any) {
        return XeroApiFp(this.configuration).getClientXeroTenantConnection(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getContact(id: string, options?: any) {
        return XeroApiFp(this.configuration).getContact(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isSupplier] 
     * @param {boolean} [isCustomer] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getContacts(isSupplier?: boolean, isCustomer?: boolean, page?: number, options?: any) {
        return XeroApiFp(this.configuration).getContacts(isSupplier, isCustomer, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getInvoice(id: string, options?: any) {
        return XeroApiFp(this.configuration).getInvoice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isBill] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getInvoices(isBill?: boolean, page?: number, options?: any) {
        return XeroApiFp(this.configuration).getInvoices(isBill, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} paymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getPayment(paymentId: string, options?: any) {
        return XeroApiFp(this.configuration).getPayment(paymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} paymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getPaymentHistory(paymentId: string, options?: any) {
        return XeroApiFp(this.configuration).getPaymentHistory(paymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getPayments(options?: any) {
        return XeroApiFp(this.configuration).getPayments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} practiceId 
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getPracticeClientConsentUrl(practiceId: string, clientId: string, options?: any) {
        return XeroApiFp(this.configuration).getPracticeClientConsentUrl(practiceId, clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getTaxRates(options?: any) {
        return XeroApiFp(this.configuration).getTaxRates(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * for the practice hub when not in a tenant / client context like there would be when accessing the client UI
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getTenantUsers(id: string, options?: any) {
        return XeroApiFp(this.configuration).getTenantUsers(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getTenants(options?: any) {
        return XeroApiFp(this.configuration).getTenants(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getUserXeroTenantConnections(options?: any) {
        return XeroApiFp(this.configuration).getUserXeroTenantConnections(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getUsers(options?: any) {
        return XeroApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getXeroSubscriptionById(subscriptionId: string, options?: any) {
        return XeroApiFp(this.configuration).getXeroSubscriptionById(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public getXeroTenantConnectionMeyseyUsers(options?: any) {
        return XeroApiFp(this.configuration).getXeroTenantConnectionMeyseyUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public handleDirectClientRedirect(options?: any) {
        return XeroApiFp(this.configuration).handleDirectClientRedirect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public handlePracticeClientRedirect(options?: any) {
        return XeroApiFp(this.configuration).handlePracticeClientRedirect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} url 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public proxyXeroAttachment(url: string, options?: any) {
        return XeroApiFp(this.configuration).proxyXeroAttachment(url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {SetXeroTenantConnectionClientDto} setXeroTenantConnectionClientDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroApi
     */
    public setXeroTenantConnectionClient(id: string, setXeroTenantConnectionClientDto: SetXeroTenantConnectionClientDto, options?: any) {
        return XeroApiFp(this.configuration).setXeroTenantConnectionClient(id, setXeroTenantConnectionClientDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * XeroWebhooksApi - axios parameter creator
 * @export
 */
export const XeroWebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} xXeroSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleWebhook: async (xXeroSignature: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'xXeroSignature' is not null or undefined
            if (xXeroSignature === null || xXeroSignature === undefined) {
                throw new RequiredError('xXeroSignature','Required parameter xXeroSignature was null or undefined when calling handleWebhook.');
            }
            const localVarPath = `/xero-webhooks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xXeroSignature !== undefined && xXeroSignature !== null) {
                localVarHeaderParameter['x-xero-signature'] = String(xXeroSignature);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XeroWebhooksApi - functional programming interface
 * @export
 */
export const XeroWebhooksApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} xXeroSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleWebhook(xXeroSignature: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await XeroWebhooksApiAxiosParamCreator(configuration).handleWebhook(xXeroSignature, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * XeroWebhooksApi - factory interface
 * @export
 */
export const XeroWebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} xXeroSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleWebhook(xXeroSignature: string, options?: any): AxiosPromise<string> {
            return XeroWebhooksApiFp(configuration).handleWebhook(xXeroSignature, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XeroWebhooksApi - object-oriented interface
 * @export
 * @class XeroWebhooksApi
 * @extends {BaseAPI}
 */
export class XeroWebhooksApi extends BaseAPI {
    /**
     * 
     * @param {string} xXeroSignature 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XeroWebhooksApi
     */
    public handleWebhook(xXeroSignature: string, options?: any) {
        return XeroWebhooksApiFp(this.configuration).handleWebhook(xXeroSignature, options).then((request) => request(this.axios, this.basePath));
    }
}


