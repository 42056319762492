import { rem } from '@mantine/core';

interface XeroIconProps extends React.ComponentPropsWithoutRef<'img'> {
  size?: number | string;
}

export function XeroIcon({ size, style, ...others }: XeroIconProps) {
  return (
    <img src={require('./icon-xero-logo.png')}   style={{ width: rem(size), height: rem(size), ...style }} {...others} alt="Xero Logo"/>
  );
}
