import { Container, Title, Text, Paper, Center, Button, Stack, TextInput, PasswordInput, Skeleton, Anchor } from '@mantine/core';
import { isEmail, isNotEmpty, useForm } from '@mantine/form';
import { Notifications, notifications } from '@mantine/notifications';
import { FirebaseError } from 'firebase/app';
import { signInWithXero } from '../lib/signInWithXero';
import { auth } from '../../../firebase';
import { useAuthState, useSignInWithEmailAndPassword, useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { IconMail } from '@tabler/icons-react';
import { GoogleIcon } from '../GoogleIcon';
import { XeroIcon } from '../XeroIcon';
import { useEffect, useRef } from 'react';
import { UnableToLoadAlert } from '../../UnableToLoadAlert';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { showGeneralErrorNotification } from '../../../lib/helpers';


export function PracticeSignInView() {

  const location = useLocation();
  
  const form = useForm({
    initialValues: {
      email: '',
      password: ''
    },
    validate: {
      email: isEmail('Invalid email'),
      password: isNotEmpty('Password is required')
    }
  });

  const [authUser, authUserLoading, authUserError] = useAuthState(auth);


  const [
    signInWithEmailAndPassword,
    ,
    signInWithEmailAndPasswordLoading,
    signInWithEmailAndPasswordError,
  ] = useSignInWithEmailAndPassword(auth);


  const [
    signInWithGoogle,
    ,
    ,
    signInWithGoogleError
  ] = useSignInWithGoogle(auth);

  const previousErrorRef = useRef<unknown>(null);


  let signInWithEmailAndPasswordErrorInvalidCredential = false;
  if ((signInWithEmailAndPasswordError as FirebaseError)?.code === 'auth/invalid-credential') {
    signInWithEmailAndPasswordErrorInvalidCredential = true;
  }

  const signInError = signInWithGoogleError || (!signInWithEmailAndPasswordErrorInvalidCredential && signInWithEmailAndPasswordError);

  useEffect(() => {
    if (signInError && signInError !== previousErrorRef.current) {
      previousErrorRef.current = signInError;

      notifications.show({
        radius: 'md',
        color: 'red',
        title: 'Error',
        message: 'An error occurred. Please try again later.'
      });
      console.error(signInError);
    }

  }, [signInError]);


  console.log('authUser', authUser);
  if (authUser) {
    return <Navigate to='../register/verify-email' state={location.state} />
  }


  const handleSignInWithXeroClick = async () => {
    try {
      await signInWithXero(auth);
    } catch (e) {
      // todo - this is fixable with some faff, not worth the time right now
      if ((e as FirebaseError).code === 'auth/account-exists-with-different-credential') {
        notifications.show({
          radius: 'md',
          color: 'red',
          title: 'Error',
          message: `An account for ${(e as FirebaseError).customData?.email} was created using a different method.`
        });
      } else {
        showGeneralErrorNotification();
      }
    }
  }


  return (
    <>
      <Notifications position="top-right" />
      <Container my="10%">
        <Title ta="center">Welcome to Meysey</Title>
        <Text ta="center" fz="lg" mt="sm">Sign in to your practice account</Text>
        <Center>
          <Paper withBorder shadow="md" radius="md" p="xl" mt="xl" w="40%">
            {authUserLoading ? (
              <>
                <Skeleton animate h={10} mt={6} />
                <Skeleton animate h={10} mt={6} />
                <Skeleton animate h={10} mt={6} />
                <Skeleton animate h={10} mt={6} />
              </>
            ) : authUserError ? (
              <UnableToLoadAlert />
            ) : (

              <form onSubmit={form.onSubmit((values) => signInWithEmailAndPassword(values.email, values.password))}>
                <Stack>
                  <TextInput
                    label="Email"
                    placeholder="Email"
                    required
                    key={form.key('email')}
                    {...form.getInputProps('email')}
                  />
                  <PasswordInput
                    label="Password"
                    placeholder="Password"
                    required
                    key={form.key('password')}
                    {...form.getInputProps('password')}
                  />
                  <Text fz="sm"><Anchor inherit component={Link} to='../reset'>Forgot password?</Anchor></Text>
                  {signInWithEmailAndPasswordErrorInvalidCredential && <Text fz="sm" c="red">Incorrect email or password.</Text>}
                  <Button leftSection={<IconMail size="20px" stroke="1px" />} type="submit" loading={signInWithEmailAndPasswordLoading}>Sign in</Button>
                  <Text ta="center" c="dimmed" fz="sm">or</Text>
                  <Stack gap="xs">
                    <Button variant="default" leftSection={<GoogleIcon width="20px" height="20px" />} onClick={() => signInWithGoogle()}>Sign in with Google</Button>
                    <Button variant="default" leftSection={<XeroIcon width="20px" height="20px" />} onClick={handleSignInWithXeroClick}>Sign in with Xero</Button>
                  </Stack>
                </Stack>
              </form>


            )}

          </Paper>
        </Center>        
        <Text ta="center" fz="sm" mt="lg">Need an account? <Anchor inherit component={Link} to='../register'>Create one here</Anchor>.</Text>
      </Container>
    </>
  );
}