import { Button } from '@mantine/core';
import { auth } from '../../../firebase';
import { useAuthState, useSignOut } from 'react-firebase-hooks/auth';
import { useEffect, useRef } from 'react';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { showGeneralErrorNotification } from '../../../lib/helpers';


export function SignOutButton({ next }: { next: string }) {
  
  const [user] = useAuthState(auth);
  const [signOut, signOutLoading, signOutError] = useSignOut(auth);
  const navigate = useNavigate();

  const prevSignOutErrorRef = useRef<unknown>(null);
  
  useEffect(() => {
    if (signOutError && signOutError !== prevSignOutErrorRef.current) {
      prevSignOutErrorRef.current = signOutError;
      showGeneralErrorNotification();
    }
  });

  return user ? (
    <Button pos="absolute" top="1rem" right="1rem" variant="transparent" loading={signOutLoading} onClick={async () => {      
      if (await signOut()) navigate(next);
    }}>Sign out</Button>
  ) : null;
}