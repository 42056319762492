import { Stack, Title, Group, TextInput, Space, Select, Button, Skeleton, rem } from '@mantine/core';
import { useIdToken } from 'react-firebase-hooks/auth';
import { auth } from '../../../firebase';
import { UnableToLoadAlert } from '../../UnableToLoadAlert';
import { Navigate, useNavigate } from 'react-router-dom';
import { isNotEmpty, useForm } from '@mantine/form';

import classes from './ProfileForm.module.css';
import { CountryCode, getCountries, getCountryCallingCode, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { useMutation, useQuery } from '@tanstack/react-query';
import { MutationKey, QueryKey } from '../../../queryKeys';
import { apiClient } from '../../../api/apiClient';
import { notifications } from '@mantine/notifications';
import { showGeneralErrorNotification } from '../../../lib/helpers';
import { useEffect, useRef } from 'react';

const validCallingCodes: { countryCode: CountryCode, callingCode: string }[] = getCountries().map(countryCode => ({ countryCode, callingCode: getCountryCallingCode(countryCode) }));

interface FormValues {
  firstName: string;
  lastName: string;
  phoneCallingCode: string;
  phone: string;
  practiceName: string;
  practiceEmployees?: string;
  practicePosition?: string;
}

export function ProfileForm({ restart, next }: { restart: string, next: string }) {


  const [firebaseUser, firebaseUserLoading, firebaseUserError] = useIdToken(auth);


  const { data: meyseyUser, isPending: meyseyUserIsPending, isError: meyseyUserIsError } = useQuery({
    queryKey: [QueryKey.GetCurrentUser, firebaseUser?.uid],
    queryFn: async () => {
      const res = await apiClient.user.getCurrentUser();
      return res.data;
    },
    enabled: !!firebaseUser
  });

  const { data: pendingPracticeUserInvite, isPending: pendingPracticeUserInviteIsPending, isError: pendingPracticeUserInviteIsError } = useQuery({
    queryKey: [QueryKey.GetPendingPracticeUserInvite, firebaseUser?.uid],
    queryFn: async () => {
      const res = await apiClient.practice.getPendingPracticeUserInvite();
      return res.data;
    },
    enabled: !!firebaseUser
  });


  const navigate = useNavigate();

  const { mutate: registerPractice, isPending: registerPracticePending } = useMutation({
    mutationKey: [MutationKey.CreatePractice, firebaseUser?.uid],
    mutationFn: async (values: FormValues) => {
      const res = await apiClient.practice.register({
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        practiceName: values.practiceName,
        practiceEmployees: values.practiceEmployees,
        practicePosition: values.practicePosition
      });
      return res.data
    },
    onSuccess: () => {
      navigate(next);
    },
    onError: () => {
      showGeneralErrorNotification();
    }
  });

  const { mutate: acceptPracticeUserInvite, isPending: acceptPracticeUserInvitePending } = useMutation({
    mutationKey: [MutationKey.AcceptPracticeUserInvite, firebaseUser?.uid],
    mutationFn: async (values: FormValues) => {
      const res = await apiClient.practice.acceptPracticeUserInvite(pendingPracticeUserInvite!.practiceId, pendingPracticeUserInvite!.id, {
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        practicePosition: values.practicePosition
      });
      return res.data
    },
    onSuccess: () => {
      navigate(next);
    },
    onError: () => {
      showGeneralErrorNotification();
    }
  });


  const form = useForm<FormValues>({
    mode: 'uncontrolled',
    initialValues: {
      firstName: '',
      lastName: '',
      phoneCallingCode: '+44',
      phone: '',
      practiceName: ''
    },
    validate: {
      firstName: isNotEmpty('First name is required'),
      lastName: isNotEmpty('Last name is required'),
      phoneCallingCode: (value) => validCallingCodes.map(c => c.callingCode).includes(value.replace(/^\+/, '')) ? null : 'Invalid',
      phone: (value) => {
        const countryCode = validCallingCodes.find(c => c.callingCode === form.getValues().phoneCallingCode.replace(/^\+/, ''))?.countryCode;
        if (countryCode && !isValidPhoneNumber(value, countryCode)) {
          return 'Invalid';
        }
      }
    },
    transformValues: (values) => ({
      ...values,
      phone: parsePhoneNumber(values.phone, validCallingCodes.find(c => c.callingCode === values.phoneCallingCode.replace(/^\+/, ''))?.countryCode || 'GB').number,
    })
  });

  const formRef = useRef(form);

  useEffect(() => {
    if (pendingPracticeUserInvite) {
      formRef.current.initialize({
        firstName: pendingPracticeUserInvite.recipientFirstName,
        lastName: pendingPracticeUserInvite.recipientLastName,
        phone: '',
        phoneCallingCode: '+44',
        practiceName: ''
      });
    }
  }, [pendingPracticeUserInvite]);

  if (!firebaseUser || !firebaseUser.emailVerified) {
    return <Navigate to={restart} />;
  }

  if (meyseyUser) {
    return <Navigate to={next} />;
  }


  const handleFormSubmit = (values: FormValues) => {
    if (pendingPracticeUserInvite) {
      acceptPracticeUserInvite(values);
    } else {
      registerPractice(values);
    }
  }


  return (
    <div className={classes.root}>
      {(firebaseUserLoading || meyseyUserIsPending || pendingPracticeUserInviteIsPending) ? (
        <>
          <Skeleton animate h={10} mt={6} />
          <Skeleton animate h={10} mt={6} />
          <Skeleton animate h={10} mt={6} />
          <Skeleton animate h={10} mt={6} />
        </>
      ) : (firebaseUserError || meyseyUserIsError || pendingPracticeUserInviteIsError) ? (
        <UnableToLoadAlert />
      ) : (
        <form onSubmit={form.onSubmit((values) => handleFormSubmit(values))}>
          <Stack>
            {pendingPracticeUserInvite && pendingPracticeUserInvite.practice && (
              <Title order={4}>You've been invited to join {pendingPracticeUserInvite.practice?.name}</Title>
            )}
            <Title order={4}>Your Details</Title>
            <Group>
              <TextInput
                label="First name"
                placeholder="First name"
                required
                key={form.key('firstName')}
                {...form.getInputProps('firstName')}
              />
              <TextInput
                label="Last name"
                placeholder="Last name"
                required
                key={form.key('lastName')}
                {...form.getInputProps('lastName')}
              />
            </Group>
            <TextInput
              label="Mobile number"
              placeholder="Mobile number"
              required
              key={form.key('phone')}
              {...form.getInputProps('phone')}
              styles={{
                input: {
                  marginLeft: rem(4),
                },
                error: {
                  marginLeft: rem(64)
                }
              }}
              leftSectionWidth={60}
              leftSection={
                <TextInput
                  styles={{
                    input: {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      width: rem(60),
                      marginLeft: rem(-2)
                    }
                  }}
                  required
                  placeholder="+44"
                  key={form.key('phoneCallingCode')}
                  {...form.getInputProps('phoneCallingCode')}
                />
              }
            />
            {pendingPracticeUserInvite && (
              <Select
                flex="1"
                label="Your position"
                data={[
                  { value: 'Partner/Managing Director', label: 'Partner/Managing Director' },
                  { value: 'Senior Accountant', label: 'Senior Accountant' },
                  { value: 'Accountant', label: 'Accountant' },
                  { value: 'Bookkeeper', label: 'Bookkeeper' },
                  { value: 'Technology Specialist', label: 'Technology Specialist' }
                ]}
                placeholder="Your position"
                key={form.key('practicePosition')}
                {...form.getInputProps('practicePosition')}
              />
            )}

            <Space h="sm" />

            {!pendingPracticeUserInvite && (
              <>
                <Title order={4}>Your Practice</Title>
                <TextInput
                  label="Practice name"
                  placeholder="Practice name"
                  required
                  key={form.key('practiceName')}
                  {...form.getInputProps('practiceName')}
                />

                <Group wrap="nowrap">
                  <Select
                    w="40%"
                    label="Number of employees"
                    data={[
                      { value: '1-5', label: '1-5' },
                      { value: '6-20', label: '6-20' },
                      { value: '21-50', label: '21-50' },
                      { value: '51+', label: '51+' }
                    ]}
                    placeholder="Number of employees"
                    key={form.key('practiceEmployees')}
                    {...form.getInputProps('practiceEmployees')}
                  />
                  <Select
                    flex="1"
                    label="Your position"
                    data={[
                      { value: 'Partner/Managing Director', label: 'Partner/Managing Director' },
                      { value: 'Senior Accountant', label: 'Senior Accountant' },
                      { value: 'Accountant', label: 'Accountant' },
                      { value: 'Bookkeeper', label: 'Bookkeeper' },
                      { value: 'Technology Specialist', label: 'Technology Specialist' }
                    ]}
                    placeholder="Your position"
                    key={form.key('practicePosition')}
                    {...form.getInputProps('practicePosition')}
                  />
                </Group>


                <Space h="sm" />
              </>
            )}

            <Button type="submit" loading={registerPracticePending || acceptPracticeUserInvitePending}>Continue</Button>
          </Stack>



        </form>
      )}
    </div>
  );
}