import { Text, Center, Container, Loader, SimpleGrid, Space, Title, Stack, ThemeIcon, Group, Anchor } from '@mantine/core';
import { HeroText } from './HeroText';
import { MonitoringStatsGroup } from './MonitoringStatsGroup';
import { ProgressCardColored } from './ProgressCardColored';
import { apiClient } from '../../api/apiClient';
import { UnableToLoadAlert } from '../UnableToLoadAlert';
import { QueryKey } from '../../queryKeys';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { UserContext } from '../../userContext';
import { TransactionValueAreaChart } from './TransactionValueAreaChart';
import { TransactionCountBarChart } from './TransactionCountBarChart';
import { Dots } from './Dots';
import classes from './DashboardView.module.css';
import { IconBulb, IconCheck, IconChecklist, IconUserExclamation } from '@tabler/icons-react';
import { MessageCentreStatus } from './MessageCentreStatus';
import dayjs from 'dayjs';
import { formatDateMedium } from '../../lib/formatters';
import { SpendHistogram } from './SpendHistogram';
import { ReceiveHistogram } from './ReceiveHistogram';
import { Link } from 'react-router-dom';

export function DashboardView() {

  const { activeClient } = useContext(UserContext);

  const { data: tenantAnalysisState, isPending: tenantAnalysisStateIsPending, isError: tenantAnalysisStateIsError } = useQuery({
    queryKey: [QueryKey.GetTenantAnalysisState, activeClient!.id],
    queryFn: async () => {
      const res = await apiClient.analysis.getTenantAnalysisState();
      return res.data;
    },
    refetchInterval: (query) => !query.state.data?.lastAnalysisJobCompletedAt ? 10000 : false
  });

  const initialAnalysisComplete = !!tenantAnalysisState?.lastAnalysisJobCompletedAt;

  const { data: tenantAnalysisStats, isLoading: tenantAnalysisStatsIsLoading, isError: tenantAnalysisStatsIsError } = useQuery({
    queryKey: [QueryKey.GetTenantAnalysisStats, activeClient!.id],
    queryFn: async () => {
      const res = await apiClient.analysis.getTenantAnalysisStats();
      return res.data;
    },
    enabled: initialAnalysisComplete
  });


  const isError = tenantAnalysisStateIsError || tenantAnalysisStatsIsError;

  const dashboardContent = (
    isError ? (
      <Center mt={130}>
        <UnableToLoadAlert />
      </Center>
    ) :
      tenantAnalysisStateIsPending || tenantAnalysisStatsIsLoading ? (
        <Center mt={130}><Loader /></Center>
      ) :
        !initialAnalysisComplete ? (
          <>
            <HeroText tenantName={activeClient!.name!} />
            <Center mt={130}><ProgressCardColored /></Center>
          </>
        ) :
          (
            <>
              <Title order={2}>Analysis Dashboard <span style={{ fontWeight: 500 }}>for {activeClient!.name!}</span></Title>
              {/* <Title order={3} fw={500}>for {activeClient!.tenantName!}</Title> */}
              <Space h="xl" />
              <Space h="xl" />

              <div className={classes.headlinesWrapper}>
                <Stack ta="center" align="center">
                  <Title order={3} fw={500}>Meysey is <Text span inherit c="green.7" fw={700}>Active</Text></Title>
                  <Center mt="md">
                    {/* <div className="iconContainer"> */}
                    {/* <IconLoader3 color="var(--mantine-color-green-7)" style={{ width: rem(100), height: rem(100) }} stroke={0.5} className={classes.pulse} /> */}
                    <ThemeIcon radius="50%" style={{ width: '70px', height: '70px' }} color="green.7" className={classes.pulseIcon}>
                      <IconCheck style={{ width: '70%', height: '70%', strokeWidth: '3px' }} />
                      {/* <IconRadar2 className={classes.statusIcon} style={{ width: '70%', height: '70%', strokeWidth: '1px' }} /> */}
                    </ThemeIcon>
                    {/* </div> */}
                  </Center>
                  <Text mt="lg" fz="xl" fw={500} maw="75%">
                    New critical alerts will be emailed to you
                  </Text>
                </Stack>
                <Stack mt={{ base: 'xl', sm: '0' }} maw={{ sm: '50%' }} gap="xl">
                  <MessageCentreStatus />
                  <Group  wrap="nowrap">
                    <IconChecklist stroke={1} size="30px" />
                    {/* todo */}
                    <Text>A monthly summary will be emailed to you on {formatDateMedium(dayjs().add(1, 'month').format())}</Text>
                  </Group>
                  <Group wrap="nowrap">
                    <IconBulb stroke={1} size="30px" />
                    <Text>Promote fraud awareness in your organisation with our learning resources
                      <br/>
                      <Anchor href="https://help.meysey.co.uk/category/27-fraud-prevention-best-practice" fz="xs">Visit our help centre</Anchor>
                    </Text>
                  </Group>
                  <Group wrap="nowrap">
                    <IconUserExclamation stroke={1} size="30px" />
                    <Stack gap="0">
                      <Text>Your whistleblowing contact is <Text inherit span fw={500}>0208 0508445</Text> / <Text inherit span fw={500}>fraud@meysey.co.uk</Text></Text>
                      <Text fz="sm">You can activate automated fraud prevention outreach to your customers & suppliers
                        <br />
                        <Anchor fz="xs" component={Link} to={'/user-settings'}>Learn more</Anchor>
                      </Text>
                    </Stack>
                  </Group>
                </Stack>
              </div>

              <Space h="xl" />
              <Space h="xl" />

              {/* <Center> */}
              <MonitoringStatsGroup stats={tenantAnalysisStats!} />
              {/* </Center> */}

              <Space h="xl" />
              <Space h="xl" />

              <SimpleGrid cols={{
                base: 1,
                lg: 2
              }}>
                {tenantAnalysisState && <TransactionValueAreaChart tenantAnalysisState={tenantAnalysisState} />}
                {tenantAnalysisState && <TransactionCountBarChart tenantAnalysisState={tenantAnalysisState} />}                
              </SimpleGrid>
              
              <Space h="xl" />
              <Space h="xl" />

              <SimpleGrid cols={{
                base: 1,
                lg: 2
              }}>
                {tenantAnalysisState && <SpendHistogram tenantAnalysisState={tenantAnalysisState} />}
                {tenantAnalysisState && <ReceiveHistogram tenantAnalysisState={tenantAnalysisState} />}                
              </SimpleGrid>

              <Space h="xl" mt="xl" />
            </>
          )
  );

  return (
    <Container fluid p={0} className={classes.wrapper}>
      <Dots style={{ left: 0, top: 0 }} />
      <Dots style={{ left: 60, top: 0 }} />
      <Dots style={{ left: 0, top: 140 }} />
      <Dots style={{ right: 0, top: 0 }} />
      <Dots style={{ right: 60, top: 0 }} />
      <Dots style={{ right: 0, top: 140 }} />
      <div className={classes.inner}>
        <Container size="xl" py="xl">
          {dashboardContent}
          {/* <Map /> */}
        </Container>
      </div>
    </Container>
  );

}