import { ContactAnalysisStateConclusion, ContactAnalysisStateConclusionTypeEnum } from '../../api/generated';
import { formatCompanyStatus } from '../formatters';


export function getDescriptionForContactAnalysisConclusion(conclusion: ContactAnalysisStateConclusion, companyName?: string): string[] {
  switch (conclusion.type) {
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasAccountsOverdue:
      return [
        `${companyName} is listed as having overdue accounts at Companies House. This implies that the company has not submitted its financial reports by the due date, which is a key requirement for maintaining corporate transparency and regulatory compliance.`,
        `Overdue financial accounts can be a red flag indicating potential financial instability or administrative disorganization within a company. Engaging with a company in this state could carry risks for your business, affecting trust and reliability in financial dealings.`
      ];

    case ContactAnalysisStateConclusionTypeEnum.CompanyHasConfirmationStatementOverdue:
      return [
        `${companyName} has been marked as having an overdue confirmation statement at Companies House. This indicates that the company has not kept its registered information, such as director details and address, up to date as required by law.`,
        `The lack of a current confirmation statement can signal potential issues with a company's governance or operational transparency. Engaging with entities that do not comply with regulatory requirements poses a risk, as it may affect their reliability and the overall integrity of your business interactions.`
      ];

    case ContactAnalysisStateConclusionTypeEnum.CompanyHasArchivalStatus:
      return [
        `${companyName} has been identified as 'dissolved' or otherwise archived at Companies House. This status indicates the company may have ceased trading or is no longer in operation.`,
        `It's important to verify this information, especially if you are engaging in or plan to initiate transactions with them. Ensure you're dealing with active and compliant entities to safeguard your business interests.`
      ];

    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersResidingOverseas:
      return [
        `${companyName} is reported to have officers residing overseas, according to the latest information at Companies House. This detail is highlighted as part of a broader analysis aimed at providing a comprehensive overview of the company's structure and potential risk factors.`,
        `The presence of overseas officers, in itself, may not indicate a problem but is considered within the context of the entity's overall profile. It serves as a piece of the puzzle that, depending on other findings, could flag the need for closer scrutiny or simply reflect the company's international nature.`
      ];

    case ContactAnalysisStateConclusionTypeEnum.CompanyHasDistressedStatus:
      return [
        `${companyName} is currently flagged as '${formatCompanyStatus(conclusion?.data?.companyStatus!)}' at Companies House, indicating it may be experiencing financial distress.`,
        `The presence of this status highlights potential instability and risk of failing to meet financial obligations. Engaging with companies in such a condition requires careful consideration and understanding of the associated risks.`
      ];

    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOnlyOneOfficer:
      return [
        `${companyName} currently has only one active officer on record at Companies House. This information forms part of the broader analysis, helping paint a complete picture of the company's operational and governance structure.`,
        `While having a single officer is not uncommon, especially in smaller enterprises, it's a factor considered in understanding the company's overall business environment and potential risk profile.`
      ];

    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersAppointedInPast6Months:
      return [
        `${companyName} has had new officers appointed within the past 6 months, according to the latest filings at Companies House. This fact contributes to a nuanced understanding of the company's current risk landscape, especially when viewed alongside other risk indicators.`,
        `Frequent changes in leadership or rapid turnover in key management positions can sometimes be associated with instability or attempts to circumvent legal and financial accountability, heightening the risk of fraudulent activity. It's important to consider this aspect within the broader context of the company's profile and history.`
      ];

    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersWithDisqualifications:
      return [
        `${companyName} includes an officer with disqualifications, as noted in their Companies House records. This information is crucial in evaluating the company's governance and ethical standards.`,
        `An officer's disqualification raises questions about the management's compliance with legal obligations and their overall reliability, potentially indicating deeper issues within the company's operational practices. Such flags are significant in assessing risk and deciding on the level of scrutiny and caution to apply in your dealings with them.`
      ];

    case ContactAnalysisStateConclusionTypeEnum.CompanyHasPoBoxAddress:
      return [
        `${companyName}'s registered office address is a PO Box, according to the latest update at Companies House. This type of address can sometimes obscure the physical location of a business, making it challenging to verify its operational presence.`,
        `While a PO Box address is not inherently indicative of malfeasance, it is considered alongside other factors to assess the transparency and authenticity of a business. This detail may warrant additional diligence in your evaluation of the company's legitimacy and stability.`
      ];
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasPreviousNamesInPast12Months:
      return [
        `${companyName} has undergone a name change in the past 12 months, as recorded at Companies House. This activity can reflect rebranding efforts, corporate restructuring, or a change in ownership or business direction.`,
        `Although changing a company's name is a legitimate business practice, frequent or recent changes can also be a tactic used to distance from past financial or legal issues. It's advisable to view this in the context of the company's overall history and any other risk indicators present.`
      ];
      case ContactAnalysisStateConclusionTypeEnum.CompanyIsLessThan12MonthsOld:
        return [
          `${companyName} was established less than 12 months ago. Given its brief history, there's limited financial and operational data available to fully assess its stability and reliability. This scarcity of information can increase the risk of fraud, as it's harder to verify the company's track record and credentials.`,
          `A company's infancy is a critical period that can be characterized by rapid changes and unpredictable challenges. Engaging with such a company requires a careful approach to due diligence to mitigate potential risks.`
        ];
      case ContactAnalysisStateConclusionTypeEnum.CompanyIsLessThan24MonthsOld:
        return [
          `${companyName} was formed less than 24 months ago. While it has had more time to establish itself compared to newer companies, it still may lack a comprehensive history for thorough risk assessment. The relative newness of the company often means there are fewer benchmarks for evaluating its financial health and business practices, which could pose a higher fraud risk.`,
          `Companies in their early stages of development are still proving their business models and establishing market trust. This phase of growth requires vigilant oversight and scrutiny from business partners to identify and avoid potential fraud.`
        ];
    default:
      return ['Please try again later'];
  }
}
