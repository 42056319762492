import { useEffect, useState } from 'react';
import { AppShell, Center, LoadingOverlay, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { Navbar } from './components/Navbar';
import { Header } from './components/Header';
import { apiClient } from './api/apiClient';
import { UserContext } from './userContext';
import { Outlet, useLocation } from 'react-router-dom';

import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/charts/styles.css';

import { QueryKey } from './queryKeys';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Notifications } from '@mantine/notifications';
import { UnableToLoadAlert } from './components/UnableToLoadAlert';
import { SubscriptionBanner } from './components/SubscriptionBanner';
import { usePostHog } from 'posthog-js/react';
import { UserTypeEnum } from './api/generated';
import { useIdToken } from 'react-firebase-hooks/auth';
import { auth } from './firebase';

function App() {

  const [opened, setOpened] = useState(false);

  const location = useLocation();
  useEffect(() => {
    setOpened(false);
  }, [location]);

  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const posthog = usePostHog();
  
  const [firebaseUser, firebaseUserLoading, firebaseUserError] = useIdToken(auth);

  const queryClient = useQueryClient();

  const { data: user, isPending: userIsLoading, isError: userIsError } = useQuery({
    queryKey: [QueryKey.GetCurrentUser, firebaseUser?.uid],
    queryFn: async () => {
      const res = await apiClient.user.getCurrentUser();
      return res.data;
    }
  });


  const { data: activeClient, isLoading: activeClientIsLoading, isError: activeClientIsError } = useQuery({
    queryKey: [QueryKey.GetActiveClient, user?.id],
    queryFn: async () => {
      const res = await apiClient.client.getActiveClient();
      return res.data;
    },
    enabled: !!user
  });


  const { data: clients, isLoading: clientsAreLoading, isError: clientsIsError } = useQuery({
    queryKey: [QueryKey.GetClients, user?.id],
    queryFn: async () => {
      const res = await apiClient.client.getClients();
      return res.data;
    },
    enabled: !!user
  });

  const { data: activePractice, isLoading: activePracticeIsLoading, isError: activePracticeIsError } = useQuery({
    queryKey: [QueryKey.GetActivePractice, user?.id],
    queryFn: async () => {
      const res = await apiClient.practice.getPracticeById(user?.practiceId!);
      return res.data;
    },    
    enabled: !!user && user.type === UserTypeEnum.Practice && !!user?.practiceId
  });



  useEffect(() => {

    if (user) {
      (window as any).Beacon && (window as any).Beacon('identify', {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        signature: user.helpscoutSignature
      });
      posthog.identify(user.id, {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      });
    }
  }, [user, posthog]);

  const isLoading = firebaseUserLoading || userIsLoading || activeClientIsLoading || clientsAreLoading || activePracticeIsLoading;
  const isError = userIsError || activeClientIsError || clientsIsError || activePracticeIsError;

  return (
    <UserContext.Provider value={{ user, isLoading, activeClient, clients, activePractice }}>
      <Notifications position="top-right" />
      <AppShell
        styles={{
          main: {
            background: colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
          },
        }}
        header={{ height: { base: 60, md: 70 } }}
        navbar={{ breakpoint: 'sm', width: { sm: 200, lg: 300 }, collapsed: { mobile: !opened } }}
      // navbarOffsetBreakpoint="sm"
      // asideOffsetBreakpoint="sm"
      // navbar={
      //   <Navbar opened={opened} />
      // }
      // header={
      //   <Header opened={opened} setOpened={setOpened} />
      // }
      >
        <LoadingOverlay visible={isLoading} />
        <Navbar opened={opened} />
        <Header opened={opened} setOpened={setOpened} />
        <AppShell.Main>
          {isLoading ? null : isError ? (
            <Center mt="30dvh">
              <UnableToLoadAlert />
            </Center>
          ) : (
            <> 
              <SubscriptionBanner />                 
              <Outlet />
            </>
          )}
        </AppShell.Main>
      </AppShell>
    </UserContext.Provider>
  );
}

export default App;
