import { BarChart } from '@mantine/charts';
import { Text, Paper, Group, Box, Divider, Space } from '@mantine/core';
import { TenantAnalysisState, TenantAnalysisStateConclusionTypeEnum } from '../../api/generated';
import dayjs from 'dayjs';

// todo - refacotr - dedupe or reuse contact chart component
export function TransactionCountBarChart({ tenantAnalysisState }: { tenantAnalysisState: TenantAnalysisState }) {

  
  // todo any
  const conclusionData = (tenantAnalysisState
    .conclusions!
    ?.find(conclusion => conclusion.type === TenantAnalysisStateConclusionTypeEnum.HasMonthlyTransactionCountHistory)
    ?.data as any)?.yearMonthTimeSeries as unknown as (Record<string, { i: number, s: number, r: number, b: number }> | undefined);

  const pastTwelveMonths = Array.from({ length: 6 }, (_, i) => i).map(i => dayjs().subtract(6, 'month').add(i + 1, 'month'));

  const graphData = conclusionData && pastTwelveMonths.map(month => ({
    month: dayjs(month).format('MMM YY'),
    Invoices: conclusionData![dayjs(month).format('YYYY-MM')]?.i || 0,
    'Spend Money Transactions': conclusionData![dayjs(month).format('YYYY-MM')]?.s || 0,
    'Receive Money Transactions': conclusionData![dayjs(month).format('YYYY-MM')]?.r || 0,
    Bills: conclusionData![dayjs(month).format('YYYY-MM')]?.b || 0,
  }));  
  
  return (
    <Paper shadow="xs" radius="md" p="lg">
      <Group>
        <Text fz="lg" fw={500}>Recent Transaction Volume (Count)</Text>
        <Box flex="1" ta="right">
          {/* <Text fz="sm" c="dimmed">Meysey is working to match this Xero Contact record to a legal entity at Companies House</Text> */}
        </Box>
      </Group>
      <Divider mt="xs" />
      <Space h="md" />
      <BarChart
        h={300}
        data={graphData || []}
        dataKey="month"
        series={[
          { name: 'Invoices', color: 'violet.6' },
          { name: 'Bills', color: 'blue.6' },
          { name: 'Spend Money Transactions', color: 'teal.6' },
          { name: 'Receive Money Transactions', color: 'yellow.6' },
        ]}
        withYAxis={false}
        withLegend
      />
    </Paper>
  );
}