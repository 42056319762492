import { AreaChart } from '@mantine/charts';
import { Text, Paper, Divider, Space } from '@mantine/core';
import { TenantAnalysisState, TenantAnalysisStateConclusionTypeEnum } from '../../api/generated';
import { formatCurrency } from '../../lib/formatters';

// todo - refacotr - dedupe or reuse contact chart component
export function SpendHistogram({ tenantAnalysisState }: { tenantAnalysisState: TenantAnalysisState }) {

  
  // todo any
  const conclusionData = (tenantAnalysisState
    .conclusions!
    ?.find(conclusion => conclusion.type === TenantAnalysisStateConclusionTypeEnum.HasSpendHistogram)
    ?.data as any)?.histogram as unknown as ({ lower?: number, upper?: number, count: number }[] | undefined);

  const graphData = [
      { value: formatCurrency(0), Count: 0 },
      ...(conclusionData?.map((i) => ({
      value: `<${formatCurrency(i.upper || 0)}`,
      Count: i.count    
    })) || [])
  ];  
  
  return (
    <Paper shadow="xs" radius="md" p="lg">
      <Text fz="lg" fw={500}>Bills & Expenditure - Frequency by Amount</Text>        
      <Divider mt="xs" />
      <Space h="md" />
      <AreaChart
        h={300}
        data={graphData}        
        dataKey="value"
        series={[
          { name: 'Count', color: 'violet.6' }          
        ]}
        withYAxis={false}
        curveType='natural'
      />
    </Paper>
  );
}