
import { Text, Center, Group,  Skeleton, Stack } from '@mantine/core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiClient } from '../../api/apiClient';
import { UnableToLoadAlert } from '../UnableToLoadAlert';
import classes from './UserList.module.css';
import { MutationKey, QueryKey } from '../../queryKeys';
import { Client, ClientUserInvite, User, XeroUser, XeroUserOrganisationRoleEnum } from '../../api/generated';
import { UserListItem } from './UserListItem';
import React from 'react';
import { notifications } from '@mantine/notifications';

export function UserList({ client, excludeUsers, excludeAdvisors }: { client: Client, excludeUsers?: User[], excludeAdvisors?: boolean }) {  

  const queryClient = useQueryClient();

  
  const { data: clientTenant, isError: clientTenantIsError, isPending: clientTenantIsPending } = useQuery({
    queryKey: [QueryKey.GetClientTenant, client.id],
    queryFn: async () => {
      const res = await apiClient.xero.getClientTenant(client.id);
      return res.data;
    }    
  });

  const { data: xeroUsers, isError: xeroUsersIsError, isPending: xeroUsersIsPending } = useQuery({
    queryKey: [QueryKey.GetXeroUsers, client.id],
    queryFn: async () => {
      const res = await apiClient.xero.getTenantUsers(clientTenant!.tenantId);
      return res.data;
    },
    enabled: !!clientTenant    
  });

  const { data: meyseyUsers, isError: meyseyUsersIsError, isPending: meyseyUsersIsPending } = useQuery({
    queryKey: [QueryKey.GetXeroTenantConnectionMeyseyUsers, client.id],
    queryFn: async () => {
      const res = await apiClient.client.getClientUsers(client.id);
      return res.data;
    }    
  });

  const { data: xeroUserInvites, isError: xeroUserInvitesIsError, isPending: xeroUserInvitesIsPending } = useQuery({
    queryKey: [QueryKey.GetClientUserInvites, client.id],
    queryFn: async () => {
      const res = await apiClient.client.getClientUserInvites(client.id);
      return res.data;
    }    
  });

  const { mutate: createClientUserInvite, isPending: createClientUserInviteIsPending, variables: createClientUserInviteVariables  } = useMutation({
    mutationKey: [MutationKey.CreateClientUserInvite],
    mutationFn: async (xeroUser: XeroUser) => {
      const res = await apiClient.client.createClientUserInvite(client.id, { 
        recipientName: xeroUser.firstName + ' ' + xeroUser.lastName,
        recipientEmail: xeroUser.emailAddress!,
        extUserId: xeroUser.userID!, 
        extUserSource: 'xero' 
      });
      return res.data;
    },
    onSuccess: (data) => {      
      queryClient.invalidateQueries({
        queryKey: [QueryKey.GetClientUserInvites]
      });      
      notifications.show({
        radius: 'md',        
        title: 'Invite sent',
        message: `An invite has been sent to ${data.recipientName}.`,
      });
    },
    onError: (_error, xeroUser) => {
      notifications.show({
        radius: 'md',
        color: 'red',
        title: `Invite wasn't sent`,
        message: `An invite couldn't be sent to ${xeroUser.emailAddress} at this time. Please try again later.`,
      });
    }
  });

  // todo this could probably all shift up a level and just dont show any content on the screen until the data is loaded
  // for simplicity

  let content: React.ReactNode;
  if (meyseyUsersIsError || xeroUsersIsError || xeroUserInvitesIsError || clientTenantIsError) {
    content = (
      <Center mt="lg">
        <UnableToLoadAlert />
      </Center>
    );
  } else if (xeroUsersIsPending || meyseyUsersIsPending || xeroUserInvitesIsPending || clientTenantIsPending) {
    content = [1,2,3].map(i => (
        <Group mt="lg" key={i} wrap="nowrap">
          <Skeleton animate height={50} width={50} circle />
          <Stack flex={1}>
            <Skeleton animate height={8} radius="xl" />
            <Skeleton animate height={8} mt={6} w="70%" radius="xl" />
          </Stack>
        </Group>
      // <Center><Loader size="sm" /></Center>
    ));
  } else if (xeroUsers.length === 0) {
    content = (
      <Center mt="lg">
        <Text c="dimmed">No users found</Text>
      </Center>
    );  
  } else {
    
    const userPairs: [XeroUser, User | undefined, ClientUserInvite | undefined][] = xeroUsers
      .filter(xu => !(excludeUsers || []).some(eu => eu.email === xu.emailAddress))
      .filter(xu => !(excludeAdvisors && xu.organisationRole === XeroUserOrganisationRoleEnum.Financialadviser))
      .map((xeroUser) => [
        xeroUser, 
        meyseyUsers.find((meyseyUser) => meyseyUser.email === xeroUser.emailAddress),
        xeroUserInvites.find((xeroUserInvite) => xeroUser.userID === xeroUserInvite.extUserId)
      ]);

    content = userPairs.map(([xeroUser, meyseyUser, xeroUserInvite]) => (
      <UserListItem 
        key={xeroUser.userID} 
        xeroUser={xeroUser} 
        meyseyUser={meyseyUser} 
        clientUserInvite={xeroUserInvite} 
        createClientUserInvite={createClientUserInvite}
        createClientUserInviteIsPending={createClientUserInviteIsPending && createClientUserInviteVariables?.userID === xeroUser.userID}
      />
    ));

  }
  
  return (
    <div className={classes.root}>
      {content}
    </div>
  );
}