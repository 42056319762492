import { ConclusionSeverity } from './conclusionSeverity';

export function getColorForSeverity(severity: ConclusionSeverity) {
  switch (severity) {
    case 'critical':
      return 'var(--mantine-color-red-7)';
    case 'important':
      return 'var(--mantine-color-orange-7)';
    case 'advisory':
      return 'var(--mantine-color-blue)';
    default:
      throw new Error('Unknown severity');
  }
}