import { Text, Modal, Title, List, Stack, Anchor } from '@mantine/core';

export function FraudOutreachEmailPreviewModal({ opened, onClose, companyName }: { opened: boolean, onClose: () => void, companyName: string }) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      closeOnClickOutside={false}
      title={<Text fw={500}>Fraud Outreach Email Preview</Text>}
      centered
      size="xl"
      p="lg"
    >
      <Stack p="lg">
        <Title order={3}>
          Fraud Prevention Outreach
        </Title>

        <Text>
          You have been sent this automated email as you are a supplier or customer of {companyName}.           
        </Text>
        <Text>
          As part of their pro-active fraud prevention program, {companyName} have a confidential fraud whistleblowing facility through which you can report potential instances of wrongdoing.
        </Text>

        <Text>
          {companyName} recognise that it's vital for organisations to work together to share intelligence and stop fraud, so please take a minute to read the following message.
        </Text>

        <Title order={4}>What to look out for</Title>

        <List type="ordered">
          <List.Item>
            <Text span fw={500}>Insider trading and financial back-handers</Text>
            <br />
            Unauthorised financial payments being given between employees in exchange for awarding of contracts
          </List.Item>

          <List.Item>
            <Text span fw={500}>Bribery and bid rigging</Text>
            <br />
            Gifts or perks provided between employees to obtain commercially sensitive information
          </List.Item>

          <List.Item>
            <Text span fw={500}>Unusual invoice or billing requests</Text>
            <br />
            Non-standard / unauthorised invoicing requests which could potentially facilitate skimming or other forms of concealment
          </List.Item>

        </List>

        <Title order={4}>How to anonymously report potential wrongdoing</Title>

        <List>
          <List.Item>
            Call our confidential hotline and leave a recorded message: <Anchor inherit href="tel:+44(0)208 0508445">+44(0)208 0508445</Anchor>
          </List.Item>
          <List.Item>
            Email our privately hosted whistleblower inbox - <Anchor inherit href="mailto:fraud@meysey.co.uk">fraud@meysey.co.uk</Anchor>
          </List.Item>
        </List>

        <Text>
          Thank you for your support and collaboration in helping us stop fraud.
          <br />
          <br />
          Meysey
        </Text>
      </Stack>

    </Modal>
  );
}