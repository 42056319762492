import { Link } from 'react-router-dom';
import { ContactAnalysisStateConclusion, ContactAnalysisStateConclusionTypeEnum } from '../../api/generated';
import { Anchor } from '@mantine/core';



export function getAlertHeadingForContactAnalysisConclusion(conclusion: ContactAnalysisStateConclusion, xeroContactId: string, contactName: string, companyName?: string) {
  switch (conclusion.type) {    
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasAccountsOverdue:
      return <>A legal entity{companyName ? `, ${companyName},` : ''} matched to your contact <Anchor component={Link} to={`/suppliers-and-customers/${xeroContactId}`}>{contactName}</Anchor>, is overdue to file statutory accounts</>;
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasConfirmationStatementOverdue:
      return <>A legal entity{companyName ? `, ${companyName},` : ''} matched to your contact <Anchor component={Link} to={`/suppliers-and-customers/${xeroContactId}`}>{contactName}</Anchor>, is overdue to file a confirmation statement</>;
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasArchivalStatus:
      return <>A legal entity{companyName ? `, ${companyName},` : ''} matched to your contact <Anchor component={Link} to={`/suppliers-and-customers/${xeroContactId}`}>{contactName}</Anchor>, is dissolved or otherwise archived</>;
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasDistressedStatus:
      return <>A legal entity{companyName ? `, ${companyName},` : ''} matched to your contact <Anchor component={Link} to={`/suppliers-and-customers/${xeroContactId}`}>{contactName}</Anchor>, has a status at Companies House indicating it may be in financial distress</>;
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOnlyOneOfficer:
      return <>A legal entity{companyName ? `, ${companyName},` : ''} matched to your contact <Anchor component={Link} to={`/suppliers-and-customers/${xeroContactId}`}>{contactName}</Anchor>, only has one active officer</>;
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersAppointedInPast6Months:
      return <>A legal entity{companyName ? `, ${companyName},` : ''} matched to your contact <Anchor component={Link} to={`/suppliers-and-customers/${xeroContactId}`}>{contactName}</Anchor>, has had new officers appointed in past 6 months</>;
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersResidingOverseas:
      return <>A legal entity{companyName ? `, ${companyName},` : ''} matched to your contact <Anchor component={Link} to={`/suppliers-and-customers/${xeroContactId}`}>{contactName}</Anchor>, has officers residing overseas</>;
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersWithDisqualifications:
      return <>A legal entity{companyName ? `, ${companyName},` : ''} matched to your contact <Anchor component={Link} to={`/suppliers-and-customers/${xeroContactId}`}>{contactName}</Anchor>, has a disqualified officer</>;
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasPoBoxAddress:
      return <>A legal entity{companyName ? `, ${companyName},` : ''} matched to your contact <Anchor component={Link} to={`/suppliers-and-customers/${xeroContactId}`}>{contactName}</Anchor>, is using a PO box as its registered office</>;
    case ContactAnalysisStateConclusionTypeEnum.CompanyHasPreviousNamesInPast12Months:
      return <>A legal entity{companyName ? `, ${companyName},` : ''} matched to your contact <Anchor component={Link} to={`/suppliers-and-customers/${xeroContactId}`}>{contactName}</Anchor>, changed its name recently</>;
    case ContactAnalysisStateConclusionTypeEnum.CompanyIsLessThan12MonthsOld:
      return <>A legal entity{companyName ? `, ${companyName},` : ''} matched to your contact <Anchor component={Link} to={`/suppliers-and-customers/${xeroContactId}`}>{contactName}</Anchor>, was formed less than 12 months ago</>;
    case ContactAnalysisStateConclusionTypeEnum.CompanyIsLessThan24MonthsOld:
      return <>A legal entity{companyName ? `, ${companyName},` : ''} matched to your contact <Anchor component={Link} to={`/suppliers-and-customers/${xeroContactId}`}>{contactName}</Anchor>, was formed less than 24 months ago</>;
    default:
      try {
        return conclusion.type[0] + conclusion.type.replace(/([A-Z]|[0-9]+)/g, ' $1').trim().toLowerCase().slice(1);
      } catch (e) {
        return 'Unknown';
      }
  }
}
