



import { Text, Paper, Divider, Center } from '@mantine/core';
import { BankTransaction, BankTransactionAnalysisState, BankTransactionAnalysisStateConclusionTypeEnum } from '../../api/generated';
import classes from './BankTransactionDetailStatus.module.css';
import { IconFile, IconFlagExclamation, IconLockCheck } from '@tabler/icons-react';
import pluralize from 'pluralize';
import { getSeverityForBankTransactionAnalysisConclusionType } from '../../lib/invoiceAnalysis/getSeverityForBankTransactionAnalysisConclusionType';
import { capitalize, countBy } from 'lodash';
import { getColorForSeverity } from '../../lib/getColorForSeverity';
import { ConclusionSeverity } from '../../lib/conclusionSeverity';


function BankTransactionDetailStatusCard(props: {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  statusIcon?: React.ElementType;
  statusColor?: string;
  statusLabel?: React.ReactNode;
  children?: React.ReactNode;
}) {
  const { title, subtitle, statusLabel, children, statusColor } = props;
  const Icon = props.statusIcon || IconLockCheck;
  return (
    <Paper radius="md" shadow="md" p="xs" withBorder className={classes.statusCard}>
      <div className={classes.statusCardTitleContainer}>
        <Text className={classes.statusCardTitle}>{title}</Text>
        <Text ta="center" fz="xs">{subtitle}</Text>
      </div>
      <div className={classes.statusCardIconContainer}>
        <Center>
          <Icon className={classes.statusCardIcon} color={statusColor} />
        </Center>
        <Text className={classes.statusCardStatusLabel} c={statusColor}>{statusLabel || 'No issues detected'}</Text>
      </div>
      <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {children}
      </div>
    </Paper>
  );
}

function NoDataPlaceholder() {
  return (
    <Text fz="xs" c="dimmed" ta="center">No data</Text>
  );
}

function BankTransactionDetailStatusCards({ bankTransaction, bankTransactionAnalysisState }: { bankTransaction: BankTransaction, bankTransactionAnalysisState: BankTransactionAnalysisState }) {

  const relevantConclusions = bankTransactionAnalysisState.conclusions?.filter(c => c.type !== BankTransactionAnalysisStateConclusionTypeEnum.AttachmentDataExtracted) || [];
  // one card summarising advisories
  const advisoryCount = relevantConclusions.length;

  const advisoryCardTitle = `${advisoryCount || 'No'} ${pluralize('Alert', advisoryCount)} / ${pluralize('Advisory', advisoryCount)}`;
  const advisoryCardSubtitle = 'from analysis';
  const advisoryCardStatusIcon = advisoryCount > 0 ? IconFlagExclamation : undefined;
  const advisoryCardStatusLabel = advisoryCount > 0 ? <a href="#alerts">View results</a> : undefined;
  const advisoryCardStatusColor = advisoryCount > 0 ? 'var(--mantine-color-orange-7)' : undefined;

  const advisorySeverities = Object.entries(countBy(relevantConclusions.map(c => getSeverityForBankTransactionAnalysisConclusionType(c.type))));

  const advisorySummaryCard = (
    <BankTransactionDetailStatusCard
      title={advisoryCardTitle}
      subtitle={advisoryCardSubtitle}
      statusIcon={advisoryCardStatusIcon}
      statusColor={advisoryCardStatusColor}
      statusLabel={advisoryCardStatusLabel}
    >      
      <div>
        {advisorySeverities.map((entry, i) => {            
          return (
            <Text fz="xs" key={i} fw={500} c={getColorForSeverity(entry[0] as ConclusionSeverity)}>{entry[1]} {capitalize(entry[0])}</Text>
          );
        })}
      </div>              
    </BankTransactionDetailStatusCard>
  );

  // payment?


  // const paymentCount = bankTransaction.payments?.length;

  // const paymentCardTitle = `${formatNumber(paymentCount)} ${pluralize('Payment', paymentCount)}`;
  // const paymentCardSubtitle = `${formatNumber(paymentCount)} Scanned`;
  // const paymentStatusIcon = IconFile;
  // const paymentStatusLabel = 'Nothing to report';
  // const paymentStatusColor = 'var(--mantine-color-gray-6)';


  // credit note?

  // another detailing attachments + processed or not

  const attachmentCount = bankTransactionAnalysisState.normalizedBankTransaction.attachments?.length;
  const processedAttachmentCount = (bankTransactionAnalysisState.conclusions || []).filter(c => c.type === BankTransactionAnalysisStateConclusionTypeEnum.AttachmentDataExtracted).length;

  const attachmentsCardTitle = `${attachmentCount || 'No'} ${pluralize('Attachment', attachmentCount)}`;
  const attachmentsCardSubtitle = attachmentCount > 0 ? `${processedAttachmentCount}/${attachmentCount} Processed`  : `to scan`;
  const attachmentsStatusIcon = IconFile;
  const attachmentsStatusLabel = 'Nothing to report';
  const attachmentsStatusColor = 'var(--mantine-color-gray-6)';

  const attachmentsStatusCard = (
    <BankTransactionDetailStatusCard
      title={attachmentsCardTitle}
      subtitle={attachmentsCardSubtitle}
      statusIcon={attachmentsStatusIcon}
      statusColor={attachmentsStatusColor}
      statusLabel={attachmentsStatusLabel}
    >
      {/* <NoDataPlaceholder /> */}
    </BankTransactionDetailStatusCard>
  );

  return (
    <>
      {advisorySummaryCard}
      {attachmentsStatusCard}
    </>
  );

}

export function BankTransactionDetailStatus({ bankTransaction, bankTransactionAnalysisState }: { bankTransaction: BankTransaction, bankTransactionAnalysisState: BankTransactionAnalysisState | undefined }) {



  return (
    <Paper radius="md" shadow="xs" p="lg" className={classes.statusRoot}>

      <Text fz="lg" fw={500}>Fraud Detection Status</Text>
      <Divider className={classes.statusDivider} my="xs" />

      <div className={classes.statusInner}>


        {bankTransactionAnalysisState ?
          (<BankTransactionDetailStatusCards bankTransaction={bankTransaction} bankTransactionAnalysisState={bankTransactionAnalysisState} />)
          :
          (
            <Text fz="lg" c="white" my="xl">Not Yet Analysed</Text>
          )
        }


      </div>
    </Paper>
  )
}
