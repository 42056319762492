import { BankTransaction, BankTransactionAnalysisStateConclusion, BankTransactionAnalysisStateConclusionTypeEnum, Invoice, InvoiceAnalysisStateConclusion, InvoiceAnalysisStateConclusionTypeEnum } from '../../api/generated';


export function getRecommendationForInvoiceAnalysisConclusion(invoice: Invoice, conclusion: InvoiceAnalysisStateConclusion) {
  const data = conclusion.data as any;
  const meta = conclusion.meta as any;

  switch (conclusion.type) {
    case InvoiceAnalysisStateConclusionTypeEnum.DocumentIsOutlier:
    case InvoiceAnalysisStateConclusionTypeEnum.LineItemIsOutlier:
      return [
        `Review any corresponding documentation such as invoices, receipts, or contracts to verify the transaction's validity. It may also be helpful to discuss the transaction with the team member responsible for processing or authorizing it to gather more context.`,
        `Understanding whether this outlier is a result of an error, fraudulent activity, or a legitimate exception will help in taking appropriate corrective actions and refining your financial controls.`
      ];
    case InvoiceAnalysisStateConclusionTypeEnum.PossibleDuplicate:
      return [
        `Compare the details of the suspected duplicates, checking for similarities in amounts, dates, vendor details, invoice numbers, and descriptions. Consult with the responsible department or employee to ascertain whether the duplication was intentional or an error, as recurring payments may sometimes appear as duplicates.`,
        `Review relevant documentation such as purchase orders, receipts, and delivery notes to ensure that each transaction corresponds to a unique purchase or financial event.`        
      ];
    case InvoiceAnalysisStateConclusionTypeEnum.BankDetailsChanged:
      return [
        `Review the bank details provided to ensure that they are accurate and up-to-date. If the bank details have changed, verify the new details with the supplier or vendor to prevent any payment errors or fraud.`,
        `Ensure that your finance team is aware of the updated bank details to avoid any delays in processing payments or potential compliance issues.`
      ];
    default: 
      return [
        `Unknown`
      ];
  }
}



export function getRecommendationForBankTransactionAnalysisConclusion(bankTransaction: BankTransaction, conclusion: BankTransactionAnalysisStateConclusion) {
  const data = conclusion.data as any;
  const meta = conclusion.meta as any;

  switch (conclusion.type) {
    case BankTransactionAnalysisStateConclusionTypeEnum.DocumentIsOutlier:
    case BankTransactionAnalysisStateConclusionTypeEnum.LineItemIsOutlier:
      return [
        `Review any corresponding documentation such as invoices, receipts, or contracts to verify the transaction's validity. It may also be helpful to discuss the transaction with the team member responsible for processing or authorizing it to gather more context.`,
        `Understanding whether this outlier is a result of an error, fraudulent activity, or a legitimate exception will help in taking appropriate corrective actions and refining your financial controls.`
      ];
    case BankTransactionAnalysisStateConclusionTypeEnum.PossibleDuplicate:
      return [
        `Compare the details of the suspected duplicates, checking for similarities in amounts, dates, vendor details, invoice numbers, and descriptions. Consult with the responsible department or employee to ascertain whether the duplication was intentional or an error, as recurring payments may sometimes appear as duplicates.`,
        `Review relevant documentation such as purchase orders, receipts, and delivery notes to ensure that each transaction corresponds to a unique purchase or financial event.`        
      ];
    default: 
      return [
        `Unknown`
      ];
  }
}