import { Text, Progress, Card, Space, Center } from '@mantine/core';
import classes from './ProgressCardColored.module.css';

export function ProgressCardColored() {
  return (
    <Card withBorder radius="md" p="xl" className={classes.card}>
      <Center>
        <Text fz="sm" tt="uppercase" fw={700} className={classes.title}>
        Initial Analysis In Progress
      </Text>
      </Center>
      <Space h="md"/>
      <Text fz="lg" ta="center" fw={500} className={classes.stats}>
        We'll notify you when we've finished analysing your data for the first time.
        <br />
        Depending on the volume of data there is to analyse, this may take several hours.
      </Text>
      
      <Space h="lg"/>
      {/* <Center><Skeleton mt="md" height={10} w="75%" radius="xl" /></Center> */}
      <Progress        
        value={20}
        size="lg"
        radius="xl"
        classNames={{
          root: classes.progressTrack,
          section: classes.progressSection,
        }}
      />
    </Card>
  );
}