import { useNavigate, useParams } from 'react-router-dom';
import { MutationKey, QueryKey } from '../../../queryKeys';
import { apiClient } from '../../../api/apiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import { Center, Loader } from '@mantine/core';
import { UserContext } from '../../../userContext';
import { UnableToLoadAlert } from '../../UnableToLoadAlert';
import { NoClientAccessAlert } from '../../NoClientAccessAlert';


export function ActivateClientView() {
  
  const { clientId } = useParams();

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  
  
  const { user, isLoading: userIsLoading } = useContext(UserContext);

  const { mutate: setActiveClient, isPending: setActiveClientIsPending, isError: setActiveClientIsError } = useMutation({
    mutationKey: [MutationKey.SetActiveClient], 
    mutationFn: async (clientId: string) => {
      const res = await apiClient.client.setActiveClient({ clientId }); 
      return res.data;
    },
    onSuccess: (data) => {      
      queryClient.setQueryData([QueryKey.GetActiveClient, user!.id], data);
      navigate('/');
    }
  });

  useEffect(() => {
    clientId && setActiveClient(clientId);
  }, [clientId, setActiveClient]);

  const isLoading = userIsLoading || setActiveClientIsPending;  
    


  if (isLoading) return (
    <Center mt={130}><Loader /></Center>
  );

  if (!clientId) return (
    <Center mt={250}>
      <UnableToLoadAlert />
    </Center>
  );


  if (setActiveClientIsError) {
    return (
      <Center mt={250}>
        <NoClientAccessAlert />
      </Center>
    )
  }
  
  return null;

}