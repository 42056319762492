import { Avatar, Text, Group, Table, Loader } from "@mantine/core";
import { Address, AddressAddressTypeEnum, Contact, Phone, PhonePhoneTypeEnum } from "../../api/generated";
import { apiClient } from "../../api/apiClient";
import { getInitials } from '../../lib/getInitials';
import { getAvatarColorFromName } from '../../lib/getAvatarColorFromName';
import { QueryKey } from '../../queryKeys';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import classes from './ContactsRow.module.css';
export interface ContactsRowProps {
  contact: Contact;
  onClick: React.MouseEventHandler<HTMLTableRowElement>;
}

export function ContactsRow({ contact, onClick }: ContactsRowProps) {


  const { data: contactAnalysisState, isPending: analysisIsPending } = useQuery({
    queryKey: [QueryKey.GetAnalysisByContactId, contact.contactID],
    queryFn: async () => {
      const res = await apiClient.analysis.getContactAnalysisStateByXeroContactId(contact.contactID!);
      return res.data;
    }
  });


  // const [analysisOpened, setAnalysisOpened] = useState(false);

  const billingAddress = contact.addresses?.find(a => a.addressType === AddressAddressTypeEnum.Pobox);
  const getPrintedAddress = (address: Address) => {
    const addressKeys: (keyof Address)[] = ['addressLine1', 'addressLine2', 'addressLine3', 'addressLine4', 'postalCode'];
    return addressKeys.map(k => address[k]).filter(v => !!v).join(', ');
  }

  const defaultPhone = contact.phones?.find(p => p.phoneType === PhonePhoneTypeEnum.Default);
  const getPrintedPhone = (phone: Phone) => {
    return [phone.phoneAreaCode, phone.phoneNumber].filter(v => !!v).join(' ');
  }

  const contactLastAnalysed = contactAnalysisState?.lastAnalysisJobCompletedAt && dayjs(contactAnalysisState?.lastAnalysisJobCompletedAt).fromNow();  

  return (
    <>
      <Table.Tr 
        key={contact.contactID}
        className={classes.row}
        onClick={onClick} 
      >
        <Table.Td>
          <Group gap="sm">
            <Avatar size={40} radius={40} color={getAvatarColorFromName(contact?.name)}>{getInitials(contact?.name)}</Avatar>
            <div>
              <Text fz="sm" fw={500}>
                {contact.name}
              </Text>
              <Text c="dimmed" fz="xs">
                {billingAddress && getPrintedAddress(billingAddress)}
              </Text>
              <Text c="dimmed" fz="xs">
                {[contact.emailAddress, defaultPhone && getPrintedPhone(defaultPhone)].filter(v => !!v).join(' • ')}
              </Text>
            </div>
          </Group>
        </Table.Td>
        <Table.Td>
          <Group justify="flex-end" mr="lg">
            {analysisIsPending ? (
              <Loader color="gray" type="dots" size="xs" />
            ) : (
              <div>
                <Text fz="xs" c="dimmed">
                  Last analysed
                </Text>
                <Text fz="sm">{contactLastAnalysed || 'Not Analysed'}</Text>
              </div>
            )}
            {/* {mostRecentAnalysis && (
            <ActionIcon variant="subtle" color="gray" onClick={() => setAnalysisOpened(!analysisOpened)}>
              {analysisOpened ? <IconChevronUp style={{ width: rem(16), height: rem(16) }} stroke={1.5} /> : <IconChevronDown style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
            </ActionIcon>
          )} */}
          </Group>
        </Table.Td>
      </Table.Tr>
      {/* {analysisOpened && (
        <Table.Tr style={{ backgroundColor: theme.white }}>
          <Table.Td colSpan={3}>
            <DevContactAnalysisDetail contactAnalysisId={mostRecentAnalysis!.id} />
          </Table.Td>
        </Table.Tr>
      )} */}
    </>
  );
}
