import { Link } from 'react-router-dom';
import { BankTransaction, BankTransactionAnalysisStateConclusion, BankTransactionAnalysisStateConclusionTypeEnum, Invoice, InvoiceAnalysisStateConclusion, InvoiceAnalysisStateConclusionTypeEnum } from '../../api/generated';
import { formatConjunctiveList, formatDateShort, formatNounForInvoiceType, formatNounForNormalizedDocument } from '../formatters';
import pluralize from 'pluralize';
import { Anchor } from '@mantine/core';

function getOutlierDescriptionIntro(fields: string[]) {
  return `The ${formatConjunctiveList(fields)} ${pluralize('field', fields.length)} ${pluralize('has', fields.length)} been identified as${fields.length === 1 ? ` a ` : ` `}${pluralize(`statistical outlier`, fields.length)}.`;
}

export function getDescriptionForInvoiceAnalysisConclusion(invoice: Invoice, conclusion: InvoiceAnalysisStateConclusion) {
  const data = conclusion.data as any;
  const meta = conclusion.meta as any;

  switch (conclusion.type) {
    case InvoiceAnalysisStateConclusionTypeEnum.DocumentIsOutlier:
    case InvoiceAnalysisStateConclusionTypeEnum.LineItemIsOutlier:
      return [
        getOutlierDescriptionIntro(meta.outliers.map((o: any) => o.field))
      ];
    case InvoiceAnalysisStateConclusionTypeEnum.PossibleDuplicate:
      const otherDocumentHrefRoot = (conclusion.data as any).duplicate.item.entity === 'NormalizedInvoice' ? 'invoices' : 'bank-transactions';
      const otherDocumentDate = formatDateShort((conclusion.data as any).duplicate.item.date);
      const otherDocumentRef = (conclusion.data as any).duplicate.item.reference || (conclusion.data as any).duplicate.item.invoiceNumber;
      const otherDocumentNoun = formatNounForNormalizedDocument((conclusion.data as any).duplicate.item);
      const otherDocumentDescriptor = `another ${otherDocumentNoun} dated ${otherDocumentDate}${otherDocumentRef ? `, ref ${otherDocumentRef}` : ''}`;
      return [
        `This ${formatNounForInvoiceType(invoice.type!, false)} is a possible duplicate of ${otherDocumentDescriptor}.`,
        <Anchor inherit component={Link} to={`../${otherDocumentHrefRoot}/${(conclusion.data as any).duplicate.item.extId}`}>View the other {otherDocumentNoun}</Anchor>
      ];
    case InvoiceAnalysisStateConclusionTypeEnum.BankDetailsChanged:
      return [
        `Bank details for this ${formatNounForInvoiceType(invoice.type!, false)} have changed`
      ];
    default: 
      return [
        `Unknown`
      ];
  }
}


export function getDescriptionForBankTransactionAnalysisConclusion(bankTransaction: BankTransaction, conclusion: BankTransactionAnalysisStateConclusion) {
  const data = conclusion.data as any;
  const meta = conclusion.meta as any;
  switch (conclusion.type) {
    case BankTransactionAnalysisStateConclusionTypeEnum.DocumentIsOutlier:
    case BankTransactionAnalysisStateConclusionTypeEnum.LineItemIsOutlier:
      return [
        getOutlierDescriptionIntro(meta.outliers.map((o: any) => o.field))
      ];
    case BankTransactionAnalysisStateConclusionTypeEnum.PossibleDuplicate:
      const otherDocumentHrefRoot = (conclusion.data as any).duplicate.item.entity === 'NormalizedInvoice' ? 'invoices' : 'bank-transactions';
      const otherDocumentDate = formatDateShort((conclusion.data as any).duplicate.item.date);
      const otherDocumentRef = (conclusion.data as any).duplicate.item.reference || (conclusion.data as any).duplicate.item.invoiceNumber;
      const otherDocumentNoun = formatNounForNormalizedDocument((conclusion.data as any).duplicate.item);
      const otherDocumentDescriptor = `another ${otherDocumentNoun} dated ${otherDocumentDate}${otherDocumentRef ? `, ref ${otherDocumentRef}` : ''}`;
      return [
        `This transaction is a possible duplicate of ${otherDocumentDescriptor}.`,
        <Anchor inherit component={Link} to={`../${otherDocumentHrefRoot}/${(conclusion.data as any).duplicate.item.extId}`}>View the other {otherDocumentNoun}</Anchor>
      ];
    default:
      return [`Unknown`];
  }
}
