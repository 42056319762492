import { Title, Text, Container } from '@mantine/core';
import classes from './HeroText.module.css';

export function HeroText({ tenantName }: { tenantName: string }) {
  return (
    <Container className={classes.wrapper} fluid>
        <Title className={classes.title}>           
          Analysis Dashboard for
          <br/>
          <Text component="span" className={classes.highlight} inherit>
           {tenantName}
          </Text>{' '}    
        </Title>

        <Container p={0} size={600}>
          <Text size="lg" c="dimmed" className={classes.description}>
            Your dashboard provides an overview of recent analysis activity we've carried out and highlights any significant findings or critical information.
          </Text>
        </Container>

       
    </Container>
  );
}