import { Badge, Modal, ScrollArea, Space, Text, ThemeIcon, Timeline, Title } from '@mantine/core';
import { Message, MessageSeverityEnum } from '../../api/generated';

import classes from './MessageModal.module.css';
import { IconCheck } from '@tabler/icons-react';
import React, { useEffect } from 'react';
import { formatDateTimeDynamic } from '../../lib/formatters';
import { InitialAnalysisCompletedTemplate } from './templates/InitialAnalysisCompletedTemplate';
import { WelcomeTemplate } from './templates/WelcomeTemplate';
import { MutationKey, QueryKey } from '../../queryKeys';
import { apiClient } from '../../api/apiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { NewCriticalAlertTemplate } from './templates/NewCriticalAlertTemplate';

interface MessageModalProps {
  opened: boolean;
  onClose: () => void;
  message: Message;
}


const getBadgePropsForMessage = (message: Message) => {
  switch (message.severity) {
    case MessageSeverityEnum.Critical:
      return { variant: message.resolved ? 'light' : 'filled', color: 'red.7', children: [message.resolved ? (message.resolvedByUser ? 'Dismissed' : 'Resolved') : 'Critical'] };
    case MessageSeverityEnum.Important:
      return { color: 'orange.5', children: ['Important'] };
    case MessageSeverityEnum.Advisory:
      return { color: 'blue', children: ['Advisory'] };
    case MessageSeverityEnum.General:
      return { variant: 'light', color: 'gray.8', children: ['General'] };
  }
}


export function MessageModal({ opened, onClose, message }: MessageModalProps) {
  // todo refactor the styles!

  const queryClient = useQueryClient();

  const { mutate: setRead } = useMutation({
    mutationKey: [MutationKey.SetMessageRead],
    mutationFn: async (messageId: string) => {
      const res = await apiClient.message.setMessageRead(messageId);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.GetMessages] });
    }
  });

  useEffect(() => {
    if (opened && !message.read) {
      setRead(message.id);
    }
  }, [opened, message.id, message.read, setRead]);

  let body: React.ReactNode;
  if (message.contentBody) {
    body = message.contentBody.map((p, i) => <Text key={i} fz="sm">{p}</Text>);
  }
  if (message.contentTemplate) {
    switch (message.contentTemplate) {
      // todo : enum
      case 'initial-analysis-completed':
        body = (
          <InitialAnalysisCompletedTemplate message={message} />
        );
      break;
      case 'welcome':
        body = (
          <WelcomeTemplate message={message} />
        );
      break;
      case 'new-critical-alert':
        body = (
          <NewCriticalAlertTemplate message={message} />
        );
      break;
      default:
        throw new Error(`Unrecognised template ${message.contentTemplate}`);
    }
  }


  return (
    <Modal.Root
      opened={opened}
      onClose={onClose}  
      className={classes.modalRoot}    
      centered
    >
      <Modal.Overlay />
      <Modal.Content className={classes.modalContent} radius="md" pt="lg">
        <div className={classes.root}>

          <div className={classes.top}>
              <Title order={3}>{message.heading}</Title>
            {/* <Group mt="md" gap="xs">
              {icon}
              <Text fw={500}>{capitalize(message.severity)}</Text>
            </Group> */}
            <Badge mt="md" size="lg" {...getBadgePropsForMessage(message)} />
          </div>

          <div className={classes.bottom}>

              <div className={classes.left}>
                <Text fz="md" fw={500} className={classes.bodyHeading}>Message</Text>
                  <div className={classes.bodyContent}>
                    <ScrollArea.Autosize scrollbars="y" mah="100%">
                      <>
                      <Space h="md"/>
                      {body}
                      </>
                    </ScrollArea.Autosize>
                  </div>
              </div>              
            

            <div className={classes.border} />

            <div className={classes.right}>
              <Text fz="md" fw={500} className={classes.bodyHeading} >Timeline</Text>

              <div className={classes.timeline}>
                <Timeline active={2} bulletSize={12} lineWidth={2}>
                  <Timeline.Item title="Message Delivered" fz="sm">
                    <Text size="xs" mt={4}>{formatDateTimeDynamic(message.created)}</Text>
                  </Timeline.Item>

                  <Timeline.Item lineVariant="dotted" title="Read" fz="sm">
                    <Text size="xs" mt={4}>{formatDateTimeDynamic(message.read || dayjs().format())}</Text>
                  </Timeline.Item>

                  <Timeline.Item 
                    fz="sm"                     
                    title="No Action Required"
                    bullet={
                      <ThemeIcon
                      size={18}                      
                      variant="default"
                      radius="xl"
                    >
                      <IconCheck color="var(--mantine-color-blue-6)" size="0.8rem" />
                    </ThemeIcon>
                    }
                  >
                  </Timeline.Item>
                </Timeline>
              </div>
            </div>

          </div>


        </div>

      </Modal.Content>
    </Modal.Root>
  );
}
