import { Text, Group, Paper, Divider, Center, Stack, rem } from '@mantine/core';
import { Contact, ContactAnalysisState, ContactAnalysisStateConclusion, ContactAnalysisStateConclusionTypeEnum, SeekCompanyResult } from '../../api/generated';
import { getCompanyMatchStateFromContactAnalysisConclusions } from '../../lib/contactAnalysis/getCompanyMatchStateFromContactAnalysisConclusions';
import { groupBy, keyBy } from 'lodash';
import classes from './ContactDetailAnalysisConclusionList.module.css';
import { ContactDetailAnalysisConclusionListItem } from './ContactDetailAnalysisConclusionListItem';
import { IconZzz } from '@tabler/icons-react';


export function ContactDetailAnalysisConclusionList({ contact, contactAnalysisState }: { contact: Contact, contactAnalysisState: ContactAnalysisState | undefined }) {

  // compute what we actually want to display
  const { uncertainMatches, satisfactoryMatch } = getCompanyMatchStateFromContactAnalysisConclusions(contactAnalysisState?.conclusions || []);

  const relevantCompaniesByCorrelationId = keyBy((!satisfactoryMatch && uncertainMatches) || [satisfactoryMatch], 'correlationId');

  const companyConclusions = groupBy(
    contactAnalysisState?.conclusions?.filter(c =>
      Object.keys(relevantCompaniesByCorrelationId).includes(c.correlationId || '')
      && [
        ContactAnalysisStateConclusionTypeEnum.CompanyHasAccountsOverdue,
        ContactAnalysisStateConclusionTypeEnum.CompanyHasConfirmationStatementOverdue,
        ContactAnalysisStateConclusionTypeEnum.CompanyHasArchivalStatus,
        ContactAnalysisStateConclusionTypeEnum.CompanyHasDistressedStatus,
        ContactAnalysisStateConclusionTypeEnum.CompanyHasOnlyOneOfficer,
        ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersAppointedInPast6Months,
        ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersResidingOverseas,
        ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersWithDisqualifications,
        ContactAnalysisStateConclusionTypeEnum.CompanyHasPoBoxAddress,
        ContactAnalysisStateConclusionTypeEnum.CompanyHasPreviousNamesInPast12Months,
        ContactAnalysisStateConclusionTypeEnum.CompanyIsLessThan12MonthsOld,
        ContactAnalysisStateConclusionTypeEnum.CompanyIsLessThan24MonthsOld
      ].includes(c.type)
    ) || [],
    c => c.correlationId
  );

  const data = Object.entries(companyConclusions).map<[
    SeekCompanyResult,
    ContactAnalysisStateConclusion[]
  ]>(([correlationId, conclusions]) => {
    return [
      relevantCompaniesByCorrelationId[correlationId]!.data.seekCompanyResult!,
      conclusions
    ];
  });

  return (
    <Paper shadow="xs" radius="md" p="lg">
      <Group justify="space-between">
        <Text fz="lg" fw={500}>Alerts & Advisories</Text>
      </Group>
      <Divider mt="xs" />
      {data.length ? (
        <div className={classes.root}>          
            {data.map(([companyData, conclusions]) => (
              <>
                <div className={classes.subheadingItem}>                  
                    <Group align="center">
                      <Text fw={500}>Re:</Text>
                      <Text>{companyData.companyName}</Text>
                      <Text c="dimmed" fz="sm">{companyData.companyNumber}</Text>
                    </Group>
                </div>
                {conclusions.map(conclusion => (
                  <ContactDetailAnalysisConclusionListItem contact={contact} conclusion={conclusion} companyData={companyData} />
                ))}
              </>
            ))}
        </div>
      ) : (
        <Stack ta="center" h={rem(200)} justify="center">
          <Center mt="md">
            <IconZzz color="var(--mantine-color-gray-6)" style={{ height: rem(48), width: rem(48) }} stroke={1}/>            
          </Center>
          <Text c="dimmed" fz="sm" fw={700}>Nothing to report</Text>          
          <Text c="dimmed" fz="sm">We've not found anything about this contact to bring to your attention.<br/>If we detect changes that might be a cause for concern, we'll notify you straight away.</Text>
          <Text c="dimmed" fz="sm"></Text>
        </Stack>
      )}
    </Paper>
  );

}