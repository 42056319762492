import { Text, Container, Space, Title, Group, Button, Paper, Table, Center, Loader, Modal, Box, Stack, TextInput } from '@mantine/core';
import { useContext } from 'react';
import { UserContext } from '../../../userContext';
import { IconMailPlus } from '@tabler/icons-react';
import { MutationKey, QueryKey } from '../../../queryKeys';
import { apiClient } from '../../../api/apiClient';
import { useDisclosure } from '@mantine/hooks';
import { sortBy } from 'lodash';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CreatePracticeUserInviteDto, PracticeUserInvite, User } from '../../../api/generated';
import { ColleagueRow } from './ColleagueRow';
import { UnableToLoadAlert } from '../../UnableToLoadAlert';
import { isPracticeUserInvite, showGeneralErrorNotification } from '../../../lib/helpers';
import { isEmail, isNotEmpty, useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';


export function ColleaguesView() {

  const { activePractice } = useContext(UserContext);

  const queryClient = useQueryClient();

  const [inviteColleagueOpened, { open: openInviteColleague, close: closeInviteColleague }] = useDisclosure();

  const inviteColleagueForm = useForm({
    mode: 'uncontrolled',
    initialValues: {
      recipientEmail: '',
      recipientFirstName: '',
      recipientLastName: ''
    },
    validate: {
      recipientFirstName: isNotEmpty('First name is required'),
      recipientLastName: isNotEmpty('Last name is required'),
      recipientEmail: isEmail('Invalid email')
    }
  });

  const { mutate: createPracticeUserInvite, isPending: createPracticeUserInviteIsPending } = useMutation({
    mutationKey: [MutationKey.CreatePracticeUserInvite, activePractice?.id],
    mutationFn: async (values: CreatePracticeUserInviteDto) => {
      const res = await apiClient.practice.createPracticeUserInvite(activePractice!.id, values);
      return res.data;
    },
    onError: (error: any) => {
      console.log(error);
      if (error.response.data.code === 'DUPLICATE') {
        notifications.show({
          title: 'Invite not sent',
          message: 'A user or invite already exists for this email',
          color: 'red'        
        });
      } else {
        showGeneralErrorNotification();
      }      
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.GetPracticeUserInvites, activePractice?.id] });
      closeInviteColleague();
      inviteColleagueForm.reset();
    }
  });

  const { data: practiceUserInvites, isPending: practiceUserInvitesIsPending, isError: practiceUsersInvitesIsError } = useQuery({
    queryKey: [QueryKey.GetPracticeUserInvites, activePractice?.id],
    queryFn: async () => {
      const res = await apiClient.practice.getPracticeUserInvites(activePractice?.id!, true);
      return res.data;
    },
    enabled: !!activePractice
  });

  const { data: practiceUsers, isPending: practiceUsersIsPending, isError: practiceUsersIsError } = useQuery({
    queryKey: [QueryKey.GetPracticeUsers, activePractice?.id],
    queryFn: async () => {
      const res = await apiClient.practice.getPracticeUsers(activePractice?.id!);
      return res.data;
    },
    enabled: !!activePractice
  });


  const getRows = (practiceUsers: User[], practiceUserInvites: PracticeUserInvite[]) => {

    return sortBy([...practiceUsers, ...practiceUserInvites], practiceUserOrInvite => {
      if (isPracticeUserInvite(practiceUserOrInvite)) {
        return practiceUserOrInvite.recipientFirstName + ' ' + practiceUserOrInvite.recipientLastName;
      }
      return practiceUserOrInvite.firstName + ' ' + practiceUserOrInvite.lastName;
    }).map((practiceUser, idx) => (
      <ColleagueRow practiceUserOrInvite={practiceUser} key={idx} />
    ));
  }

  return (
    <>
      <Modal
        opened={inviteColleagueOpened}
        onClose={closeInviteColleague}
        closeOnClickOutside={false}
        title={<Text fw={500}>Invite Colleague</Text>}
        centered
        size="lg"
      >
        <Box p="lg">      
          <Text fz="sm" p="lg">An email will be sent to your colleague inviting them to join your practice account</Text>    
          <form onSubmit={inviteColleagueForm.onSubmit((values) => createPracticeUserInvite(values))}>
            <Stack p="lg">              
              <Group>
                <TextInput
                  label="First name"
                  required
                  placeholder="First name"
                  key={inviteColleagueForm.key('recipientFirstName')}
                  {...inviteColleagueForm.getInputProps('recipientFirstName')}
                />
                <TextInput
                  label="Last name"
                  required
                  placeholder="Last name"
                  key={inviteColleagueForm.key('recipientLastName')}
                  {...inviteColleagueForm.getInputProps('recipientLastName')}
                />
              </Group>
              <TextInput
                mt="lg"
                required
                label="Email"
                placeholder="Email"                
                key={inviteColleagueForm.key('recipientEmail')}
                {...inviteColleagueForm.getInputProps('recipientEmail')}
              />
              <Group justify="flex-end" mt="lg">
                <Button type="submit" loading={createPracticeUserInviteIsPending}>Invite</Button>
              </Group>
            </Stack>
          </form>
        </Box>
      </Modal>
      <Container size="xl" py="xl">
        <Title order={2}>Colleagues</Title>
        <Text mt="md" fz="lg" fw={700} c="dimmed">{activePractice?.name}</Text>

        <Space h="xl" />

        {practiceUsersIsError || practiceUsersInvitesIsError ? (
          <Center mt={250}>
            <UnableToLoadAlert />
          </Center>
        ) : practiceUsersIsPending || !practiceUsers || practiceUserInvitesIsPending || !practiceUsers ? (
          <Center mt={250}><Loader /></Center>
        ) : (
          <>
            <Group justify="flex-end" p="lg">
              <Button leftSection={<IconMailPlus size="22px" />} onClick={openInviteColleague}>Invite Colleague</Button>
            </Group>
            <Paper shadow="xs" radius="md">
              <Table verticalSpacing="md">
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Name</Table.Th>
                    <Table.Th>Email</Table.Th>
                    <Table.Th>Role</Table.Th>
                    <Table.Th>Status</Table.Th>
                    <Table.Th></Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{getRows(practiceUsers, practiceUserInvites)}</Table.Tbody>
              </Table>
            </Paper>
          </>
        )}

      </Container>
    </>
  );
}