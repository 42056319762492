
import { Avatar, Box, Button, Group, Text, rem } from '@mantine/core';
import classes from './UserListItem.module.css';
import { ClientUserInvite, User, XeroUser } from '../../api/generated';
import { getAvatarColorFromName } from '../../lib/getAvatarColorFromName';
import { getInitials } from '../../lib/getInitials';
import { formatDateTimeRelative, formatOrganisationRole } from '../../lib/formatters';
import { IconMailForward, IconUserPlus } from '@tabler/icons-react';

export function UserListItem({ 
  xeroUser, 
  meyseyUser, 
  clientUserInvite,
  createClientUserInvite,
  createClientUserInviteIsPending
}: { 
  xeroUser: XeroUser, 
  meyseyUser: User | undefined, 
  clientUserInvite: ClientUserInvite | undefined,
  createClientUserInvite: (xeroUser: XeroUser) => void,
  createClientUserInviteIsPending: boolean
}) {  



  return (
    <div className={classes.root}>


      <Avatar
        color={getAvatarColorFromName(xeroUser.lastName)}
        radius="xl"
      >{getInitials(`${xeroUser.firstName} ${xeroUser.lastName}`)}</Avatar>

      <div className={classes.nameEmailRole}>
        <Group>
          <Text fw={500}>{`${xeroUser.firstName} ${xeroUser.lastName}`}</Text>
          <Text >{xeroUser.emailAddress}</Text>
        </Group>
        <div>
          <Text fz="sm" c="dimmed">{formatOrganisationRole(xeroUser.organisationRole!)}</Text>
        </div>

      </div>

      {clientUserInvite && (
        <Box px="lg" w={rem(175)} ta="center">
          <Text fz="xs" c="dimmed">Invited {formatDateTimeRelative(clientUserInvite.created)}</Text>
        </Box>
      )}

      <Box w={rem(150)} ta="center">
        {meyseyUser ? (
          // <Button className={classes.connectedButton} size="xs" w={rem(140)} color="green.6" variant="outline" leftSection={<IconCheck size={14} />}>Connected</Button>
          <Text fw={500}>Connected</Text>
        ) : (
          <Button 
            size="xs" 
            variant={clientUserInvite ? 'default' : 'outline'} 
            onClick={() => createClientUserInvite(xeroUser)} 
            loading={createClientUserInviteIsPending} 
            leftSection={clientUserInvite ? <IconMailForward size={14} /> : <IconUserPlus size={14} />}>
              {clientUserInvite ? `Resend Invite` : `Invite to Meysey`}
          </Button>
        )}
      </Box>

    </div>
  );
}