import { useIdToken, useSendEmailVerification } from 'react-firebase-hooks/auth';
import { auth } from '../../../firebase';
import { Button, Center, Skeleton, Stack, Text, Title } from '@mantine/core';

import classes from './EmailVerificationCheckpoint.module.css';
import { UnableToLoadAlert } from '../../UnableToLoadAlert';
import { useEffect, useState } from 'react';
import { useInterval } from '@mantine/hooks';
import { Navigate, useLocation } from 'react-router-dom';
import { showGeneralErrorNotification } from '../../../lib/helpers';

export function EmailVerificationCheckpoint({ next, restart }: { next: string, restart: string }) {

  // todo - redirect somewhere if already verified email!

  const location = useLocation();

  const [user, authStateLoading, authStateError] = useIdToken(auth);

  const [sendEmailVerification, sendEmailVerificationLoading, sendEmailVerificationError] = useSendEmailVerification(auth);

  // todo the cooldown shoudl really live server side somewhere since i can just refresh to bypass it
  const [cooldown, setCooldown] = useState(0);
  const cooldownInterval = useInterval(() => {
    setCooldown((s) => {
      if (s <= 1) {
        cooldownInterval.stop();
        return 0;
      }
      return s - 1;
    });
  }, 1000);

  // think this is correct to cleanup?  
  useEffect(() => {    
    return cooldownInterval.stop;
  }, [cooldownInterval]);


  // reload user every 5 seconds to check if email is verified
  const userReloadInterval = useInterval(() => {
    user?.reload();
  }, 5000);

  useEffect(() => {
    userReloadInterval.start();
    return userReloadInterval.stop;
  }, [userReloadInterval]);



  // const previoussendEmailVerificationErrorRef = useRef<unknown>(null);

  useEffect(() => {
    // if (sendEmailVerificationError !== previoussendEmailVerificationErrorRef.current) {
    //   previoussendEmailVerificationErrorRef.current = sendEmailVerificationError;

    // if (!error) return;

    if (sendEmailVerificationError) {
      showGeneralErrorNotification();
    }
    // }

  }, [sendEmailVerificationError]);

  if (!user) {
    return <Navigate to={restart} />;
  }


  if (user.emailVerified) {
    return <Navigate to={next} state={location.state} />;
  }

  const handleClick = async () => {
    await sendEmailVerification();
    setCooldown(60);
    cooldownInterval.start();
  }

  return (
    <div className={classes.root}>
      {authStateLoading ? (
        <>
          <Skeleton animate h={10} mt={6} />
          <Skeleton animate h={10} mt={6} />
          <Skeleton animate h={10} mt={6} />
          <Skeleton animate h={10} mt={6} />
        </>
      ) : authStateError ? (
        <UnableToLoadAlert />
      ) : (
        <Stack>
          <Title ta="center" order={4}>Verify your email address</Title>
          <Text mt="md" ta="center">
            A verification email has been sent to <strong>{user?.email}</strong>
            <br />
            Follow the instructions in the email to verify your email address
          </Text>
          <Center mt="sm">
            <Button
              size="sm"
              variant="subtle"
              color="gray"
              onClick={handleClick}
              loading={sendEmailVerificationLoading}
              disabled={cooldown > 0}
            >
              {cooldown > 0 ? `Wait ${cooldown}s` : `Resend Email`}
            </Button>
          </Center>
        </Stack >
      )
      }

    </div >
  );
}
