import { Divider, Space, Text } from '@mantine/core';
import { ContactAnalysisStateConclusion, Message, NormalizedContact } from '../../../api/generated';
import { getDescriptionForContactAnalysisConclusion } from '../../../lib/contactAnalysis/getDescriptionForContactAnalysisConclusion';
import { getRecommendationForContactAnalysisConclusion } from '../../../lib/contactAnalysis/getRecommendationForContactAnalysisConclusion';
import { getAlertHeadingForContactAnalysisConclusion } from '../../../lib/contactAnalysis/getAlertHeadingForContactAnalysisConclusion';

export function NewCriticalAlertTemplate({ message }: { message: Message }) {
  const data: {
    conclusion: ContactAnalysisStateConclusion;
    xeroContactId?: string;
    normalizedContact?: NormalizedContact;  
    companyMatchConclusion?: ContactAnalysisStateConclusion;
  } = message.contentData as any;

  const heading = getAlertHeadingForContactAnalysisConclusion(data.conclusion, data.xeroContactId!, data.normalizedContact?.contactName!, data.companyMatchConclusion?.data.seekCompanyResult?.companyName);
  const description = getDescriptionForContactAnalysisConclusion(data.conclusion, data.companyMatchConclusion?.data.seekCompanyResult?.companyName);
  const recommendation = getRecommendationForContactAnalysisConclusion(data.conclusion, data.companyMatchConclusion?.data.seekCompanyResult?.companyName);

  return <>
    <Text fw={700}>{heading}</Text>
    <Space h="md" />
    <Text fw={500}>Findings</Text>      
    <Divider mb="sm" />
    {description.map((text, i) => <Text key={i}>{text}</Text>)}

    <Space h="md" />
    <Text fw={500}>Recommendation</Text>
    <Divider mb="sm" />
    {recommendation.map((text, i) => <Text key={i}>{text}</Text>)}
    
  </>;
}