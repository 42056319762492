import { Alert } from '@mantine/core';
import { IconPlugX } from '@tabler/icons-react';


export function UnableToLoadAlert() {
  return (
    <Alert
      variant="transparent"
      color="red"
      title="Unable to load content"
      icon={<IconPlugX />}
      maw={400}
    >
      We're currently experiencing issues displaying some content. Please check your connection and try refreshing the page.
      If the problem persists, contact our support team for assistance.
    </Alert>
  );

}