import { Skeleton, Table } from '@mantine/core';
import { formatDateMedium, formatCurrency } from '../../lib/formatters';
import { Invoice } from '../../api/generated';
import { QueryKey } from '../../queryKeys';
import { apiClient } from '../../api/apiClient';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { sortBy } from 'lodash';
import dayjs from 'dayjs';


export function XeroInvoiceRecordPaymentRow({ invoice, paymentId }: { invoice: Invoice, paymentId: string }) {

  
  const { data: payment } = useQuery({
    queryKey: [QueryKey.GetPayment, paymentId],
    queryFn: async () => {
      const res = await apiClient.xero.getPayment(paymentId);
      return res.data;
    }
  });
  
  const { data: paymentHistory } = useQuery({
    queryKey: [QueryKey.GetPaymentHistory, paymentId],
    queryFn: async () => {
      const res = await apiClient.xero.getPaymentHistory(paymentId);
      return res.data;
    },
    enabled: !!payment
  });


  const reconciliationHistoryRecord = sortBy(paymentHistory || [], h => -dayjs(h.dateUTC).unix()).find(h => h.changes === 'Reconciled');

  const statusContent = !payment || !paymentHistory ? (
    <Skeleton h="1rem" animate />
   ) : (
    payment.isReconciled ? (
       `Reconciled by ${reconciliationHistoryRecord?.user} on ${formatDateMedium(reconciliationHistoryRecord?.dateUTC!)}`
    ) : (
      `Unreconciled`
    )
   );

   console.log(paymentHistory);
  

  return (
    <Table.Tr>
      <Table.Td>
        {!payment ? <Skeleton h="1rem" animate /> : formatDateMedium(payment.date!)}
      </Table.Td>
      <Table.Td ta="right">
        {!payment ? <Skeleton h="1rem" animate /> : formatCurrency(payment.amount!, invoice.currencyCode)}
      </Table.Td>
      <Table.Td>
        {statusContent}
      </Table.Td>
    </Table.Tr>
  );
}