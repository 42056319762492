import { AppShell, Burger, Menu, useMantineTheme, Group, Button } from '@mantine/core';
import React, { useContext } from 'react';
import logo from '../logo.svg';
import { ConnectXeroButton } from './ConnectXeroButton';
import { UserContext } from '../userContext';
import { IconChevronDown } from '@tabler/icons-react';
import { apiClient } from '../api/apiClient';
import { MutationKey, QueryKey } from '../queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useSignOut } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';

interface HeaderProps {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Header({ opened, setOpened }: HeaderProps) {

  const theme = useMantineTheme();

  const { user, activeClient, clients, isLoading: userContextIsLoading, activePractice } = useContext(UserContext);

  const [firebaseSignOut] = useSignOut(auth);

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  
  const { mutate: setActiveClient, isPending: setActiveClientIsPending } = useMutation({
    mutationKey: [MutationKey.SetActiveClient], 
    mutationFn: async (clientId: string) => {
      const res = await apiClient.client.setActiveClient({ clientId }); 
      return res.data;
    },
    onSuccess: (data) => {      
      queryClient.setQueryData([QueryKey.GetActiveClient, user!.id], data);
      navigate('/');
    }
  });

  const { mutate: clearActiveClient } = useMutation({
    mutationKey: [MutationKey.ClearActiveClient], 
    mutationFn: async () => {
      const res = await apiClient.client.clearActiveClient(); 
      return res.data;
    },
    onSuccess: (data) => {      
      queryClient.setQueryData([QueryKey.GetActiveClient, user!.id], null);
      navigate('/practice');
    }
  });

  const { mutate: logout, isPending: logoutIsPending } = useMutation({
    mutationKey: [MutationKey.Logout], 
    mutationFn: async () => {
      const isPracticeUser = !!user?.practiceId;
      await firebaseSignOut();
      await apiClient.user.logout();       
      // todo this isn't seamless
      if (isPracticeUser) navigate('/practice');
    },
    onSuccess: () => {      
      queryClient.invalidateQueries();
    }
  });

  return (
    <>
    <AppShell.Header p="md">
      <div style={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'space-between' }}>
        <Burger
          hiddenFrom="sm"
          opened={opened}
          onClick={() => setOpened((o) => !o)}
          size="sm"
          color={theme.colors.gray[6]}
          mr="xl"
        />
        <img src={logo} style={{ height: '100%' }} alt="logo" />        
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {user && activeClient && clients && (            
            <Menu shadow="xs">
              <Menu.Target>
                <Group gap="xs">
                  <Button size="md" variant="white" loading={setActiveClientIsPending || logoutIsPending} rightSection={<IconChevronDown size={14} />}>{activeClient.name}</Button>
                </Group>
              </Menu.Target>

              <Menu.Dropdown>
                {activePractice && (
                  <>
                    <Menu.Label>Your Practice</Menu.Label>                      
                    <Menu.Item key={activePractice.id} onClick={() => clearActiveClient()}>
                      {activePractice.name}
                    </Menu.Item>
                  </>
                )}
                <Menu.Label>Your Organisations</Menu.Label>
                {clients.map(client => (
                  <Menu.Item key={client.id} onClick={() => setActiveClient(client.id)}>
                    {client.name}
                  </Menu.Item>
                ))}
                <Menu.Divider />
                <Menu.Item onClick={() => logout()}>
                  Log Out
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )} 
          {user && !activeClient && (
          <>
            <Button variant="transparent" onClick={() => logout()} loading={logoutIsPending}>Log Out</Button>
          </>
          )}
          {!userContextIsLoading && !user && <>
            <ConnectXeroButton visibleFrom="sm" size="md" type="client">Connect Xero</ConnectXeroButton>
            <ConnectXeroButton hiddenFrom="sm" size="xs" type="client">Connect Xero</ConnectXeroButton>
          </>
          }
        </div>
      </div>
    </AppShell.Header>
    </>
  );
}
