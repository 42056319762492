import { Paper, Table } from '@mantine/core';
import { BankTransaction } from '../../api/generated';
import { BankTransactionsHeaderRow, BankTransactionsRow } from './BankTransactionsRow';
import { useNavigate } from 'react-router-dom';

export function BankTransactionsTable({ bankTransactions }: { bankTransactions: BankTransaction[] }) {
  
  const navigate = useNavigate();

  return (
    <>
      <Paper shadow="xs" radius="md">
        <Table verticalSpacing="md" highlightOnHover>
          <Table.Thead>
            <BankTransactionsHeaderRow />
          </Table.Thead>
          <Table.Tbody>
            {bankTransactions.map((bankTransaction, idx) => <BankTransactionsRow key={idx} bankTransaction={bankTransaction} onClick={(() => navigate(`bank-transactions/${bankTransaction.bankTransactionID}`))} />)}
          </Table.Tbody>
        </Table>
      </Paper>
    </>
  )

}