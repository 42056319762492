import { useEffect } from 'react';
import { AdobeDcClient } from '../lib/adobeDcClient';

interface AdobePdfViewerProps {   
  url: string, 
  fileName: string, 
  fileId: string,
  className?: string;
  height?: number;
  width?: number;
};

export function AdobePdfViewer({ url, fileName, fileId, height, width, className }: AdobePdfViewerProps) {


  useEffect(() => {
    const adobeDcClient = new AdobeDcClient();
    // https://developer.adobe.com/document-services/docs/overview/pdf-embed-api/howtos_ui/#menu-and-tool-options
    adobeDcClient.ready().then(() => {
      adobeDcClient.previewFile({ divId: `adobe-dc-view-${fileId}`, url, fileName, fileId, viewerConfig: { 
        showPageControls: false, 
        showAnnotationTools: false ,
        showThumbnails: false,
        showBookmarks: false,
        showDownloadPDF: true,
        showPrintPDF: false,
        showFullScreen: true,
        // showZoomControl: true,
        //  <FULL_WINDOW, SIZED_CONTAINER, IN_LINE OR LIGHT_BOX>
        // in IN_LINE, cannot specify height!
        embedMode: `SIZED_CONTAINER`
      } });
    });
  });

  const divId = `adobe-dc-view-${fileId}`;

  return (
    <div id={divId} className={className} style={{ height, width }} />
  )
}