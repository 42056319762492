import { Text, Group, Paper, Divider, Center, Stack, rem } from '@mantine/core';
import { Invoice, InvoiceAnalysisState, InvoiceAnalysisStateConclusionTypeEnum } from '../../api/generated';
import { IconZzz } from '@tabler/icons-react';
import classes from './InvoiceDetailAnalysisConclusionList.module.css';
import { InvoiceDetailAnalysisConclusionListItem } from './InvoiceDetailAnalysisConclusionListItem';

export function InvoiceDetailAnalysisConclusionList({ invoice, invoiceAnalysisState }: { invoice: Invoice, invoiceAnalysisState: InvoiceAnalysisState | undefined }) {
  
  const data = (invoiceAnalysisState?.conclusions || []).filter(c => ![InvoiceAnalysisStateConclusionTypeEnum.AttachmentDataExtracted].includes(c.type));

  return (
    <Paper id="alerts" shadow="xs" radius="md" p="lg">
      <Group justify="space-between">
        <Text fz="lg" fw={500}>Alerts & Advisories</Text>
      </Group>
      <Divider mt="xs" />
        {data.length ? (
        <div className={classes.root}>          
            {data.map((conclusion) => (
              <>
                {/*
                could group by line item if wanted...
                 <div className={classes.subheadingItem}>                  
                    <Group align="center">
                      <Text fw={500}>Re:</Text>
                      <Text>{companyData.companyName}</Text>
                      <Text c="dimmed" fz="sm">{companyData.companyNumber}</Text>
                    </Group>
                </div> */}
                {/* {conclusions.map(conclusion => ( */}
                  <InvoiceDetailAnalysisConclusionListItem invoice={invoice} conclusion={conclusion} />
                {/* ))} */}
              </>
            ))}
        </div>
      ) : (
      <Stack ta="center" h={rem(200)} justify="center">
          <Center mt="md">
            <IconZzz color="var(--mantine-color-gray-6)" style={{ height: rem(48), width: rem(48) }} stroke={1}/>            
          </Center>
          <Text c="dimmed" fz="sm" fw={700}>Nothing to report</Text>          
          <Text c="dimmed" fz="sm">We've not found anything about this invoice to bring to your attention.<br/>If we detect changes that might be a cause for concern, we'll notify you straight away.</Text>
          <Text c="dimmed" fz="sm"></Text>
        </Stack>
        )}
    </Paper>
  );

}