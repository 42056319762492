import { Alert } from '@mantine/core';
import { IconLock } from '@tabler/icons-react';


export function NoClientAccessAlert() {
  return (
    <Alert
      variant="transparent"
      color="red"
      title="You don't have access to this client"
      icon={<IconLock />}
      maw={400}
    >
      You don't have access to this client. Please request access from the appropriate colleague.
    </Alert>
  );

}