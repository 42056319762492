import { Text, Container, Stepper, Center, Loader, Stack, Select, Group, Button } from '@mantine/core';
import { useContext, useState } from 'react';
import { MutationKey, QueryKey } from '../../../queryKeys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiClient } from '../../../api/apiClient';
import { UnableToLoadAlert } from '../../UnableToLoadAlert';
import { UserContext } from '../../../userContext';
import { UserList } from '../../userSettings/UserList';
import { showGeneralErrorNotification } from '../../../lib/helpers';

export function CompleteXeroIntegrationSteps({ onClose, practiceId, clientId }: { onClose: () => void, practiceId: string, clientId: string }) {

  const queryClient = useQueryClient();
  const { user } = useContext(UserContext);
  const [activeStep, setActiveStep] = useState(0);

  const [selectedTenantConnection, setSelectedTenant] = useState<string | null>(null);

  const prevStep = () => {
    setActiveStep(s => s - 1);
  };

  const nextStep = () => {
    setActiveStep(s => s + 1);
  };

  const { data: practiceUsers, isPending: practiceUsersIsPending, isError: practiceUsersIsError } = useQuery({
    queryKey: [QueryKey.GetPracticeUsers, practiceId],
    queryFn: async () => {
      const res = await apiClient.practice.getPracticeUsers(practiceId);
      return res.data;
    }
  });
  

  const { data: client, isPending: clientPending, isError: clientError } = useQuery({
    queryKey: [QueryKey.GetClient, clientId],
    queryFn: async () => {
      const res = await apiClient.client.findClientById(clientId);
      return res.data;
    }
  });

  const { data: userXeroTenantConnections, isPending: userXeroTenantConnectionsIsPending, isError: userXeroTenantConnectionsError } = useQuery({
    queryKey: [QueryKey.GetTenants, user?.id],
    queryFn: async () => {
      const res = await apiClient.xero.getUserXeroTenantConnections();
      return res.data;
    },
    enabled: !!user
  });

  const { mutate: setXeroTenantConnectionClient, isPending: setXeroTenantConnectionClientPending } = useMutation({
    mutationKey: [MutationKey.SetClientXeroTenant, clientId],
    mutationFn: async (xeroTenantConnectionId: string) => {
      const res = await apiClient.xero.setXeroTenantConnectionClient(xeroTenantConnectionId, { clientId });
      return res.data;
    },
    onError: () => {
      showGeneralErrorNotification();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.GetClientXeroTenantConnection, clientId] });
      nextStep();
    }
  });

  return (
    <Container pt="lg" px="lg">
      <Stepper active={activeStep} allowNextStepsSelect={false} size="sm" iconSize={28}>
        <Stepper.Step label="Connect Xero">
          <Stack>
            {clientPending || userXeroTenantConnectionsIsPending ? (
              <>
                <Center p="xl">
                  <Loader />
                </Center>
              </>) : (!client || clientError || !userXeroTenantConnections || userXeroTenantConnectionsError) ? (
                <Center p="xl">
                  <UnableToLoadAlert />
                </Center>
              ) : (
              <Stack>
                <Text mt="xl" ta="center">Select a Xero Organisation to connect to <strong>{client.name}</strong></Text>
                <Center mb="xl">
                  <Select w="80%"
                    placeholder="Select a Xero Organisation"
                    data={userXeroTenantConnections.filter(tc => !tc.clientId).map(tenantConnection => ({ label: tenantConnection.tenantName!, value: tenantConnection.id }))}
                    value={selectedTenantConnection}
                    onChange={setSelectedTenant}
                  />
                </Center>
                <Group justify="flex-end">
                  <Button mt="lg" disabled={!selectedTenantConnection} onClick={() => setXeroTenantConnectionClient(selectedTenantConnection!)} loading={setXeroTenantConnectionClientPending}>Continue</Button>
                </Group>
              </Stack>
            )}
          </Stack>
        </Stepper.Step>
        <Stepper.Step label="Invite Client Users">
          <Stack>
            {clientPending || userXeroTenantConnectionsIsPending || practiceUsersIsPending ? (
              <>
                <Center p="xl">
                  <Loader />
                </Center>
              </>) : (!client || clientError || !userXeroTenantConnections || userXeroTenantConnectionsError || !practiceUsers || practiceUsersIsError) ? (
                <Center p="xl">
                  <UnableToLoadAlert />
                </Center>
              ) : (
              <Stack>
                <Text mt="xl" ta="center">Invite <strong>{client.name}</strong>'s Xero Users to use Meysey (optional)</Text>
                <Center mb="xl">
                  <UserList client={client} excludeUsers={practiceUsers} excludeAdvisors />                
                </Center>
                <Group justify="flex-end">
                  <Button mt="lg" onClick={onClose}>I'm done</Button>
                </Group>
              </Stack>
            )}
          </Stack>
        </Stepper.Step>
      </Stepper>
    </Container>
  );
}