import { Space, Text, Title } from '@mantine/core';
import classes from './MonitoringStatsGroup.module.css';
import { useCountUp } from 'use-count-up';
import { TenantAnalysisStats } from '../../api/generated';


export function MonitoringStatsGroup({ stats }: { stats: TenantAnalysisStats }) {


  const countUpCfg = {
    thousandsSeparator: ',',
    isCounting: true
  };

  const { value: contactsCount } = useCountUp({
    end: stats.contactCount,
    ...countUpCfg
  });

  const { value: supplierCount } = useCountUp({
    end: stats.supplierCount,
    ...countUpCfg
  });

  const { value: customerCount } = useCountUp({
    end: stats.customerCount,
    ...countUpCfg
  });

  const { value: unclassifiedCount } = useCountUp({
    end: stats.contactCount - stats.supplierCount - stats.customerCount,
    ...countUpCfg
  });

  const { value: companyCount } = useCountUp({
    end: stats.companyCount,
    ...countUpCfg
  });

  const { value: officersCount } = useCountUp({
    end: stats.officersCount,
    ...countUpCfg
  });

  const { value: invoiceCount } = useCountUp({
    end: stats.invoiceCount,
    ...countUpCfg
  });

  const { value: billCount } = useCountUp({
    end: stats.billCount,
    ...countUpCfg
  });

  const { value: billAndInvoiceCount } = useCountUp({
    end: stats.invoiceCount + stats.billCount,
    ...countUpCfg
  });


  const { value: otherTransactionCount } = useCountUp({
    end: stats.otherTransactionCount,
    ...countUpCfg
  });

  
  const { value: allUserCount } = useCountUp({
    end: stats.readonlyUserCount + stats.standardUserCount + stats.advisorUserCount,
    ...countUpCfg
  });

  const { value: readonlyUserCount } = useCountUp({
    end: stats.readonlyUserCount,
    ...countUpCfg
  });

  const { value: standardUserCount } = useCountUp({
    end: stats.standardUserCount,
    ...countUpCfg
  });

  const { value: advisorUserCount } = useCountUp({
    end: stats.advisorUserCount,
    ...countUpCfg
  });


  return <div className={classes.root}>
    <Title ta="center" order={3}>Meysey is monitoring</Title>
    <Space h="xl" />
    <div className={classes.statsRoot}>
      <div className={classes.statsRow}>
        <div className={classes.stat}>
          <Text className={classes.count}>{contactsCount}</Text>
          <Text className={classes.title}>Xero Contacts</Text>
          <Text className={classes.description}>{supplierCount} Suppliers, {customerCount} Customer, {unclassifiedCount} Unclassified</Text>
        </div>
        <div className={classes.stat}>
          <Text className={classes.count}>{companyCount}</Text>
          <Text className={classes.title}>Company Records</Text>
          <Text className={classes.description}>that we've matched to your Xero Contacts via Companies House and other sources</Text>
        </div>
        <div className={classes.stat}>
          <Text className={classes.count}>{officersCount}</Text>
          <Text className={classes.title}>Company Officers</Text>
          <Text className={classes.description}>for new disqualifications and other factors that may indicate increased risk of fraud</Text>
        </div>
      </div>
      <div className={classes.statsRow}>
        <div className={classes.stat}>
          <Text className={classes.count}>{billAndInvoiceCount}</Text>
          <Text className={classes.title}>Bills & Invoices</Text>
          <Text className={classes.description}>analysed for indicators of intentional or unintentional wrongdoing</Text>
        </div>
        <div className={classes.stat}>
          <Text className={classes.count}>{otherTransactionCount}</Text>
          <Text className={classes.title}>Other Transactions</Text>
          <Text className={classes.description}>Spend & Receive Money transactions that are not attributed to a Xero Bill or Invoice are included in analysis</Text>
        </div>
        <div className={classes.stat}>
          <Text className={classes.count}>{allUserCount}</Text>
          <Text className={classes.title}>Xero Users</Text>
          {/* todo pluralize */}
          <Text className={classes.description}>{standardUserCount} Standard, {advisorUserCount} Advisor and {readonlyUserCount} Read Only</Text>
        </div>
      </div>
    </div>
  </div>;
}