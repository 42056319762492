import { Text, Container, Space, Title } from '@mantine/core';
import { ContactsTable } from './ContactsTable';

export function SuppliersAndCustomersView() {


  return (
    <Container size="xl" py="xl">
      <Title order={2}>Suppliers & Customers</Title>
      <Text mt="md">
        Here you'll find the results of the analysis conducted on your Xero Suppliers and Customers. To view detailed findings, simply select a contact.
      </Text>
      <Space h="xl" />      
    
      <ContactsTable />
      
    </Container>
  );
}