

import { Text, Modal, Tabs, Center, Loader, Box, Stack, Button, Group } from '@mantine/core';
import { Client } from '../../../api/generated';
import { UserList } from '../../userSettings/UserList';
import { useContext } from 'react';
import { UserContext } from '../../../userContext';
import { MutationKey, QueryKey } from '../../../queryKeys';
import { apiClient } from '../../../api/apiClient';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UnableToLoadAlert } from '../../UnableToLoadAlert';
import { notifications } from '@mantine/notifications';

export function EditXeroIntegrationModal({ opened, onClose, client }: { opened: boolean, onClose: () => void, client: Client }) {

  const { activePractice } = useContext(UserContext);

  const queryClient = useQueryClient();

  const { data: practiceUsers, isPending: practiceUsersIsPending, isError: practiceUsersIsError } = useQuery({
    queryKey: [QueryKey.GetPracticeUsers, activePractice!.id],
    queryFn: async () => {
      const res = await apiClient.practice.getPracticeUsers(activePractice!.id);
      return res.data;
    }
  });

  
  const { data: xeroTenantConnection, isError: xeroTenantConnectionIsError, isPending: xeroTenantConnectionIsPending } = useQuery({
    queryKey: [QueryKey.GetClientXeroTenantConnection, client.id],
    queryFn: async () => {
      const res = await apiClient.xero.getClientXeroTenantConnection(client.id);
      return res.data;
    }
  });

  
  const { mutate: disconnectXero, isPending: disconnectXeroIsPending } = useMutation({
    mutationKey: [MutationKey.DisconnectXero],
    mutationFn: async () => {
      const res = await apiClient.xero.disconnectXeroByTenantId(xeroTenantConnection!.xeroTenantId);
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.GetClientXeroTenantConnection, client.id]
      });
      notifications.show({
        radius: 'md',
        color: 'green',
        title: `Xero disconnected`,
        message: `Meysey has been disconnected from this Xero organisation.`,
      });
      onClose();
    },
    onError: () => {
      notifications.show({
        radius: 'md',
        color: 'red',
        title: `Xero could not be disconnected`,
        message: `Something went wrong disconnecting Meysey from this Xero organisation. Please try again later.`,
      });
    }
  });

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      closeOnClickOutside={false}
      title={<Text fw={500}>Editing Xero Integration for {client.name}</Text>}
      centered
      size="xl"
    >
      <Tabs defaultValue="connection">
        <Tabs.List>
          <Tabs.Tab value="connection">
            Connection
          </Tabs.Tab>
          <Tabs.Tab value="users">
            Xero Users
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="connection">
          {xeroTenantConnectionIsPending ? (
            <Center mt="xl">
              <Loader />
            </Center>
          ) : xeroTenantConnectionIsError ? (
            <UnableToLoadAlert />
          ) : xeroTenantConnection && (
            <Box mih="250px">
              <Stack p="lg">
                <Text mt="lg" ta="center" fw={500}>Connected to {xeroTenantConnection?.tenantName}</Text>
                <Center mt="lg">
                  <Button color="red" variant="outline" loading={disconnectXeroIsPending} onClick={() => disconnectXero()}>Disconnect</Button>
                </Center>
                <Text ta="center" mt="lg" fz="sm">Disconnecting Xero will deactivate fraud protection</Text>
              </Stack>
            </Box>
          )}
        </Tabs.Panel>

        <Tabs.Panel value="users">
          {practiceUsersIsPending ? (
            <Center mt="xl">
              <Loader />
            </Center>
          ) : practiceUsersIsError ? (
            <UnableToLoadAlert />
          ) : (
            <Box mih="250px">
              <UserList client={client} excludeAdvisors excludeUsers={practiceUsers} />
            </Box>
          )}
        </Tabs.Panel>
      </Tabs>

    </Modal>
  );


}