import { Anchor, Text, Title } from '@mantine/core';
import { Link } from 'react-router-dom';
import { Message } from '../../../api/generated';

export function WelcomeTemplate({ message }: { message: Message }) {
  const data: {
    tenantName: string;
  } = message.contentData as any;
  
  return (
    <>
      <Title order={3}>Welcome, {data.tenantName}</Title>
      <Text>
        We're thrilled to have you on board with Meysey, your new ally in enhancing your organization's financial security. Meysey is dedicated to providing you with advanced AI-driven insights, designed to keep your financial landscape secure and transparent.
      </Text>
      <Text>
        By connecting your Xero account, you've already taken a crucial step towards safeguarding your business against financial fraud.
      </Text>

      <Title order={4}>
        Getting Started with Meysey
      </Title>

      <Text>
        Your journey towards a more secure financial environment begins now.
      </Text>
      <ol>
        <li>
          <strong>Explore Your Dashboard</strong>
          <br/>
            Discover your <Anchor component={Link} to={'/'}>dashboard</Anchor>, offering an overview of the fraud detection activity and status.
        </li>
        <li>
          <strong>Initial Findings on the Way</strong>
          <br/>
          Our system is diligently analyzing your data to bring you actionable insights. We'll notify you as soon as your initial findings are prepared.
        </li>
        <li>
          <strong>Customise Your Alerts</strong>
          <br />
          Set your alert preferences to ensure you're informed about the aspects that matter most to your business. For guidance on personalizing your settings, click <Anchor href="TODO">here</Anchor>.
        </li>
      </ol>

      <Title order={4}>
        Support & Resources
      </Title>
      <Text>
        We're here to ensure your experience is as seamless and productive as possible.
      </Text>
      <ul>
        <li>Our <Anchor href="TODO">Help Center</Anchor> is your go-to for FAQs and tutorials.</li>
        <li>For direct assistance, our Support Team is available at <Anchor href="mailto:support@meysey.co.uk">support@meysey.co.uk</Anchor>.</li>
        <li>Keep up-to-date with <Anchor href="https://blog.meysey.co.uk">Meysey's Blog</Anchor> for insights into fraud prevention strategies and platform updates.</li>
      </ul>

      <Title order={4}>
        Your Feedback Matters
      </Title>
      <Text>
        As you begin to navigate Meysey, your insights are incredibly valuable to us. Your feedback will help us enhance our platform to better meet your needs.        
      </Text>
      <Text>
        Thank you for choosing Meysey as your partner in financial security. Together, we're on the path to a more secure and transparent financial future.
      </Text>

      <Text fw={500}>
        The Meysey Team
      </Text>
     

    </>
  )
}