import { BarChart } from '@mantine/charts';
import { Text, Paper, Group, Box, Divider, Space } from '@mantine/core';
import { ContactAnalysisState, ContactAnalysisStateConclusionTypeEnum } from '../../api/generated';
import dayjs from 'dayjs';

export function TransactionCountBarChart({ contactAnalysisState }: { contactAnalysisState: ContactAnalysisState }) {

  
  const conclusionData = contactAnalysisState
    .conclusions
    ?.find(conclusion => conclusion.type === ContactAnalysisStateConclusionTypeEnum.ContactHasMonthlyTransactionCountHistory)
    ?.data.yearMonthTimeSeries as unknown as (Record<string, { i: number, b: number, p: number }> | undefined);

  const pastTwelveMonths = Array.from({ length: 6 }, (_, i) => i).map(i => dayjs().subtract(6, 'month').add(i + 1, 'month'));

  const graphData = conclusionData && pastTwelveMonths.map(month => ({
    month: dayjs(month).format('MMM YY'),
    Invoices: conclusionData![dayjs(month).format('YYYY-MM')]?.i || 0,
    'Bank Transactions': conclusionData![dayjs(month).format('YYYY-MM')]?.p || 0,
    Bills: conclusionData![dayjs(month).format('YYYY-MM')]?.b || 0,
  }));  

  
  return (
    <Paper shadow="xs" radius="md" p="lg">
      <Group>
        <Text fz="lg" fw={500}>Recent Transaction Volume (Count)</Text>
        <Box flex="1" ta="right">
          {/* <Text fz="sm" c="dimmed">Meysey is working to match this Xero Contact record to a legal entity at Companies House</Text> */}
        </Box>
      </Group>
      <Divider mt="xs" />
      <Space h="md" />
      <BarChart
        h={300}
        data={graphData || []}
        dataKey="month"
        series={[
          { name: 'Invoices', color: 'violet.6' },
          { name: 'Bills', color: 'blue.6' },
          { name: 'Bank Transactions', color: 'teal.6' },
        ]}
        withYAxis={false}
        withLegend
      />
    </Paper>
  );
}