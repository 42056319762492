import { Paper, Divider, Stack, Group, Text, Table, Skeleton } from '@mantine/core';


import classes from './XeroBankTransactionRecord.module.css';
import { formatCurrency, formatDateMedium } from '../../lib/formatters';
import { Account, BankTransaction, TaxRate } from '../../api/generated';
import { QueryKey } from '../../queryKeys';
import { apiClient } from '../../api/apiClient';
import { useQuery } from '@tanstack/react-query';
import { sortBy } from 'lodash';
import dayjs from 'dayjs';
import { isSpend } from '../../lib/invoiceAnalysis/helpers';


export function XeroBankTransactionRecord({ bankTransaction, taxRates, accounts }: { bankTransaction: BankTransaction, taxRates: TaxRate[] | undefined, accounts: Account[] | undefined }) {


  const { data: bankTransactionHistory } = useQuery({
    queryKey: [QueryKey.GetBankTransactionHistory, bankTransaction.bankTransactionID],
    queryFn: async () => {
      const res = await apiClient.xero.getPaymentHistory(bankTransaction.bankTransactionID!);
      return res.data;
    }    
  });

  
  const reconciliationHistoryRecord = sortBy(bankTransactionHistory || [], h => -dayjs(h.dateUTC).unix()).find(h => h.changes === 'Reconciled');

  const statusContent = !bankTransactionHistory ? (
    <Skeleton h="1rem" animate />
   ) : (
    bankTransaction.isReconciled ? (
      <Text fz="sm" fw={500}>
        Reconciled by {reconciliationHistoryRecord?.user} on {formatDateMedium(reconciliationHistoryRecord?.dateUTC!)}
      </Text>
    ) : (
      <Text fz="sm" fw={500}>
        Unreconciled
      </Text>
    )
   );

  return (
    <Paper
      className={classes.xeroRecordPaper}
      shadow="xs"
      radius="md"
    >

      <div className={classes.xeroRecordTitle}>
        <Text fz="lg" fw={500}>Xero Record</Text>
        <Divider mt="xs" />
      </div>


      <div className={classes.xeroRecordGrid}>
        <Text my="lg" fw={500}>Bank Transaction {isSpend(bankTransaction) ? '(Spend)' : '(Receive)'}</Text>

        <Group gap="xl">
          <Stack>
            <div className={classes.xeroRecordLabel}>{isSpend(bankTransaction) ? `To` : `From`}</div>
            <div className={classes.xeroRecordValue}>{bankTransaction.contact?.name}</div>
          </Stack>
          <Stack>
            <div className={classes.xeroRecordLabel}>Date</div>
            <div className={classes.xeroRecordValue}>{formatDateMedium(bankTransaction.date!)}</div>
          </Stack>
          <Stack>
            <div className={classes.xeroRecordLabel}>Reference</div>
            <div className={classes.xeroRecordValue}>{bankTransaction.reference}</div>
          </Stack>
          <Stack>
            <div className={classes.xeroRecordLabel}>Total</div>
            <div className={classes.xeroRecordValue}>{formatCurrency(bankTransaction.total!, bankTransaction.currencyCode)}</div>
          </Stack>
          <Stack>
            <div className={classes.xeroRecordLabel}>Status</div>
            <div className={classes.xeroRecordValue}>
              {statusContent}
            </div>
          </Stack>
        </Group>

        {/* <Divider my="sm" />             */}
        <Group justify='flex-end' mt="xl" p="sm">
          <Text fz="sm">Amounts are tax exclusive</Text>
        </Group>

        <Table.ScrollContainer minWidth="800px">
          <Table withRowBorders captionSide='top' fz="xs" className={classes.lineItemTable}>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>
                  Item
                </Table.Th>
                <Table.Th>
                  Account
                </Table.Th>
                <Table.Th ta="right">
                  Quantity
                </Table.Th>
                <Table.Th ta="right">
                  Unit Price
                </Table.Th>
                <Table.Th ta="right">
                  Tax Rate
                </Table.Th>
                <Table.Th ta="right">
                  Amount
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {bankTransaction.lineItems?.map((lineItem, idx) => (
                <Table.Tr key={idx}>
                  <Table.Td>
                    {lineItem.description}
                  </Table.Td>
                  <Table.Td>
                    {!accounts ? (
                      <Skeleton h="1rem" animate />
                    ) : (
                      accounts.find(a => a.accountID === lineItem.accountID)?.name || ''
                    )}
                  </Table.Td>
                  <Table.Td ta="right">
                    {lineItem.quantity}
                  </Table.Td>
                  <Table.Td ta="right">
                    {formatCurrency((lineItem.unitAmount || 0) - ((lineItem.taxAmount || 0) / (lineItem.quantity || 1)), bankTransaction.currencyCode)}
                  </Table.Td>
                  <Table.Td ta="right">
                    {!taxRates ? (
                      <Skeleton h="1rem" animate />
                    ) : (
                      taxRates.find(tr => tr.taxType === lineItem.taxType)?.name || ''
                    )}
                  </Table.Td>
                  <Table.Td ta="right">
                    {formatCurrency((lineItem.lineAmount || 0) - (lineItem.taxAmount || 0), bankTransaction.currencyCode)}
                  </Table.Td>
                </Table.Tr>
              ))}
              <Table.Tr className={classes.totalsRow} fz="sm">
                <Table.Td colSpan={4}></Table.Td>
                <Table.Td ta="right">
                  Subtotal
                </Table.Td>
                <Table.Td ta="right">
                  {formatCurrency(bankTransaction.subTotal || 0, bankTransaction.currencyCode)}
                </Table.Td>
              </Table.Tr>
              <Table.Tr className={classes.totalsRow} fz="sm">
                <Table.Td colSpan={4}></Table.Td>
                <Table.Td ta="right">
                  Tax
                </Table.Td>
                <Table.Td ta="right">
                  {formatCurrency(bankTransaction.totalTax || 0, bankTransaction.currencyCode)}
                </Table.Td>
              </Table.Tr>
              <Table.Tr fz="lg" className={classes.totalsRow}>
                <Table.Td colSpan={4}></Table.Td>
                <Table.Td ta="right">
                  TOTAL
                </Table.Td>
                <Table.Td ta="right">
                  {formatCurrency(bankTransaction.total || 0, bankTransaction.currencyCode)}
                </Table.Td>
              </Table.Tr>

            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>



      </div>


    </Paper>
  );
}