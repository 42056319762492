import { Paper, Divider, Stack, Group, Text, Table, Skeleton } from '@mantine/core';


import classes from './XeroInvoiceRecord.module.css';
import { Account, Invoice, TaxRate } from '../../api/generated';
import { formatCurrency, formatDateMedium, formatNounForInvoiceType, formatXeroInvoiceStatus } from '../../lib/formatters';
import { getColorForXeroInvoiceStatus } from './helpers';
import { XeroInvoiceRecordPaymentRow } from './XeroInvoiceRecordPaymentRow';
import { isBill } from '../../lib/invoiceAnalysis/helpers';


export function XeroInvoiceRecord({ invoice, taxRates, accounts }: { invoice: Invoice, taxRates: TaxRate[] | undefined, accounts: Account[] | undefined }) {


  const renderFinalTotalLines = () => {

    return (
      <>
        <Table.Tr key={`${invoice.invoiceID}-total`} fz={(invoice.amountDue === invoice.total) ? 'lg' : 'sm'} className={classes.totalsRow}>
          <Table.Td colSpan={4}></Table.Td>
          <Table.Td ta="right">
            TOTAL
          </Table.Td>
          <Table.Td ta="right">
            {formatCurrency(invoice.total || 0, invoice.currencyCode)}
          </Table.Td>
        </Table.Tr>
        {invoice.payments?.map((payment, idx) => (
          <Table.Tr key={`${invoice.invoiceID}-payment-${idx}`} className={classes.totalsRow}>
            <Table.Td colSpan={4}></Table.Td>
            <Table.Td ta="right">
              Less Payment {formatDateMedium(payment.date!)}
            </Table.Td>
            <Table.Td ta="right">
              {formatCurrency(payment.amount!, invoice.currencyCode)}
            </Table.Td>
          </Table.Tr>
        ))}
        {invoice.creditNotes?.map((creditNote, idx) => (
          <Table.Tr key={`${invoice.invoiceID}-credit-${idx}`} className={classes.totalsRow}>
            <Table.Td colSpan={4}></Table.Td>
            <Table.Td ta="right">
              Less Credit Note {formatDateMedium(creditNote.date!)}
            </Table.Td>
            <Table.Td ta="right">
              {formatCurrency(creditNote.appliedAmount!, invoice.currencyCode)}
            </Table.Td>
          </Table.Tr>
        ))}
        {(invoice.amountDue !== invoice.total) && (
          <Table.Tr key={`${invoice.invoiceID}-amountDue`} fz={'lg'} className={classes.totalsRow}>
            <Table.Td colSpan={4}></Table.Td>
            <Table.Td ta="right">
              AMOUNT DUE
            </Table.Td>
            <Table.Td ta="right">
              {formatCurrency(invoice.amountDue || 0, invoice.currencyCode)}
            </Table.Td>
          </Table.Tr>
        )}
      </>
    );
  }

  return (
    <Paper
      className={classes.xeroRecordPaper}
      shadow="xs"
      radius="md"
    >

      <div className={classes.xeroRecordTitle}>
        <Text fz="lg" fw={500}>Xero Record</Text>
        <Divider mt="xs" />
      </div>


      <div className={classes.xeroRecordGrid}>
        <Text my="lg" fw={500}>{formatNounForInvoiceType(invoice.type!)}</Text>

        <Group gap="xl">
          <Stack>
            <div className={classes.xeroRecordLabel}>{isBill(invoice) ? 'From' : 'To'}</div>
            <div className={classes.xeroRecordValue}>{invoice.contact?.name}</div>
          </Stack>
          <Stack>
            <div className={classes.xeroRecordLabel}>Date</div>
            <div className={classes.xeroRecordValue}>{formatDateMedium(invoice.date!)}</div>
          </Stack>
          <Stack>
            <div className={classes.xeroRecordLabel}>Due Date</div>
            <div className={classes.xeroRecordValue}>{formatDateMedium(invoice.dueDate!)}</div>
          </Stack>
          <Stack>
            <div className={classes.xeroRecordLabel}>Reference</div>
            <div className={classes.xeroRecordValue}>{invoice.invoiceNumber || invoice.reference}</div>
          </Stack>
          <Stack>
            <div className={classes.xeroRecordLabel}>Total</div>
            <div className={classes.xeroRecordValue}>{formatCurrency(invoice.total!, invoice.currencyCode)}</div>
          </Stack>
          <Stack>
            <div className={classes.xeroRecordLabel}>Status</div>
            <div className={classes.xeroRecordValue}>
              <Text fz="sm" c={getColorForXeroInvoiceStatus(invoice.status)} tt="uppercase" fw={500}>{formatXeroInvoiceStatus(invoice.status!)}</Text>
            </div>
          </Stack>
        </Group>

        {/* <Divider my="sm" />             */}
        <Group justify='flex-end' mt="xl" p="sm">
          <Text fz="sm">Amounts are tax {isBill(invoice) ? `exclusive` : `inclusive`}</Text>
        </Group>

        <Table.ScrollContainer minWidth="800px">
          <Table withRowBorders captionSide='top'  fz="xs" className={classes.lineItemTable}>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>
                  Item
                </Table.Th>
                <Table.Th>
                  {isBill(invoice) ? (
                    'Account'
                   ) : (
                    ''
                   )}                    
                </Table.Th>
                <Table.Th ta="right">
                  Quantity
                </Table.Th>
                <Table.Th ta="right">
                  {isBill(invoice) ? `Unit Price` : `Price`}
                </Table.Th>                
                <Table.Th ta="right">
                  {isBill(invoice) ? `Tax Rate` : `Tax Amount`}
                </Table.Th>
                <Table.Th ta="right">
                  Amount
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {invoice.lineItems?.map((lineItem, idx) => (
                <Table.Tr key={idx}>
                  <Table.Td>
                    {lineItem.description}
                  </Table.Td>
                  <Table.Td>
                    {isBill(invoice) ? (
                        !accounts ? (
                        <Skeleton h="1rem" animate />
                      ) : (
                        accounts.find(a => a.accountID === lineItem.accountID)?.name || ''
                      )) : (
                      ``
                    )}
                  </Table.Td>
                  <Table.Td ta="right">
                    {lineItem.quantity}
                  </Table.Td>
                  <Table.Td ta="right">
                    {isBill(invoice) ? (
                      formatCurrency((lineItem.unitAmount || 0) - ((lineItem.taxAmount || 0) / (lineItem.quantity || 1)), invoice.currencyCode)
                    ) : (formatCurrency(lineItem.unitAmount || 0, invoice.currencyCode))}
                  </Table.Td>                 
                  <Table.Td ta="right">
                    {isBill(invoice) ? (
                      !taxRates ? (
                      <Skeleton h="1rem" animate />
                    ) : (
                      taxRates.find(tr => tr.taxType === lineItem.taxType)?.name || ''
                    )) : (
                      formatCurrency(lineItem.taxAmount || 0)
                    )}
                  </Table.Td>
                  <Table.Td ta="right">
                    {isBill(invoice) ? (
                      formatCurrency((lineItem.lineAmount || 0) - (lineItem.taxAmount || 0), invoice.currencyCode)
                    ) : (
                      formatCurrency(lineItem.lineAmount || 0, invoice.currencyCode)
                    )}
                  </Table.Td>
                </Table.Tr>
              ))}
              <Table.Tr className={classes.totalsRow} fz="sm">
                <Table.Td colSpan={4}></Table.Td>
                <Table.Td ta="right">
                  Subtotal
                </Table.Td>
                <Table.Td ta="right">
                  {formatCurrency(invoice.subTotal || 0, invoice.currencyCode)}
                </Table.Td>
              </Table.Tr>
              <Table.Tr className={classes.totalsRow} fz="sm">
                <Table.Td colSpan={4}></Table.Td>
                <Table.Td ta="right">
                  Tax
                </Table.Td>
                <Table.Td ta="right">
                  {formatCurrency(invoice.totalTax || 0, invoice.currencyCode)}
                </Table.Td>
              </Table.Tr>
              {renderFinalTotalLines()}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>

        <Text my="lg" fw={500}>Payments</Text>
        {(invoice.payments && invoice.payments.length && (
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>
                  Date
                </Table.Th>
                <Table.Th ta="right">
                  Amount
                </Table.Th>
                <Table.Th>
                  Status
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {invoice.payments.map((payment, idx) => (
                <XeroInvoiceRecordPaymentRow  key={idx} invoice={invoice} paymentId={payment.paymentID!} />
              ))}
            </Table.Tbody>
          </Table>

        )) || (
            <Text>Not on record</Text>
          )}

      </div>


    </Paper>
  );
}