import { Paper, Group, Divider, Table, Text, Center, rem, Stack } from '@mantine/core';
import { ContactAnalysisState, ContactAnalysisStateConclusion, ContactAnalysisStateConclusionTypeEnum } from '../../api/generated';
import { getCompanyMatchStateFromContactAnalysisConclusions } from '../../lib/contactAnalysis/getCompanyMatchStateFromContactAnalysisConclusions';
import { formatCompanyStatus, formatDateLong, formatDateMedium, formatNatureOfBusiness, formatPercentage, formatRegisteredOfficeAddressLong, formatRegisteredOfficeAddressMedium } from '../../lib/formatters';

import classes from './ContactDetailCompaniesHouseRecords.module.css';
import { IconAlertCircleFilled, IconCircleCheckFilled, IconExternalLink, IconHelpCircleFilled } from '@tabler/icons-react';
import { getCorrelatedConclusions } from '../../lib/contactAnalysis/getCorrelatedConclusions';
import { companiesHouseEnumerations } from '../../lib/companiesHouseEnumerations';
import { getCompaniesHouseCompanyOfficersHref, getCompaniesHouseCompanyProfileHref } from '../../lib/hrefs';

function CompaniesHouseRecordsTable({ uncertainMatches }: { uncertainMatches: ContactAnalysisStateConclusion[] }) {
  return (

    <Table className={classes.uncertainCompanyDetailTable} withTableBorder={false} highlightOnHover withRowBorders={false} striped>
      <Table.Thead fz="sm" c="dimmed">
        <Table.Tr visibleFrom="md">
          <Table.Th>
            Company Name
          </Table.Th>
          <Table.Th>
            Registered Office Address
          </Table.Th>
          <Table.Th>
            Company Status
          </Table.Th>
          <Table.Th>
          </Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {uncertainMatches?.map(conclusion => (
          <Table.Tr>
            <Table.Td>
              <Text hiddenFrom="md" fz="xs" c="dimmed">Company Name</Text>
              <Group align="flex-end" gap={rem(5)}>
                <Text fw={700}>{conclusion.data.seekCompanyResult?.companyName}</Text>
                <Text hiddenFrom="md"><a href={getCompaniesHouseCompanyProfileHref(conclusion.data.seekCompanyResult?.companyNumber!)}>
                  <IconExternalLink style={{ height: rem(15), width: rem(15) }} color="var(--mantine-color-blue-4)" />
                </a>
                </Text>
              </Group>
              <Group>
                <Text w={rem(50)} fw={500} fz="xs">{conclusion.data.seekCompanyResult?.companyNumber}</Text>
                <Text fz="xs">{formatPercentage(conclusion.confidence! - 0.01)} Match</Text>
              </Group>
            </Table.Td>
            {/* <Table.Td>
              {conclusion.data.seekCompanyResult?.companyNumber}
            </Table.Td> */}
            <Table.Td className={classes.uncertainCompanyDetailTableAddress}>
              <Text hiddenFrom="md" fz="xs" c="dimmed" flex={1}>Registered Office</Text>
              {formatRegisteredOfficeAddressMedium(conclusion.data.seekCompanyResult!.registeredOfficeAddress!)}
            </Table.Td>
            <Table.Td>
              <Text hiddenFrom="md" fz="xs" c="dimmed">Company Status</Text>
              {formatCompanyStatus(conclusion.data.seekCompanyResult?.companyStatus!)}
            </Table.Td>
            <Table.Td visibleFrom="md">
              <a href={getCompaniesHouseCompanyProfileHref(conclusion.data.seekCompanyResult?.companyNumber!)}>
                <IconExternalLink style={{ height: rem(15), width: rem(15) }} color="var(--mantine-color-blue-4)" />
              </a>
            </Table.Td>
          </Table.Tr>
        )
        )}
      </Table.Tbody>
    </Table>
  );
}


export function ContactDetailCompaniesHouseRecords({ contactAnalysisState }: { contactAnalysisState: ContactAnalysisState | undefined }) {





  let content: React.ReactNode;

  if (!contactAnalysisState) {
    content = (
      <Text mx="auto" my="xl">Not Yet Analysed</Text>
    );
  } else {

    const { satisfactoryMatch, uncertainMatches } = getCompanyMatchStateFromContactAnalysisConclusions(contactAnalysisState?.conclusions!);

    if (!satisfactoryMatch && !(uncertainMatches && uncertainMatches.length)) {
      content = <div className={`${classes.matchedCompanyDetailSide} ${classes.noMatchCompanyDetailSide}`}>
        <div className={classes.matchedCompanyDetailSideIconContainer}>
          <Center>
            <IconHelpCircleFilled className={classes.noMatchCompanyDetailSideIcon} />
          </Center>
          <Text className={classes.matchedCompanyDetailSideLabel}>Unable to Match</Text>
        </div>
        <Text ta="center" fz="xs">We were unable to match this contact to a legal entity. <br /> We'll keep trying as more information becomes available.</Text>
        <Text ta="center" mt="lg" c="dimmed" fz={rem(10)}><a href="#foo">Click here if this doesn't look right.</a></Text>
      </div>
    }


    if (!satisfactoryMatch && uncertainMatches && uncertainMatches.length) {
      content = (
        <>
          <div className={`${classes.matchedCompanyDetailMain} ${classes.uncertainCompanyDetailMain}`}>
            <CompaniesHouseRecordsTable uncertainMatches={uncertainMatches} />
          </div>
          <div className={classes.matchedCompanyDetailSide}>
            <div className={classes.matchedCompanyDetailSideIconContainer}>
              <Center>
                <IconAlertCircleFilled className={classes.uncertainCompanyDetailSideIcon} />
              </Center>
              <Text className={classes.matchedCompanyDetailSideLabel}>Multiple Matches</Text>
            </div>
            <Text ta="center" fz="xs">We found several legal entities matching this contact but aren't sure which is correct. <br /><br /> We'll monitor them all for now and narrow down as more information becomes available.</Text>
            <Text ta="center" mt="lg" c="dimmed" fz={rem(10)}><a href="#foo">Click here if this doesn't look right.</a></Text>
          </div>
        </>
      );
    }


    if (satisfactoryMatch) {
      const officers = getCorrelatedConclusions(satisfactoryMatch, contactAnalysisState.conclusions!).find(c => c.type === ContactAnalysisStateConclusionTypeEnum.CompanyHasOfficersCount)?.data.officers;
      const natureOfBusiness = getCorrelatedConclusions(satisfactoryMatch, contactAnalysisState.conclusions!).find(c => c.type === ContactAnalysisStateConclusionTypeEnum.CompanyHasNatureOfBusiness)?.data.natureOfBusiness;
      const previousNames = getCorrelatedConclusions(satisfactoryMatch, contactAnalysisState.conclusions!).find(c => c.type === ContactAnalysisStateConclusionTypeEnum.CompanyHasPreviousNames)?.data.previousNames;


      content = (
        <>
          <div className={classes.matchedCompanyDetailMain}>


            <div className={classes.matchedCompanyDetailTitle}>
              <div>
                <Text fz="xl" fw={700}>
                  {satisfactoryMatch.data.seekCompanyResult?.companyName}
                </Text>
                <Text fz="lg" fw={500}>
                  {satisfactoryMatch.data.seekCompanyResult?.companyNumber}
                </Text>
              </div>
              <div>
                <a href={getCompaniesHouseCompanyProfileHref(satisfactoryMatch.data.seekCompanyResult?.companyNumber!)}><IconExternalLink color="var(--mantine-color-blue-6)" /></a>
              </div>
            </div>
            <Stack flex={1}>
              <Group gap="xl">
                <div>
                  <Text fz="xs" c="dimmed">
                    Company Status
                  </Text>
                  <Text fz="sm">
                    {formatCompanyStatus(satisfactoryMatch.data.seekCompanyResult?.companyStatus!)}
                  </Text>
                </div>
                <div>
                  <Text fz="xs" c="dimmed">
                    Incorporated On
                  </Text>
                  <Text fz="sm">
                    {formatDateLong(satisfactoryMatch.data.seekCompanyResult?.dateOfCreation!)}
                  </Text>
                </div>
                {satisfactoryMatch.data.seekCompanyResult?.dateOfCessation && (
                  <div>
                    <Text fz="xs" c="dimmed">
                      Dissolved On
                    </Text>
                    <Text fz="sm">
                      {formatDateLong(satisfactoryMatch.data.seekCompanyResult?.dateOfCessation)}
                    </Text>
                  </div>
                )}
                <div>
                  <Text fz="xs" c="dimmed">
                    Registered Office Address
                  </Text>
                  <Text fz="sm">
                    {formatRegisteredOfficeAddressLong(satisfactoryMatch.data.seekCompanyResult!.registeredOfficeAddress!)}
                  </Text>
                </div>
              </Group>
              {natureOfBusiness && (
                <Group gap="xl">
                  <div>
                    <Text fz="xs" c="dimmed">
                      Nature of Business (SIC)
                    </Text>
                    {natureOfBusiness.map(nob => (
                      <Text fz="sm">
                        {formatNatureOfBusiness(nob)}
                      </Text>
                    ))}
                  </div>
                </Group>
              )}
              <Group gap="xl" align="flex-start">
                {officers && (<div>
                  <Text fz="xs" c="dimmed">
                    Current Officers
                  </Text>
                  <Group pr={50} gap="xl">
                    <div>
                      {officers.slice(0, 3).map(o => (
                        <Text fz="sm">{o.name}</Text>
                      ))}
                    </div>
                    <div>
                      {officers.slice(0, 3).map(o => (
                        <Text fz="sm" >{o.occupation || companiesHouseEnumerations.officer_role[o.officer_role]}</Text>
                      ))}
                    </div>
                  </Group>
                  {officers.length > 3 && (
                    <a href={getCompaniesHouseCompanyOfficersHref(satisfactoryMatch?.data.seekCompanyResult?.companyNumber!)} style={{ fontSize: rem(12), color: 'var(--mantine-color-blue-7)' }}> +{officers.length - 3} more</a>
                  )}
                </div>
                )}
                <div>
                  <Text fz="xs" c="dimmed">
                    Previous Company Names
                  </Text>
                  {((previousNames && previousNames.length) && (<Group gap="xl">
                    <div>
                      {previousNames.map(pn => (
                        <Text fz="sm">
                          {pn.name}
                        </Text>
                      ))}
                    </div>
                    <div>
                      {previousNames.map(pn => (
                        <Text fz="sm">
                          {formatDateMedium(pn.effectiveFrom)} → {formatDateMedium(pn.ceasedOn)}
                        </Text>
                      ))}
                    </div>
                  </Group>
                  )) || (
                      <Text fz="sm">
                        None
                      </Text>
                    )}
                </div>

              </Group>
            </Stack>
          </div>
          <div className={classes.matchedCompanyDetailSide}>
            <div className={classes.matchedCompanyDetailSideIconContainer}>
              <Center>
                <IconCircleCheckFilled className={classes.matchedCompanyDetailSideIcon} />
              </Center>
              <Text className={classes.matchedCompanyDetailSideLabel}>Strong Match</Text>
            </div>
            <Text ta="center" fz="xs">We've matched this contact to a legal entity based on the information available.</Text>
            <Text ta="center" mt="lg" c="dimmed" fz={rem(10)}><a href="#foo">Click here if this doesn't look right.</a></Text>
          </div>
        </>
      );
    }

  }


  return (
    <Paper id="companies-house-records" shadow="xs" radius="md" p="lg">
      <Group justify="space-between">
        <Text fz="lg" fw={500}>Companies House Records</Text>
        {/* <Text fz="sm" c="dimmed">Meysey is working to match this Xero Contact record to a legal entity at Companies House</Text> */}
      </Group>
      <Divider mt="xs" />
      <div className={classes.companyDetailRoot}>
        {content}
      </div>

    </Paper>
  );
}