import React from 'react';
import { Group, Text } from '@mantine/core';
import { apiClient } from '../../api/apiClient';
import { BankTransactionAnalysisStateConclusion, InvoiceAnalysisStateConclusion, NormalizedAttachment } from '../../api/generated';
import { AdobePdfViewer } from '../AdobePdfViewer';
import classes from './AttachmentDetail.module.css';
import { ExtractedAttachmentData } from '../../lib/invoiceAnalysis/types';
import { formatCurrency, formatDateMedium, formatPercentage } from '../../lib/formatters';

export function AttachmentDetail({ attachment, extractionConclusion }: { attachment: NormalizedAttachment, extractionConclusion: (InvoiceAnalysisStateConclusion | BankTransactionAnalysisStateConclusion) | undefined }) {

  let preview: React.ReactNode;

  if (attachment.mimeType === 'application/pdf') {
    preview = (
      <AdobePdfViewer url={apiClient.getXeroAttachmentProxyUrl(attachment.url)} fileName={attachment.fileName} fileId={attachment.extId} height={931} width={700} />
    );
  } else if (attachment.mimeType === 'image/jpeg' || attachment.mimeType === 'image/png') {
    preview = (
      <img src={apiClient.getXeroAttachmentProxyUrl(attachment.url)} alt={attachment.fileName} width={700} />
    );
  } else {
    preview = (
      <div style={{ width: 700, height: 400, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: "var(--mantine-color-gray-2)" }}>
        <Text>Preview is not available for this type of file.</Text>
      </div>
    );
  }

  const extractedData = (extractionConclusion?.data as any)?.attachmentData as ExtractedAttachmentData;




  // todo - for currency - validate as valid currency code and if its not, dont show currency sybmol
  return (
    <div className={classes.root}>
      {
        extractionConclusion ? (
          <div className={classes.fieldsWrapper}>
            <div className={classes.fieldWrapper}>
              <div className={classes.fieldLabel}>
                Issuer Company Name
              </div>
              <div className={classes.fieldValue}>
                {extractedData.supplierLegalEntityName || 'Not Detected'}
              </div>
            </div>
            <div className={classes.fieldWrapper}>
              <div className={classes.fieldLabel}>
                Issuer Company Number
              </div>
              <div className={classes.fieldValue}>
                {extractedData.supplierCompanyNumber || 'Not Detected'}
              </div>
            </div>
            <div className={classes.fieldWrapper}>
              <div className={classes.fieldLabel}>
                Issuer VAT Number
              </div>
              <div className={classes.fieldValue}>
                {extractedData.supplierVatNumber || 'Not Detected'}
              </div>
            </div>
            <div className={classes.fieldWrapper}>
              <div className={classes.fieldLabel}>
                Issuer Registered Address
              </div>
              <div className={classes.fieldValue}>
                {extractedData.supplierRegisteredAddress || 'Not Detected'}
              </div>
            </div>
            <div className={classes.fieldWrapper}>
              <div className={classes.fieldLabel}>
                Issuer Email
              </div>
              <div className={classes.fieldValue}>
                {extractedData.supplierEmail || 'Not Detected'}
              </div>
            </div>
            <div className={classes.fieldWrapper}>
              <div className={classes.fieldLabel}>
                Issuer Website
              </div>
              <div className={classes.fieldValue}>
                {extractedData.supplierWebsite || 'Not Detected'}
              </div>
            </div>
            <div className={classes.fieldWrapper}>
              <div className={classes.fieldLabel}>
                Invoice Number
              </div>
              <div className={classes.fieldValue}>
                {extractedData.invoiceNumber || 'Not Detected'}
              </div>
            </div>
            <div className={classes.fieldWrapper}>
              <div className={classes.fieldLabel}>
                Issue Date
              </div>
              <div className={classes.fieldValue}>
                {(extractedData.issueDate && formatDateMedium(extractedData.issueDate)) || 'Not Detected'}
              </div>
            </div>
            <div className={classes.fieldWrapper}>
              <div className={classes.fieldLabel}>
                Line Items
              </div>
              <div className={classes.fieldValue}>
                {extractedData.lineItems?.length || 'Not Detected'}
              </div>
            </div>
            <Group>
              <div className={classes.fieldWrapper}>
                <div className={classes.fieldLabel}>
                  Subtotal
                </div>
                <div className={classes.fieldValue}>
                  {(extractedData.subtotal && formatCurrency(extractedData.subtotal, extractedData.currencyCode)) || 'Not Detected'}
                </div>
              </div>
              <div className={classes.fieldWrapper}>
                <div className={classes.fieldLabel}>
                  VAT
                </div>
                <div className={classes.fieldValue}>
                  {(extractedData.vatAmount && formatCurrency(extractedData.vatAmount, extractedData.currencyCode)) || 'Not Detected'} {extractedData.vatRate && `@ ${formatPercentage(extractedData.vatRate)}`}
                </div>
              </div>
              <div className={classes.fieldWrapper}>
                <div className={classes.fieldLabel}>
                  Total
                </div>
                <div className={classes.fieldValue}>
                  {(extractedData.total && formatCurrency(extractedData.total, extractedData.currencyCode)) || 'Not Detected'}
                </div>
              </div>
            </Group>

            <div className={classes.fieldWrapper}>
              <div className={classes.fieldLabel}>
                Payment Bank Account
              </div>
              <div className={classes.fieldValue}>
                {
                  (extractedData.paymentBankAccountName || extractedData.paymentBankAccountNumber || extractedData.paymentBankAccountSortCode) ? (
                    <>
                      {extractedData.paymentBankAccountName && (<>{extractedData.paymentBankAccountName}<br /></>)}
                      {(extractedData.paymentBankAccountNumber && (<><Text span c="dimmed" fw={500}>A/N</Text> {extractedData.paymentBankAccountNumber}<br /></>)) || 'Not Detected'}
                      {(extractedData.paymentBankAccountSortCode && (<><Text span c="dimmed" fw={500}>Sort Code</Text> {extractedData.paymentBankAccountSortCode}</>)) || 'Not Detected'}
                    </>) : 'Not Detected'
                }
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.notProcessedWrapper}>
            <Text fw={500} mt="lg">Not Processed</Text>
            <Text fz="sm" ta="center">If this attachment is processed by Meysey, information extracted will be displayed here.</Text>
          </div>
        )}
      <div>
        {preview}
      </div>
    </div >
  );
}