import { Text, Container, Flex, Title, Button, Space, Center, Loader, Anchor, Stack } from '@mantine/core';

import classes from './SelectTenantView.module.css';
import { useContext } from 'react';
import { UserContext } from '../../userContext';
import { ConnectXeroButton } from '../ConnectXeroButton';
import { MutationKey, QueryKey } from '../../queryKeys';
import { apiClient } from '../../api/apiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IconPlus } from '@tabler/icons-react';
import { useSignOut } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase';

export function SelectTenantView() {

  const { user, clients, isLoading } = useContext(UserContext);

  const queryClient = useQueryClient();
  
  const [firebaseSignOut] = useSignOut(auth);
  
  const { mutate: logout } = useMutation({
    mutationKey: [MutationKey.Logout], 
    mutationFn: async () => {      
      await firebaseSignOut();
      await apiClient.user.logout(); 
    },
    onSuccess: () => {      
      queryClient.invalidateQueries();
    }
  });

  
  const { mutate: setActiveClient, isPending: setActiveClientIsPending } = useMutation({
    mutationKey: [MutationKey.SetActiveClient], 
    mutationFn: async (clientId: string) => {
      const res = await apiClient.client.setActiveClient({ clientId }); 
      return res.data;
    },
    onSuccess: (data) => {      
      queryClient.setQueryData([QueryKey.GetActiveClient, user!.id], data);
    }
  });

  if (isLoading) return (
    <Center mt={130}><Loader /></Center>
  );

  return (
    <Container size="xl" py="xl" mt="25dvh">
      {(clients && clients.length && (
        <>
          <Title className={classes.title}>Select a Xero Organisation</Title>
          <Space h="xl" />
          <Stack>
            <Flex m="auto" justify="center" wrap="wrap" gap="xl" w="75%">
              {clients?.map(client => (
                <Button variant="outline" size="xl" onClick={() => setActiveClient(client.id)} loading={setActiveClientIsPending}>
                  {client.name}
                </Button>
              ))}
            </Flex>
            <Space h="md" /> 
            <Center>
              <Stack>                
                <ConnectXeroButton type="client" size="md" variant="light" leftSection={<IconPlus size={14} />}>Connect Another Organisation</ConnectXeroButton>              
              </Stack>
            </Center>
          </Stack>
        </>
      )) || (
        <Container size={600}>
          <Text ta="center" size="lg">
            There are no Xero Organisations linked to your account. 
          </Text>                    
          <Space h="xl" />
          <Center>
            <Stack>
              <ConnectXeroButton type="client" size="md">Connect an Organisation</ConnectXeroButton>              
              <Text ta="center">or <Anchor onClick={() => logout()}>Log Out</Anchor></Text>
            </Stack>
          </Center>
        </Container>
      )}
    </Container>
  );
}