import { Text, Center, Container, Group, Loader, Space, Title } from '@mantine/core';
import { IconArrowNarrowLeft } from '@tabler/icons-react';
import { Link, useParams } from 'react-router-dom';

import classes from './InvoiceDetailView.module.css';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../queryKeys';
import { apiClient } from '../../api/apiClient';
import { UnableToLoadAlert } from '../UnableToLoadAlert';
import { formatDateTimeRelative, formatInvoiceTitle, formatNounForInvoiceType } from '../../lib/formatters';
import { XeroInvoiceRecord } from './XeroInvoiceRecord';
import { useContext } from 'react';
import { UserContext } from '../../userContext';
import { InvoiceDetailStatus } from './InvoiceDetailStatus';
import { InvoiceDetailAnalysisConclusionList } from './InvoiceDetailAnalysisConclusionList';
import { InvoiceDetailAttachments } from './InvoiceDetailAttachments';
import { isBill } from '../../lib/invoiceAnalysis/helpers';

export function InvoiceDetailView() {

  const { invoiceId } = useParams();

  if (!invoiceId) throw new Error('invoiceId undefined');

  const { activeClient } = useContext(UserContext);

  const { data: invoice, isPending: invoiceIsPending, isError: invoiceIsError } = useQuery({
    queryKey: [QueryKey.GetInvoice, activeClient?.id, invoiceId],
    queryFn: async () => {
      const res = await apiClient.xero.getInvoice(invoiceId);
      return res.data;
    }
  });
  

  const { data: invoiceAnalysisState, isPending: invoiceAnalysisStateIsPending } = useQuery({
    queryKey: [QueryKey.GetInvoiceAnalysisState, invoice?.invoiceID],
    queryFn: async () => {
      const res = await apiClient.analysis.getInvoiceAnalysisStateByXeroInvoiceId(invoice!.invoiceID!);
      return res.data;
    },
    enabled: !!invoice
  });


  const { data: taxRates } = useQuery({
    queryKey: [QueryKey.GetTaxTypes, activeClient?.id],
    queryFn: async () => {
      const res = await apiClient.xero.getTaxRates();
      return res.data;
    }
  });

  
  const { data: accounts } = useQuery({
    queryKey: [QueryKey.GetAccounts, activeClient?.id],
    queryFn: async () => {
      const res = await apiClient.xero.getAccounts();
      return res.data;
    }
  });


  if (invoiceIsPending || invoiceAnalysisStateIsPending) return (
    <Center mt={350}><Loader /></Center>
  );

  if (invoiceIsError || !invoice) return (
    <Center mt={250}>
      <UnableToLoadAlert />
    </Center>
  );


  return (
    <Container size="xl" py="xl">
      <Link to={`..?itemType=${isBill(invoice) ? 'bills' : 'invoices'}`} className={classes.backAnchor}>
        <IconArrowNarrowLeft className={classes.backAnchorIcon} />
        Bills & Invoices
      </Link>
      <Space h="sm" />
      <Group p="sm" justify="space-between">
        <div>
          <Title order={2}>{formatInvoiceTitle(invoice)}</Title>
          {invoice.contact?.name && (
            <Text tt="uppercase" c="dimmed" fw={700} fz="md">{invoice.contact.name}</Text>
          )}
        </div>
        <div>
          <Text fz="md" fw={500} c="dimmed">
            Last analysed
          </Text>
          <Text fz="lg">{invoiceAnalysisState?.lastAnalysisJobCompletedAt ? formatDateTimeRelative(invoiceAnalysisState?.lastAnalysisJobCompletedAt) : 'Not Analysed'}</Text>
        </div>
      </Group>
      <Text pl="sm" mt="md">
        Here's an in-depth look at what we've discovered about this Xero {formatNounForInvoiceType(invoice.type!)}.
      </Text>
      <Space h="xl" />

      <XeroInvoiceRecord invoice={invoice} taxRates={taxRates} accounts={accounts} />
      <Space h="xl" />

      <InvoiceDetailStatus invoice={invoice} invoiceAnalysisState={invoiceAnalysisState} />
      <Space h="xl" />

      <InvoiceDetailAttachments invoice={invoice} invoiceAnalysisState={invoiceAnalysisState} />
      <Space h="xl" />

      <InvoiceDetailAnalysisConclusionList invoice={invoice} invoiceAnalysisState={invoiceAnalysisState} />
      <Space h="xl" />

    </Container>
  );
}
