import { Container, Title, Text, Space, Group, Center, Loader, ActionIcon } from '@mantine/core';
import { MessageList } from './MessageList';
import { NoMessagesHeroText } from './NoMessagesHeroText';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import React, { useContext } from 'react';
import { apiClient } from '../../api/apiClient';
import { UserContext } from '../../userContext';
import { UnableToLoadAlert } from '../UnableToLoadAlert';
import { QueryKey } from '../../queryKeys';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

export function MessageCentreView() {

  const [desiredOffset, setDesiredOffset] = React.useState(0);
  const desiredLimit = 15;

  const { activeClient } = useContext(UserContext);

  const { data: messages, isError, isPending, isPlaceholderData } = useQuery({
    queryKey: [QueryKey.GetMessages, activeClient?.id, desiredLimit, desiredOffset],
    queryFn: async () => {
      const res = await apiClient.message.getMessages(desiredLimit, desiredOffset);
      return res.data;
    },
    placeholderData: keepPreviousData
  });


  let content: React.ReactNode;
  if (isError) {
    content = (
      <Center mt={250}>
        <UnableToLoadAlert />
      </Center>
    );
  } else if (isPending) {
    content = (
      <Center mt={250}><Loader /></Center>
    );
  } else if (messages && messages.data.length) {    


    const fromIdx = messages.offset || 1;
    const toIdx = ((messages.offset || 0) + (messages.limit || 0)) ? Math.min(((messages.offset || 0) + (messages.limit || 0)), messages.total) : messages.total;
    const maxIdx = messages.total;
    const prevEnabled = !isPlaceholderData && fromIdx > 1;
    const nextEnabled = !isPlaceholderData && messages.total > toIdx;
    const unreadCount = messages.unread;

    content = (
      <>
        {/* todo finish this */}
        {/* <Paper shadow="xs" p="sm" radius="md">
          <Group px="md" justify="space-between">
            <Text>For your attention</Text>
            <Group justify="center" gap="xl" style={{ flexGrow: 1 }}>
              <Anchor c="red">
                <Title order={4}>1 Unresolved Critical Alert</Title>
              </Anchor>
              {'•'}
              <Anchor c="yellow.8">
                <Title order={4}>4 Unread Important Messages</Title>
              </Anchor>
              {'•'}
              <Anchor c="blue.8">
                <Title order={4} c="blue.8">1 Unread Advisory Notice</Title>
              </Anchor>
            </Group>
          </Group>
        </Paper> */}
        <Space h="xl" />
        <Space h="xl" />
        <Group px="md" justify="space-between">
          <Group>
            <Text c="dimmed">{unreadCount} Unread</Text>
          </Group>
          <Group justify="flex-end" gap="xs">
            <Text c="dimmed">{fromIdx}-{toIdx} of {maxIdx}</Text>
            <ActionIcon 
              variant="subtle" 
              size="sm" 
              disabled={!prevEnabled} onClick={() => setDesiredOffset(desiredOffset - desiredLimit)}
            >
              <IconChevronLeft />
            </ActionIcon>
            <ActionIcon 
              variant="subtle" 
              size="sm" 
              disabled={!nextEnabled} 
              onClick={() => setDesiredOffset(desiredOffset + desiredLimit)}
              loading={isPlaceholderData}
            >
              <IconChevronRight />
            </ActionIcon>
          </Group>
        </Group>
        <MessageList messages={messages.data} />
      </>
    );
  } else {
    content = (
      <>
        <Space h="xl" />
        <NoMessagesHeroText />
      </>
    );
  }
 

  return (
    <Container size="xl" py="xl">
      <Title order={2}>Message Centre</Title>
      <Text mt="md">
        In the Message Centre, you'll find all your important updates and notifications. To explore specific messages or alerts, just select a topic.
      </Text>
      <Space h="xl" />
      <Space h="xl" />

      {/* todo add icons */}
      {content}


    </Container>
  );

}