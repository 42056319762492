import { Title, Text, Container, Center } from '@mantine/core';
import classes from './NoMessagesHeroText.module.css';
import { IconMailFast } from '@tabler/icons-react';

export function NoMessagesHeroText() {
  return (
    <Container className={classes.wrapper} size={1400}>
      <div className={classes.inner}>
        <Center className={classes.iconContainer}>
          <IconMailFast className={classes.icon} size="4rem" stroke={1}/>
        </Center>
        <Title className={classes.title}>
          No messages yet          
        </Title>

        <Container p={0} size={600}>          
          <Text size="lg" className={classes.description}>
            Your message centre is currently empty. As soon as there's something you need to know or take action on, you'll find it here. 
          </Text>
        </Container>
      </div>
    </Container>
  );
}