import { Auth, OAuthProvider, sendEmailVerification, signInWithPopup } from 'firebase/auth';


const xeroProvider = new OAuthProvider('oidc.xero');
xeroProvider.addScope('email');
xeroProvider.addScope('profile');

export async function signInWithXero(auth: Auth) {    
  const cred = await signInWithPopup(auth, xeroProvider);
  if (!cred.user.emailVerified) await sendEmailVerification(cred.user); 
}

