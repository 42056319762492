import { Container, Space, Title, Text, Center, Loader, Group, Avatar, SimpleGrid } from '@mantine/core';
import { QueryKey } from '../../queryKeys';
import { useQuery } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';
import { apiClient } from '../../api/apiClient';
import { UnableToLoadAlert } from '../UnableToLoadAlert';
import { IconArrowNarrowLeft } from '@tabler/icons-react';

import classes from './ContactDetailView.module.css'
import { getAvatarColorFromName } from '../../lib/getAvatarColorFromName';
import { getInitials } from '../../lib/getInitials';
import { XeroContactRecord } from './XeroContactRecord';
import dayjs from 'dayjs';
import { ContactDetailStatus } from './ContactDetailStatus';
import { ContactDetailCompaniesHouseRecords } from './ContactDetailCompaniesHouseRecords';
import { ContactDetailAnalysisConclusionList } from './ContactDetailAnalysisConclusionList';
import { TransactionValueAreaChart } from './TransactionValueAreaChart';
import { TransactionCountBarChart } from './TransactionCountBarChart';

export function ContactDetailView() {

  const { contactId } = useParams();

  if (!contactId) throw Error('contactId undefined');

  const { data: contact, isPending: contactIsPending, isError: contactIsError } = useQuery({
    queryKey: [QueryKey.GetContact, contactId],
    queryFn: async () => {
      const res = await apiClient.xero.getContact(contactId);
      return res.data;
    }
  });


  const { data: contactAnalysisState, isPending: analysisIsPending } = useQuery({
    queryKey: [QueryKey.GetAnalysisByContactId, contact?.contactID],
    queryFn: async () => {
      const res = await apiClient.analysis.getContactAnalysisStateByXeroContactId(contact!.contactID!);
      return res.data;
    },
    enabled: !!contact
  });

  if (contactIsPending || analysisIsPending) return (
    <Center mt={350}><Loader /></Center>
  );

  if (contactIsError || !contact) return (
    <Center mt={250}>
      <UnableToLoadAlert />
    </Center>
  );

  const customerSupplierLabel = contact.isCustomer ? 'Customer' : contact.isSupplier ? 'Supplier' : undefined;

  const contactLastAnalysed = contactAnalysisState?.lastAnalysisJobCompletedAt && dayjs(contactAnalysisState?.lastAnalysisJobCompletedAt).fromNow();

  return (
    <Container size="xl" py="xl">
      <Link to=".." className={classes.backAnchor}>
        <IconArrowNarrowLeft className={classes.backAnchorIcon} />
        Suppliers & Customers
      </Link>
      <Space h="sm" />
      <Group p="sm" justify="space-between">
        <Group>
          <Avatar size={40} mt="3px" radius={40} color={getAvatarColorFromName(contact.name)}>{getInitials(contact.name)}</Avatar>
          <div>
            <Title order={2}>{contact.name}</Title>
            {customerSupplierLabel && (
              <Text tt="uppercase" c="dimmed" fw={700} fz="xs">{customerSupplierLabel}</Text>
            )}
          </div>
        </Group>
        <div>
          <Text fz="md" fw={500} c="dimmed">
            Last analysed
          </Text>
          <Text fz="lg">{contactLastAnalysed || 'Not Analysed'}</Text>
        </div>
      </Group>
      <Text pl="lg" mt="md">
        Here's an in-depth look at what we've discovered about this Xero Contact, including transaction history and analysis highlights.
      </Text>

      <Space h="xl" />

      <XeroContactRecord contact={contact} />
      <Space h="xl" />

      <ContactDetailStatus contact={contact} contactAnalysisState={contactAnalysisState} />

      <Space h="xl" />

      <ContactDetailCompaniesHouseRecords contactAnalysisState={contactAnalysisState} />

      <Space h="xl" />

      <ContactDetailAnalysisConclusionList contact={contact} contactAnalysisState={contactAnalysisState} />

      <Space h="xl" />

      <SimpleGrid cols={{
      base: 1,
      lg: 2
    }}>
      {contactAnalysisState && <TransactionValueAreaChart contactAnalysisState={contactAnalysisState}  />}
      {contactAnalysisState && <TransactionCountBarChart contactAnalysisState={contactAnalysisState} />}
      </SimpleGrid>
       
    </Container>
  );
}
